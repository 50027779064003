import { Component, OnInit, signal } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { Router } from '@angular/router';
import { StaffStudentPermission } from '~ngx-shared/models';
import { AuthorizationService } from '~ngx-shared/authentication';
import { TenantService } from '~madrasa/services';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent } from '~ngx-shared/layout';
import { GraphQlColumnModel } from '~ngx-shared/graph-ql';
import { StudentListComponent } from '~madrasa/staff/components/student-list/student-list.component';

@UntilDestroy()
@Component({
  selector: 'app-course-invoice-list-page',
  standalone: true,
  imports: [BasePageComponent, TranslocoDirective, FormlyModule, StudentListComponent],
  templateUrl: './course-invoice-list-page.component.html',
  styleUrl: './course-invoice-list-page.component.scss'
})
export class CourseInvoiceListPageComponent implements OnInit {
  form = new FormGroup<any>({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.course_invoice_list'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  protected readonly StaffStudentPermission = StaffStudentPermission;

  readonly result = signal<{ [key: number]: number } | undefined>(undefined);
  readonly columns = signal<GraphQlColumnModel[]>([]);

  constructor(
    public authorizationService: AuthorizationService,
    private apollo: Apollo,
    public router: Router,
    public tenantService: TenantService
  ) {}

  ngOnInit(): void {
    this.columns.set([
      {
        label: 'course_price',
        path: 'id',
        type: 'currency',
        patchResult: (result: any) => this.result()?.[result.person_id],
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      }
    ]);

    this.fields = [
      FormlyUtil.createRow([
        FormlyUtil.createDatePickerField('date', {
          props: {
            required: true,
            view: 'month'
          }
        })
      ])
    ];

    this.submit = (formSaveModel: FormSaveModel) => {
      // const input = {
      //   ...formSaveModel.input
      // };

      return this.apollo.query<{ result: { person_id: number; course_price: number }[] }>({
        query: gql`
          query AccountingCoursePriceStudents($input_date: date!) {
            result: academy_calculate_students_course_price_by_date(
              args: { input_date: $input_date }
            ) {
              data
            }
          }
        `,
        variables: {
          input_date: FormlyUtil.toIsoDateString(formSaveModel.input.date)
        }
      });
    };
  }

  savedEvent(formSaveModel: FormSaveModel) {
    // Convert into an object with person_id as key and course_price as value, event.output.result is an array of objects
    // gets first element then come data array with person_id and course_price
    this.result.set(
      formSaveModel.output?.result?.[0]?.data?.reduce((acc: any, curr: any) => {
        acc[curr.person_id] = curr.course_price;
        return acc;
      }, {})
    );
  }
}
