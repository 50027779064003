import { Component, computed, effect, input, OnInit, signal, ViewChild } from '@angular/core';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DialogService } from 'primeng/dynamicdialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo, gql } from 'apollo-angular';
import { NgClass } from '@angular/common';
import { AccessRoleListComponent } from '~madrasa/access/components/access-role-list/access-role-list.component';
import { DialogUtil } from '~ngx-shared/utils/dialog.util';
import { CreateAccessFormDialogComponent } from '~madrasa/access/components/create-access-form-dialog/create-access-form-dialog.component';
import { GraphQLResult } from '~ngx-shared/graph-ql';
import {
  AccessRolePermission,
  CorePersonDataModel,
  StaffDirectorPermission,
  StaffManagerPermission,
  StaffOrganisatorPermission,
  StaffParentPermission,
  StaffStudentPermission,
  StaffTeacherPermission
} from '~ngx-shared/models';
import { BusyComponent } from '~ngx-shared/layout';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { AccessService } from '~ngx-shared/services';
import { ChangeEmailFormDialogComponent } from '~madrasa/access/components/change-email-form-dialog/change-email-form-dialog.component';
import { AuthorizationService } from '~ngx-shared/authentication';
import { UserService } from '~madrasa/services';

@UntilDestroy()
@Component({
  selector: 'app-access',
  standalone: true,
  imports: [
    AccessRoleListComponent,
    TranslocoDirective,
    ButtonDirective,
    NgxPermissionsModule,
    BusyComponent,
    DetailComponent,
    NgClass
  ],
  templateUrl: './access.component.html',
  styleUrl: './access.component.scss'
})
export class AccessComponent implements OnInit {
  @ViewChild('roleList') roleListComponent: AccessRoleListComponent;

  inputPersonId = input<number | undefined>(undefined, { alias: 'personId' });

  readonly personId = signal<number | undefined>(undefined);
  readonly personData = signal<CorePersonDataModel | undefined>(undefined);
  readonly isLoading = signal(true);
  readonly isAccessChanging = signal(false);
  readonly isEmailChanging = signal(false);
  readonly isPasswortResetting = signal(false);

  readonly hasAccess = computed(() => !!this.personData()?.user);
  readonly fields = computed<DetailField[]>(() => {
    const personData = this.personData();
    if (!personData) {
      return [];
    }
    return [
      {
        label: 'user_name',
        value: personData.user?.name
      },
      {
        label: 'email',
        value: personData.user?.email
      },
      {
        label: 'is_enabled',
        type: 'boolean',
        value: personData.user?.is_enabled
      }
    ];
  });

  constructor(
    private apollo: Apollo,
    public userService: UserService,
    private dialogService: DialogService,
    private accessService: AccessService,
    private translocoService: TranslocoService,
    public authorizationService: AuthorizationService
  ) {
    effect(
      () => {
        const inputPersonId = this.inputPersonId();
        if (inputPersonId) {
          this.personId.set(inputPersonId);
        }
      },
      { allowSignalWrites: true }
    );
    effect(
      () => {
        this._fetchPersonData(this.personId());
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    if (!this.personId()) {
      this.userService.user$.pipe(untilDestroyed(this)).subscribe(user => {
        const personId = user?.person_id;
        if (personId) {
          this.personId.set(personId);
        }
      });
    }
  }

  createAccess() {
    this.dialogService
      .open(CreateAccessFormDialogComponent, {
        ...DialogUtil.BASE_DIALOG_CONFIG,
        header: this.translocoService.translate('create_value', {
          value: this.translocoService.translate('access')
        }),
        data: {
          personData: this.personData(),
          addingRole: false
        }
      })
      .onClose.pipe(untilDestroyed(this))
      .subscribe(() => {
        this._fetchPersonData(this.personId());
        this.roleListComponent?.updateTable();
      });
  }

  addRole() {
    this.dialogService
      .open(CreateAccessFormDialogComponent, {
        ...DialogUtil.BASE_DIALOG_CONFIG,
        header: this.translocoService.translate('create_value', {
          value: this.translocoService.translate('access')
        }),
        data: {
          personData: this.personData(),
          addingRole: true
        }
      })
      .onClose.pipe(untilDestroyed(this))
      .subscribe(() => {
        this._fetchPersonData(this.personId());
        this.roleListComponent?.updateTable();
      });
  }

  changeAccess() {
    this.isAccessChanging.set(true);

    this.accessService
      .toggleAccess(this.personId())
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this._fetchPersonData(this.personId());
        this.isAccessChanging.set(false);
      });
  }

  changeEmail() {
    this.isEmailChanging.set(true);

    this.dialogService
      .open(ChangeEmailFormDialogComponent, {
        ...DialogUtil.BASE_DIALOG_CONFIG,
        header: this.translocoService.translate('change_value', {
          value: this.translocoService.translate('access')
        }),
        data: {
          id: this.personId(),
          email: this.personData()?.user?.email
        }
      })
      .onClose.pipe(untilDestroyed(this))
      .subscribe(() => {
        this._fetchPersonData(this.personId());
        this.isEmailChanging.set(false);
      });
  }

  resetPassword() {
    this.isPasswortResetting.set(true);

    this.accessService
      .resendAccess(this.personId())
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this._fetchPersonData(this.personId());
        this.isPasswortResetting.set(false);
      });
  }

  private _fetchPersonData(personId: number | undefined) {
    if (personId) {
      this.isLoading.set(true);
      this.apollo
        .query<GraphQLResult<CorePersonDataModel>>({
          query: gql`
            query ReadCoreCurrentPersonDataById($personId: bigint!) {
              result: core_current_person_data(where: { person_id: { _eq: $personId } }) {
                id
                person_id
                academic_degree_prefix
                academic_degree_suffix
                first_name
                last_name
                email_address

                ${
                  this.authorizationService.can(StaffManagerPermission.READ)
                    ? 'latest_manager { id }'
                    : ''
                }
                ${
                  this.authorizationService.can(StaffOrganisatorPermission.READ)
                    ? 'latest_organisator { id }'
                    : ''
                }
                ${
                  this.authorizationService.can(StaffDirectorPermission.READ)
                    ? 'latest_director { id }'
                    : ''
                }
                ${
                  this.authorizationService.can(StaffTeacherPermission.READ)
                    ? 'latest_teacher { id }'
                    : ''
                }
                ${
                  this.authorizationService.can(StaffStudentPermission.READ)
                    ? 'latest_student { id }'
                    : ''
                }
                ${
                  this.authorizationService.can(StaffParentPermission.READ)
                    ? 'latest_parent { id }'
                    : ''
                }

                user {
                  id
                  name
                  is_enabled
                  email
                  roles_active {
                    id
                    role
                  }
                }

                tenant {
                  id
                }
              }
            }
          `,
          variables: {
            personId
          }
        })
        .subscribe(queryResult => {
          this.personData.set(queryResult.data?.result?.[0]);
          this.isLoading.set(false);
        });
    }
  }

  protected readonly AccessRolePermission = AccessRolePermission;
}
