<div *transloco="let transloco">
  <quill-editor
    class="w-full"
    [formControl]="this.formControl"
    [formlyAttributes]="this.field"
    [ngClass]="{
      'ng-dirty ng-invalid p-invalid': this.showError
    }"
    [modules]="this.modules"
    [sanitize]="true"
    [styles]="{ height: '200px' }"
    [placeholder]="transloco(this.props.placeholder || '') || '&nbsp;'"
    [required]="this.props.required || false"
    [readOnly]="this.props.readonly || false">
  </quill-editor>
</div>
