import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export function detailGuard(parameterName: string): CanActivateFn {
  return (route, state) => {
    if (route.paramMap.keys.some(key => key.endsWith(parameterName))) {
      // Get parameter value
      // Can be a string with 'new' or a number
      const value = route.paramMap.get(parameterName);
      if (!isNaN(Number(value))) {
        return true;
      }
    }
    const router = inject(Router);
    return router.navigate(['/', 'not-found']);
  };
}
