import { Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { CorePersonDataModel } from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';

@Component({
  selector: 'app-person-detail-link',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './person-detail-link.component.html',
  styleUrl: './person-detail-link.component.scss'
})
export class PersonDetailLinkComponent {
  readonly person = input<CorePersonDataModel>();
  readonly showLink = input<boolean>(true);

  constructor(private translocoService: TranslocoService) {}

  getFullName(personDataModel: CorePersonDataModel | undefined) {
    return ModelUtil.getFullName(personDataModel, this.translocoService);
  }
}
