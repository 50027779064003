import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { map, of, switchMap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { TranslocoService } from '@jsverse/transloco';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { AcademyTeacherHourlyRateModel } from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';
import { FormlyTeacherHourlyRateTypeComponent } from '~madrasa/academy/components/formly-teacher-hourly-rate-type/formly-teacher-hourly-rate-type.component';

@UntilDestroy()
@Component({
  selector: 'app-create-update-teacher-hourly-rate-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-teacher-hourly-rate-form-page.component.html',
  styleUrl: './create-update-teacher-hourly-rate-form-page.component.scss'
})
export class CreateUpdateTeacherHourlyRateFormPageComponent implements OnInit {
  form = new FormGroup({});
  model: AcademyTeacherHourlyRateModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_teacher_hourly_rate'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  constructor(
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AcademyTeacherHourlyRateModel }>({
                query: gql`
                  query ReadAcademyTeacherHourlyRateById($id: bigint!) {
                    result: academy_teacher_hourly_rate_by_pk(id: $id) {
                      id
                      created_at
                      updated_at
                      name
                      description
                      columns
                      rows
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          let model = cloneDeep(result);
          if (model) {
            this.model = {
              ...model,
              table: {
                columns: model.columns,
                rows: model.rows?.map(row => {
                  return row.map((column, index) => {
                    return index === 0 ? column : column / 100;
                  });
                })
              }
            };
          }
        }

        this.fields = [
          {
            key: 'id'
          },
          FormlyUtil.createRow([
            FormlyUtil.createTextField('name', {
              props: {
                required: true
              }
            })
          ]),
          FormlyUtil.createRow([FormlyUtil.createTextField('description')]),
          FormlyUtil.createRow([
            {
              key: 'table',
              props: {
                required: true,
                description: 'table_description'
              },
              validators: {
                table: {
                  expression: (control: AbstractControl, field: FormlyFieldConfig) => {
                    const table = control.value as AcademyTeacherHourlyRateModel;
                    // Columns can't be empty and do not have duplicates
                    // Rows can't be empty, and it must have the same amount of columns and has content
                    return (
                      !!table &&
                      table?.columns &&
                      table?.columns?.length > 0 &&
                      table?.columns?.length === new Set(table.columns).size &&
                      table?.columns?.every(column => column && column.trim()) &&
                      table?.rows &&
                      table?.rows?.length > 0 &&
                      table?.rows?.every(row => row.length - 1 === table?.columns?.length) &&
                      table?.rows?.every(row => row.every(column => !isNaN(column)))
                    );
                  },
                  message: (error: any, field: FormlyFieldConfig) => {
                    const table = field.model.table as AcademyTeacherHourlyRateModel;

                    if (table?.columns?.length !== new Set(table.columns).size) {
                      return this.translocoService.translate(
                        this.options.formState.transloco + '.header_duplicates'
                      );
                    }

                    return this.translocoService.translate('validation.required');
                  }
                }
              },
              type: FormlyTeacherHourlyRateTypeComponent,
              wrappers: ['field-wrapper']
            }
          ])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,
        columns: formSaveModel.input.table.columns,
        // In the Database this is saved as integer[][].
        // Postgres needs a { } around the array and the inner arrays.
        rows: `{${formSaveModel.input.table.rows
          .map((row: number[]) => {
            return row.map((column, index) => {
              return index === 0 ? column : Math.round(column * 100);
            });
          })
          .map((row: number[]) => `{${row.join(',')}}`)
          .join(',')}}`
      };

      ModelUtil.deleteKey(input, 'table');

      let options: any = {
        mutation: gql`
          mutation CreateAcademyTeacherHourlyRate(
            $input: academy_teacher_hourly_rate_insert_input!
          ) {
            result: insert_academy_teacher_hourly_rate_one(object: $input) {
              __typename
            }
          }
        `,
        variables: {
          input
        }
      };

      if (this.model?.id) {
        ModelUtil.deleteKey(input);

        options = {
          mutation: gql`
            mutation UpdateAcademyTeacherHourlyRate(
              $id: bigint!
              $input: academy_teacher_hourly_rate_set_input!
            ) {
              result: update_academy_teacher_hourly_rate_by_pk(
                pk_columns: { id: $id }
                _set: $input
              ) {
                __typename
              }
            }
          `,
          variables: {
            id: this.model.id,
            input
          }
        };
      }

      return this.apollo.mutate(options);
    };
  }
}
