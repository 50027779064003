<div
  *transloco="let transloco"
  [ngClass]="{
    'p-inputgroup': this.props.iconPre || this.props.iconPost,
    'flex-col': !(this.props.iconPre || this.props.iconPost)
  }"
  class="flex">
  @if (this.props.iconPre) {
    <span class="p-inputgroup-addon">
      <i [class]="this.props.iconPre"></i>
    </span>
  }
  <p-inputMask
    [characterPattern]="this.props.characterPattern || '[A-Za-z]'"
    [formControl]="this.formControl"
    [formlyAttributes]="this.field"
    [mask]="this.props.mask"
    [ngClass]="{
      'ng-dirty ng-invalid p-invalid': this.showError
    }"
    [placeholder]="transloco(this.props.placeholder || '')"
    [readonly]="this.props.readonly"
    [slotChar]="this.props.slotChar || '_'"
    class="p-fluid">
  </p-inputMask>
  @if (this.props.iconPost) {
    <span class="p-inputgroup-addon">
      <i [class]="this.props.iconPost"></i>
    </span>
  }
</div>
