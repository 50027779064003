import { TranslocoService } from '@jsverse/transloco';
import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';

export class FormlyTranslocoExtension implements FormlyExtension {
  prePopulate(field: FormlyFieldConfig) {
    const props = field.props || {};
    if (props['_translated']) {
      return;
    }
    props['_translated'] = true;
    let label = props.label || (field.key ? String(field.key) : undefined);
    if (label && field.options?.formState?.transloco) {
      label = `${field.options?.formState?.transloco}.${label}`;
    }

    const fullPlaceholder = field.options?.formState?.transloco
      ? `${field.options?.formState?.transloco}.${props.placeholder || ''}`
      : props.placeholder || '';

    const placeholder = props.placeholder ? fullPlaceholder : props.required ? '' : 'optional';

    let description = props.description;
    if (description && field.options?.formState?.transloco) {
      description = `${field.options?.formState?.transloco}.${description}`;
    }

    field.expressions = {
      ...(field.expressions || {}),
      'props.label': () => label || '',
      'props.placeholder': () => placeholder || ''
    };

    if (!('props.description' in field.expressions)) {
      field.expressions['props.description'] = () => description || '';
    }
  }
}

function createTranslatableValidationMessage(name: string, translocoService: TranslocoService) {
  return {
    name,
    message: (error: any, field: FormlyFieldConfig) => {
      return translocoService.selectTranslate('validation.' + name, { value: field.props?.[name] });
    }
  };
}

export function registerFormlyTranslocoExtension(translocoService: TranslocoService) {
  return {
    validationMessages: [
      createTranslatableValidationMessage('required', translocoService),
      createTranslatableValidationMessage('email', translocoService),
      createTranslatableValidationMessage('pattern', translocoService),
      createTranslatableValidationMessage('requiredTrue', translocoService),
      createTranslatableValidationMessage('max', translocoService),
      createTranslatableValidationMessage('maxLength', translocoService),
      createTranslatableValidationMessage('min', translocoService),
      createTranslatableValidationMessage('minLength', translocoService)
    ],
    extensions: [
      {
        name: 'transloco',
        extension: new FormlyTranslocoExtension()
      }
    ]
  };
}
