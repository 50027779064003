import { Component, input } from '@angular/core';
import { NgStyle } from '@angular/common';
import { SpinnerComponent } from '~ngx-shared/layout';

@Component({
  selector: 'lib-busy',
  templateUrl: './busy.component.html',
  styleUrl: './busy.component.css',
  imports: [SpinnerComponent, NgStyle],
  standalone: true
})
export class BusyComponent {
  readonly height = input<string>('75vh');
  readonly isLoading = input.required<boolean>();
}
