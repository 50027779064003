import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgClass } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TeacherHourlyRateComponent } from '~madrasa/academy/components/teacher-hourly-rate/teacher-hourly-rate.component';
import { FieldWrapperProps } from '~ngx-shared/formly/field-wrapper/field-wrapper.component';

@Component({
  selector: 'app-formly-teacher-hourly-rate-type',
  standalone: true,
  imports: [
    TranslocoDirective,
    TeacherHourlyRateComponent,
    NgClass,
    FormlyModule,
    ReactiveFormsModule
  ],
  templateUrl: './formly-teacher-hourly-rate-type.component.html',
  styleUrl: './formly-teacher-hourly-rate-type.component.scss'
})
export class FormlyTeacherHourlyRateTypeComponent extends FieldType<
  FieldTypeConfig<FieldWrapperProps>
> {}
