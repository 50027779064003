import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { select } from '@ngneat/elf';
import { MenuItem } from 'primeng/api';
import { filter, map } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';
import { StoreService } from '~ngx-shared/services';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  breadcrumbStore = this.storeService.createStore<MenuItem[]>({
    name: 'breadcrumb',
    initial: [],
    persist: { storage: 'session' }
  });

  breadcrumbs = toSignal(this.breadcrumbStore.pipe(select(state => state)));

  constructor(
    private router: Router,
    private titleService: Title,
    private translocoService: TranslocoService,
    private storeService: StoreService
  ) {
    this.router.events
      .pipe(
        // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(event => {
        // Construct the breadcrumb hierarchy
        const breadcrumbs = this.getBreadcrumbRecursive(this.router.routerState.snapshot.root);
        this.translateBreadcrumbs(breadcrumbs);
        this.breadcrumbStore.update(() => breadcrumbs);
      });
    this.translocoService.events$
      .pipe(
        filter(event => event.type === 'translationLoadSuccess'),
        map(item => item['payload'])
      )
      .subscribe(() => {
        let breadcrumbs: MenuItem[] = [];

        if (Array.isArray(this.breadcrumbStore.value)) {
          breadcrumbs = [...this.breadcrumbStore.value];
        } else {
          Object.keys(this.breadcrumbStore.value).forEach(key => {
            breadcrumbs.push((this.breadcrumbStore.value as any)[key]);
          });
        }
        if (breadcrumbs.length) {
          this.translateBreadcrumbs(breadcrumbs);
          this.breadcrumbStore.update(() => breadcrumbs);
        }
      });
  }

  translateBreadcrumbs(breadcrumbs?: MenuItem[]) {
    breadcrumbs?.forEach((breadcrumb, index) => {
      if (breadcrumb.label) {
        breadcrumb.label = this.translocoService.translate(breadcrumb.state?.['breadcrumb'], {
          value: breadcrumb.state?.['breadcrumbParamValue']
            ? this.translocoService.translate(breadcrumb.state?.['breadcrumbParamValue'])
            : undefined
        });
      }
      if (breadcrumbs.length === index + 1) {
        this.titleService.setTitle(breadcrumb.label || 'MADRASA');
      }
    });
  }

  getBreadcrumbRecursive(
    activatedRoute: ActivatedRouteSnapshot,
    routerLink: string = ''
  ): MenuItem[] {
    const breadcrumbs: MenuItem[] = [];
    if (activatedRoute) {
      routerLink = [routerLink, ...activatedRoute.url.map(fUrl => fUrl.path)].join('/');
      if (activatedRoute.data?.['breadcrumb']) {
        breadcrumbs.push({
          label: this.translocoService.translate(activatedRoute.data?.['breadcrumb'], {
            value: activatedRoute.data?.['breadcrumbParamValue']
              ? this.translocoService.translate(activatedRoute.data?.['breadcrumbParamValue'])
              : undefined
          }),
          icon: activatedRoute.data?.['icon'],
          state: {
            breadcrumb: activatedRoute.data?.['breadcrumb'],
            breadcrumbParamValue: activatedRoute.data?.['breadcrumbParamValue']
          },
          routerLink
        });
      }
      if (activatedRoute.children?.length) {
        for (const fActivatedChild of activatedRoute.children) {
          if (fActivatedChild?.url?.length || fActivatedChild?.children?.length) {
            breadcrumbs.push(...this.getBreadcrumbRecursive(fActivatedChild, routerLink));
          }
        }
      }
    }
    return breadcrumbs;
  }
}
