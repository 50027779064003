import { Component, computed, inject } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap, tap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { TabViewModule } from 'primeng/tabview';
import { AcademySchoolPeriodModel } from '~ngx-shared/models';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { TabViewQueryDirective } from '~ngx-shared/directives';

@UntilDestroy()
@Component({
  selector: 'app-school-period-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    DetailComponent,
    TabViewModule,
    TabViewQueryDirective
  ],
  templateUrl: './school-period-detail-page.component.html',
  styleUrl: './school-period-detail-page.component.scss'
})
export class SchoolPeriodDetailPageComponent {
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);

  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{ result: AcademySchoolPeriodModel }>({
              query: gql`
                query ReadAcademySchoolPeriodById($id: bigint!) {
                  result: academy_school_period_by_pk(id: $id) {
                    id
                    created_at
                    updated_at
                    starts_at
                    ends_at
                    is_active
                    name
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result));
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );

  readonly fields = computed<DetailField[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    return [
      {
        label: 'id',
        value: item.id
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'starts_at',
        type: 'date',
        value: item.starts_at
      },
      {
        label: 'ends_at',
        type: 'date',
        value: item.ends_at
      },
      {
        label: 'is_active',
        type: 'boolean',
        value: item.is_active
      }
    ];
  });
}
