<div>
  <div class="flex flex-col">
    <p-table
      *transloco="let transloco"
      [value]="[{}]"
      responsiveLayout="scroll"
      styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm ">
      <ng-template pTemplate="header">
        <tr>
          <th class="w-1/12">
            <div class="flex items-center justify-center gap-2">
              <span class="block">{{ transloco('years') }}</span>
            </div>
          </th>
          @for (column of this.columns(); track column; let index = $index) {
            <th>
              <div class="flex items-center justify-center gap-2">
                <p-dropdown
                  #dropdown
                  (focusout)="this.updateColumnValue(dropdown.value, index)"
                  [ngModel]="column"
                  [options]="this.educationTypes()"
                  [placeholder]="'&nbsp;'"
                  [editable]="true"
                  appendTo="body">
                </p-dropdown>
                <button
                  (click)="this.removeColumn(index)"
                  [pTooltip]="transloco('delete')"
                  class="p-button-danger p-button-text"
                  icon="pi pi-trash"
                  pButton
                  tooltipPosition="top"
                  type="button"></button>
              </div>
            </th>
          }
          <th class="w-1/12">
            <div class="flex items-center justify-center">
              <button
                (click)="this.addColumn()"
                [label]="transloco('add_value', { value: transloco('column') })"
                class="p-button-primary p-button-text"
                icon="pi pi-plus"
                pButton
                type="button"></button>
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body">
        @for (row of this.rows(); track row; let rowIndex = $index) {
          <tr>
            <td>
              <span class="block text-center">{{ rowIndex }}</span>
            </td>
            @for (column of this.columns(); track column; let index = $index) {
              <td>
                <div class="p-inputgroup w-full">
                  <span class="p-inputgroup-addon">
                    <i [class]="'pi pi-euro'"></i>
                  </span>
                  <p-inputNumber
                    #cell
                    (focusout)="this.updateRowValue(cell.value, rowIndex, index + 1)"
                    [ngModel]="row[index + 1]"
                    [mode]="'decimal'"
                    [useGrouping]="false"
                    class="flex w-full"
                    maxFractionDigits="2"
                    currency="EUR"
                    locale="de-DE"
                    min="0"></p-inputNumber>
                </div>
              </td>
            }
            <td>
              <div class="flex items-center justify-center">
                <button
                  (click)="this.removeRow(rowIndex)"
                  [pTooltip]="transloco('delete')"
                  class="p-button-danger p-button-text"
                  icon="pi pi-trash"
                  pButton
                  tooltipPosition="top"
                  type="button"></button>
              </div>
            </td>
          </tr>
        }
        <tr>
          <td [colSpan]="(this.columns().length || 0) + 2">
            <div class="flex items-center justify-start">
              <button
                (click)="this.addRow()"
                [label]="transloco('add_value', { value: transloco('row') })"
                class="p-button-primary p-button-text"
                icon="pi pi-plus"
                pButton
                type="button"></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
