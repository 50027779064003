import { Component, computed, effect, input, signal } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { BusyComponent } from '~ngx-shared/layout';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { CorePersonDataModel, StaffStudentModel } from '~ngx-shared/models';

@Component({
  selector: 'app-parent-detail',
  standalone: true,
  imports: [BusyComponent, DetailComponent],
  templateUrl: './parent-detail.component.html',
  styleUrl: './parent-detail.component.scss'
})
export class ParentDetailComponent {
  readonly inputPersonData = input<CorePersonDataModel | undefined>(undefined, {
    alias: 'personData'
  });
  readonly isLoading = signal(true);
  readonly personData = signal<CorePersonDataModel | undefined>(undefined);

  readonly fields = computed<DetailField[]>(() => {
    const item = this.personData();
    if (!item?.latest_parent) {
      return [];
    }
    return [
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'organisation',
        value: item.organisation_persons_active?.map(opa => opa.organisation?.name).join(', ')
      },
      {
        label: 'school',
        value: item.latest_parent.school_parents_active?.map(spa => spa.school?.name).join(', ')
      },
      {
        label: 'status',
        translate: true,
        value: 'status.' + item.latest_parent.status
      }
    ];
  });

  constructor(private apollo: Apollo) {
    effect(
      () => {
        const personId = this.inputPersonData()?.person_id;
        if (personId) {
          this.isLoading.set(true);
          this.apollo
            .query<{ result: StaffStudentModel[] }>({
              query: gql`
                query ReadStaffParentById($personId: bigint!) {
                  result: staff_latest_parent(where: { person_id: { _eq: $personId } }, limit: 1) {
                    id
                    person_id
                    updated_at
                    status
                    school_parents_active {
                      school {
                        name
                      }
                    }
                  }
                }
              `,
              variables: {
                personId: personId
              }
            })
            .pipe(map(queryResult => queryResult.data?.result?.[0]))
            .subscribe(staffParent => {
              const personData = cloneDeep(this.inputPersonData());
              if (personData) {
                personData.latest_parent = staffParent;
                this.personData.set(personData);
              }
              this.isLoading.set(false);
            });
        }
      },
      { allowSignalWrites: true }
    );
  }
}
