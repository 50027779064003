import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Data } from '@angular/router';
import { FilterMetadata, PrimeTemplate } from 'primeng/api';
import { Table, TableLazyLoadEvent, TableModule, TableService } from 'primeng/table';
import { TranslocoPipe } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { NgTemplateOutlet } from '@angular/common';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  DataProvider,
  FilterMatchModeEnum,
  GraphQlFilterModel,
  GraphQlSortModel
} from '../../models';

@Component({
  selector: 'lib-graph-ql-table',
  templateUrl: './graph-ql-table.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./graph-ql-table.component.css'],
  providers: [
    TableService,
    {
      provide: Table,
      useFactory: (tableComponent: GraphQlTableComponent) => tableComponent.tableComponent,
      deps: [GraphQlTableComponent]
    }
  ],
  standalone: true,
  imports: [TableModule, PrimeTemplate, NgTemplateOutlet, ButtonDirective, TranslocoPipe]
})
export class GraphQlTableComponent<T extends { [key: string]: any } = any>
  implements OnInit, AfterContentInit
{
  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>;

  @ViewChild('table', { static: true }) tableComponent: Table;

  @Input() isPaginated = true;
  @Input() isLoading = true;
  @Input() showCurrentPageReport = true;
  @Input() stateStorage: 'session' | 'local' = 'session';
  @Input() stateKey: string | undefined;

  @Output() refreshed: EventEmitter<any> = new EventEmitter();

  tableData: T[] = [];
  totalRecords = -1;
  isLazy = true;
  isInit = false;
  sortBy?: GraphQlSortModel[];
  filter?: GraphQlFilterModel;
  headerTemplate: TemplateRef<any> | null = null;
  bodyTemplate: TemplateRef<any> | null = null;
  emptyTemplate: TemplateRef<any> | null = null;
  captionTemplate: TemplateRef<any> | null = null;
  footerTemplate: TemplateRef<any> | null = null;
  lastLazyLoadEvent: TableLazyLoadEvent;
  private offset = 0;

  private _dataProvider: DataProvider<T>;

  get dataProvider(): DataProvider<T> {
    return this._dataProvider;
  }

  @Input() set dataProvider(dataProvider: DataProvider<T>) {
    const temp = this._dataProvider;
    this._dataProvider = dataProvider;
    if (dataProvider != null && temp != dataProvider && this.isInit) {
      this.updateTable();
    }
  }

  private _limit = 10;

  get limit(): number {
    return this._limit;
  }

  @Input() set limit(limit: number) {
    const tempLimit = this._limit;
    this._limit = limit;
    if (limit != null && tempLimit != limit && this.isInit) {
      this.updateTable();
    }
  }

  ngOnInit(): void {
    this.isInit = true;
  }

  ngAfterContentInit(): void {
    this.templates.forEach(tableTemplate => {
      switch (tableTemplate.name) {
        case 'header':
          this.headerTemplate = tableTemplate.template;
          break;
        case 'body':
          this.bodyTemplate = tableTemplate.template;
          break;
        case 'empty':
          this.emptyTemplate = tableTemplate.template;
          break;
        case 'caption':
          this.captionTemplate = tableTemplate.template;
          break;
        case 'footer':
          this.footerTemplate = tableTemplate.template;
          break;
      }
    });
  }

  updateTable(): void {
    this.isLoading = true;
    this.dataProvider?.({
      limit: this.limit,
      offset: this.offset,
      sortBy: this.sortBy,
      filter: this.filter
    }).subscribe(data => {
      if (data) {
        this.tableData = data.result;
        this.totalRecords = data.aggregate?.aggregate.count || -1;
      } else {
        this.tableData = [];
        this.totalRecords = 0;
      }
      this.isLoading = false;
    });
  }

  loadData(event: TableLazyLoadEvent): void {
    this.lastLazyLoadEvent = event;
    this.offset = event.first!;
    this.limit = event.rows!;
    if (event.sortField && !Array.isArray(event.sortField)) {
      const sortBy: Data = {};
      const path = event.sortField.split('.');
      const last = path.pop();
      if (last) {
        path.reduce<Data>(function (tempWhere, tempKey) {
          return (tempWhere[tempKey] = tempWhere[tempKey] || {});
        }, sortBy)[last] = event.sortOrder && event.sortOrder > 0 ? 'asc' : 'desc';
        this.sortBy = [sortBy];
      }
    } else if (event.multiSortMeta && event.multiSortMeta.length > 0) {
      const sortBy: Data = {};
      for (const sortField of event.multiSortMeta) {
        const path = sortField.field.split('.');
        const last = path.pop();
        if (last) {
          path.reduce<Data>(function (tempWhere, tempKey) {
            return (tempWhere[tempKey] = tempWhere[tempKey] || {});
          }, sortBy)[last] = sortField.order && sortField.order > 0 ? 'asc' : 'desc';
        }
      }
      this.sortBy = [sortBy];
    } else {
      this.sortBy = undefined;
    }
    this.filter = {};
    if (event.filters) {
      const andArray: Data[] = [];
      this.filter['_and'] = andArray;
      Object.keys(event.filters).forEach(key => {
        if (event.filters && Array.isArray(event.filters[key])) {
          for (const fFilter of event.filters[key] as FilterMetadata[]) {
            if (
              fFilter &&
              fFilter.value &&
              (!Array.isArray(fFilter.value) || fFilter.value.length)
            ) {
              const matchMode = fFilter.matchMode as FilterMatchModeEnum;
              const filter: Data = {};
              const path = key.split('.');
              const last = path.pop();
              if (last) {
                path.reduce<Data>(function (tempWhere, tempKey) {
                  return (tempWhere[tempKey] = tempWhere[tempKey] || {});
                }, filter)[last] = GraphQlTableComponent.convertToOperation(
                  matchMode,
                  fFilter.value
                );

                andArray.push(filter);
              }
            }
          }
        }
      });
    }
    this.updateTable();
  }

  refresh() {
    this.loadData(this.lastLazyLoadEvent);
    this.refreshed.next(null);
  }

  static convertToOperation(matchMode?: FilterMatchModeEnum, value?: any): Data {
    let operation = {};
    if (matchMode) {
      switch (matchMode) {
        // text
        case FilterMatchModeEnum.STARTS_WITH:
          operation = { _ilike: `${value}%` };
          break;
        // text
        case FilterMatchModeEnum.CONTAINS:
          operation = { _ilike: `%${value}%` };
          break;
        // text
        case FilterMatchModeEnum.NOT_CONTAINS:
          operation = { _nilike: `%${value}%` };
          break;
        // text
        case FilterMatchModeEnum.ENDS_WITH:
          operation = { _ilike: `%${value}` };
          break;
        // text
        // numeric
        case FilterMatchModeEnum.EQUALS:
          operation = { _eq: value };
          break;
        // text
        // numeric
        case FilterMatchModeEnum.NOT_EQUALS:
          operation = { _neq: value };
          break;
        case FilterMatchModeEnum.IN:
          operation = { _in: value };
          break;
        // numeric
        case FilterMatchModeEnum.LESS_THAN:
          operation = { _lt: value };
          break;
        // numeric
        case FilterMatchModeEnum.LESS_THAN_OR_EQUAL_TO:
          operation = { _lte: value };
          break;
        // numeric
        case FilterMatchModeEnum.GREATER_THAN:
          operation = { _gt: value };
          break;
        // numeric
        case FilterMatchModeEnum.GREATER_THAN_OR_EQUAL_TO:
          operation = { _gte: value };
          break;
        case FilterMatchModeEnum.BETWEEN:
          break;
        case FilterMatchModeEnum.IS:
          break;
        case FilterMatchModeEnum.IS_NOT:
          break;
        case FilterMatchModeEnum.BEFORE:
          break;
        case FilterMatchModeEnum.AFTER:
          break;
        // date
        case FilterMatchModeEnum.DATE_IS:
          operation = { _eq: value };
          break;
        // date
        case FilterMatchModeEnum.DATE_IS_NOT:
          operation = { _neq: value };
          break;
        // date
        case FilterMatchModeEnum.DATE_BEFORE:
          operation = { _lt: value };
          break;
        // date
        case FilterMatchModeEnum.DATE_AFTER:
          operation = { _gt: value };
          break;
      }
    }
    return operation;
  }
}
