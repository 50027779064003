import { Component, computed, ElementRef, OnInit, signal, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AsyncPipe, NgClass, UpperCasePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AvatarModule } from 'primeng/avatar';
import { toSignal } from '@angular/core/rxjs-interop';
import { switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentlyOnlineComponent } from '~madrasa/access/components/currently-online/currently-online.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { UserService } from '~madrasa/services';
import { AuthenticationService, AuthorizationService, Role } from '~ngx-shared/authentication';
import { ModelUtil } from '~ngx-shared/utils';
import { HttpImgSrcPipe } from '~ngx-shared/pipes/html-img-src.pipe';
import { LayoutService } from '../services/layout.service';

@UntilDestroy()
@Component({
  selector: 'lib-layout-topbar',
  templateUrl: './layout-top-bar.component.html',
  standalone: true,
  imports: [
    RouterLink,
    NgClass,
    TranslocoDirective,
    TooltipModule,
    MenuModule,
    AvatarModule,
    UpperCasePipe,
    AsyncPipe,
    CurrentlyOnlineComponent,
    NgxPermissionsModule,
    HttpImgSrcPipe
  ]
})
export class LayoutTopBarComponent implements OnInit {
  readonly items = signal<MenuItem[]>([]);

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  readonly user = toSignal(this.userService.user$);
  readonly currentRole = toSignal(this.authorizationService.currentRole$);

  readonly fullName = computed(() => {
    return ModelUtil.getFullName(this.user()?.current_person_data, this.translocoService);
  });

  protected readonly Role = Role;

  constructor(
    public layoutService: LayoutService,
    public authenticationService: AuthenticationService,
    private userService: UserService,
    public authorizationService: AuthorizationService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.authorizationService.availableRoles$
      .pipe(
        untilDestroyed(this),
        switchMap(roles => {
          return this.translocoService
            .selectTranslate([
              'profile',
              'settings',
              'sign_out',
              'as',
              ...(roles?.map(role => {
                return 'roles.' + role;
              }) ?? [])
            ])
            .pipe(map(translated => [roles, translated]));
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        const items: MenuItem[] = [];

        result[0].forEach((role: string, index: number) => {
          items.push({
            visible: true,
            state: { role },
            label: result[1][3] + ' ' + result[1][index + 4],
            styleClass: this.currentRole() === role ? 'font-bold' : undefined,
            icon: 'pi pi-user',
            command: () => {
              this.setRole(role);
            }
          });
        });

        items.push(
          {
            label: result[1][1],
            icon: 'pi pi-cog',
            routerLink: ['/', 'staff', 'settings']
          },
          {
            label: result[1][2],
            icon: 'pi pi-sign-out',
            command: () => this.authenticationService.signOut()
          }
        );

        this.items.set(items);
      });
  }

  setRole(role: string) {
    this.authorizationService.setRole(role);
    document.location.reload();
  }
}
