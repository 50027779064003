import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  effect,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Data } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo, gql } from 'apollo-angular';
import { DocumentNode } from 'graphql/language';
import { isEqual, isNaN } from 'lodash-es';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { ObjectUtils } from 'primeng/utils';
import { debounceTime, distinctUntilChanged, of, Subject, Subscription, switchMap } from 'rxjs';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule } from '@angular/forms';
import { CurrencyPipe, DecimalPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonDirective } from 'primeng/button';
import { PrimeTemplate } from 'primeng/api';
import { TranslocoDirective, TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { TagModule } from 'primeng/tag';
import { utils, writeFileXLSX } from 'xlsx';
import { FileSizePipe, NgxFilesizeModule } from 'ngx-filesize';
import { TemplateUtil } from '~ngx-shared/utils';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  DataProviderOptionModel,
  GraphQlColumnModel,
  GraphQLResult,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { AuthorizationService } from '~ngx-shared/authentication';
import { DatePipe, DateTimePipe, TimePipe } from '~ngx-shared/pipes';
import { GraphQlService } from '../../services/graph-ql.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'lib-graph-ql-advanced-table',
  templateUrl: './graph-ql-advanced-table.component.html',
  styleUrls: ['./graph-ql-advanced-table.component.css'],
  standalone: true,
  imports: [
    TranslocoDirective,
    TableModule,
    PrimeTemplate,
    ButtonDirective,
    OverlayPanelModule,
    CdkDropList,
    CdkDrag,
    NgClass,
    CdkDragPlaceholder,
    CdkDragHandle,
    CheckboxModule,
    FormsModule,
    TooltipModule,
    NgTemplateOutlet,
    NgxPermissionsModule,
    NgStyle,
    DecimalPipe,
    CurrencyPipe,
    TranslocoPipe,
    DateTimePipe,
    DatePipe,
    TagModule,
    TimePipe,
    NgxFilesizeModule
  ]
})
export class GraphQlAdvancedTableComponent<T extends { [key: string]: any } = any>
  implements OnInit, AfterContentInit
{
  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>;

  @ViewChild('table', { static: true }) tableComponent: Table;

  readonly parentTemplates = input<QueryList<TemplateDirective>>();
  readonly graphQlTable = input.required<GraphQlTableModel>();
  readonly stateKey = input<string | undefined>(undefined);

  @Input() showColumnFilter: boolean = true;
  @Input() showExport: boolean = true;
  @Input() limit = 10;

  @Output() updated: EventEmitter<DataProviderOptionModel> = new EventEmitter();

  headerTemplate: TemplateRef<any> | null = null;
  actionsTemplate: TemplateRef<any> | null = null;
  bodyTemplate: TemplateRef<any> | null = null;
  emptyTemplate: TemplateRef<any> | null = null;
  captionTemplate: TemplateRef<any> | null = null;
  footerTemplate: TemplateRef<any> | null = null;
  headerTemplates: Map<string, TemplateRef<any>> | null = null;
  cellTemplates: Map<string, TemplateRef<any>> | null = null;
  tableData: T[] = [];
  totalRecords = -1;
  isLazy = true;
  isLoading = true;
  offset = 0;
  option?: DataProviderOptionModel;
  outputFragment: DocumentNode;
  isPrinting = false;
  lastLazyLoadEvent: TableLazyLoadEvent;
  isUpdatingState = false;
  subscription: Subscription | undefined;

  private _updateTable$ = new Subject<DataProviderOptionModel | undefined>();

  constructor(
    public authorizationService: AuthorizationService,
    private translocoService: TranslocoService,
    private apollo: Apollo,
    private graphQlService: GraphQlService
  ) {
    effect(() => {
      if (this.graphQlTable()) {
        this.updateTable();
      }

      if (this.stateKey()) {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        this.subscription = this.graphQlService
          .state$(this.stateKey()!)
          .pipe(untilDestroyed(this))
          .subscribe(state => {
            let changed = false;
            if (!this.isUpdatingState) {
              // Compare options
              if (this.option && state?.option) {
                if (!isEqual(state?.option.limit, this.option.limit)) {
                  this.limit = state.option.limit;
                  changed = true;
                }
                if (!isEqual(state?.option.offset, this.option.offset)) {
                  this.offset = state.option.offset;
                  changed = true;
                }
                if (!isEqual(state?.option.sortBy, this.option.sortBy)) {
                  this.option.sortBy = state.option.sortBy;
                  changed = true;
                }
                if (!isEqual(state?.option.filter, this.option.filter)) {
                  this.option.filter = state.option.filter;
                  changed = true;
                }
              }
              // Change columns order and visibility on graphQlTable
              if (state?.columns?.length) {
                if (this.graphQlTable().columns?.length) {
                  // Patch order and visibility, if column is not in state, it will be added at the end
                  const columns = state.columns
                    .map(stateColumn => {
                      const column = this.graphQlTable().columns.find(
                        fColumn =>
                          (fColumn.path && fColumn.path === stateColumn.path) ||
                          (fColumn.label && fColumn.label === stateColumn.label)
                      );
                      if (column) {
                        column.hidden = stateColumn.hidden;
                        return column;
                      }
                      return undefined;
                    })
                    .filter((column): column is GraphQlColumnModel => !!column);

                  this.graphQlTable().columns.forEach(column => {
                    if (
                      !columns.find(
                        fColumn =>
                          (fColumn.path && fColumn.path === column.path) ||
                          (fColumn.label && fColumn.label === column.label)
                      )
                    ) {
                      columns.push(column);
                    }
                  });
                  this.graphQlTable().columns = columns;
                }
              }
            }
            if (changed) {
              this.updateTable();
            }
          });
      }
    });
  }

  private _patchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;

  @Input() get patchOptions():
    | ((options: DataProviderOptionModel) => DataProviderOptionModel)
    | undefined {
    return this._patchOptions;
  }

  set patchOptions(
    value: ((options: DataProviderOptionModel) => DataProviderOptionModel) | undefined
  ) {
    this._patchOptions = value;
    if (value) {
      this.updateTable();
    }
  }

  ngOnInit(): void {
    this._updateTable$
      .pipe(
        distinctUntilChanged(),
        debounceTime(300),
        untilDestroyed(this),
        switchMap(option => {
          if (this.graphQlTable().table && this.outputFragment) {
            this.isLoading = true;

            // Save table state
            this.isUpdatingState = true;
            this.graphQlService.updateLimitAndOffset(this.stateKey(), this.limit, this.offset);
            this.isUpdatingState = false;

            if (this.patchOptions && option) {
              option = this.patchOptions(option);
            }

            return this.apollo
              .query<GraphQLResult<T>>({
                query: gql`
                ${this.outputFragment}
                query ${this.graphQlTable().table?.toUpperCase() + '_QUERY'}(
                  $limit: Int!
                  $offset: Int!
                  $filter: ${this.graphQlTable().table}_bool_exp = {}
                  $sortBy: [${this.graphQlTable().table}_order_by!] = []
                ) {
                  result: ${this.graphQlTable().table} (
                    limit: $limit
                    offset: $offset
                    where: $filter
                    order_by: $sortBy
                  ) {
                    ...OutputFragment
                  }
                  aggregate: ${this.graphQlTable().table}_aggregate(where: $filter) {
                    aggregate {
                      count
                    }
                  }
                }
                `,
                variables: option
              })
              .pipe(untilDestroyed(this));
          }
          return of(undefined);
        })
      )
      .subscribe(queryResult => {
        if (queryResult && queryResult.data) {
          this.tableData = queryResult.data.result;
          this.totalRecords = queryResult.data.aggregate?.aggregate.count || -1;
        } else {
          this.tableData = [];
          this.totalRecords = 0;
        }
        this.isLoading = false;
      });
  }

  ngAfterContentInit(): void {
    const templatesList = [this.templates];
    const parentTemplates = this.parentTemplates();
    if (parentTemplates) {
      templatesList.push(parentTemplates);
    }

    TemplateUtil.setTemplates(
      this,
      templatesList,
      [
        {
          template: 'headerTemplate',
          name: 'header'
        },
        {
          template: 'bodyTemplate',
          name: 'body'
        },
        {
          template: 'actionsTemplate',
          name: 'actions'
        },
        {
          template: 'captionTemplate',
          name: 'caption'
        },
        {
          template: 'emptyTemplate',
          name: 'empty'
        },
        {
          template: 'footerTemplate',
          name: 'footer'
        }
      ],
      [
        {
          templates: 'headerTemplates',
          name: 'Header'
        },
        {
          templates: 'cellTemplates',
          name: 'Cell'
        }
      ]
    );
  }

  updateTable(): void {
    this._buildFragment();

    this.option = {
      ...this.option,
      limit: this.limit,
      offset: this.offset
    };

    this._updateTable$.next(this.option);
  }

  loadData(event: TableLazyLoadEvent): void {
    this.lastLazyLoadEvent = event;
    this.offset = event.first!;
    this.limit = event.rows!;

    this.updateTable();
  }

  dropField(event: CdkDragDrop<GraphQlColumnModel[]>) {
    moveItemInArray(this.graphQlTable().columns || [], event.previousIndex, event.currentIndex);
    this.saveColumnVisibilityAndOrder();
  }

  checkBoxChanged(event: CheckboxChangeEvent, index: number) {
    event.originalEvent?.preventDefault();
    event.originalEvent?.stopPropagation();

    if (this.graphQlTable().columns?.[index]) {
      this.graphQlTable().columns[index].hidden = !event.checked;
      this.saveColumnVisibilityAndOrder();
      this.updateTable();
    }
  }

  saveColumnVisibilityAndOrder() {
    // Save column order
    this.isUpdatingState = true;
    this.graphQlService.updateColumns(this.stateKey(), this.graphQlTable().columns || []);
    this.isUpdatingState = false;
  }

  getValue(data: any, column: GraphQlColumnModel): any {
    if (column.patchResult) {
      return column.patchResult(data, column);
    }
    let value = TemplateUtil.getValueAsString(data, column.path);

    if (value) {
      if (column.prefix) {
        value = column.prefix + value;
      }
      if (column.suffix) {
        value = value + column.suffix;
      }
    }
    return value;
  }

  printTable() {
    if (!this.isPrinting) {
      this.isPrinting = true;
      const translatedHeader = this.graphQlTable()
        .columns.filter(
          fColumn => !fColumn.hidden && !fColumn.isNotSelectable && !fColumn.isNotExportable
        )
        .map(fColumn => this.translocoService.translate(fColumn.label || fColumn.path || ''));
      const header = this.graphQlTable()
        .columns.filter(
          fColumn => !fColumn.hidden && !fColumn.isNotSelectable && !fColumn.isNotExportable
        )
        .map(fColumn => fColumn.label || fColumn.path || '');

      let option: any = {
        filter: this.option?.filter,
        sortBy: this.option?.sortBy
      };
      if (this.patchOptions) {
        option = this.patchOptions(option);
      }

      // Query data
      this.apollo
        .query<GraphQLResult<T>>({
          query: gql`
            ${this.outputFragment}
            query ${this.graphQlTable().table?.toUpperCase() + '_QUERY'}(
              $filter: ${this.graphQlTable().table}_bool_exp = {}
              $sortBy: [${this.graphQlTable().table}_order_by!] = []
            ) {
              result: ${this.graphQlTable().table} (
                where: $filter
                order_by: $sortBy
              ) {
                ...OutputFragment
              }
            }
          `,
          variables: option
        })
        .pipe(untilDestroyed(this))
        .subscribe(queryResult => {
          if (queryResult && queryResult.data) {
            const data = queryResult.data.result.map((fData: any) => {
              let data: any = {};
              this.graphQlTable()
                .columns.filter(
                  fColumn => !fColumn.hidden && !fColumn.isNotSelectable && !fColumn.isNotExportable
                )
                .forEach(fColumn => {
                  let result = '';
                  let value = this.getValue(fData, fColumn);
                  if (fColumn.patchResult) {
                    value = String(fColumn.patchResult(fData, fColumn));
                  }

                  switch (this.getType(fColumn.type, fData)) {
                    // DO NOT FORGET IN TEMPLATE
                    case 'number':
                      if (!!value && !isNaN(Number(value))) {
                        const decimalPipe = new DecimalPipe('de_AT');
                        result = String(
                          decimalPipe.transform(value, fColumn.typeOptions)
                        ).replaceAll(' ', '');
                      } else {
                        result = '';
                      }
                      break;
                    case 'date':
                      const libDatePipe = new DatePipe();
                      result = libDatePipe.transform(value);
                      break;
                    case 'datetime':
                      const dateTimePipe = new DateTimePipe();
                      result = dateTimePipe.transform(value);
                      break;
                    case 'time':
                      const timePipe = new TimePipe();
                      result = timePipe.transform(value);
                      break;
                    case 'currency':
                      if (!!value && !isNaN(Number(value))) {
                        const decimalPipe = new DecimalPipe('de_AT');
                        result = String(
                          decimalPipe.transform(value / 100, fColumn.typeOptions)
                        ).replaceAll(' ', '');
                      } else {
                        result = '';
                      }
                      break;
                    case 'boolean':
                      if (value) {
                        result = this.translocoService.translate('true');
                      } else {
                        result = this.translocoService.translate('false');
                      }
                      break;
                    case 'filesize':
                      const fileSizePipe = new FileSizePipe();
                      result = fileSizePipe.transform(value, { separator: ',' }) as string;
                      break;
                    default:
                      result = value as string;
                  }

                  if (fColumn.translate) {
                    result = this.translocoService.translate(result);
                  }
                  data[fColumn.label || fColumn.path || ''] = result?.trim();
                });

              return data;
            });

            // Create xlsx
            const ws = utils.json_to_sheet(data, { header });
            // Replace header
            utils.sheet_add_aoa(ws, [translatedHeader!], { origin: 'A1' });
            // Create workbook and append worksheet
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, 'Export');
            // Export to XLSX
            writeFileXLSX(wb, 'Export.xlsx');
          }
          this.isPrinting = false;
        });
    }
  }

  getType(
    type?:
      | 'number'
      | 'date'
      | 'datetime'
      | 'time'
      | 'currency'
      | 'boolean'
      | 'filesize'
      | ((
          value: any
        ) => 'number' | 'date' | 'datetime' | 'time' | 'currency' | 'boolean' | 'filesize'),
    data?: any
  ) {
    if (ObjectUtils.isFunction(type)) {
      // @ts-ignore
      return type(data);
    }
    return type;
  }

  private _buildFragment() {
    if (this.graphQlTable().table) {
      this.outputFragment = gql`
      fragment OutputFragment on ${this.graphQlTable().table} {
        ${this._buildOutput().join('\n')}
      }
    `;
    }
  }

  private _buildOutput(): string[] {
    const output: string[] = [];
    this.graphQlTable().columns?.forEach(column => {
      if (!column.hidden) {
        if (column.query) {
          if (ObjectUtils.isFunction(column.query)) {
            // @ts-ignore
            output.push(column.query());
          } else if (typeof column.query === 'string') {
            output.push(column.query);
          }
        } else if (column.path?.includes('.')) {
          const object: Data = {};
          const path = column.path.split('.') as string[];
          const last = path.pop();
          if (last) {
            path.reduce<Data>(function (tempWhere: any, tempKey: any) {
              return (tempWhere[tempKey] = tempWhere[tempKey] || {});
            }, object)[last] = 'null';
            output.push(
              JSON.stringify(object)
                .replace(/"([^"]+)":/g, '$1:')
                .replace(/:"null"/g, '')
                .replace(/:\{/g, '{')
                .slice(1)
                .slice(0, -1)
            );
          }
        } else if (column.path) {
          output.push(column.path);
        }
      }
    });
    return output;
  }
}
