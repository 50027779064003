<lib-layout-base-page
  *transloco="let transloco"
  [titleParamValue]="this.item()?.course_name?.name || this.item()?.name">
  <div class="flex flex-col">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('general_information') }}">
        <ng-template pTemplate="content">
          <app-course-detail [id]="this.item()?.id"></app-course-detail>
        </ng-template>
      </p-tabPanel>

      @if (this.item()?.id) {
        <p-tabPanel header="{{ transloco('students') }}">
          <ng-template pTemplate="content">
            <app-student-list
              [columns]="this.studentColumns()"
              [patchOptions]="this.studentPatchOptions"
              [stateKey]="this.router.url + 'course-detail-student-list'">
            </app-student-list>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="{{ transloco('teachers') }}">
          <ng-template pTemplate="content">
            <app-person-list
              [columns]="this.teacherColumns()"
              [patchOptions]="this.teacherPatchOptions"
              [showDetailButton]="this.authorizationService.cannot(Role.TEACHER)"
              [showExport]="true"
              [showFilter]="true"
              [showSort]="true"
              [stateKey]="this.router.url + 'course-detail-teacher-list'">
            </app-person-list>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="{{ transloco('units') }}">
          <ng-template pTemplate="content">
            <app-unit-list
              [patchOptions]="this.unitPatchOptions"
              [showExport]="true"
              [showFilter]="true"
              [showSort]="true"
              [stateKey]="this.router.url + 'course-detail-unit-list'"></app-unit-list>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="{{ transloco('class_book_entries') }}">
          <ng-template pTemplate="content">
            <app-class-book-entry-list
              [patchOptions]="this.cbePatchOptions"
              [showExport]="true"
              [showFilter]="true"
              [showSort]="true"
              [stateKey]="this.router.url + 'course-detail-cbe-list'"></app-class-book-entry-list>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel
          *ngxPermissionsOnly="this.AcademyCoursePermission.CREATE"
          header="{{ transloco('notes') }}">
          <ng-template pTemplate="content">
            <app-note-list
              [patchInput]="this.notePatchInput"
              [patchOptions]="this.notePatchOptions"
              [stateKey]="this.router.url + 'course-detail-note-list'"
              [table]="'note_course_note'"></app-note-list>
          </ng-template>
        </p-tabPanel>
      }
    </p-tabView>
  </div>
</lib-layout-base-page>
