import { Component, effect, input, OnInit, ViewChild } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { RouterLink } from '@angular/router';
import { gql } from 'apollo-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AsyncPipe } from '@angular/common';
import { CoreTenantModel } from '~ngx-shared/models';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  DataProviderOptionModel,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { BasePageComponent, ConfirmationService } from '~ngx-shared/layout';
import { TenantService } from '~madrasa/services';
import { AccessService } from '~ngx-shared/services';

@UntilDestroy()
@Component({
  selector: 'app-tenant-list-page',
  standalone: true,
  imports: [
    BasePageComponent,
    GraphQlAdvancedTableComponent,
    TranslocoDirective,
    ButtonDirective,
    TooltipModule,
    RouterLink,
    TemplateDirective,
    AsyncPipe
  ],
  templateUrl: './tenant-list-page.component.html',
  styleUrl: './tenant-list-page.component.scss'
})
export class TenantListPageComponent implements OnInit {
  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  graphQlTable: GraphQlTableModel;

  constructor(
    public tenantService: TenantService,
    private accessService: AccessService,
    private confirmationService: ConfirmationService
  ) {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }
        return options;
      };
    });
  }

  ngOnInit() {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        type: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        sort: {
          isSortable: true
        },
        filter: {
          type: 'number'
        }
      },
      {
        path: 'currently_active',
        query: 'id',
        classHeader: 'text-center',
        classBody: 'text-center'
      },
      {
        path: 'name',
        sort: { isSortable: true },
        filter: { type: 'string' }
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true }
      }
    ];

    this.graphQlTable = {
      table: 'core_tenant',
      isPaginated: true,

      showCurrentPageReport: true,
      columns: columns
    };

    this.tenantService.getTenants(false).pipe(untilDestroyed(this)).subscribe();
  }

  getRow(data: any): CoreTenantModel | undefined {
    return data;
  }

  setCurrentTenant(data: any) {
    this.tenantService.setCurrentTenant(data);
    this.accessService.changeTenantIdOfAccess(data.id).subscribe();
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      this.confirmationService.confirmDeleteApollo({
        name: row.name,
        mutationOptions: {
          mutation: gql`
            mutation DeleteCoreTenantById($id: bigint!) {
              result: delete_core_tenant_by_pk(id: $id) {
                __typename
              }
            }
          `,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }
}
