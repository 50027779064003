import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface NumberProps extends FieldWrapperProps {
  iconPre?: string;
  iconPost?: string;
  maxFractionDigits?: number;
  minFractionDigits?: number;
  mode?: string;
  prefix?: string;
  suffix?: string;
  showButtons?: boolean;
  showClear?: boolean;
}

export interface NumberFieldConfig extends FormlyFieldConfig<NumberProps> {
  type?: 'number' | Type<NumberTypeComponent>;
}

@Component({
  selector: 'lib-formly-number-type',
  templateUrl: './number-type.component.html',
  styleUrl: './number-type.component.css'
})
export class NumberTypeComponent extends FieldType<FieldTypeConfig<NumberProps>> {}
