import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { map, of, switchMap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { TranslocoService } from '@jsverse/transloco';
import { validateIBAN } from 'ngx-iban-validator';
import { FormlyService } from '~madrasa/services';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil, QueryUtil } from '~ngx-shared/utils';
import {
  CoreAcademicDegreesPrefix,
  CoreAcademicDegreesSuffix,
  CoreCountryEnum,
  CoreGenderEnum,
  CoreMaritalStatusEnum,
  CorePersonDataModel,
  CoreRelationshipEnum,
  FilePersonMediaTypeEnum
} from '~ngx-shared/models';
import { PersonUtil } from '~madrasa/core/utils/person.util';

@UntilDestroy()
@Component({
  selector: 'app-create-person-data-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-person-data-form-page.component.html',
  styleUrl: './create-person-data-form-page.component.scss'
})
export class CreatePersonDataFormPageComponent implements OnInit {
  form = new FormGroup({});
  readOnlyModel: CorePersonDataModel = {};
  model: CorePersonDataModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_core_person'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  constructor(
    private apollo: Apollo,
    private formlyService: FormlyService,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: CorePersonDataModel[] }>({
                query: gql`
                  query ReadCoreCurrentPersonDataById($id: bigint!) {
                    result: core_current_person_data(where: { person_id: { _eq: $id } }, limit: 1) {
                      id
                      created_at
                      updated_at
                      address
                      email_address
                      phone_number
                      academic_degree_prefix
                      academic_degree_suffix
                      account_owner
                      bic
                      date_of_birth
                      place_of_birth
                      extra_name
                      first_name
                      gender
                      iban
                      insurance_number
                      last_name
                      marital_status
                      profession
                      person_id
                      zmr_number

                      profile_picture: person_media(
                        limit: 1
                        order_by: { created_at: desc }
                        where: { person_media_type: { _eq: "${FilePersonMediaTypeEnum.PROFILE_PICTURE}" } }
                      ) {
                        id
                      }

                      side_a_person_relationships {
                        id
                        relationship
                        person_in_relationship_id
                        person_in_relationship_current_person_data {
                          person_id
                          academic_degree_prefix
                          academic_degree_suffix
                          first_name
                          last_name
                        }
                      }
                      side_b_person_relationships {
                        id
                        relationship
                        person_id
                        person_current_person_data {
                          person_id
                          academic_degree_prefix
                          academic_degree_suffix
                          first_name
                          last_name
                        }
                      }

                      organisation_persons_active {
                        id
                        organisation_id
                      }
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data.result?.[0]));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          this.readOnlyModel = result;
          let model = cloneDeep(result);

          if (model) {
            this.model = {
              ...model,
              date_of_birth: FormlyUtil.fromIsoDateString(model.date_of_birth),
              profile_picture: model?.['profile_picture']?.map((item: any) => item.id),
              organisations: model.organisation_persons_active?.map(item => item.organisation_id),
              relationships: PersonUtil.convertRelationshipsFromQueryResult(
                model,
                this.translocoService
              )
            };
          }
        }

        this.fields = [
          {
            key: 'id'
          },
          {
            key: 'person_id'
          },
          FormlyUtil.createGroup('general_information', [
            this.formlyService.createOrganisationsFieldConfig({ required: true }),
            FormlyUtil.createRow([
              FormlyUtil.createMultiSelectField('academic_degree_prefix', {
                props: {
                  options: Object.values(CoreAcademicDegreesPrefix).map(value => ({
                    label: 'academic_degree.' + value,
                    value: value
                  }))
                }
              }),
              FormlyUtil.createMultiSelectField('academic_degree_suffix', {
                props: {
                  options: Object.values(CoreAcademicDegreesSuffix).map(value => ({
                    label: 'academic_degree.' + value,
                    value: value
                  }))
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createTextField('first_name', {
                props: {
                  required: true
                }
              }),
              FormlyUtil.createTextField('last_name', {
                props: {
                  required: true
                }
              }),
              FormlyUtil.createTextField('extra_name')
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createDatePickerField('date_of_birth', {
                props: {
                  required: true
                }
              }),
              FormlyUtil.createTextField('place_of_birth')
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createSelectField('citizenship', {
                defaultValue: 'AT',
                props: {
                  required: true,
                  options: Object.keys(CoreCountryEnum).map(key => ({
                    label: 'country_iso.' + key,
                    value: key
                  }))
                }
              }),
              FormlyUtil.createTextField('profession')
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createSelectField('gender', {
                defaultValue: CoreGenderEnum.MALE,
                props: {
                  required: true,
                  options: Object.values(CoreGenderEnum).map(value => ({
                    label: 'gender.' + value,
                    value: value
                  }))
                }
              }),
              FormlyUtil.createSelectField('marital_status', {
                defaultValue: CoreMaritalStatusEnum.SINGLE,
                props: {
                  required: true,
                  options: Object.values(CoreMaritalStatusEnum).map(value => ({
                    label: 'marital_status.' + value,
                    value: value
                  }))
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createInputMaskField('insurance_number', {
                props: {
                  type: 'number',
                  mask: '9999 999999'
                }
              }),
              FormlyUtil.createTextField('zmr_number', {
                props: {
                  type: 'number'
                }
              })
            ]),
            this.formlyService.createFileUploadFieldConfig('profile_picture', {
              props: {
                required: false,
                namespace: 'person_media',
                accept: 'image/*',
                maxFiles: 1
              }
            }),
            this.formlyService.createPersonSelectFieldConfig({
              key: 'person_in_relationship_select',
              label: 'relationships',
              excludePersonIds: this.model?.person_id ? [this.model.person_id] : undefined,
              click: (event, field) => {
                const value = field.formControl?.value;
                if (
                  value &&
                  (!this.model?.['relationships'] ||
                    this.model['relationships']?.findIndex(
                      (item: any) =>
                        item.person_in_relationship_id === value.value?.value?.person_id ||
                        item.person_id === value.value?.value?.person_id
                    ) === -1)
                ) {
                  this.model = {
                    ...this.model,
                    person_in_relationship_select: undefined,
                    relationships: [
                      ...(this.model['relationships'] || []),
                      {
                        person_in_relationship_id: value.value?.value?.person_id,
                        full_name: `${value.label}, Id: ${value.value?.value?.person_id}`,
                        relationship: undefined
                      }
                    ]
                  };
                }
              }
            }),
            FormlyUtil.createRow([
              FormlyUtil.createTable(
                {
                  key: 'relationships',
                  props: {
                    showIndex: true,
                    canAdd: () => false,
                    hideLabel: true,
                    columns: [
                      {
                        key: 'full_name',
                        header: 'name'
                      },
                      {
                        key: 'relationship',
                        header: 'relationship'
                      }
                    ]
                  }
                },
                [
                  {
                    key: 'id'
                  },
                  {
                    key: 'person_in_relationship_id'
                  },
                  {
                    key: 'person_id'
                  },
                  FormlyUtil.createLabelField('full_name', {
                    props: {
                      label: 'name',
                      disableMargin: true,
                      hideLabel: true,
                      labelClass: 'font-normal'
                    }
                  }),
                  FormlyUtil.createSelectField('relationship', {
                    props: {
                      disableMargin: true,
                      hideLabel: true,
                      required: true,
                      options: Object.values(CoreRelationshipEnum).map(relationship => ({
                        label: 'relationship.' + relationship,
                        value: relationship
                      }))
                    }
                  })
                ]
              )
            ]),
            this.formlyService.createAddressFieldConfig(),
            this.formlyService.createPhoneNumberFieldConfig(),
            this.formlyService.createEmailAddressFieldConfig(),
            FormlyUtil.createGroup('account_infos', [
              FormlyUtil.createRow([
                FormlyUtil.createInputMaskField('iban', {
                  props: {
                    required: false,
                    mask: 'aa99 9999 9999 9999 9999',
                    characterPattern: '[A-Z]',
                    description: 'iban_description'
                  },
                  validators: {
                    iban: {
                      expression: (control: any) => !validateIBAN(control.value)?.ibanInvalid,
                      message: (error: any, field: any) =>
                        this.translocoService.selectTranslate('invalid_iban')
                    }
                  }
                }),
                FormlyUtil.createTextField('bic', {
                  props: {
                    required: false
                  }
                })
              ]),
              FormlyUtil.createRow([
                FormlyUtil.createTextField('account_owner', {
                  props: {
                    required: false
                  }
                })
              ])
            ])
          ])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,

        date_of_birth: FormlyUtil.toIsoDateString(formSaveModel.input.date_of_birth),
        ...(formSaveModel.input?.profile_picture?.length
          ? {
              person_media: {
                data: {
                  id: formSaveModel.input.profile_picture[0],
                  person_media_type: FilePersonMediaTypeEnum.PROFILE_PICTURE
                }
              }
            }
          : {}),
        person: { data: {} },
        organisation_persons_active: {
          data: (this.model?.person_id
            ? PersonUtil.getNewOrganisations(
                formSaveModel.input.organisations,
                this.readOnlyModel.organisation_persons_active
              )
            : formSaveModel.input.organisations
          )?.map((id: number) => ({
            organisation_id: id
          }))
        },
        side_a_person_relationships: {
          data: (this.model?.person_id
            ? formSaveModel.input.relationships?.filter((item: any) => !item.id)
            : formSaveModel.input.relationships || []
          )?.map((item: any) => {
            return {
              person_in_relationship_id: item.person_in_relationship_id,
              relationship: item.relationship
            };
          })
        }
      };

      ModelUtil.deleteKey(input, 'person_in_relationship_select');
      ModelUtil.deleteKey(input, 'relationships');
      ModelUtil.deleteKey(input, 'organisations');
      ModelUtil.deleteKey(input, 'profile_picture');

      const params: string[] = [];
      const queries: string[] = [];
      let variables: any = { input };

      if (this.model?.person_id) {
        ModelUtil.deleteKey(input);
        ModelUtil.deleteKey(input, 'person');

        // Get deleted organisations
        const updateOrganisations = [
          ...PersonUtil.getDeletedOrganisations(
            this.readOnlyModel.organisation_persons_active,
            formSaveModel.input.organisations
          )
        ];

        if (updateOrganisations.length) {
          params.push('$updateOrganisations: [academy_organisation_person_active_updates!] = []');
          queries.push(
            `update_academy_organisation_person_active_many(updates: $updateOrganisations) { __typename }`
          );
          variables = {
            ...variables,
            updateOrganisations
          };
        }

        const updateSchoolActorsActive = [
          ...PersonUtil.getDeletedSchoolIdsFromDeletedOrganisations(
            this.model?.person_id,
            this.readOnlyModel.organisation_persons_active,
            formSaveModel.input.organisations
          )
        ];

        if (updateSchoolActorsActive.length) {
          params.push('$updateSchoolActorsActive: [academy_school_actor_active_updates!] = []');
          queries.push(
            `update_academy_school_actor_active_many(updates: $updateSchoolActorsActive) { __typename }`
          );
          variables = {
            ...variables,
            updateSchoolActorsActive
          };
        }

        // Get deleted relationships
        const updateRelationships = [
          ...PersonUtil.getDeletedRelationships(
            this.readOnlyModel.side_a_person_relationships,
            formSaveModel.input.relationships
          ),
          ...PersonUtil.getDeletedRelationships(
            this.readOnlyModel.side_b_person_relationships,
            formSaveModel.input.relationships,
            true
          )
        ];

        // Get updated relationships
        updateRelationships.push(
          ...PersonUtil.getUpdatedRelationships(
            this.readOnlyModel.side_a_person_relationships,
            formSaveModel.input.relationships
          ),
          ...PersonUtil.getUpdatedRelationships(
            this.readOnlyModel.side_b_person_relationships,
            formSaveModel.input.relationships,
            true
          )
        );

        if (updateRelationships.length) {
          params.push('$updateRelationships: [core_person_relationship_updates!] = []');
          queries.push(
            `update_core_person_relationship_many(updates: $updateRelationships) { __typename }`
          );
          variables = {
            ...variables,
            updateRelationships
          };
        }

        const updatePersonMedia = [
          // Get deleted person media
          ...(QueryUtil.comparer({
            itemsA: this.readOnlyModel?.['profile_picture']?.map((item: any) => item.id),
            itemsB: formSaveModel.input?.profile_picture,
            compareFunc: (itemA: any, itemB: any) => itemA === itemB,
            resultMapFunc: item => ({
              where: {
                id: { _eq: item }
              },
              _set: { deleted_at: 'now()' }
            })
          }) || [])
        ];

        if (updatePersonMedia.length) {
          params.push('$updatePersonMedia: [file_person_media_updates!] = []');
          queries.push('update_file_person_media_many(updates: $updatePersonMedia) { __typename }');
          variables = {
            ...variables,
            updatePersonMedia
          };
        }
      }

      return this.apollo.mutate({
        mutation: gql`
          mutation CreateCorePersonData(
            $input: core_person_data_insert_input!,
            ${params.join('\n')}
            ) {
            result: insert_core_person_data_one(object: $input) {
              __typename
            }
            ${queries.join('\n')}
          }
        `,
        variables
      });
    };
  }
}
