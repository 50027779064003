<ul class="layout-menu">
  @for (item of this.menuService.sideBarItems$ | async; track item; let i = $index) {
    @if (!item.separator) {
      <li layout-menuitem [item]="item" [index]="i" [root]="true"></li>
    }
    @if (item.separator) {
      <li class="menu-separator"></li>
    }
  }
</ul>
