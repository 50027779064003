import { Routes } from '@angular/router';
import { ngxPermissionsGuard } from 'ngx-permissions';
import { LayoutComponent } from '~ngx-shared/layout';
import { crudGuard } from '~ngx-shared/guards/crud.guard';
import { crudResolver } from '~ngx-shared/resolvers/crud.resolver';
import { detailGuard } from '~ngx-shared/guards/detail.guard';
import { breadcrumbResolver } from '~ngx-shared/resolvers/breadcrumb.resolver';
import {
  AcademyClassBookEntryPermission,
  AcademyCourseNamePermission,
  AcademyCoursePermission,
  AcademyCourseTypePermission,
  AcademyOrganisationPermission,
  AcademyRoomPermission,
  AcademySchoolPeriodPermission,
  AcademySchoolPermission,
  AcademySubjectPermission,
  AcademyTeacherHourlyRatePermission,
  AcademyUnitPermission,
  CoreTenantPermission,
  FilePersonDocumentTypePermission,
  StaffDirectorPermission,
  StaffEducationTypePermission,
  StaffManagerPermission,
  StaffOrganisatorPermission,
  StaffParentPermission,
  StaffStudentPermission,
  StaffTeacherPermission
} from '~ngx-shared/models';
import { Role } from '~ngx-shared/authentication';
import { CreateUpdateOperatingCostTypeFormPageComponent } from './accounting/pages/create-update-operating-cost-type-form-page/create-update-operating-cost-type-form-page.component';
import { OperatingCostTypeListPageComponent } from './accounting/pages/operating-cost-type-list-page/operating-cost-type-list-page.component';
import { CreateUpdateOperatingCostFormPageComponent } from './accounting/pages/create-update-operating-cost-form-page/create-update-operating-cost-form-page.component';
import { OperatingCostListPageComponent } from './accounting/pages/operating-cost-list-page/operating-cost-list-page.component';
import { CourseEvaluationsPageComponent } from './academy/pages/course-evaluations-page/course-evaluations-page.component';
import { TenantDetailPageComponent } from './core/pages/tenant-detail-page/tenant-detail-page.component';
import { NewRegistrationWizardPageComponent } from './staff/pages/new-registration-wizard-page/new-registration-wizard-page.component';
import { CreatePersonDataFormPageComponent } from './staff/pages/create-person-data-form-page/create-person-data-form-page.component';
import { CreateTeacherDataFormPageComponent } from './staff/pages/create-teacher-data-form-page/create-teacher-data-form-page.component';
import { CreateDirectorDataFormPageComponent } from './staff/pages/create-director-data-form-page/create-director-data-form-page.component';
import { CreateStudentDataFormPageComponent } from './staff/pages/create-student-data-form-page/create-student-data-form-page.component';
import { ManagementPageComponent } from './academy/pages/management-page/management-page.component';
import { CreateParentDataFormPageComponent } from './staff/pages/create-parent-data-form-page/create-parent-data-form-page.component';
import { ParentListPageComponent } from './staff/pages/parent-list-page/parent-list-page.component';
import { ManagerListPageComponent } from './staff/pages/manager-list-page/manager-list-page.component';
import { CreateManagerDataFormPageComponent } from './staff/pages/create-manager-data-form-page/create-manager-data-form-page.component';
import { OrganisatorListPageComponent } from './staff/pages/organisator-list-page/organisator-list-page.component';
import { CreateOrganisatorDataFormPageComponent } from './staff/pages/create-organisator-data-form-page/create-organisator-data-form-page.component';
import { TenantListPageComponent } from './core/pages/tenant-list-page/tenant-list-page.component';
import { PageNotFoundPageComponent } from './core/pages/page-not-found-page/page-not-found-page.component';
import { CreateUpdateTenantFormPageComponent } from './core/pages/create-update-tenant-form-page/create-update-tenant-form-page.component';
import { ClassBookEntryListPageComponent } from './academy/pages/class-book-entry-list-page/class-book-entry-list-page.component';
import { ClassBookEntryDetailPageComponent } from './academy/pages/class-book-entry-detail-page/class-book-entry-detail-page.component';
import { UpdateClassBookEntryFormPageComponent } from './academy/pages/update-class-book-entry-form-page/update-class-book-entry-form-page.component';
import { CourseListPageComponent } from './academy/pages/course-list-page/course-list-page.component';
import { CourseDetailPageComponent } from './academy/pages/course-detail-page/course-detail-page.component';
import { CreateUpdateCourseFormPageComponent } from './academy/pages/create-update-course-form-page/create-update-course-form-page.component';
import { CreateUpdateOrganisationFormPageComponent } from './academy/pages/create-update-organisation-form-page/create-update-organisation-form-page.component';
import { OrganisationDetailPageComponent } from './academy/pages/organisation-detail-page/organisation-detail-page.component';
import { CreateUpdateSubjectFormPageComponent } from './academy/pages/create-update-subject-form-page/create-update-subject-form-page.component';
import { SchoolListPageComponent } from './academy/pages/school-list-page/school-list-page.component';
import { SchoolDetailPageComponent } from './academy/pages/school-detail-page/school-detail-page.component';
import { CreateUpdateSchoolFormPageComponent } from './academy/pages/create-update-school-form-page/create-update-school-form-page.component';
import { SubjectDetailPageComponent } from './academy/pages/subject-detail-page/subject-detail-page.component';
import { CreateUpdateUnitFormPageComponent } from './academy/pages/create-update-unit-form-page/create-update-unit-form-page.component';
import { UnitListPageComponent } from './academy/pages/unit-list-page/unit-list-page.component';
import { UnitDetailPageComponent } from './academy/pages/unit-detail-page/unit-detail-page.component';
import { CourseTypeDetailPageComponent } from './academy/pages/course-type-detail-page/course-type-detail-page.component';
import { CreateUpdateCourseTypeFormPageComponent } from './academy/pages/create-update-course-type-form-page/create-update-course-type-form-page.component';
import { CreateUpdateSchoolPeriodFormPageComponent } from './academy/pages/create-update-school-period-form-page/create-update-school-period-form-page.component';
import { SchoolPeriodDetailPageComponent } from './academy/pages/school-period-detail-page/school-period-detail-page.component';
import { TeacherListPageComponent } from './staff/pages/teacher-list-page/teacher-list-page.component';
import { DirectorListPageComponent } from './staff/pages/director-list-page/director-list-page.component';
import { StudentListPageComponent } from './staff/pages/student-list-page/student-list-page.component';
import { PersonDetailPageComponent } from './staff/pages/person-detail-page/person-detail-page.component';
import { PersonListPageComponent } from './staff/pages/person-list-page/person-list-page.component';
import { SettingsPageComponent } from './staff/pages/settings-page/settings-page.component';
import { DashboardPageComponent } from './academy/pages/dashboard-page/dashboard-page.component';
import { RoomListPageComponent } from './academy/pages/room-list-page/room-list-page.component';
import { RoomDetailPageComponent } from './academy/pages/room-detail-page/room-detail-page.component';
import { CreateUpdateRoomFormPageComponent } from './academy/pages/create-update-room-form-page/create-update-room-form-page.component';
import { CreateUpdateCourseNameFormPageComponent } from './academy/pages/create-update-course-name-form-page/create-update-course-name-form-page.component';
import { CourseNameDetailPageComponent } from './academy/pages/course-name-detail-page/course-name-detail-page.component';
import { TeacherHourlyRateDetailPageComponent } from './academy/pages/teacher-hourly-rate-detail-page/teacher-hourly-rate-detail-page.component';
import { CreateUpdateTeacherHourlyRateFormPageComponent } from './academy/pages/create-update-teacher-hourly-rate-form-page/create-update-teacher-hourly-rate-form-page.component';
import { CreateUpdateEducationTypeFormPageComponent } from './staff/pages/create-update-education-type-form-page/create-update-education-type-form-page.component';
import { CourseInvoiceListPageComponent } from './accounting/pages/course-invoice-list-page/course-invoice-list-page.component';
import { CalculateTeacherWageListPageComponent } from './accounting/pages/calculate-teacher-wage-list-page/calculate-teacher-wage-list-page.component';
import { ProfilePageComponent } from './staff/pages/profile-page/profile-page.component';
import { TeacherWageDetailPageComponent } from './accounting/pages/teacher-wage-detail-page/teacher-wage-detail-page.component';
import { CreateUpdatePersonDocumentTypeFormPageComponent } from './file/pages/create-update-person-document-type-form-page/create-update-person-document-type-form-page.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/academy',
        pathMatch: 'full'
      },
      {
        path: 'core',
        canActivate: [ngxPermissionsGuard],
        data: {
          breadcrumb: 'system',
          permissions: {
            only: CoreTenantPermission.READ,
            redirectTo: '/'
          }
        },
        children: [
          {
            path: 'tenants',
            data: {
              breadcrumb: 'tenants',
              icon: 'pi pi-fw pi-building-columns'
            },
            children: [
              {
                path: '',
                component: TenantListPageComponent
              },
              {
                path: 'detail/:tenant-id',
                component: TenantDetailPageComponent,
                canActivate: [detailGuard('tenant-id')],
                resolve: { crud: crudResolver('tenant-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'tenant'
                }
              },
              {
                path: ':tenant-id',
                component: CreateUpdateTenantFormPageComponent,
                canActivate: [crudGuard('tenant-id')],
                resolve: {
                  crud: crudResolver('tenant-id'),
                  breadcrumb: breadcrumbResolver('tenant-id')
                },
                data: {
                  breadcrumbParamValue: 'tenant'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'accounting',
        data: {
          breadcrumb: 'accounting'
        },
        children: [
          {
            path: '',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'course-invoice',
            canActivate: [ngxPermissionsGuard],
            component: CourseInvoiceListPageComponent,
            data: {
              breadcrumb: 'course_invoice',
              icon: 'pi pi-fw pi-dollar',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER],
                redirectTo: '/'
              }
            }
          },
          {
            path: 'teacher-wages',
            data: {
              breadcrumb: 'teacher_wages',
              icon: 'pi pi-fw pi-money-bill'
            },
            children: [
              {
                path: '',
                redirectTo: '/',
                pathMatch: 'full'
              },
              {
                path: 'calculate',
                canActivate: [ngxPermissionsGuard],
                component: CalculateTeacherWageListPageComponent,
                data: {
                  breadcrumb: 'calculate_teacher_wages',
                  icon: 'pi pi-fw pi-money-bill',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                }
              },
              {
                path: 'detail/:teacher-wage-id',
                component: TeacherWageDetailPageComponent,
                canActivate: [detailGuard('teacher-wage-id')],
                resolve: { crud: crudResolver('teacher-wage-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'teacher_wage'
                }
              }
            ]
          },
          {
            path: 'operating-cost-types',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'operating_cost_types',
              icon: 'pi pi-fw pi-money-bill',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: OperatingCostTypeListPageComponent
              },
              {
                path: ':operating-cost-type-id',
                component: CreateUpdateOperatingCostTypeFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('operating-cost-type-id')],
                resolve: {
                  crud: crudResolver('operating-cost-type-id'),
                  breadcrumb: breadcrumbResolver('operating-cost-type-id')
                },
                data: {
                  breadcrumbParamValue: 'operating_cost_type',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'operating-costs',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'operating_costs',
              icon: 'pi pi-fw pi-money-bill',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: OperatingCostListPageComponent
              },
              {
                path: ':operating-cost-id',
                component: CreateUpdateOperatingCostFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('operating-cost-id')],
                resolve: {
                  crud: crudResolver('operating-cost-id'),
                  breadcrumb: breadcrumbResolver('operating-cost-id')
                },
                data: {
                  breadcrumbParamValue: 'operating_cost',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                }
              }
            ]
          }
        ]
      },
      {
        path: 'academy',
        data: {
          breadcrumb: 'academy'
        },
        children: [
          {
            path: '',
            redirectTo: '/academy/dashboard',
            pathMatch: 'full'
          },
          {
            path: 'dashboard',
            component: DashboardPageComponent,
            data: {
              breadcrumb: 'dashboard',
              icon: 'pi pi-fw pi-home'
            }
          },
          {
            path: 'management',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'management',
              icon: 'pi pi-fw pi-cog',
              permissions: {
                only: [
                  ...AcademyOrganisationPermission.READ,
                  ...AcademySchoolPeriodPermission.READ,
                  ...AcademyCourseTypePermission.READ,
                  ...AcademySubjectPermission.READ,
                  ...AcademyTeacherHourlyRatePermission.READ
                ],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: ManagementPageComponent
              },
              {
                path: 'organisations',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'organisations',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyOrganisationPermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:organisation-id',
                    component: OrganisationDetailPageComponent,
                    canActivate: [detailGuard('organisation-id')],
                    resolve: { crud: crudResolver('organisation-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'organisation'
                    }
                  },
                  {
                    path: ':organisation-id',
                    component: CreateUpdateOrganisationFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('organisation-id')],
                    resolve: {
                      crud: crudResolver('organisation-id'),
                      breadcrumb: breadcrumbResolver('organisation-id')
                    },
                    data: {
                      breadcrumbParamValue: 'organisation',
                      permissions: {
                        only: [
                          ...AcademyOrganisationPermission.CREATE,
                          ...AcademyOrganisationPermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'school-periods',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'school_periods',
                  icon: 'pi pi-fw pi-eject',
                  permissions: {
                    only: AcademySchoolPeriodPermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:school-period-id',
                    component: SchoolPeriodDetailPageComponent,
                    canActivate: [detailGuard('school-period-id')],
                    resolve: { crud: crudResolver('school-period-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'school_period'
                    }
                  },
                  {
                    path: ':school-period-id',
                    component: CreateUpdateSchoolPeriodFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('school-period-id')],
                    resolve: {
                      crud: crudResolver('school-period-id'),
                      breadcrumb: breadcrumbResolver('school-period-id')
                    },
                    data: {
                      breadcrumbParamValue: 'school_period',
                      permissions: {
                        only: [
                          ...AcademySchoolPeriodPermission.CREATE,
                          ...AcademySchoolPeriodPermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'course-types',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'course_types',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyCourseTypePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:course-type-id',
                    component: CourseTypeDetailPageComponent,
                    canActivate: [detailGuard('course-type-id')],
                    resolve: { crud: crudResolver('course-type-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'course_type'
                    }
                  },
                  {
                    path: ':course-type-id',
                    component: CreateUpdateCourseTypeFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('course-type-id')],
                    resolve: {
                      crud: crudResolver('course-type-id'),
                      breadcrumb: breadcrumbResolver('course-type-id')
                    },
                    data: {
                      breadcrumbParamValue: 'course_type',
                      permissions: {
                        only: [
                          ...AcademyCourseTypePermission.CREATE,
                          ...AcademyCourseTypePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'course-names',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'course_names',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyCourseNamePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:course-name-id',
                    component: CourseNameDetailPageComponent,
                    canActivate: [detailGuard('course-name-id')],
                    resolve: { crud: crudResolver('course-name-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'course_name'
                    }
                  },
                  {
                    path: ':course-name-id',
                    component: CreateUpdateCourseNameFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('course-name-id')],
                    resolve: {
                      crud: crudResolver('course-name-id'),
                      breadcrumb: breadcrumbResolver('course-name-id')
                    },
                    data: {
                      breadcrumbParamValue: 'course_name',
                      permissions: {
                        only: [
                          ...AcademyCourseNamePermission.CREATE,
                          ...AcademyCourseNamePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'subjects',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'subjects',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademySubjectPermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:subject-id',
                    component: SubjectDetailPageComponent,
                    canActivate: [detailGuard('subject-id')],
                    resolve: { crud: crudResolver('subject-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'subject'
                    }
                  },
                  {
                    path: ':subject-id',
                    component: CreateUpdateSubjectFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('subject-id')],
                    resolve: {
                      crud: crudResolver('subject-id'),
                      breadcrumb: breadcrumbResolver('subject-id')
                    },
                    data: {
                      breadcrumbParamValue: 'subject',
                      permissions: {
                        only: [
                          ...AcademySubjectPermission.CREATE,
                          ...AcademySubjectPermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'education-types',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'education_type',
                  icon: 'pi pi-fw pi-cog',
                  permissions: {
                    only: StaffEducationTypePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: ':education-type-id',
                    component: CreateUpdateEducationTypeFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('education-type-id')],
                    resolve: {
                      crud: crudResolver('education-type-id'),
                      breadcrumb: breadcrumbResolver('education-type-id')
                    },
                    data: {
                      breadcrumbParamValue: 'education_type',
                      permissions: {
                        only: [
                          ...StaffEducationTypePermission.CREATE,
                          ...StaffEducationTypePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'teacher-hourly-rates',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'teacher_hourly_rates',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyTeacherHourlyRatePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:teacher-hourly-rate-id',
                    component: TeacherHourlyRateDetailPageComponent,
                    canActivate: [detailGuard('teacher-hourly-rate-id')],
                    resolve: { crud: crudResolver('teacher-hourly-rate-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'teacher_hourly_rate'
                    }
                  },
                  {
                    path: ':teacher-hourly-rate-id',
                    component: CreateUpdateTeacherHourlyRateFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('teacher-hourly-rate-id')],
                    resolve: {
                      crud: crudResolver('teacher-hourly-rate-id'),
                      breadcrumb: breadcrumbResolver('teacher-hourly-rate-id')
                    },
                    data: {
                      breadcrumbParamValue: 'teacher_hourly_rate',
                      permissions: {
                        only: [
                          ...AcademyTeacherHourlyRatePermission.CREATE,
                          ...AcademyTeacherHourlyRatePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'person-document-types',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'person_document_type',
                  icon: 'pi pi-fw pi-cog',
                  permissions: {
                    only: FilePersonDocumentTypePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: ':person-document-type-id',
                    component: CreateUpdatePersonDocumentTypeFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('person-document-type-id')],
                    resolve: {
                      crud: crudResolver('person-document-type-id'),
                      breadcrumb: breadcrumbResolver('person-document-type-id')
                    },
                    data: {
                      breadcrumbParamValue: 'person_document_type',
                      permissions: {
                        only: [
                          ...FilePersonDocumentTypePermission.CREATE,
                          ...FilePersonDocumentTypePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'class-book-entries',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'class_book_entries',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademyClassBookEntryPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: ClassBookEntryListPageComponent
              },
              {
                path: 'detail/:class-book-entry-id',
                component: ClassBookEntryDetailPageComponent,
                canActivate: [detailGuard('class-book-entry-id')],
                resolve: { crud: crudResolver('class-book-entry-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'class_book_entry'
                }
              },
              {
                path: ':class-book-entry-id',
                component: UpdateClassBookEntryFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('class-book-entry-id', false)],
                resolve: {
                  crud: crudResolver('class-book-entry-id'),
                  breadcrumb: breadcrumbResolver('class-book-entry-id')
                },
                data: {
                  breadcrumbParamValue: 'class_book_entry',
                  permissions: {
                    only: [
                      ...AcademyClassBookEntryPermission.CREATE,
                      ...AcademyClassBookEntryPermission.UPDATE
                    ],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'courses',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'courses',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademyCoursePermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: CourseListPageComponent
              },
              {
                path: 'evaluations',
                component: CourseEvaluationsPageComponent,
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'evaluations',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyCoursePermission.CREATE,
                    redirectTo: '/'
                  }
                }
              },
              {
                path: 'detail/:course-id',
                component: CourseDetailPageComponent,
                canActivate: [detailGuard('course-id')],
                resolve: { crud: crudResolver('course-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'course'
                }
              },
              {
                path: ':course-id',
                component: CreateUpdateCourseFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('course-id')],
                resolve: {
                  crud: crudResolver('course-id'),
                  breadcrumb: breadcrumbResolver('course-id')
                },
                data: {
                  breadcrumbParamValue: 'course',
                  permissions: {
                    only: [...AcademyCoursePermission.CREATE, ...AcademyCoursePermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'rooms',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'rooms',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademyRoomPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: RoomListPageComponent
              },
              {
                path: 'detail/:room-id',
                component: RoomDetailPageComponent,
                canActivate: [detailGuard('room-id')],
                resolve: { crud: crudResolver('room-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'room'
                }
              },
              {
                path: ':room-id',
                component: CreateUpdateRoomFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('room-id')],
                resolve: {
                  crud: crudResolver('room-id'),
                  breadcrumb: breadcrumbResolver('room-id')
                },
                data: {
                  breadcrumbParamValue: 'room',
                  permissions: {
                    only: [...AcademyRoomPermission.CREATE, ...AcademyRoomPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'schools',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'schools',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademySchoolPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: SchoolListPageComponent
              },
              {
                path: 'detail/:school-id',
                component: SchoolDetailPageComponent,
                canActivate: [detailGuard('school-id')],
                resolve: { crud: crudResolver('school-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'school'
                }
              },
              {
                path: ':school-id',
                component: CreateUpdateSchoolFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('school-id')],
                resolve: {
                  crud: crudResolver('school-id'),
                  breadcrumb: breadcrumbResolver('school-id')
                },
                data: {
                  breadcrumbParamValue: 'school',
                  permissions: {
                    only: [...AcademySchoolPermission.CREATE, ...AcademySchoolPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'units',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'units',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademyUnitPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: UnitListPageComponent
              },
              {
                path: 'detail/:unit-id',
                component: UnitDetailPageComponent,
                canActivate: [detailGuard('unit-id')],
                resolve: { crud: crudResolver('unit-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'unit'
                }
              },
              {
                path: ':unit-id',
                component: CreateUpdateUnitFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('unit-id')],
                resolve: {
                  crud: crudResolver('unit-id'),
                  breadcrumb: breadcrumbResolver('unit-id')
                },
                data: {
                  breadcrumbParamValue: 'unit',
                  permissions: {
                    only: [...AcademyUnitPermission.CREATE, ...AcademyUnitPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          }
        ]
      },
      {
        path: 'staff',
        data: {
          breadcrumb: 'staff'
        },
        children: [
          {
            path: 'settings',
            data: {
              breadcrumb: 'settings',
              icon: 'pi pi-fw pi-cog'
            },
            children: [
              {
                path: '',
                component: SettingsPageComponent
              }
            ]
          },
          {
            path: 'profile',
            component: ProfilePageComponent,
            data: {
              breadcrumb: 'profile',
              icon: 'pi pi-fw pi-user'
            }
          },
          {
            path: 'people',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'people',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                except: Role.TEACHER,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: PersonListPageComponent
              },
              {
                path: 'detail/:person-id',
                component: PersonDetailPageComponent,
                canActivate: [detailGuard('person-id')],
                resolve: { crud: crudResolver('person-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'person'
                }
              },
              {
                path: ':person-id',
                component: CreatePersonDataFormPageComponent,
                canActivate: [crudGuard('person-id')],
                resolve: {
                  crud: crudResolver('person-id'),
                  breadcrumb: breadcrumbResolver('person-id')
                },
                data: {
                  breadcrumbParamValue: 'person'
                }
              }
            ]
          },
          {
            path: 'new-registration',
            component: NewRegistrationWizardPageComponent,
            data: {
              breadcrumb: 'madrasa.components.staff.new_registration_wizard.new_registration',
              icon: 'pi pi-fw pi-building-columns'
            }
          },
          {
            path: 'students',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'students',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffStudentPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: StudentListPageComponent
              },
              {
                path: 'detail/:student-id',
                redirectTo: '/staff/people/detail/:student-id'
              },
              {
                path: ':student-id',
                component: CreateStudentDataFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('student-id')],
                resolve: {
                  crud: crudResolver('student-id'),
                  breadcrumb: breadcrumbResolver('student-id')
                },
                data: {
                  breadcrumbParamValue: 'student',
                  permissions: {
                    only: [...StaffStudentPermission.CREATE, ...StaffStudentPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'teachers',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'teachers',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffTeacherPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: TeacherListPageComponent
              },
              {
                path: 'detail/:teacher-id',
                redirectTo: '/staff/people/detail/:teacher-id'
              },
              {
                path: ':teacher-id',
                component: CreateTeacherDataFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('teacher-id')],
                resolve: {
                  crud: crudResolver('teacher-id'),
                  breadcrumb: breadcrumbResolver('teacher-id')
                },
                data: {
                  breadcrumbParamValue: 'teacher',
                  permissions: {
                    only: [...StaffTeacherPermission.CREATE, ...StaffTeacherPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'directors',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'directors',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffStudentPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: DirectorListPageComponent
              },
              {
                path: 'detail/:director-id',
                redirectTo: '/staff/people/detail/:director-id'
              },
              {
                path: ':director-id',
                component: CreateDirectorDataFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('director-id')],
                resolve: {
                  crud: crudResolver('director-id'),
                  breadcrumb: breadcrumbResolver('director-id')
                },
                data: {
                  breadcrumbParamValue: 'director',
                  permissions: {
                    only: [...StaffDirectorPermission.CREATE, ...StaffDirectorPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'parents',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'parents',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffParentPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: ParentListPageComponent
              },
              {
                path: 'detail/:parent-id',
                redirectTo: '/staff/people/detail/:parent-id'
              },
              {
                path: ':parent-id',
                component: CreateParentDataFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('parent-id')],
                resolve: {
                  crud: crudResolver('parent-id'),
                  breadcrumb: breadcrumbResolver('parent-id')
                },
                data: {
                  breadcrumbParamValue: 'parent',
                  permissions: {
                    only: [...StaffParentPermission.CREATE, ...StaffParentPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'managers',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'managers',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffManagerPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: ManagerListPageComponent
              },
              {
                path: 'detail/:manager-id',
                redirectTo: '/staff/people/detail/:manager-id'
              },
              {
                path: ':manager-id',
                component: CreateManagerDataFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('manager-id')],
                resolve: {
                  crud: crudResolver('manager-id'),
                  breadcrumb: breadcrumbResolver('manager-id')
                },
                data: {
                  breadcrumbParamValue: 'manager',
                  permissions: {
                    only: [...StaffManagerPermission.CREATE, ...StaffManagerPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'organisators',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'organisators',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffOrganisatorPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: OrganisatorListPageComponent
              },
              {
                path: 'detail/:organisator-id',
                redirectTo: '/staff/people/detail/:organisator-id'
              },
              {
                path: ':organisator-id',
                component: CreateOrganisatorDataFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('organisator-id')],
                resolve: {
                  crud: crudResolver('organisator-id'),
                  breadcrumb: breadcrumbResolver('organisator-id')
                },
                data: {
                  breadcrumbParamValue: 'organisator',
                  permissions: {
                    only: [
                      ...StaffOrganisatorPermission.CREATE,
                      ...StaffOrganisatorPermission.UPDATE
                    ],
                    redirectTo: '/'
                  }
                }
              }
            ]
          }
        ]
      },
      {
        path: 'not-found',
        data: {
          breadcrumb: 'Not Found'
        },
        component: PageNotFoundPageComponent
      },
      { path: '**', redirectTo: 'not-found' }
    ]
  }
];
