<lib-busy *transloco="let transloco" [height]="'50vh'" [isLoading]="!this.item()">
  <div>
    <a
      *ngxPermissionsOnly="this.AcademyCoursePermission.UPDATE"
      [label]="transloco('edit_value', { value: transloco('course') })"
      [routerLink]="['/', 'academy', 'courses', this.item()?.id]"
      icon="pi pi-pencil"
      pButton
      type="button"></a>
  </div>
  <lib-detail [fields]="this.fields()"></lib-detail>
</lib-busy>
