<lib-busy [height]="'50vh'" [isLoading]="this.isLoading()">
  <div *transloco="let transloco" class="flex flex-col gap-6">
    @if (this.hasAccess()) {
      <span class="block text-2xl">{{ transloco('access') }}</span>
      <lib-detail [fields]="this.fields()"></lib-detail>

      <span class="block text-2xl">{{ transloco('actions') }}</span>

      <div class="flex flex-wrap items-center gap-4">
        @if (this.userService.user()?.person_id !== this.personId()) {
          <button
            (click)="this.changeAccess()"
            [label]="
              transloco(
                this.personData()?.user?.is_enabled ? 'deactivate_value' : 'activate_value',
                {
                  value: transloco('access')
                }
              )
            "
            [loading]="this.isAccessChanging()"
            [ngClass]="{
              'p-button-primary': !this.personData()?.user?.is_enabled,
              'p-button-danger': this.personData()?.user?.is_enabled
            }"
            [icon]="this.personData()?.user?.is_enabled ? 'pi pi-ban' : 'pi pi-check'"
            pButton
            type="button"></button>
        }
        <button
          (click)="this.changeEmail()"
          [loading]="this.isEmailChanging()"
          [label]="transloco('change_value', { value: transloco('email') })"
          class="p-button-warning"
          icon="pi pi-pencil"
          pButton
          type="button"></button>

        <button
          (click)="this.resetPassword()"
          [loading]="this.isPasswortResetting()"
          [label]="transloco('reset_value', { value: transloco('password') })"
          class="p-button-danger"
          icon="pi pi-refresh"
          pButton
          type="button"></button>
      </div>

      <span class="block text-2xl">{{ transloco('roles') }}</span>

      <app-access-role-list
        #accessRoleList
        (createAccessEvent)="this.addRole()"
        [personId]="this.personId()"
        [showCreateButton]="this.authorizationService.can(this.AccessRolePermission.CREATE)"
        [showPerson]="false"></app-access-role-list>
    } @else {
      <div class="flex items-center gap-4 !text-3xl">
        <i class="pi pi-exclamation-triangle !text-3xl"></i>
        <span>{{ transloco('has_no_access_yet') }}</span>
      </div>

      <div>
        <button
          *ngxPermissionsOnly="this.AccessRolePermission.CREATE"
          (click)="this.createAccess()"
          [label]="transloco('create_value', { value: transloco('access') })"
          class="p-button-success"
          icon="pi pi-plus"
          pButton
          type="button"></button>
      </div>
    }
  </div>
</lib-busy>
