import { Component, OnInit, signal } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { Router } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuModule } from 'primeng/menu';
import { AsyncPipe } from '@angular/common';
import { BasePageComponent } from '~ngx-shared/layout';
import { GraphQlColumnModel } from '~ngx-shared/graph-ql';
import { StaffTeacherPermission } from '~ngx-shared/models';
import { AuthorizationService } from '~ngx-shared/authentication';
import { SearchPersonFormDialogComponent } from '~madrasa/staff/components/search-person-form-dialog/search-person-form-dialog.component';
import { DialogUtil } from '~ngx-shared/utils/dialog.util';
import { TenantService } from '~madrasa/services';
import { TeacherListComponent } from '~madrasa/staff/components/teacher-list/teacher-list.component';

@UntilDestroy()
@Component({
  selector: 'app-teacher-list-page',
  standalone: true,
  imports: [
    BasePageComponent,
    ButtonDirective,
    TranslocoDirective,
    NgxPermissionsModule,
    MenuModule,
    AsyncPipe,
    TeacherListComponent
  ],
  templateUrl: './teacher-list-page.component.html',
  styleUrl: './teacher-list-page.component.scss'
})
export class TeacherListPageComponent implements OnInit {
  readonly items = signal<MenuItem[]>([]);
  readonly columns = signal<GraphQlColumnModel[]>([]);

  protected readonly StaffTeacherPermission = StaffTeacherPermission;

  constructor(
    public authorizationService: AuthorizationService,
    private translocoService: TranslocoService,
    private dialogService: DialogService,
    public router: Router,
    public tenantService: TenantService
  ) {}

  ngOnInit() {
    this.translocoService
      .selectTranslate(['new', 'madrasa.components.staff.create_from_existing_person'])
      .pipe(untilDestroyed(this))
      .subscribe(translated => {
        this.items.set([
          {
            label: translated[0],
            icon: 'pi pi-plus',
            routerLink: ['.', 'new']
          },
          {
            label: translated[1],
            icon: 'pi pi-arrows-h',
            command: () => {
              this.dialogService
                .open(SearchPersonFormDialogComponent, {
                  ...DialogUtil.BASE_DIALOG_CONFIG,
                  header: this.translocoService.translate(
                    'madrasa.components.staff.select_from_existing_person'
                  )
                })
                .onClose.pipe(untilDestroyed(this))
                .subscribe(result => {
                  if (result?.input?.person_id?.value?.value?.person_id) {
                    void this.router.navigate(
                      [this.router.url, result.input.person_id.value.value.person_id],
                      { queryParams: { is_new: true } }
                    );
                  }
                });
            }
          }
        ]);
      });

    this.columns.set([]);
  }
}
