import { Component, computed, effect, inject, input, signal } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { RouterLink } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { TranslocoDirective } from '@jsverse/transloco';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { AcademyUnitModel, AcademyUnitPermission, AcademyWeekdayEnum } from '~ngx-shared/models';
import { AuthorizationService } from '~ngx-shared/authentication';
import { ModelUtil } from '~ngx-shared/utils';
import { BusyComponent } from '~ngx-shared/layout';

@Component({
  selector: 'app-unit-detail',
  standalone: true,
  imports: [
    ButtonDirective,
    DetailComponent,
    NgxPermissionsModule,
    RouterLink,
    TranslocoDirective,
    BusyComponent
  ],
  templateUrl: './unit-detail.component.html',
  styleUrl: './unit-detail.component.scss'
})
export class UnitDetailComponent {
  readonly id = input.required<number | undefined>();

  readonly apollo = inject(Apollo);
  readonly authorizationService = inject(AuthorizationService);

  protected readonly AcademyUnitPermission = AcademyUnitPermission;

  readonly item = signal<AcademyUnitModel | undefined>(undefined);

  readonly fields = computed<DetailField[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    return [
      {
        label: 'id',
        value: item.id
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'created_by',
        type: 'person_link',
        value: item.created_by_current_person_data
      },
      {
        label: 'changed_by',
        type: 'person_link',
        value: item.changed_by_current_person_data
      },
      {
        label: 'subject',
        value: item.subject?.name
      },
      {
        label: 'course',
        value: item.course?.course_name?.name || item.course?.name
      },
      {
        label: 'school_period',
        value: item.course?.school_period?.name
      },
      {
        label: 'school',
        value: item.school?.name
      },
      {
        label: 'organisation',
        value: item.school?.organisation?.name
      },
      {
        label: 'teachers',
        value: item.unit_teachers_aggregate?.aggregate?.count
      },
      {
        label: 'weekday',
        translate: true,
        value: 'weekday.' + ModelUtil.getEnumKeyByValue(AcademyWeekdayEnum, item.weekday)
      },
      {
        label: 'starts_at',
        value: item.starts_at
      },
      {
        label: 'ends_at',
        value: item.ends_at
      }
    ];
  });

  constructor() {
    effect(() => {
      this.apollo
        .query<{ result: AcademyUnitModel }>({
          query: gql`
            query ReadAcademyUnitById($id: bigint!) {
              result: academy_unit_by_pk(id: $id) {
                id
                created_at
                updated_at

                created_by_current_person_data {
                  person_id
                  academic_degree_prefix
                  academic_degree_suffix
                  first_name
                  last_name
                }

                changed_by_current_person_data {
                  person_id
                  academic_degree_prefix
                  academic_degree_suffix
                  first_name
                  last_name
                }

                subject {
                  name
                }
                school {
                  name
                  organisation {
                    name
                  }
                }
                course {
                  id
                  name
                  course_name {
                    name
                  }
                  school_period {
                    name
                  }
                }
                weekday
                starts_at
                ends_at
                color

                unit_teachers_aggregate {
                  aggregate {
                    count
                  }
                }
              }
            }
          `,
          variables: {
            id: this.id()
          }
        })
        .subscribe(queryResult => {
          this.item.set(queryResult.data?.result);
        });
    });
  }
}
