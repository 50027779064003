import { HttpClient, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, inject, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  Subscription,
  switchMap,
  take,
  tap
} from 'rxjs';

@Pipe({
  name: 'httpImgSrc',
  standalone: true,
  pure: false
})
export class HttpImgSrcPipe implements PipeTransform, OnDestroy {
  private readonly httpClient = inject(HttpClient);
  private readonly domSanitizer = inject(DomSanitizer);
  private readonly cdr = inject(ChangeDetectorRef);

  private subscription = new Subscription();
  private latestValue!: string | SafeUrl;
  private transformValue = new BehaviorSubject<string>('');
  private loadingImagePath!: string;
  private errorImagePath!: string;

  constructor() {
    const transformSubscription = this.transformValue
      .asObservable()
      .pipe(
        filter((v: string | null): v is string => !!v),
        distinctUntilChanged(),
        switchMap((imagePath: string) =>
          this.httpClient.get(imagePath, { observe: 'response', responseType: 'blob' }).pipe(
            take(1),
            map((response: HttpResponse<Blob>) =>
              response.body !== null ? URL.createObjectURL(response.body) : ''
            ),
            map((unsafeBlobUrl: string) => this.domSanitizer.bypassSecurityTrustUrl(unsafeBlobUrl)),
            filter((blobUrl: SafeUrl) => blobUrl !== this.latestValue)
          )
        ),
        tap((imagePath: string | SafeUrl) => {
          if (this.latestValue && typeof this.latestValue === 'string') {
            URL.revokeObjectURL(this.latestValue);
          }

          this.latestValue = imagePath;
          this.cdr.markForCheck();
        })
      )
      .subscribe();

    this.subscription.add(transformSubscription);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();

    if (typeof this.latestValue === 'string') {
      URL.revokeObjectURL(this.latestValue);
    }
  }

  public transform(imagePath: string): string | SafeUrl {
    const svgLoaderString = `
        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.008-3.018a1.502 1.502 0 0 1 2.522 1.159v.024a1.44 1.44 0 0 1-1.493 1.418 1 1 0 0 0-1.037.999V14a1 1 0 1 0 2 0v-.539a3.44 3.44 0 0 0 2.529-3.256 3.502 3.502 0 0 0-7-.255 1 1 0 0 0 2 .076c.014-.398.187-.774.48-1.044Zm.982 7.026a1 1 0 1 0 0 2H12a1 1 0 1 0 0-2h-.01Z" clip-rule="evenodd"/>
        </svg>
    `;

    this.loadingImagePath = `data:image/svg+xml;base64,${btoa(svgLoaderString)}`;

    const svgErrorString = `
      <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m6 6 12 12m3-6a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
      </svg>
    `;

    this.errorImagePath = `data:image/svg+xml;base64,${btoa(svgErrorString)}`;

    if (!imagePath) {
      return this.errorImagePath;
    }

    this.transformValue.next(imagePath);
    return this.latestValue || this.loadingImagePath;
  }
}
