import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { Apollo, gql } from 'apollo-angular';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AcademySchoolPeriodModel } from '~ngx-shared/models';
import { StoreService } from '~ngx-shared/services';

type SchoolPeriodStore = {
  activePeriod?: AcademySchoolPeriodModel;
  currentPeriod?: AcademySchoolPeriodModel;
  periods?: AcademySchoolPeriodModel[];
};

@Injectable({
  providedIn: 'root'
})
export class SchoolPeriodService {
  periodStore = this.storeService.createStore<SchoolPeriodStore>({
    name: 'school-period',
    initial: undefined
  });

  // Shows the current selected period
  currentPeriod$ = this.periodStore.pipe(select(state => state.currentPeriod));
  // Shows active period
  activePeriod$ = this.periodStore.pipe(select(state => state.activePeriod));
  // Shows all periods
  periods$ = this.periodStore.pipe(select(state => state.periods));

  constructor(
    private apollo: Apollo,
    private storeService: StoreService
  ) {}

  getPeriods(fromCache: boolean = true): Observable<AcademySchoolPeriodModel[] | undefined> {
    if (fromCache && this.periodStore.value?.periods && this.periodStore.value.periods.length) {
      return this.periods$;
    }
    return this.apollo
      .query<{ periods: AcademySchoolPeriodModel[] }>({
        query: gql`
          query ReadAcademySchoolPeriodsOrderedById {
            periods: academy_school_period(order_by: { starts_at: desc }) {
              id
              created_at
              updated_at
              starts_at
              ends_at
              name
              is_active
            }
          }
        `
      })
      .pipe(
        map(queryResult => queryResult.data.periods),
        tap(periods => this.updatePeriods(periods, true))
      );
  }

  updatePeriods(periods: AcademySchoolPeriodModel[] = [], setActiveAsCurrent: boolean = false) {
    const activePeriod = periods.find(period => period.is_active);
    this.periodStore.update(state => ({
      ...state,
      periods,
      activePeriod,
      currentPeriod: setActiveAsCurrent ? activePeriod : undefined
    }));
  }

  setCurrentPeriod(currentPeriod: AcademySchoolPeriodModel): void {
    if (
      this.periodStore.value.periods &&
      this.periodStore.value.periods.length &&
      this.periodStore.value.periods.some(
        (period: AcademySchoolPeriodModel) => period.id === currentPeriod.id
      )
    ) {
      this.periodStore.update(state => ({
        ...state,
        currentPeriod
      }));
    }
  }
}
