import { TranslocoService } from '@jsverse/transloco';
import { CorePersonDataModel, CorePersonRelationshipModel } from '~ngx-shared/models';
import { ModelUtil, QueryUtil } from '~ngx-shared/utils';

export class PersonUtil {
  static convertRelationshipsFromQueryResult(
    model: CorePersonDataModel,
    translocoService: TranslocoService
  ): CorePersonRelationshipModel[] {
    return [
      ...(model.side_a_person_relationships?.map(person_relationship => ({
        id: person_relationship.id,
        person_in_relationship_id: person_relationship.person_in_relationship_id,
        full_name: `${ModelUtil.getFullName(
          person_relationship['person_in_relationship_current_person_data'],
          translocoService
        )}, Id: ${person_relationship.person_in_relationship_id}`,
        relationship: person_relationship.relationship
      })) || []),
      ...(model.side_b_person_relationships?.map(person_relationship => ({
        id: person_relationship.id,
        person_id: person_relationship.person_id,
        full_name: `${ModelUtil.getFullName(
          person_relationship['person_current_person_data'],
          translocoService
        )}, Id: ${person_relationship.person_id}`,
        relationship: person_relationship.relationship
      })) || [])
    ];
  }

  static getDeletedRelationships(itemsA?: any[], itemsB?: any[], isSiteB = false): any[] {
    return (
      QueryUtil.comparer({
        itemsA,
        itemsB,
        compareFunc: (itemA: any, itemB: any) =>
          !!itemB?.id &&
          ((!isSiteB && itemB.person_in_relationship_id) || (isSiteB && itemB.person_id)) &&
          itemA.id === itemB.id,
        resultMapFunc: item => ({
          where: {
            id: { _eq: item.id }
          },
          _set: { deleted_at: 'now()' }
        })
      }) || []
    );
  }

  static getUpdatedRelationships(itemsA?: any[], itemsB?: any[], isSiteB = false): any[] {
    return (
      itemsA?.reduce((acc, itemA: any) => {
        const itemB = itemsB?.find(
          (relationship: any) =>
            (!isSiteB &&
              relationship.person_in_relationship_id === itemA.person_in_relationship_id) ||
            (isSiteB && relationship.person_id === itemA.person_id)
        );
        if (itemB && itemB.relationship !== itemA.relationship) {
          acc.push({
            where: {
              id: { _eq: itemA.id }
            },
            _set: { relationship: itemB.relationship }
          });
        }
        return acc;
      }, [] as any[]) || []
    );
  }

  static getDeletedOrganisations(itemsA?: any[], itemsB?: any[]): any[] {
    return (
      QueryUtil.comparer({
        itemsA,
        itemsB,
        compareFunc: (itemA: any, itemB: any) => itemA.organisation_id === itemB,
        resultMapFunc: item => ({
          where: {
            id: { _eq: item.id }
          },
          _set: { deleted_at: 'now()' }
        })
      }) || []
    );
  }

  static getDeletedSchoolIdsFromDeletedOrganisations(
    personId: number,
    itemsA?: any[],
    itemsB?: any[]
  ): any[] {
    return (
      QueryUtil.comparer({
        itemsA,
        itemsB,
        compareFunc: (itemA: any, itemB: any) => itemA.organisation_id === itemB,
        resultMapFunc: item => ({
          where: {
            school: { organisation_id: { _eq: item.organisation_id } },
            person_id: { _eq: personId }
          },
          _set: { deleted_at: 'now()' }
        })
      }) || []
    );
  }

  static getNewOrganisations(itemsA?: any[], itemsB?: any[]): any[] {
    return (
      QueryUtil.comparer({
        itemsA,
        itemsB,
        compareFunc: (itemA: any, itemB: any) => itemA === itemB?.organisation_id,
        resultMapFunc: item => item
      }) || []
    );
  }

  static getNewSchools(itemsA?: any[], itemsB?: any[]): any[] {
    return (
      QueryUtil.comparer({
        itemsA,
        itemsB,
        compareFunc: (itemA: any, itemB: any) => itemA === itemB?.school?.id,
        resultMapFunc: item => item
      }) || []
    );
  }

  static getDeletedSchools(itemsA?: any[], itemsB?: any[]): any[] {
    return (
      QueryUtil.comparer({
        itemsA,
        itemsB,
        compareFunc: (itemA: any, itemB: any) => itemA.school?.id === itemB,
        resultMapFunc: item => ({
          where: {
            id: { _eq: item.id }
          },
          _set: { deleted_at: 'now()' }
        })
      }) || []
    );
  }

  static getNewPerson(itemsA?: any[], itemsB?: any[], returnResult = false): any[] {
    return (
      QueryUtil.comparer({
        itemsA,
        itemsB,
        compareField: 'person_id',
        resultMapFunc: item => (returnResult ? item : item?.person_id)
      }) || []
    );
  }

  static getDeletedPerson(itemsA?: any[], itemsB?: any[]): any[] {
    return (
      QueryUtil.comparer({
        itemsA,
        itemsB,
        compareField: 'person_id',
        resultMapFunc: item => ({
          where: {
            id: { _eq: item.id }
          },
          _set: { deleted_at: 'now()' }
        })
      }) || []
    );
  }
}
