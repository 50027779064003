import { Component, OnInit, signal } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { toDate } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { PrimeTemplate } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';
import { DatePipe } from '~ngx-shared/pipes';
import { ModelUtil } from '~ngx-shared/utils';
import { AcademySubjectModel, CorePersonDataModel } from '~ngx-shared/models';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';

@UntilDestroy()
@Component({
  selector: 'app-course-student-status-evaluation',
  standalone: true,
  imports: [
    FormlyModule,
    TranslocoDirective,
    PrimeTemplate,
    TableModule,
    AccordionModule,
    DatePipe,
    RouterLink,
    NgClass
  ],
  templateUrl: './course-student-status-evaluation.component.html',
  styleUrl: './course-student-status-evaluation.component.scss'
})
export class CourseStudentStatusEvaluationComponent implements OnInit {
  form = new FormGroup<any>({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.generate_course_student_status_evaluation'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;
  readonly result = signal<any[]>([]);

  constructor(
    private apollo: Apollo,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.fields = [
      {
        validators: {
          starts_at: {
            expression: (control: FormControl, field: FormlyFieldConfig) => {
              const startsAt = toDate(field.model?.starts_at);
              const endsAt = toDate(field.model?.ends_at);
              return startsAt < endsAt;
            },
            message: (error: any, field: any) =>
              this.translocoService.selectTranslate(
                'madrasa.forms.generate_course_student_status_evaluation.starts_at_less_than_ends_at'
              ),
            errorPath: 'starts_at'
          },
          ends_at: {
            expression: (control: FormControl, field: FormlyFieldConfig) => {
              const startsAt = toDate(field.model?.starts_at);
              const endsAt = toDate(field.model?.ends_at);
              return endsAt > startsAt;
            },
            message: (error: any, field: any) =>
              this.translocoService.selectTranslate(
                'madrasa.forms.generate_course_student_status_evaluation.ends_at_greater_than_starts_at'
              ),
            errorPath: 'ends_at'
          }
        },
        fieldGroup: [
          FormlyUtil.createRow([
            FormlyUtil.createDatePickerField('starts_at', {
              props: {
                required: true
              }
            }),
            FormlyUtil.createDatePickerField('ends_at', {
              props: {
                required: true
              }
            })
          ])
        ]
      }
    ];

    this.submit = (formSaveModel: FormSaveModel) => {
      // const input = {
      //   ...formSaveModel.input
      // };

      return this.apollo.query<{ result: AcademySubjectModel }>({
        query: gql`
          query AcademyCourseStudentStatusEvaluation($inputStartDate: date, $inputEndDate: date) {
            start: academy_latest_course_student_by_date(
              args: { input_date: $inputStartDate }
              order_by: { current_person_data: { first_name: asc } }
              where: { status: { _eq: active } }
            ) {
              created_at
              status
              course_id
              course {
                id
                name
                course_name {
                  name
                }
                school_id
                school {
                  name
                  organisation_id
                  organisation {
                    name
                  }
                }
              }
              current_person_data {
                person_id
                academic_degree_prefix
                academic_degree_suffix
                first_name
                last_name
              }
            }
            end: academy_latest_course_student_by_date(
              args: { input_date: $inputEndDate }
              order_by: { current_person_data: { first_name: asc } }
            ) {
              created_at
              status
              course_id
              course {
                id
                name
                course_name {
                  name
                }
                school_id
                school {
                  name
                  organisation_id
                  organisation {
                    name
                  }
                }
              }
              current_person_data {
                person_id
                academic_degree_prefix
                academic_degree_suffix
                first_name
                last_name
              }
            }
          }
        `,
        variables: {
          inputStartDate: FormlyUtil.toIsoDateString(formSaveModel.input.starts_at),
          inputEndDate: FormlyUtil.toIsoDateString(formSaveModel.input.ends_at)
        }
      });
    };
  }

  savedEvent(formSaveModel: FormSaveModel) {
    const start = cloneDeep(formSaveModel.output.start);
    const end = cloneDeep(formSaveModel.output.end);
    const combined = [...start, ...end];

    // Get all organisations as object from combined data
    const organisationMap: any = {};
    combined.forEach((item: any) => {
      organisationMap[item.course.school.organisation_id] = item.course.school.organisation;
    });

    // Add school to organisation from combined data
    combined.forEach((item: any) => {
      const organisation = organisationMap[item.course.school.organisation_id];
      organisation.schools = organisation.schools || {};
      organisation.schools[item.course.school_id] = item.course.school;
    });

    // Add course to school from combined data
    combined.forEach((item: any) => {
      const organisation = organisationMap[item.course.school.organisation_id];
      const school = organisation.schools[item.course.school_id];
      school.courses = school.courses || {};
      school.courses[item.course_id] = item.course;
    });

    // Add start and end data to course
    start.forEach((item: any) => {
      const course =
        organisationMap[item.course.school.organisation_id].schools[item.course.school_id].courses[
          item.course_id
        ];
      course.start = course.start || [];
      course.start.push(item);
    });

    end.forEach((item: any) => {
      const course =
        organisationMap[item.course.school.organisation_id].schools[item.course.school_id].courses[
          item.course_id
        ];
      course.end = course.end || [];
      course.end.push(item);

      // End count active and inactive students
      course.active = course.active || 0;
      course.inactive = course.inactive || 0;
      if (item.status === 'active') {
        course.active++;
      } else {
        course.inactive++;
      }
    });

    // Convert organisationMap to an array of organisations
    const organisations = Object.values(organisationMap);

    // Convert schools object to an array within each organisation
    organisations.forEach((organisation: any) => {
      // Sort by name
      organisation.schools = Object.values(organisation.schools).sort((a: any, b: any) => {
        return a.name.localeCompare(b.name);
      });
      organisation.schools.forEach((school: any) => {
        school.courses = Object.values(school.courses).sort((a: any, b: any) => {
          return (a.course_name?.name || a.name).localeCompare(b.course_name?.name || b.name);
        });
      });
    });

    this.result.set(organisations);
  }

  getFullName(personDataModel: CorePersonDataModel | undefined) {
    return ModelUtil.getFullName(personDataModel, this.translocoService);
  }
}
