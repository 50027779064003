import { inject, Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { KeycloakService } from 'keycloak-angular';
import { TenantService } from '~madrasa/services';
import { toSignal } from '@angular/core/rxjs-interop';
import { StoreService } from '~ngx-shared/services';
import { AuthModel } from '../models';

@Injectable()
export class AuthenticationService {
  private readonly _initialAuthModel = <AuthModel>{
    isAuthenticated: false,
    isAuthorizing: false,
    isAuthorized: false,
    isLoggedOut: true,
    roles: []
  };
  authStore = this.storeService.createStore<AuthModel>({
    name: 'authentication',
    initial: this._initialAuthModel,
    persist: { storage: 'session' }
  });
  authModel$ = this.authStore.pipe(select(state => state));
  readonly claims = toSignal(this.authStore.pipe(select(state => state.claims)));

  constructor(
    private storeService: StoreService,
    private keycloak: KeycloakService
  ) {}

  /**
   * Logout the current user
   */
  signOut() {
    this.keycloak.logout();

    const tenantService = inject(TenantService);
    tenantService.clearTenants();
  }

  init(): void {
    const keycloak = this.keycloak;
    const keycloakInstance = keycloak.getKeycloakInstance();
    const decodedPayload = keycloakInstance.tokenParsed;
    if (decodedPayload) {
      const allowedHasuraRoles: string[] =
        decodedPayload['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'];
      // default-roles-madrasa, offline_access, uma_authorization are keycloak specific roles. Ignore them
      // Filter also the hasura default role 'madrasa-user'. If the user has no role that value will be later used.
      const ignoredRoles = ['default-roles-madrasa', 'offline_access', 'uma_authorization'];
      // Use the first role the user or fall back to 'madrasa-user'

      const newState = <AuthModel>{
        isLoggedOut: false,
        isAuthenticated: true,
        isAuthorizing: true,
        roles: allowedHasuraRoles.filter(item => !ignoredRoles.includes(item)),
        claims: this._convertClaimsToCamelCase(decodedPayload['https://hasura.io/jwt/claims']),
        headers: this._convertClaimsToKeyValue(decodedPayload['https://hasura.io/jwt/claims'])
      };

      this.authStore.update(state => ({
        ...state,
        ...newState
      }));
    }
  }

  updateAuthorization(isAuthorized: boolean) {
    this.authStore.update(state => ({
      ...state,
      isAuthorized,
      isAuthorizing: false
    }));
  }

  // This method converts the claims like x-hasura-allowed-roles to camelCase like allowedRoles or x-hasura-user-id to userId
  // But ignore 'x-hasura-allowed-roles' and 'x-hasura-default-role'
  // Change the ones which ends with -ids the value to an array like 'x-hasura-organisation-ids' with value "{1,2,3}" to 'organisationIds' with value [1,2,3]
  private _convertClaimsToCamelCase(claims: { [key: string]: any }) {
    const camelCaseClaims: any = {};
    Object.keys(claims).forEach(key => {
      if (key !== 'x-hasura-allowed-roles' && key !== 'x-hasura-default-role') {
        const camelCaseKey = key
          .replace('x-hasura-', '')
          .replace(/-([a-z])/g, g => g[1].toUpperCase());
        if (camelCaseKey.endsWith('Ids')) {
          // claims[key] is a string like "{1,2,3}"
          camelCaseClaims[camelCaseKey] = claims[key].replace(/[{}]/g, '').split(',').map(Number);
        } else {
          camelCaseClaims[camelCaseKey] = claims[key];
        }
      }
    });
    return camelCaseClaims;
  }

  // This method converts the claims like x-hasura-allowed-roles key value
  // But ignore 'x-hasura-allowed-roles' and 'x-hasura-default-role'
  private _convertClaimsToKeyValue(claims: { [key: string]: any }) {
    const keyValueClaims: any = {};
    Object.keys(claims).forEach(key => {
      if (key !== 'x-hasura-allowed-roles' && key !== 'x-hasura-default-role') {
        keyValueClaims[key] = claims[key];
      }
    });
    return keyValueClaims;
  }
}
