<div *transloco="let transloco" class="w-full">
  <lib-busy [height]="'50vh'" [isLoading]="this.isLoading()">
    <table class="org_table border-spacing-0">
      <tbody>
        @for (organisation of this.organisations(); track organisation) {
          @if (organisation['has_courses']) {
            <tr>
              <td class="rounded-2xl border-2 border-white bg-primary-500 align-top">
                <div class="p-4 text-center text-3xl font-bold text-white">
                  {{ organisation.name | uppercase }}
                </div>

                <table class="school_table border-spacing-y-4">
                  <tbody>
                    @for (school of organisation.schools; track school) {
                      @if (school['has_courses']) {
                        <tr>
                          <td class="rounded-2xl border-2 border-white bg-primary-300 align-top">
                            <div class="p-4 text-center text-2xl text-white">
                              {{ school.name | uppercase }}
                            </div>

                            <table
                              class="type_table border-spacing-0 rounded-2xl border-2 border-white bg-primary-100">
                              <thead>
                                <tr>
                                  <th class="text-left">{{ transloco('course_type') }}</th>
                                  <th class="text-left">{{ transloco('course_name') }}</th>
                                  <th>
                                    {{ transloco('students') }} -
                                    <span class="text-xl">&female;</span>
                                  </th>
                                  <th>
                                    {{ transloco('students') }} -
                                    <span class="text-xl">&male;</span>
                                  </th>
                                  <th>
                                    {{ transloco('students') }} - <span class="text-xl">&sum;</span>
                                  </th>
                                  <th *ngxPermissionsOnly="this.StaffTeacherPermission.READ">
                                    {{ transloco('teachers') }} -
                                    <span class="text-xl">&female;</span>
                                  </th>
                                  <th *ngxPermissionsOnly="this.StaffTeacherPermission.READ">
                                    {{ transloco('teachers') }} -
                                    <span class="text-xl">&male;</span>
                                  </th>
                                  <th *ngxPermissionsOnly="this.StaffTeacherPermission.READ">
                                    {{ transloco('teachers') }} - <span class="text-xl">&sum;</span>
                                  </th>
                                  <th>{{ transloco('units') }}</th>
                                </tr>
                              </thead>
                              <tbody>
                                @for (
                                  course_type of organisation.course_types;
                                  track course_type;
                                  let last = $last
                                ) {
                                  @if (course_type['has_courses']) {
                                    @let available_courses =
                                      this.courses()[
                                        'org_' +
                                          organisation.id +
                                          '_school_' +
                                          school.id +
                                          '_type_' +
                                          course_type.id
                                      ];
                                    @for (
                                      course of available_courses;
                                      track course;
                                      let first = $first
                                    ) {
                                      <tr>
                                        @if (first) {
                                          <td
                                            [attr.rowspan]="available_courses?.length"
                                            class="text-left align-top">
                                            <div class="">{{ course_type.name }}</div>
                                          </td>
                                        }
                                        <td class="border-b border-white text-left">
                                          {{ course.name || course.course_name?.name }}
                                        </td>
                                        <td class="border-b border-white">
                                          {{ course['student_female']?.aggregate?.count || 0 }}
                                        </td>
                                        <td class="border-b border-white">
                                          {{ course['student_male']?.aggregate?.count || 0 }}
                                        </td>
                                        <td class="border-b border-white">
                                          {{ course['student_all']?.aggregate?.count || 0 }}
                                        </td>
                                        <td
                                          *ngxPermissionsOnly="this.StaffTeacherPermission.READ"
                                          class="border-b border-white">
                                          {{ course['teacher_female']?.aggregate?.count || 0 }}
                                        </td>
                                        <td
                                          *ngxPermissionsOnly="this.StaffTeacherPermission.READ"
                                          class="border-b border-white">
                                          {{ course['teacher_male']?.aggregate?.count || 0 }}
                                        </td>
                                        <td
                                          *ngxPermissionsOnly="this.StaffTeacherPermission.READ"
                                          class="border-b border-white">
                                          {{ course['teacher_all']?.aggregate?.count || 0 }}
                                        </td>
                                        <td class="border-b border-white">
                                          {{ course.units_aggregate?.aggregate?.count || 0 }}
                                        </td>
                                      </tr>
                                    }
                                    @if (available_courses?.length) {
                                      <tr>
                                        <td class="border-b border-white"></td>
                                        <td class="border-b border-white text-left">
                                          <span class="text-xl">&sum;</span>
                                        </td>
                                        <td class="border-b border-white font-bold">
                                          {{
                                            this.getSum(
                                              available_courses,
                                              'student_female.aggregate.count'
                                            )
                                          }}
                                        </td>
                                        <td class="border-b border-white font-bold">
                                          {{
                                            this.getSum(
                                              available_courses,
                                              'student_male.aggregate.count'
                                            )
                                          }}
                                        </td>
                                        <td class="border-b border-white font-bold">
                                          {{
                                            this.getSum(
                                              available_courses,
                                              'student_all.aggregate.count'
                                            )
                                          }}
                                        </td>
                                        <td
                                          *ngxPermissionsOnly="this.StaffTeacherPermission.READ"
                                          class="border-b border-white font-bold">
                                          {{
                                            this.getSum(
                                              available_courses,
                                              'teacher_female.aggregate.count'
                                            )
                                          }}
                                        </td>
                                        <td
                                          *ngxPermissionsOnly="this.StaffTeacherPermission.READ"
                                          class="border-b border-white font-bold">
                                          {{
                                            this.getSum(
                                              available_courses,
                                              'teacher_male.aggregate.count'
                                            )
                                          }}
                                        </td>
                                        <td
                                          *ngxPermissionsOnly="this.StaffTeacherPermission.READ"
                                          class="border-b border-white font-bold">
                                          {{
                                            this.getSum(
                                              available_courses,
                                              'teacher_all.aggregate.count'
                                            )
                                          }}
                                        </td>
                                        <td class="border-b border-white font-bold">
                                          {{
                                            this.getSum(
                                              available_courses,
                                              'units_aggregate.aggregate.count'
                                            )
                                          }}
                                        </td>
                                      </tr>
                                    }
                                  }
                                }

                                <tr class="text-xl">
                                  <td></td>
                                  <td class="text-left">
                                    <span class="text-xl">&sum;</span>
                                  </td>
                                  <td class="font-bold">
                                    {{ school['sums']['student_female'] }}
                                  </td>
                                  <td class="font-bold">
                                    {{ school['sums']['student_male'] }}
                                  </td>
                                  <td class="font-bold">
                                    {{ school['sums']['student_all'] }}
                                  </td>
                                  <td
                                    *ngxPermissionsOnly="this.StaffTeacherPermission.READ"
                                    class="font-bold">
                                    {{ school['sums']['teacher_female'] }}
                                  </td>
                                  <td
                                    *ngxPermissionsOnly="this.StaffTeacherPermission.READ"
                                    class="font-bold">
                                    {{ school['sums']['teacher_male'] }}
                                  </td>
                                  <td
                                    *ngxPermissionsOnly="this.StaffTeacherPermission.READ"
                                    class="font-bold">
                                    {{ school['sums']['teacher_all'] }}
                                  </td>
                                  <td class="font-bold">
                                    {{ school['sums']['units'] }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      }
                    }
                  </tbody>
                </table>
              </td>
            </tr>
          }
        }
      </tbody>
    </table>
  </lib-busy>
</div>
