import { DefaultProperties } from '~ngx-shared/models';
import { Role } from '~ngx-shared/authentication';

export interface FilePersonDocumentTypeModel extends DefaultProperties {
  // Columns
  id?: string;
  name?: string;
  description?: string;
}

export class FilePersonDocumentTypePermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...FilePersonDocumentTypePermission.DELETE, Role.MANAGER];
}
