import { Injectable, isDevMode } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

@Injectable({ providedIn: 'root' })
export class LoggerService {
  constructor(private apollo: Apollo) {}

  info(message: string): void {
    this._sendLog(message, 'INFO');
  }

  error(message: string, stacktrace?: string): void {
    this._sendLog(message, 'ERROR', stacktrace);
  }

  private _sendLog(message: string, severity: string, stacktrace?: string): void {
    if (message && !isDevMode()) {
      this.apollo
        .mutate({
          mutation: gql`
            mutation CreateCoreLog($input: [core_log_insert_input!]!) {
              insert_core_log(objects: $input) {
                __typename
              }
            }
          `,
          variables: {
            input: {
              message,
              severity,
              stacktrace
            }
          }
        })
        .subscribe();
    }
  }
}
