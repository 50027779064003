import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface SelectProps extends FieldWrapperProps, FormlyFieldSelectProps {
  virtualScroll?: boolean;
  virtualScrollSize?: number;

  groupLabelProp?: string;
  groupChildrenProp?: string;

  canFilter?: boolean;
  editable?: boolean;
  scrollHeight?: string;

  showClear?: boolean;
}

export interface SelectFieldConfig extends FormlyFieldConfig<SelectProps> {
  type?: 'select' | Type<SelectTypeComponent>;
}

@Component({
  selector: 'lib-formly-select-type',
  templateUrl: './select-type.component.html',
  styleUrl: './select-type.component.css'
})
export class SelectTypeComponent extends FieldType<FieldTypeConfig<SelectProps>> {}
