import { DatePipe as AngularDatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'libDate', standalone: true })
export class DatePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const datePipe = new AngularDatePipe('de-AT');
    return datePipe.transform(value, args?.[0]);
  }
}
