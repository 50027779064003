import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { StoreService } from '~ngx-shared/services';
import {
  DataProviderOptionModel,
  GraphQlColumnModel,
  GraphQlFilterModel,
  GraphQlSortModel
} from '../models';

type GraphQlStore = {
  data: {
    [key: string]: {
      option: DataProviderOptionModel;
      columns: GraphQlColumnModel[];
    };
  };
};

@Injectable({
  providedIn: 'root'
})
export class GraphQlService {
  graphQlStore = this.storeService.createStore<GraphQlStore>({
    name: 'graphql',
    initial: { data: {} },
    persist: { storage: 'local' }
  });

  state$(key: string) {
    return this.graphQlStore.pipe(select(state => state.data?.[key]));
  }

  sortBy$(key: string) {
    return this.graphQlStore.pipe(select(state => state.data?.[key]?.option?.sortBy));
  }

  filter$(key: string) {
    return this.graphQlStore.pipe(select(state => state.data?.[key]?.option?.filter));
  }

  constructor(private storeService: StoreService) {}

  updateSort(key: string | undefined, sortBy?: GraphQlSortModel[]) {
    if (key) {
      this.graphQlStore.update(state => {
        return {
          ...state,
          data: {
            ...state.data,
            [key]: {
              ...state.data?.[key],
              option: {
                ...state.data?.[key]?.option,
                offset: 0,
                sortBy
              }
            }
          }
        };
      });
    }
  }

  updateFilter(key: string | undefined, filter?: GraphQlFilterModel) {
    if (key) {
      this.graphQlStore.update(state => {
        return {
          ...state,
          data: {
            ...state.data,
            [key]: {
              ...state.data?.[key],
              option: {
                ...state.data?.[key]?.option,
                offset: 0,
                filter
              }
            }
          }
        };
      });
    }
  }

  updateLimitAndOffset(key: string | undefined, limit: number, offset: number) {
    if (key) {
      this.graphQlStore.update(state => {
        return {
          ...state,
          data: {
            ...state.data,
            [key]: {
              ...state.data?.[key],
              option: {
                ...state.data?.[key]?.option,
                limit,
                offset
              }
            }
          }
        };
      });
    }
  }

  updateColumns(key: string | undefined, columns: GraphQlColumnModel[]) {
    if (key) {
      this.graphQlStore.update(state => {
        return {
          ...state,
          data: {
            ...state.data,
            [key]: {
              ...state.data?.[key],
              columns: columns.map(column => {
                return {
                  label: column.label,
                  path: column.path,
                  hidden: column.hidden
                };
              })
            }
          }
        };
      });
    }
  }
}
