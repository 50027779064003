import { Injectable } from '@angular/core';
import { HashMap, TranslocoService } from '@jsverse/transloco';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class ToastService {
  readonly DIALOG_KEY = 'dialog';
  readonly POPUP_KEY = 'popup';

  constructor(
    private translocoService: TranslocoService,
    private messageService: MessageService
  ) {}

  toastSuccess(detail: string = '', params?: HashMap | undefined) {
    this.messageService.add({
      severity: 'success',
      summary: this.translocoService.translate('overlay.toast.success'),
      detail: this.translocoService.translate(detail, params)
    });
  }

  toastError(detail: string = '', params?: HashMap | undefined) {
    this.messageService.add({
      severity: 'error',
      summary: this.translocoService.translate('overlay.toast.error'),
      detail: this.translocoService.translate(detail, params)
    });
  }

  toastSuccessSave(params?: HashMap | undefined) {
    this.toastSuccess('overlay.toast.save_success', params);
  }

  toastErrorSave(params?: HashMap | undefined) {
    this.toastError('overlay.toast.save_error', params);
  }

  toastSuccessDelete() {
    this.messageService.add({
      severity: 'success',
      summary: this.translocoService.translate('overlay.toast.success'),
      detail: this.translocoService.translate('overlay.toast.delete_success')
    });
  }

  toastErrorDelete() {
    this.messageService.add({
      severity: 'error',
      summary: this.translocoService.translate('overlay.toast.error'),
      detail: this.translocoService.translate('overlay.toast.delete_error')
    });
  }
}
