import { Role } from '~ngx-shared/authentication';
import { Aggregate } from '~ngx-shared/models';
import { DefaultProperties } from '../default.model';
import { AcademyUnitModel } from './unit.model';
import { AcademyClassBookEntryStudentModel } from './class-book-entry-student.model';
import { AcademyClassBookEntryTeacherModel } from './class-book-entry-teacher.model';
import { AcademySchoolModel } from './school.model';
import { AcademyCourseModel } from './course.model';

export interface AcademyClassBookEntryModel extends DefaultProperties {
  // Columns
  id?: number;
  unit_id?: number;
  school_id?: number;
  course_id?: number;
  date?: Date | string;
  subject_matter?: string;
  homework?: string;
  is_online?: boolean;
  is_filled_out?: boolean;

  // Computed
  expires_at?: Date | string;

  // Relations
  unit?: AcademyUnitModel;
  school?: AcademySchoolModel;
  course?: AcademyCourseModel;

  class_book_entry_students?: AcademyClassBookEntryStudentModel[];
  class_book_entry_students_aggregate?: Aggregate;

  class_book_entry_teachers?: AcademyClassBookEntryTeacherModel[];
  class_book_entry_teachers_aggregate?: Aggregate;
}

export class AcademyClassBookEntryPermission {
  static readonly CREATE = [];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR, Role.TEACHER];
  static readonly UPDATE = [
    Role.LESSOR,
    Role.MANAGER,
    Role.ORGANISATOR,
    Role.DIRECTOR,
    Role.TEACHER
  ];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AcademyClassBookEntryPermission.DELETE];
}
