import { Component, computed, inject } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap, tap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AcademyOrganisationModel, AcademyOrganisationPermission } from '~ngx-shared/models';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { AuthorizationService, Role } from '~ngx-shared/authentication';
import { NoteListComponent } from '~madrasa/note/components/note-list/note-list.component';
import { DataProviderOptionModel } from '~ngx-shared/graph-ql';
import { FormSaveModel } from '~ngx-shared/formly';

@UntilDestroy()
@Component({
  selector: 'app-organisation-detail-page',
  standalone: true,
  imports: [
    TranslocoDirective,
    BasePageComponent,
    DetailComponent,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective,
    NoteListComponent,
    NgxPermissionsModule
  ],
  templateUrl: './organisation-detail-page.component.html',
  styleUrl: './organisation-detail-page.component.scss'
})
export class OrganisationDetailPageComponent {
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly authorizationService = inject(AuthorizationService);
  readonly router = inject(Router);

  notePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  notePatchInput: (formSaveModel: FormSaveModel, input: any) => any;

  protected readonly AcademyOrganisationPermission = AcademyOrganisationPermission;

  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{ result: AcademyOrganisationModel }>({
              query: gql`
                query ReadAcademyOrganisationById($id: bigint!) {
                  result: academy_organisation_by_pk(id: $id) {
                    id
                    created_at
                    updated_at
                    name
                    address

                   ${
                     !this.authorizationService.can(Role.TEACHER)
                       ? `
                          organisation_course_price {
                            single_price
                            dual_price
                            three_or_more_price
                          }
                      teacher_hourly_rate{
                        name
                      }
                      hourly_rate
                      monthly_rate
                                 `
                       : ''
                   }
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result));
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );

  readonly fields = computed<DetailField[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }

    this.notePatchOptions = options => {
      options.filter = {
        ...options.filter,
        _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
      };
      if (
        Array.isArray(options?.filter?._and) &&
        !options.filter?._and?.some((cond: any) => cond.organisation_id)
      ) {
        options.filter._and.push({
          organisation_id: {
            _eq: item?.id
          }
        });
      }
      return options;
    };

    this.notePatchInput = (formSaveModel, input) => {
      return {
        ...input,
        organisation_id: item.id
      };
    };

    const fields: DetailField[] = [
      {
        label: 'id',
        value: item.id
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'name',
        value: item.name
      },
      {
        label: 'address',
        type: 'address',
        value: item.address
      }
    ];

    if (!this.authorizationService.can(Role.TEACHER)) {
      fields.push(
        {
          label: 'course_price.single_price',
          type: 'currency',
          value: item.organisation_course_price?.single_price
        },
        {
          label: 'course_price.dual_price',
          type: 'currency',
          value: item.organisation_course_price?.dual_price
        },
        {
          label: 'course_price.three_or_more_price',
          type: 'currency',
          value: item.organisation_course_price?.three_or_more_price
        },
        {
          label: 'teacher_hourly_rate',
          type: 'string',
          value: item.teacher_hourly_rate?.name
        },
        {
          label: 'hourly_rate',
          type: 'currency',
          value: item.hourly_rate
        },
        {
          label: 'monthly_rate',
          type: 'currency',
          value: item.monthly_rate
        }
      );
    }

    return fields;
  });
}
