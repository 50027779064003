<p-tabView
  *transloco="let transloco"
  [sharedUiTabViewQuery]="this.props.query || 't'"
  libTabViewQuery>
  @for (tab of this.field.fieldGroup; track tab) {
    <p-tabPanel>
      <ng-template pTemplate="header">
        <span
          class="-my-2 mr-2 flex items-center gap-2 whitespace-nowrap py-2 text-lg leading-4"
          [ngClass]="{ 'p-error': (errorCount$(tab) | async) || false }">
          {{ transloco(tab.props?.label || '') }}
        </span>
        @if (errorCount$(tab) | async) {
          <span class="-my-2 rounded bg-red-500 px-1 py-px text-white"
            >{{ (errorCount$(tab) | async) || '' }} {{ transloco('error') }}</span
          >
        }
      </ng-template>
      <formly-field [field]="tab"></formly-field>
    </p-tabPanel>
  }
</p-tabView>
