import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { finalize, map } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { environment } from '~ngx-shared/environment';
import { ToastService } from '~ngx-shared/layout';
import { FileModel } from '~ngx-shared/models';

export type FileStatus = {
  [key: string]: {
    isOpening?: boolean;
    isDownloading?: boolean;
  };
};

@Injectable({ providedIn: 'root' })
export class FileService {
  readonly apollo = inject(Apollo);
  readonly httpClient = inject(HttpClient);
  readonly toastService = inject(ToastService);

  readonly status = signal<FileStatus>({});
  private externalServiceUrl = environment.externalServiceUrl;

  // META

  getFileMetas$(ids: string[], namespace: string) {
    return this.apollo
      .query<{ files: FileModel[] }>({
        query: gql`
        query ReadFileMeta($where: file_${namespace}_bool_exp) {
          files: file_${namespace}(where: $where) {
            id
            name
            size
          }
        }
      `,
        variables: {
          where: { _or: { id: { _in: ids || [] } } }
        }
      })
      .pipe(map(queryResult => queryResult.data.files));
  }

  // UPLOAD

  uploadFile$(file: File, namespace: string) {
    let mimeType = file.type;
    if (file.name.endsWith('.msg')) {
      mimeType = 'application/vnd.ms-outlook';
    }

    const request = new HttpRequest<File>(
      'POST',
      `${this.externalServiceUrl}/file/upload/${namespace}`,
      file,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/octet-stream'
        }),
        params: new HttpParams({
          fromObject: {
            'mime-type': mimeType,
            name: file.name
          }
        }),
        reportProgress: true,
        responseType: 'text'
      }
    );
    return this.httpClient.request<string>(request);
  }

  //

  getFileUrl(id?: string, namespace?: string): string {
    return `${this.externalServiceUrl}/file/${namespace}/${id}`;
  }

  // DOWNLOAD

  downloadFile(id?: string, namespace?: string, fileNameToDownload?: string): void {
    const status = this.status();
    if (id && namespace && !status[id]?.isDownloading) {
      status[id] = { isDownloading: true, isOpening: !fileNameToDownload };
      this.status.set(status);
      this.httpClient
        .get(this.getFileUrl(id, namespace), {
          responseType: 'blob'
        })
        .pipe(
          finalize(() => {
            const status = this.status();
            status[id] = { isDownloading: false, isOpening: false };
            this.status.set(status);
          })
        )
        .subscribe(fileBlob => {
          this.openFileBlob(fileBlob, fileNameToDownload);
        });
    }
  }

  // OPEN

  openBlob(data: any, options?: BlobPropertyBag, fileNameToDownload?: string) {
    this.openFileBlob(new Blob([data], options), fileNameToDownload);
  }

  openFileBlob(blob: Blob, fileNameToDownload?: string) {
    if (blob) {
      const reader = new FileReader();
      reader.onload = () => {
        const timeoutHandle = setTimeout(() => {
          const objectUrl = URL.createObjectURL(new Blob([blob], { type: blob.type }));
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = objectUrl;
          a.target = '_blank';
          if (fileNameToDownload) {
            a.download = fileNameToDownload;
          }
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);

          clearTimeout(timeoutHandle);
        });
        this.toastService.toastSuccess();
      };
      reader.onerror = () => {
        this.toastService.toastError();
      };
      reader.readAsDataURL(blob);
    } else {
      this.toastService.toastError();
    }
  }
}
