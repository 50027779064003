import { Component, computed, effect, inject } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap, tap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AcademyUnitModel, AcademyUnitPermission } from '~ngx-shared/models';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { CourseDetailComponent } from '~madrasa/academy/components/course-detail/course-detail.component';
import { ClassBookEntryListComponent } from '~madrasa/academy/components/class-book-entry-list/class-book-entry-list.component';
import { DataProviderOptionModel, GraphQlColumnModel } from '~ngx-shared/graph-ql';
import { UnitDetailComponent } from '~madrasa/academy/components/unit-detail/unit-detail.component';
import { PersonListComponent } from '~madrasa/staff/components/person-list/person-list.component';
import { TenantService } from '~madrasa/services';

@UntilDestroy()
@Component({
  selector: 'app-unit-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective,
    CourseDetailComponent,
    ClassBookEntryListComponent,
    NgxPermissionsModule,
    UnitDetailComponent,
    PersonListComponent
  ],
  templateUrl: './unit-detail-page.component.html',
  styleUrl: './unit-detail-page.component.scss'
})
export class UnitDetailPageComponent {
  readonly router = inject(Router);
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly tenantService = inject(TenantService);

  protected readonly AcademyUnitPermission = AcademyUnitPermission;

  cbePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  teacherPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;

  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{ result: AcademyUnitModel }>({
              query: gql`
                query ReadAcademyUnitById($id: bigint!) {
                  result: academy_unit_by_pk(id: $id) {
                    id
                    course {
                      id
                      name
                      course_name {
                        name
                      }
                    }
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result));
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );

  readonly teacherColumns = computed<GraphQlColumnModel[]>(() => {
    const item = this.item();
    if (!item || !this.tenantService.canCrudTeacher) {
      return [];
    }

    return [
      {
        path: 'unit_teachers.hourly_rate',
        query: `unit_teachers (where: { unit_id: { _eq: ${this.item()?.id} } }) { hourly_rate }`,
        type: 'currency',
        filter: {
          type: 'number'
        },
        patchResult: (result: any) => result?.unit_teachers?.[0]?.hourly_rate,
        sort: { isSortable: true }
      }
    ];
  });

  constructor() {
    effect(() => {
      const id = this.item()?.id;
      if (id) {
        this.teacherPatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (
            Array.isArray(options?.filter?._and) &&
            !options.filter?._and?.some((cond: any) => cond.unit_teachers)
          ) {
            options.filter._and.push({
              unit_teachers: {
                unit_id: {
                  _eq: this.item()?.id
                }
              }
            });
          }

          // Add default sorting
          if (!options.sortBy?.length) {
            options.sortBy = [{ first_name: 'asc' }];
          }
          return options;
        };

        this.cbePatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (
            Array.isArray(options?.filter?._and) &&
            !options.filter?._and?.some((cond: any) => cond.unit_id)
          ) {
            options.filter._and.push({
              unit_id: {
                _eq: this.item()?.id
              }
            });
          }
          return options;
        };
      }
    });
  }
}
