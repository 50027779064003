<lib-layout-base-page *transloco="let transloco" [titleParamValue]="this.item()?.name">
  <div class="flex flex-col">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('general_information') }}">
        <ng-template pTemplate="content">
          <div>
            <a
              *ngxPermissionsOnly="this.AcademySchoolPermission.UPDATE"
              [label]="transloco('edit_value', { value: transloco('school') })"
              [routerLink]="['/', 'academy', 'schools', this.item()?.id]"
              icon="pi pi-pencil"
              pButton
              type="button"></a>
          </div>
          <lib-detail [fields]="this.fields()"></lib-detail>
        </ng-template>
      </p-tabPanel>

      @if (this.item()?.id) {
        <p-tabPanel header="{{ transloco('students') }}">
          <ng-template pTemplate="content">
            <app-person-list
              [patchOptions]="this.studentPatchOptions"
              [showDetailButton]="this.authorizationService.cannot(Role.TEACHER)"
              [showExport]="true"
              [showFilter]="true"
              [showSort]="true"
              [stateKey]="this.router.url + 'school-detail-student-list'">
            </app-person-list>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="{{ transloco('teachers') }}">
          <ng-template pTemplate="content">
            <app-person-list
              [patchOptions]="this.teacherPatchOptions"
              [showDetailButton]="this.authorizationService.cannot(Role.TEACHER)"
              [showExport]="true"
              [showFilter]="true"
              [showSort]="true"
              [stateKey]="this.router.url + 'school-detail-teacher-list'">
            </app-person-list>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="{{ transloco('courses') }}">
          <ng-template pTemplate="content">
            <app-course-list
              [patchOptions]="this.coursePatchOptions"
              [showExport]="true"
              [showFilter]="true"
              [showSort]="true"
              [stateKey]="this.router.url + 'school-detail-course-list'"></app-course-list>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="{{ transloco('units') }}">
          <ng-template pTemplate="content">
            <app-unit-list
              [patchOptions]="this.unitPatchOptions"
              [showExport]="true"
              [showFilter]="true"
              [showSort]="true"
              [stateKey]="this.router.url + 'school-detail-unit-list'"></app-unit-list>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="{{ transloco('class_book_entries') }}">
          <ng-template pTemplate="content">
            <app-class-book-entry-list
              [patchOptions]="this.cbePatchOptions"
              [showExport]="true"
              [showFilter]="true"
              [showSort]="true"
              [stateKey]="this.router.url + 'school-detail-cbe-list'"></app-class-book-entry-list>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel
          *ngxPermissionsOnly="[Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR]"
          header="{{ transloco('notes') }}">
          <ng-template pTemplate="content">
            <app-note-list
              [patchInput]="this.notePatchInput"
              [patchOptions]="this.notePatchOptions"
              [stateKey]="this.router.url + 'school-detail-note-list'"
              [table]="'note_school_note'"></app-note-list>
          </ng-template>
        </p-tabPanel>
      }
    </p-tabView>
  </div>
</lib-layout-base-page>
