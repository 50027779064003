export enum CoreAcademicDegreesPrefix {
  DIPL_PAD = 'Dipl_Pad',
  DIPL_THEOL = 'Dipl_Theol',
  DI = 'DI',
  MAG = 'Mag',
  MAG_THEOL = 'Mag_theol',
  MAG_IUR = 'Mag_iur',
  MAG_RER_NAT = 'Mag_rer_nat',
  MAG_PHIL = 'Mag_phil',
  DR = 'Dr',
  DR_THEOL = 'Dr_theol',
  DR_PHIL = 'Dr_phil',
  DR_IUR = 'Dr_iur',
  DR_RER_SOC_OEC = 'Dr_rer_soc_oec',
  PROF = 'Prof'
}
