<lib-formly-form
  #formComponent
  (savedEvent)="this.savedEvent.next($event)"
  [fields]="this.fields"
  [form]="this.form"
  [model]="this.model"
  [navigateBackOnCancel]="true"
  [navigateBackOnSave]="true"
  [options]="this.options"
  [showCancelButton]="this.showCancelButton()"
  [showSaveButton]="this.showSaveButton()"
  [showToastMessages]="this.showToastMessages()"
  [submit]="this.submit">
</lib-formly-form>
