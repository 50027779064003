<div *transloco="let transloco" class="flex items-center gap-2">
  <p-colorPicker
    [formControl]="this.formControl"
    [formlyAttributes]="this.field"
    [inline]="this.props.inline"
    [ngClass]="{
      'ng-dirty ng-invalid p-invalid': this.showError
    }"
    [ngModel]="this.formControl.value"></p-colorPicker>

  <input
    [formControl]="this.formControl"
    [formlyAttributes]="this.field"
    [ngClass]="{
      'ng-dirty ng-invalid p-invalid': this.showError
    }"
    [placeholder]="transloco(this.props.placeholder || '------')"
    [readonly]="this.props.readonly"
    [type]="this.props.type || 'text'"
    [value]="this.formControl.value"
    class="w-full"
    pInputText />
</div>
