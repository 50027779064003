<app-person-list
  *transloco="let transloco"
  [columns]="this.columns()"
  [deleteFunc]="this.deleteFunc"
  [patchOptions]="this.patchOptions()"
  [route]="'students'"
  [showDeleteButton]="this.authorizationService.can(this.StaffStudentPermission.SOFT_DELETE)"
  [showDetailButton]="this.authorizationService.can(this.StaffStudentPermission.READ)"
  [showExport]="true"
  [showFilter]="true"
  [showSort]="true"
  [showUpdateButton]="this.authorizationService.can(this.StaffStudentPermission.UPDATE)"
  [stateKey]="this.stateKey()">
  <ng-template let-data libTemplate="fatherCell">
    <ng-container
      [ngTemplateOutletContext]="{
        relationshipA: data.father_side_a,
        relationshipB: data.father_side_b
      }"
      [ngTemplateOutlet]="relationshipTemplate"></ng-container>
  </ng-template>
  <ng-template let-data libTemplate="motherCell">
    <ng-container
      [ngTemplateOutletContext]="{
        relationshipA: data.mother_side_a,
        relationshipB: data.mother_side_b
      }"
      [ngTemplateOutlet]="relationshipTemplate"></ng-container>
  </ng-template>
  <ng-template let-data libTemplate="siblingCell">
    <ng-container
      [ngTemplateOutletContext]="{
        relationshipA: data.sibling_side_a,
        relationshipB: data.sibling_side_b
      }"
      [ngTemplateOutlet]="relationshipTemplate"></ng-container>
  </ng-template>

  <ng-template
    #relationshipTemplate
    let-relationshipA="relationshipA"
    let-relationshipB="relationshipB">
    <div class="flex flex-col gap-2">
      @for (relationship of relationshipA; track relationship) {
        <app-person-detail-link
          [person]="relationship['current_person_data']"></app-person-detail-link>
      }

      @for (relationship of relationshipB; track relationship) {
        <app-person-detail-link
          [person]="relationship['current_person_data']"></app-person-detail-link>
      }
    </div>
  </ng-template>
</app-person-list>
