import { Component, OnInit, signal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { FormlyModule } from '~ngx-shared/formly';
import { BasePageComponent } from '~ngx-shared/layout';
import { CreateStudentDataFormComponent } from '~madrasa/staff/components/create-student-data-form/create-student-data-form.component';

@UntilDestroy()
@Component({
  selector: 'app-create-student-data-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule, CreateStudentDataFormComponent],
  templateUrl: './create-student-data-form-page.component.html',
  styleUrl: './create-student-data-form-page.component.scss'
})
export class CreateStudentDataFormPageComponent implements OnInit {
  readonly personId = signal<number | undefined>(undefined);
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(data => {
      const hasId = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
      this.personId.set(hasId ? data['crud']['id'] : undefined);
    });
  }
}
