import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface EditorProps extends FieldWrapperProps {
  rows?: number;
  cols?: number;
}

export interface EditorFieldConfig extends FormlyFieldConfig<EditorProps> {
  type?: 'editor' | Type<EditorTypeComponent>;
}

@Component({
  selector: 'lib-editor-type',
  templateUrl: './editor-type.component.html',
  styleUrl: './editor-type.component.css'
})
export class EditorTypeComponent extends FieldType<FieldTypeConfig<EditorProps>> {
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      // [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      // [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button

      ['link'] //, 'image', 'video'] // link and image, video
    ]
  };
}
