import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { map, of, switchMap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { validateIBAN } from 'ngx-iban-validator';
import { TranslocoService } from '@jsverse/transloco';
import { LoadingService } from '~ngx-shared/layout';
import { FormlyService, UserService } from '~madrasa/services';
import {
  CoreAcademicDegreesPrefix,
  CoreAcademicDegreesSuffix,
  CoreCountryEnum,
  CoreGenderEnum,
  CoreMaritalStatusEnum,
  CorePersonDataModel,
  FilePersonMediaTypeEnum
} from '~ngx-shared/models';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { ModelUtil, QueryUtil } from '~ngx-shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-profile-form',
  standalone: true,
  imports: [FormlyModule],
  templateUrl: './profile-form.component.html',
  styleUrl: './profile-form.component.scss'
})
export class ProfileFormComponent implements OnInit {
  form = new FormGroup({});
  readOnlyModel: CorePersonDataModel = {};
  model: CorePersonDataModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_core_person'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  constructor(
    private apollo: Apollo,
    private userService: UserService,
    private loadingService: LoadingService,
    private formlyService: FormlyService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.loadingService.startLoading();
    this.userService.user$
      .pipe(
        untilDestroyed(this),
        switchMap(user => {
          const personId = user?.person_id;
          if (personId) {
            return this.apollo
              .query<{ result: CorePersonDataModel[] }>({
                query: gql`
                  query ReadCoreCurrentPersonDataById($id: bigint!) {
                    result: core_current_person_data(where: { person_id: { _eq: $id } }, limit: 1) {
                      id
                      created_at
                      updated_at
                      address
                      email_address
                      phone_number
                      academic_degree_prefix
                      academic_degree_suffix
                      account_owner
                      bic
                      date_of_birth
                      place_of_birth
                      extra_name
                      first_name
                      gender
                      iban
                      insurance_number
                      last_name
                      marital_status
                      person_id
                      zmr_number

                      profile_picture: person_media(
                        limit: 1
                        order_by: { created_at: desc }
                        where: { person_media_type: { _eq: "${FilePersonMediaTypeEnum.PROFILE_PICTURE}" } }
                      ) {
                        id
                      }
                    }
                  }
                `,
                variables: {
                  id: personId
                }
              })
              .pipe(map(queryResult => queryResult.data.result?.[0]));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          this.readOnlyModel = result;
          let model = cloneDeep(result);

          if (model) {
            this.model = {
              ...model,
              date_of_birth: FormlyUtil.fromIsoDateString(model.date_of_birth),
              profile_picture: model?.['profile_picture']?.map((item: any) => item.id)
            };
          }
        }

        this.fields = [
          {
            key: 'id'
          },
          {
            key: 'person_id'
          },
          FormlyUtil.createGroup('general_information', [
            FormlyUtil.createRow([
              FormlyUtil.createMultiSelectField('academic_degree_prefix', {
                props: {
                  options: Object.values(CoreAcademicDegreesPrefix).map(value => ({
                    label: 'academic_degree.' + value,
                    value: value
                  }))
                }
              }),
              FormlyUtil.createMultiSelectField('academic_degree_suffix', {
                props: {
                  options: Object.values(CoreAcademicDegreesSuffix).map(value => ({
                    label: 'academic_degree.' + value,
                    value: value
                  }))
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createTextField('first_name', {
                props: {
                  required: true
                }
              }),
              FormlyUtil.createTextField('last_name', {
                props: {
                  required: true
                }
              }),
              FormlyUtil.createTextField('extra_name')
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createDatePickerField('date_of_birth', {
                props: {
                  required: true
                }
              }),
              FormlyUtil.createTextField('place_of_birth')
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createSelectField('citizenship', {
                defaultValue: 'AT',
                props: {
                  required: true,
                  options: Object.keys(CoreCountryEnum).map(key => ({
                    label: 'country_iso.' + key,
                    value: key
                  }))
                }
              }),
              FormlyUtil.createTextField('profession')
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createSelectField('gender', {
                defaultValue: CoreGenderEnum.MALE,
                props: {
                  required: true,
                  options: Object.values(CoreGenderEnum).map(value => ({
                    label: 'gender.' + value,
                    value: value
                  }))
                }
              }),
              FormlyUtil.createSelectField('marital_status', {
                defaultValue: CoreMaritalStatusEnum.SINGLE,
                props: {
                  required: true,
                  options: Object.values(CoreMaritalStatusEnum).map(value => ({
                    label: 'marital_status.' + value,
                    value: value
                  }))
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createInputMaskField('insurance_number', {
                props: {
                  type: 'number',
                  mask: '9999 999999'
                }
              }),
              FormlyUtil.createTextField('zmr_number', {
                props: {
                  type: 'number'
                }
              })
            ]),
            this.formlyService.createFileUploadFieldConfig('profile_picture', {
              props: {
                required: false,
                namespace: 'person_media',
                accept: 'image/*',
                maxFiles: 1
              }
            }),
            this.formlyService.createAddressFieldConfig(),
            this.formlyService.createPhoneNumberFieldConfig(),
            this.formlyService.createEmailAddressFieldConfig(),
            FormlyUtil.createGroup('account_infos', [
              FormlyUtil.createRow([
                FormlyUtil.createInputMaskField('iban', {
                  props: {
                    required: false,
                    mask: 'aa99 9999 9999 9999 9999',
                    characterPattern: '[A-Z]',
                    description: 'iban_description'
                  },
                  validators: {
                    iban: {
                      expression: (control: any) => !validateIBAN(control.value)?.ibanInvalid,
                      message: (error: any, field: any) =>
                        this.translocoService.selectTranslate('invalid_iban')
                    }
                  }
                }),
                FormlyUtil.createTextField('bic', {
                  props: {
                    required: false
                  }
                })
              ]),
              FormlyUtil.createRow([
                FormlyUtil.createTextField('account_owner', {
                  props: {
                    required: false
                  }
                })
              ])
            ])
          ])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,
        date_of_birth: FormlyUtil.toIsoDateString(formSaveModel.input.date_of_birth),
        ...(formSaveModel.input?.profile_picture?.length
          ? {
              person_media: {
                data: {
                  id: formSaveModel.input.profile_picture[0],
                  person_media_type: FilePersonMediaTypeEnum.PROFILE_PICTURE
                }
              }
            }
          : {})
      };

      ModelUtil.deleteKey(input);
      ModelUtil.deleteKey(input, 'person');
      ModelUtil.deleteKey(input, 'profile_picture');

      const params: string[] = [];
      const queries: string[] = [];
      let variables: any = { input };

      const updatePersonMedia = [
        // Get deleted person media
        ...(QueryUtil.comparer({
          itemsA: this.readOnlyModel?.['profile_picture']?.map((item: any) => item.id),
          itemsB: formSaveModel.input?.profile_picture,
          compareFunc: (itemA: any, itemB: any) => itemA === itemB,
          resultMapFunc: item => ({
            where: {
              id: { _eq: item }
            },
            _set: { deleted_at: 'now()' }
          })
        }) || [])
      ];

      if (updatePersonMedia.length) {
        params.push('$updatePersonMedia: [file_person_media_updates!] = []');
        queries.push('update_file_person_media_many(updates: $updatePersonMedia) { __typename }');
        variables = {
          ...variables,
          updatePersonMedia
        };
      }

      return this.apollo.mutate({
        mutation: gql`
          mutation CreateCorePersonData(
            $input: core_person_data_insert_input!,
            ${params.join('\n')}
            ) {
            result: insert_core_person_data_one(object: $input) {
              __typename
            }
            ${queries.join('\n')}
          }
        `,
        variables
      });
    };
  }

  savedEvent(formSaveModel: FormSaveModel) {
    if (!formSaveModel.hasError) {
      document.location.reload();
    }
  }
}
