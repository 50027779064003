import { Component, effect, inject, input, OnInit, ViewChild } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { Apollo, gql } from 'apollo-angular';
import { TooltipModule } from 'primeng/tooltip';
import { map } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CreatePersonDocumentFormDialogComponent } from '~madrasa/file/components/create-person-document-form-dialog/create-person-document-form-dialog.component';
import { FilePersonDocumentModel, FilePersonDocumentTypeModel } from '~ngx-shared/models';
import { ConfirmationService } from '~ngx-shared/layout';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { DialogUtil } from '~ngx-shared/utils/dialog.util';
import { FileService } from '~madrasa/services';

@UntilDestroy()
@Component({
  selector: 'app-person-document-list',
  standalone: true,
  imports: [
    ButtonDirective,
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    GraphQlAdvancedTableComponent,
    NgxPermissionsModule,
    TemplateDirective,
    TranslocoDirective,
    TooltipModule
  ],
  templateUrl: './person-document-list.component.html',
  styleUrl: './person-document-list.component.scss'
})
export class PersonDocumentListComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly fileService = inject(FileService);
  readonly dialogService = inject(DialogService);
  readonly confirmationService = inject(ConfirmationService);
  readonly translocoService = inject(TranslocoService);

  personId = input<number | undefined>(undefined);

  showFilter = input<boolean>(false);
  showSort = input<boolean>(false);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);
  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();

  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  graphQlTable: GraphQlTableModel;

  constructor() {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const patchOptions = this.patchOptions();
        const personId = this.personId();

        if (patchOptions) {
          options = patchOptions(options);
        }

        if (personId) {
          if (!Array.isArray(options?.filter?._and)) {
            options.filter = {
              ...options.filter,
              _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
            };
          }
          if (
            Array.isArray(options?.filter?._and) &&
            !options.filter?._and?.some((cond: any) => cond.person_id)
          ) {
            options.filter._and.push({
              person_id: {
                _eq: personId
              }
            });
          }
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ name: 'asc' }];
        }

        return options;
      };
    });
  }

  ngOnInit() {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        sort: { isSortable: false },
        filter: { isNotFilterable: true },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        label: 'person_document_type',
        path: 'person_document_type.name',
        filter: {
          label: 'person_document_types',
          path: 'person_document_type.id',
          type: 'array',
          options: this.apollo
            .query<{
              result: FilePersonDocumentTypeModel[];
            }>({
              query: gql`
                query ReadAcademySchool($where: file_person_document_type_bool_exp) {
                  result: file_person_document_type(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(item => ({
                  label: item.name,
                  value: item.id
                }))
              )
            )
        },
        sort: { isSortable: true }
      },
      {
        path: 'name',
        filter: { type: 'string' },
        sort: { isSortable: true }
      },
      {
        path: 'size',
        type: 'filesize',
        filter: { type: 'number' },
        sort: { isSortable: true }
      },
      {
        label: 'uploaded_at',
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true }
      }
    ];

    this.graphQlTable = {
      table: 'file_person_document',
      isPaginated: true,

      showCurrentPageReport: true,
      columns: columns
    };
  }

  getRow(data: any): FilePersonDocumentModel | undefined {
    return data;
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      let mutation: any = gql`
        mutation SoftDeleteFilePersonDocumentById($id: uuid!) {
          result: update_file_person_document_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: "now()" }
          ) {
            __typename
          }
        }
      `;

      this.confirmationService.confirmDeleteApollo({
        name: row.name,
        mutationOptions: {
          mutation,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }

  openDialog() {
    if (this.personId()) {
      this.dialogService
        .open(CreatePersonDocumentFormDialogComponent, {
          ...DialogUtil.BASE_DIALOG_CONFIG,
          header: this.translocoService.translate('add_value', {
            value: this.translocoService.translate('document')
          }),
          data: {
            personId: this.personId()
          }
        })
        .onClose.pipe(untilDestroyed(this))
        .subscribe(() => {
          this.tableComponent?.updateTable();
        });
    }
  }

  downloadFile(data: any, show: boolean = false) {
    const row = this.getRow(data);
    if (row) {
      this.fileService.downloadFile(row.id, 'person_document', show ? undefined : row.name);
    }
  }
}
