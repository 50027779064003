import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface DatePickerProps extends FieldWrapperProps {
  defaultDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
  hourFormat?: string;
  showTime?: boolean;
  showButtonBar?: boolean;
  showOtherMonths?: boolean;
  selectOtherMonths?: boolean;
  numberOfMonths?: number;
  selectionMode?: 'single' | 'multiple' | 'range';
  inline?: boolean;
  touchUI?: boolean;
  timeOnly?: boolean;
  showWeek?: boolean;
  disabledDays?: number[];
  disabledDates?: Date[];
  view?: 'date' | 'month';
  dataType?: 'date' | 'string';
}

export interface DatepickerFieldConfig extends FormlyFieldConfig<DatePickerProps> {
  type?: 'date-picker' | Type<DateTypeComponent>;
}

@Component({
  selector: 'lib-formly-date-type',
  templateUrl: './date-type.component.html',
  styleUrl: './date-type.component.css'
})
export class DateTypeComponent extends FieldType<FieldTypeConfig<DatePickerProps>> {}
