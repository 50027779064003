import {
  Component,
  computed,
  ContentChildren,
  effect,
  input,
  QueryList,
  ViewChild
} from '@angular/core';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { RouterLink } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { map } from 'rxjs';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { TemplateDirective } from '~ngx-shared/directives';
import { ConfirmationService } from '~ngx-shared/layout';
import { AuthorizationService } from '~ngx-shared/authentication';
import { TenantService } from '~madrasa/services';
import {
  AcademyCourseModel,
  AcademyCoursePermission,
  AcademyCoursePricePermission,
  AcademyOrganisationModel,
  AcademySchoolModel,
  AcademySchoolPeriodModel
} from '~ngx-shared/models';
import { PersonDetailLinkComponent } from '~madrasa/staff/components/person-detail-link/person-detail-link.component';
import { ModelUtil } from '~ngx-shared/utils';

@Component({
  selector: 'app-course-list',
  standalone: true,
  imports: [
    TranslocoDirective,
    GraphQlAdvancedTableComponent,
    ButtonDirective,
    TooltipModule,
    RouterLink,
    TemplateDirective,
    NgxPermissionsModule,
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    PersonDetailLinkComponent
  ],
  templateUrl: './course-list.component.html',
  styleUrl: './course-list.component.scss'
})
export class CourseListComponent {
  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>;

  showCreateButton = input<boolean>(false);
  showFilter = input<boolean>(false);
  showSort = input<boolean>(false);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);
  hideTeacherColumn = input<boolean>(false);

  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();
  columns = input<GraphQlColumnModel[]>([]);

  graphQlTable = computed(() => {
    let columns = this.columns();

    columns.unshift(...this.getDefaultColumns());

    const graphQlTable: GraphQlTableModel = {
      table: 'academy_course',
      isPaginated: true,

      showCurrentPageReport: true,
      columns
    };
    return graphQlTable;
  });

  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  protected readonly AcademyCoursePermission = AcademyCoursePermission;

  constructor(
    private confirmationService: ConfirmationService,
    private authorizationService: AuthorizationService,
    private tenantService: TenantService,
    private translocoService: TranslocoService,
    private apollo: Apollo
  ) {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ updated_at: 'desc' }];
        }

        return options;
      };
    });
  }

  getDefaultColumns(): GraphQlColumnModel[] {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        type: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        sort: { isSortable: true },
        filter: { type: 'number' }
      },
      {
        path: 'color',
        sort: { isSortable: false },
        filter: { isNotFilterable: true },
        isNotExportable: true
      },
      {
        label: 'organisation',
        path: 'school.organisation.name',
        filter: {
          label: 'organisation',
          path: 'school.organisation_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademyOrganisationModel[];
            }>({
              query: gql`
                query ReadAcademyOrganisation($where: academy_organisation_bool_exp) {
                  result: academy_organisation(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(organisation => ({
                  label: organisation.name,
                  value: organisation.id
                }))
              )
            )
        },
        sort: { isSortable: true }
      },
      {
        label: 'school',
        path: 'school.name',
        filter: {
          label: 'school',
          path: 'school_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademySchoolModel[];
            }>({
              query: gql`
                query ReadAcademySchool($where: academy_school_bool_exp) {
                  result: academy_school(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(school => ({
                  label: school.name,
                  value: school.id
                }))
              )
            )
        },
        sort: { isSortable: false }
      },
      this.tenantService.currentTenant?.are_course_names_forced
        ? {
            label: 'name',
            path: 'course_name.name',
            filter: { type: 'string' },
            sort: { isSortable: true }
          }
        : {
            path: 'name',
            filter: { type: 'string' },
            sort: { isSortable: true }
          },
      {
        label: 'course_type',
        path: 'course_type.name',
        filter: { type: 'string' },
        sort: { isSortable: false }
      },
      {
        label: 'school_period',
        path: 'school_period.name',
        filter: {
          label: 'school_period',
          path: 'school_period_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademySchoolPeriodModel[];
            }>({
              query: gql`
                query ReadAcademySchoolPeriod($where: academy_school_period_bool_exp) {
                  result: academy_school_period(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(school => ({
                  label: school.name,
                  value: school.id
                }))
              )
            )
        },
        sort: { isSortable: false }
      },
      {
        path: 'is_online',
        type: 'boolean',
        filter: { type: 'boolean' },
        sort: { isSortable: true }
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        label: 'teachers',
        path: 'course_teachers',
        query: `
          course_teachers (order_by: { current_person_data: { first_name: asc } }) {
            teaching_role
            current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
          }
        `,
        patchResult: value =>
          value?.course_teachers
            .map((courseTeacher: any) =>
              ModelUtil.getFullName(courseTeacher.current_person_data, this.translocoService)
            )
            .join(', '),
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        hidden: this.hideTeacherColumn()
      },
      {
        label: 'student_count',
        path: 'latest_course_students_active_aggregate.count',
        query: `latest_course_students_active_aggregate { aggregate { count } }`,
        filter: { isNotFilterable: true },
        sort: { isSortable: true },
        patchResult: (result: any) =>
          result?.latest_course_students_active_aggregate?.aggregate?.count
      },
      {
        label: 'unit_count',
        path: 'units_aggregate.count',
        query: `units_aggregate { aggregate { count } }`,
        filter: { isNotFilterable: true },
        sort: { isSortable: true },
        patchResult: (result: any) => result?.units_aggregate?.aggregate?.count
      }
    ];

    if (this.authorizationService.can(AcademyCoursePricePermission.READ)) {
      columns.push(
        {
          label: 'course_price.single_price',
          path: 'course_course_price.single_price',
          type: 'currency',
          filter: { type: 'number' },
          sort: { isSortable: true }
        },
        {
          label: 'course_price.dual_price',
          path: 'course_course_price.dual_price',
          type: 'currency',
          filter: { type: 'number' },
          sort: { isSortable: true }
        },
        {
          label: 'course_price.three_or_more_price',
          path: 'course_course_price.three_or_more_price',
          type: 'currency',
          filter: { type: 'number' },
          sort: { isSortable: true }
        }
      );
    }

    return columns;
  }

  getRow(data: any): AcademyCourseModel | undefined {
    return data;
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      let mutation: any = gql`
        mutation SoftDeleteAcademyCourseById($id: bigint!) {
          result: update_academy_course_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: "now()" }
          ) {
            __typename
          }
        }
      `;

      this.confirmationService.confirmDeleteApollo({
        name: row.name,
        mutationOptions: {
          mutation,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }
}
