import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { CoreTenantModel } from '~ngx-shared/models';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil } from '~ngx-shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-create-update-tenant-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-tenant-form-page.component.html',
  styleUrl: './create-update-tenant-form-page.component.scss'
})
export class CreateUpdateTenantFormPageComponent implements OnInit {
  form = new FormGroup({});
  model: CoreTenantModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_tenant'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  constructor(
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: CoreTenantModel }>({
                query: gql`
                  query ReadCoreTenantById($id: bigint!) {
                    result: core_tenant_by_pk(id: $id) {
                      id
                      created_at
                      updated_at
                      name
                      slug
                      primary_color
                      secondary_color
                      logo_url
                      favicon_url
                      is_active
                      are_course_names_forced
                      organisations_allowed_count
                      schools_allowed_count
                      can_director_crud_teacher
                      can_director_crud_course_price
                      can_organisator_crud_course_price
                      can_organisator_crud_teacher
                      can_organisator_crud_director
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          this.model = cloneDeep(result);
        }

        this.fields = [
          FormlyUtil.createRow([
            FormlyUtil.createTextField('name', {
              props: {
                required: true
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createTextField('slug', {
              props: {
                required: true
              }
            })
          ]),
          FormlyUtil.createRow([FormlyUtil.createTextField('logo_url')]),
          FormlyUtil.createRow([FormlyUtil.createTextField('favicon_url')]),
          FormlyUtil.createRow([FormlyUtil.createNumberField('organisations_allowed_count')]),
          FormlyUtil.createRow([FormlyUtil.createNumberField('schools_allowed_count')]),
          FormlyUtil.createRow([
            FormlyUtil.createCheckboxField('is_active', {
              defaultValue: true,
              props: {
                required: true
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createCheckboxField('are_course_names_forced', {
              defaultValue: false
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createCheckboxField('can_director_crud_teacher', {
              defaultValue: false
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createCheckboxField('can_director_crud_course_price', {
              defaultValue: false
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createCheckboxField('can_organisator_crud_teacher', {
              defaultValue: false
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createCheckboxField('can_organisator_crud_course_price', {
              defaultValue: false
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createCheckboxField('can_organisator_crud_director', {
              defaultValue: false
            })
          ])
        ];
        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input
      };

      let options: any = {
        mutation: gql`
          mutation CreateCoreTenant($input: core_tenant_insert_input!) {
            result: insert_core_tenant_one(object: $input) {
              __typename
            }
          }
        `,
        variables: {
          input
        }
      };

      if (this.model?.id) {
        ModelUtil.deleteKey(input);

        options = {
          mutation: gql`
            mutation UpdateCoreTenant($id: bigint!, $input: core_tenant_set_input!) {
              result: update_core_tenant_by_pk(pk_columns: { id: $id }, _set: $input) {
                __typename
              }
            }
          `,
          variables: {
            id: this.model.id,
            input
          }
        };
      }

      return this.apollo.mutate(options);
    };
  }
}
