<p-table
  *transloco="let transloco"
  [value]="this.files()"
  responsiveLayout="scroll"
  styleClass="p-datatable-gridlines p-datatable-striped">
  @if (this.captionTemplate) {
    <ng-template pTemplate="caption">
      <ng-container [ngTemplateOutlet]="this.captionTemplate"></ng-container>
    </ng-template>
  }

  <ng-template pTemplate="header">
    <tr>
      <th class="w-1/12 text-center">{{ transloco('number') }}</th>
      <th>{{ transloco('name') }}</th>
      <th>{{ transloco('size') }}</th>
      <th>{{ transloco('actions') }}</th>
    </tr>
  </ng-template>

  <ng-template let-file let-rowIndex="rowIndex" pTemplate="body">
    <tr>
      <td class="w-1/12 text-center">{{ rowIndex + 1 }}</td>
      <td>
        <div>{{ file.name }}</div>
      </td>
      <td>{{ file.size | filesize: { separator: ',' } }}</td>
      <td class="w-0">
        <div class="flex items-center justify-center space-x-2">
          @if (!file.cannotShow) {
            <button
              (click)="this.fileService.downloadFile(file?.id, this.namespace())"
              [icon]="'pi pi-eye'"
              [pTooltip]="transloco('show')"
              class="p-button-info p-button-text mr-2"
              pButton
              tooltipPosition="top"
              type="button"></button>
          }

          @if (this.actionsTemplate) {
            <ng-container
              [ngTemplateOutletContext]="{ $implicit: file }"
              [ngTemplateOutlet]="this.actionsTemplate">
            </ng-container>
          }
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="4">
        {{ transloco('no_data') }}
      </td>
    </tr>
  </ng-template>
</p-table>
