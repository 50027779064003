import { Component, computed, inject } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, of, switchMap, tap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { AcademyCourseTypeModel } from '~ngx-shared/models';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { AuthorizationService, Role } from '~ngx-shared/authentication';

@UntilDestroy()
@Component({
  selector: 'app-course-type-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    DetailComponent,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective
  ],
  templateUrl: './course-type-detail-page.component.html',
  styleUrl: './course-type-detail-page.component.scss'
})
export class CourseTypeDetailPageComponent {
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly authorizationService = inject(AuthorizationService);

  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{ result: AcademyCourseTypeModel }>({
              query: gql`
                query ReadAcademyCourseTypeById($id: bigint!) {
                  result: academy_course_type_by_pk(id: $id) {
                    id
                    created_at
                    updated_at
                    organisation {
                      id
                      name
                    }
                    name
                    shortcut
                    description
                    is_active
                    ${
                      !this.authorizationService.can(Role.TEACHER)
                        ? `
                     course_type_course_price {
                       single_price
                       dual_price
                       three_or_more_price
                     }
                    `
                        : ''
                    }
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result));
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );

  readonly fields = computed<DetailField[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    const fields: DetailField[] = [
      {
        label: 'id',
        value: item.id
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'organisation',
        value: item.organisation?.name
      },
      {
        label: 'name',
        value: item.name
      },
      {
        label: 'shortcut',
        value: item.shortcut
      },
      {
        label: 'description',
        value: item.description
      },
      {
        label: 'is_active',
        type: 'boolean',
        value: item.is_active
      }
    ];

    if (!this.authorizationService.can(Role.TEACHER)) {
      fields.push(
        {
          label: 'course_price.single_price',
          type: 'currency',
          value: item.course_type_course_price?.single_price
        },
        {
          label: 'course_price.dual_price',
          type: 'currency',
          value: item.course_type_course_price?.dual_price
        },
        {
          label: 'course_price.three_or_more_price',
          type: 'currency',
          value: item.course_type_course_price?.three_or_more_price
        }
      );
    }

    return fields;
  });
}
