import {
  AcademyOrganisationOrganisatorModel,
  CorePersonDataModel,
  CorePersonModel,
  DefaultProperties,
  StaffStatusEnum
} from '~ngx-shared/models';
import { Role } from '~ngx-shared/authentication';

export interface StaffOrganisatorModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  status?: StaffStatusEnum;

  // Relations
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;

  organisation_organisators_active?: AcademyOrganisationOrganisatorModel[];
}

export class StaffOrganisatorPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffOrganisatorPermission.DELETE, Role.MANAGER];
}
