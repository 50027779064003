import { AccountingOperatingCostModel, DefaultProperties } from '~ngx-shared/models';
import { Role } from '~ngx-shared/authentication';

export interface AccountingOperatingCostTypeModel extends DefaultProperties {
  // Columns
  id?: number;
  name?: string;
  description?: string;

  // Relations
  operating_costs?: AccountingOperatingCostModel[];
}

export class AccountingOperatingCostTypePermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AccountingOperatingCostTypePermission.DELETE, Role.MANAGER];
}
