<lib-formly-form
  (cancelEvent)="this.canceled($event)"
  (savedEvent)="this.savedEvent($event)"
  [fields]="this.fields"
  [form]="this.form"
  [model]="this.model"
  [navigateBackOnCancel]="!this.dialogRef"
  [navigateBackOnSave]="!this.dialogRef"
  [saveButtonText]="'select'"
  [saveButtonIcon]="'pi pi-check'"
  [options]="this.options">
</lib-formly-form>
