import { Component, signal } from '@angular/core';
import { StepperModule } from 'primeng/stepper';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgClass, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { AccordionModule } from 'primeng/accordion';
import { FormGroup } from '@angular/forms';
import { ObjectUtils } from 'primeng/utils';
import { delay, map, of, switchMap } from 'rxjs';
import { Data, Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { StaffService } from '~madrasa/staff/services/staff.service';
import { LoggerService } from '~ngx-shared/services';
import { StaffDetailService } from '~madrasa/staff/services/staff-detail.service';
import { DetailComponent } from '~ngx-shared/ui/detail/detail.component';
import { CreateParentDataFormComponent } from '~madrasa/staff/components/create-parent-data-form/create-parent-data-form.component';
import { CreateStudentDataFormComponent } from '~madrasa/staff/components/create-student-data-form/create-student-data-form.component';
import { BasePageComponent, SpinnerComponent, ToastService } from '~ngx-shared/layout';

@Component({
  selector: 'app-new-registration-wizard-page',
  standalone: true,
  imports: [
    StepperModule,
    ButtonDirective,
    BasePageComponent,
    TranslocoDirective,
    CreateStudentDataFormComponent,
    CreateParentDataFormComponent,
    UpperCasePipe,
    AccordionModule,
    SpinnerComponent,
    DetailComponent,
    NgClass,
    NgTemplateOutlet
  ],
  templateUrl: './new-registration-wizard-page.component.html',
  styleUrl: './new-registration-wizard-page.component.scss'
})
export class NewRegistrationWizardPageComponent {
  studentForm = new FormGroup({});
  studentModel: any = {};

  fatherForm = new FormGroup({});
  fatherModel: any = {};

  motherForm = new FormGroup({});
  motherModel: any = {};

  readonly status = signal<Data>({
    isSaving: false,
    isSaved: false
  });
  readonly studentPersonId = signal('');
  readonly fatherPersonId = signal('');
  readonly motherPersonId = signal('');

  constructor(
    private apollo: Apollo,
    private router: Router,
    private staffService: StaffService,
    private loggerService: LoggerService,
    private staffDetailService: StaffDetailService,
    private toastService: ToastService
  ) {}

  getStudentFields() {
    return this.staffDetailService.studentDetailFields(this.studentModel);
  }

  getFatherFields() {
    return this.staffDetailService.parentDetailFields(this.fatherModel);
  }

  getMotherFields() {
    return this.staffDetailService.parentDetailFields(this.motherModel);
  }

  studentNext(nextCallback: any) {
    if (this.studentForm.invalid) {
      this.studentForm.markAllAsTouched();
      return;
    }

    if (!this.studentForm.dirty) {
      this.studentForm.markAllAsTouched();
      return;
    }

    // Copy default information to parents
    const defaultProperties = [
      'address',
      'iban',
      'bic',
      'account_owner',
      'organisations',
      'schools'
    ];
    this._propertyCopier(this.studentModel, this.fatherModel, defaultProperties);
    this._propertyCopier(this.studentModel, this.motherModel, defaultProperties);

    nextCallback.emit();
    this._clearQueryParams();
  }

  fatherNext(nextCallback: any) {
    if (this.fatherModel.first_name || this.fatherModel.last_name) {
      if (this.fatherForm.invalid) {
        this.fatherForm.markAllAsTouched();
        return;
      }

      if (!this.fatherForm.dirty) {
        this.fatherForm.markAllAsTouched();
        return;
      }
    }
    nextCallback.emit();
    this._clearQueryParams();
  }

  motherNext(nextCallback: any) {
    if (this.motherModel.first_name || this.motherModel.last_name) {
      if (this.motherForm.invalid) {
        this.motherForm.markAllAsTouched();
        return;
      }

      if (!this.motherForm.dirty) {
        this.motherForm.markAllAsTouched();
        return;
      }
    }
    nextCallback.emit();
    this._clearQueryParams();
  }

  back(prevCallback: any) {
    prevCallback.emit();
    this._clearQueryParams();
  }

  restart() {
    document.location.reload();
  }

  save() {
    this.status.set({
      ...this.status(),
      isSaving: true,
      isStudentSaving: true
    });
    // Start with the student form
    this.staffService
      .studentSubmit(
        {
          input: this.studentForm.getRawValue(),
          init: this.studentModel
        },
        this.studentModel
      )
      .pipe(
        map(mutationResult => {
          const hasErrors = this._checkForErrors(mutationResult);

          this.status.set({
            ...this.status(),
            isStudentSaving: false,
            isStudentDone: true,
            isStudentSuccessfulSaved: !hasErrors
          });

          if (!hasErrors) {
            this.studentPersonId.set(
              ObjectUtils.resolveFieldData(mutationResult, 'data.result.person_id')
            );
          }

          return hasErrors;
        }),
        delay(500),
        switchMap(hasErrors => {
          if (hasErrors) {
            return of(hasErrors);
          }
          if (!this.fatherModel.first_name) {
            return of(undefined);
          }
          this.status.set({
            ...this.status(),
            isFatherSaving: true
          });

          return this.staffService.parentSubmit(
            {
              input: this.fatherForm.getRawValue(),
              init: this.fatherModel
            },
            this.fatherModel
          );
        }),
        map(mutationResult => {
          // We got an error from student form
          if (mutationResult === true) {
            return true;
          }
          // We don't have a father form
          if (mutationResult === undefined) {
            return undefined;
          }

          const hasErrors = this._checkForErrors(mutationResult);

          if (!hasErrors) {
            this.fatherPersonId.set(
              ObjectUtils.resolveFieldData(mutationResult, 'data.result.person_id')
            );
          }

          this.status.set({
            ...this.status(),
            isFatherSaving: false,
            isFatherDone: true,
            isFatherSuccessfulSaved: !hasErrors
          });

          return hasErrors;
        }),
        delay(500),
        switchMap(hasErrors => {
          if (hasErrors === true) {
            return of(hasErrors);
          }

          if (!this.motherModel.first_name) {
            return of(undefined);
          }
          this.status.set({
            ...this.status(),
            isMotherSaving: true
          });
          return this.staffService.parentSubmit(
            {
              input: this.motherForm.getRawValue(),
              init: this.motherModel
            },
            this.motherModel
          );
        }),
        map(mutationResult => {
          // We got an error from student form
          if (mutationResult === true) {
            return true;
          }
          // We don't have a mother form
          if (mutationResult === undefined) {
            return undefined;
          }
          const hasErrors = this._checkForErrors(mutationResult);

          if (!hasErrors) {
            this.motherPersonId.set(
              ObjectUtils.resolveFieldData(mutationResult, 'data.result.person_id')
            );
          }

          this.status.set({
            ...this.status(),
            isMotherSaving: false,
            isMotherDone: true,
            isMotherSuccessfulSaved: !hasErrors
          });

          return hasErrors;
        }),
        delay(500),
        switchMap(hasErrors => {
          if (hasErrors === true) {
            return of(hasErrors);
          }
          if (hasErrors === undefined) {
            return of(undefined);
          }

          const inputs: any[] = [];

          if (this.studentPersonId() && this.fatherPersonId()) {
            inputs.push({
              person_id: this.studentPersonId(),
              person_in_relationship_id: this.fatherPersonId(),
              relationship: 'child_father'
            });
          }

          if (this.studentPersonId() && this.motherPersonId()) {
            inputs.push({
              person_id: this.studentPersonId(),
              person_in_relationship_id: this.motherPersonId(),
              relationship: 'child_mother'
            });
          }

          if (this.fatherPersonId() && this.motherPersonId()) {
            inputs.push({
              person_id: this.fatherPersonId(),
              person_in_relationship_id: this.motherPersonId(),
              relationship: 'partner'
            });
          }

          // Save relationships
          return this.apollo.mutate({
            mutation: gql`
              mutation CreateCorePersonRelationships(
                $inputs: [core_person_relationship_insert_input!] = []
              ) {
                result: insert_core_person_relationship(objects: $inputs) {
                  __typename
                }
              }
            `,
            variables: {
              inputs
            }
          });
        })
      )
      .subscribe(mutationResult => {
        let hasErrors = mutationResult === true || mutationResult === undefined;
        if (!hasErrors) {
          hasErrors = this._checkForErrors(mutationResult);
        }

        this.status.set({
          ...this.status(),
          isSaving: false,
          isSaved: true,
          hasErrors
        });

        if (hasErrors) {
          this.toastService.toastErrorSave();
        } else {
          this.toastService.toastSuccessSave();
        }
      });
  }

  private _propertyCopier(source: any, target: any, properties: string[]) {
    properties.forEach(property => {
      if (source[property]) {
        target[property] = source[property];
      }
    });
  }

  private _clearQueryParams() {
    void this.router.navigate([], {
      queryParams: {}
    });
  }

  private _checkForErrors(mutationResult: any) {
    const errors = ObjectUtils.resolveFieldData(mutationResult, 'errors');
    if (errors) {
      console.error(errors);
      if (Array.isArray(errors)) {
        errors.forEach(error => {
          this.loggerService.error(error.message, JSON.stringify(error.extensions));
        });
      } else {
        this.loggerService.error(errors.message, JSON.stringify(errors.extensions));
      }
    }
    return !!errors;
  }
}
