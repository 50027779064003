import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface InputMaskProps extends FieldWrapperProps {
  iconPre?: string;
  iconPost?: string;
  characterPattern?: string;
  mask?: string;
  slotChar?: string;
}

export interface InputMaskFieldConfig extends FormlyFieldConfig<InputMaskProps> {
  type?: 'input-mask' | Type<InputMaskTypeComponent>;
}

@Component({
  selector: 'lib-input-mask-type',
  templateUrl: './input-mask-type.component.html',
  styleUrl: './input-mask-type.component.css'
})
export class InputMaskTypeComponent extends FieldType<FieldTypeConfig<InputMaskProps>> {}
