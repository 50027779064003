import { Injectable, signal } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoggerService } from '~ngx-shared/services/logger.service';

@UntilDestroy({ checkProperties: true })
@Injectable({ providedIn: 'root' })
export class VersionService {
  readonly isNewVersionAvailable = signal(false);

  constructor(
    private loggerService: LoggerService,
    private swUpdate: SwUpdate
  ) {
    if (!this.swUpdate.isEnabled) {
      console.info('Service worker updates are disabled.');
      return;
    }
    console.info('Service worker updates are enabled.');
    swUpdate.versionUpdates.pipe(untilDestroyed(this)).subscribe(event => {
      if (event.type === 'VERSION_READY') {
        this.isNewVersionAvailable.set(true);
      }
    });
  }

  applyUpdate(): void {
    // Reload the page to update to the latest version after the new version is activated
    this.swUpdate
      .activateUpdate()
      .then(() => document.location.reload())
      .catch(error => {
        console.error('Failed to apply updates:', error);
        this.loggerService.error(error?.message, error.stack);
      });
  }
}
