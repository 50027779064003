import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from '~ngx-shared/services';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private loggerService: LoggerService) {}

  handleError(error: any): void {
    console.error('An error occurred:', error);
    this.loggerService.error(error?.message, error.stack);
  }
}
