import { Component, effect, input, signal } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { cloneDeep } from 'lodash-es';
import { UpperCasePipe } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ObjectUtils } from 'primeng/utils';
import { BusyComponent } from '~ngx-shared/layout';
import { AuthorizationService } from '~ngx-shared/authentication';
import {
  AcademyCourseModel,
  AcademyCourseTypeModel,
  AcademyOrganisationModel,
  AcademySchoolModel,
  StaffTeacherPermission
} from '~ngx-shared/models';

@UntilDestroy()
@Component({
  selector: 'app-statistic-overall-table',
  standalone: true,
  imports: [TranslocoDirective, NgxPermissionsModule, UpperCasePipe, BusyComponent],
  templateUrl: './statistic-overall-table.component.html',
  styleUrl: './statistic-overall-table.component.scss'
})
export class StatisticOverallTableComponent {
  readonly schoolPeriodId = input.required<number>();

  readonly isLoading = signal(true);
  readonly organisations = signal<AcademyOrganisationModel[]>([]);
  readonly courses = signal<{ [key: string]: AcademyCourseModel[] }>({});
  protected readonly StaffTeacherPermission = StaffTeacherPermission;

  constructor(
    private apollo: Apollo,
    private authorizationService: AuthorizationService
  ) {
    effect(
      () => {
        this.isLoading.set(true);
        const schoolPeriodId = this.schoolPeriodId();
        if (schoolPeriodId) {
          this.isLoading.set(true);

          let variables: any = {};

          // Get overall ids
          this.apollo
            .query<{
              organisations: AcademyOrganisationModel[];
              schools: AcademySchoolModel[];
              course_types: AcademyCourseTypeModel[];
            }>({
              query: gql`
                query ReadStatisticOverall(
                  $orgFilter: academy_organisation_bool_exp = {}
                  $schoolFilter: academy_school_bool_exp = {}
                  $courseTypeFilter: academy_course_type_bool_exp = {}
                ) {
                  organisations: academy_organisation(where: $orgFilter) {
                    id
                    name
                  }
                  schools: academy_school(where: $schoolFilter) {
                    id
                    name
                    organisation_id
                  }
                  course_types: academy_course_type(where: $courseTypeFilter) {
                    id
                    name
                    organisation_id
                  }
                }
              `,
              variables
            })
            .subscribe(queryResult => {
              // Get Organisations
              const organisations = cloneDeep(queryResult.data.organisations);
              // Get Schools
              queryResult.data.schools.forEach(school => {
                const organisation = organisations.find(org => org.id === school.organisation_id);
                if (organisation) {
                  if (!organisation.schools) {
                    organisation.schools = [];
                  }
                  organisation.schools.push(cloneDeep(school));
                }
              });
              // Get Course Types
              queryResult.data.course_types.forEach(courseType => {
                const organisation = organisations.find(
                  org => org.id === courseType.organisation_id
                );
                if (organisation) {
                  if (!organisation.course_types) {
                    organisation.course_types = [];
                  }
                  organisation.course_types.push(cloneDeep(courseType));
                }
              });

              // Create for each organisation each school and course type a query
              const query: string[] = [];
              organisations.forEach(organisation => {
                organisation.schools?.forEach(school => {
                  organisation.course_types?.forEach(courseType => {
                    query.push(`
                org_${organisation.id}_school_${school.id}_type_${courseType.id}: academy_course(where: { school_period_id: { _eq: ${schoolPeriodId} }, school_id: {_eq: ${school.id} }, course_type_id: { _eq: ${courseType.id} } }) {
                  id
                  name
                  course_name {
                    name
                  }
                  student_male: latest_course_students_active_aggregate(where:{current_person_data: {gender: {_eq: male}}}) {
                    aggregate {
                      count
                    }
                  }
                  student_female: latest_course_students_active_aggregate(where:{current_person_data: {gender: {_eq: female}}}) {
                    aggregate {
                      count
                    }
                  }
                  student_all: latest_course_students_active_aggregate{
                    aggregate {
                      count
                    }
                  }
                  ${
                    this.authorizationService.can(StaffTeacherPermission.READ)
                      ? `
                  teacher_male: course_teachers_aggregate(where: {current_person_data: {gender: {_eq: male}}}) {
                    aggregate {
                      count
                    }
                  }
                  teacher_female: course_teachers_aggregate(where: {current_person_data: {gender: {_eq: female}}}) {
                    aggregate {
                      count
                    }
                  }
                  teacher_all: course_teachers_aggregate {
                    aggregate {
                      count
                    }
                  }
                  `
                      : ''
                  }
                  units_aggregate {
                    aggregate {
                      count
                    }
                  }
                }
           `);
                  });
                });
              });

              this.apollo
                .query<{
                  [key: string]: AcademyCourseModel[];
                }>({
                  query: gql`query ReadStatisticOverallDetails {
              ${query.join('\n')}
            }`
                })
                .subscribe(queryResult => {
                  // Parse query result and assign to organisations course types
                  // And add sums
                  organisations.forEach(organisation => {
                    organisation.schools?.forEach(school => {
                      const sums: any = {
                        student_female: 0,
                        student_male: 0,
                        student_all: 0,
                        teacher_female: 0,
                        teacher_male: 0,
                        teacher_all: 0,
                        units: 0
                      };
                      organisation.course_types?.forEach(courseType => {
                        const key = `org_${organisation.id}_school_${school.id}_type_${courseType.id}`;
                        if (key in queryResult.data && queryResult.data[key].length) {
                          const available_courses = queryResult.data[key];

                          sums.student_female += this.getSum(
                            available_courses,
                            'student_female.aggregate.count'
                          );
                          sums.student_male += this.getSum(
                            available_courses,
                            'student_male.aggregate.count'
                          );
                          sums.student_all += this.getSum(
                            available_courses,
                            'student_all.aggregate.count'
                          );
                          sums.teacher_female += this.getSum(
                            available_courses,
                            'teacher_female.aggregate.count'
                          );
                          sums.teacher_male += this.getSum(
                            available_courses,
                            'teacher_male.aggregate.count'
                          );
                          sums.teacher_all += this.getSum(
                            available_courses,
                            'teacher_all.aggregate.count'
                          );
                          sums.units += this.getSum(
                            available_courses,
                            'units_aggregate.aggregate.count'
                          );

                          courseType['has_courses'] = true;
                          school['has_courses'] = true;
                          organisation['has_courses'] = true;
                        }
                      });

                      school['sums'] = sums;
                    });
                  });

                  this.courses.set(queryResult.data);
                  this.organisations.set(organisations);

                  this.isLoading.set(false);
                });
            });
        }
      },
      { allowSignalWrites: true }
    );
  }

  getSum(available_courses: AcademyCourseModel[], path: string) {
    return available_courses.reduce((sum, course) => {
      return sum + (ObjectUtils.resolveFieldData(course, path) || 0);
    }, 0);
  }
}
