import { Component, input } from '@angular/core';

@Component({
  selector: 'lib-formly-html-viewer',
  templateUrl: './html-viewer.component.html',
  styleUrl: './html-viewer.component.css'
})
export class HtmlViewerComponent {
  content = input<string>();
  theme = input<string>('snow');
  sanitize = input<boolean>(true);
}
