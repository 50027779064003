import { Component } from '@angular/core';
import { FieldWrapper, FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';

export interface FieldWrapperProps extends FormlyFieldProps {
  hideRequiredMarker?: boolean;
  hideLabel?: boolean;
  disableMargin?: boolean;
}

@Component({
  selector: 'lib-formly-field-wrapper',
  templateUrl: './field-wrapper.component.html',
  styleUrl: './field-wrapper.component.css'
})
export class FieldWrapperComponent extends FieldWrapper<FormlyFieldConfig<FieldWrapperProps>> {}
