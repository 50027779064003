<div *transloco="let transloco" class="flex flex-col space-y-5">
  @if (this.showFilter() || this.showSort()) {
    <div class="flex flex-wrap justify-stretch gap-5">
      <div class="min-w-fit flex-1">
        @if (this.showFilter()) {
          <lib-graph-ql-advanced-filter
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable()"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-filter>
        }
      </div>
      <div class="min-w-fit flex-1">
        @if (this.showSort()) {
          <lib-graph-ql-advanced-sort
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable()"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-sort>
        }
      </div>
    </div>
  }

  <lib-graph-ql-advanced-table
    #advancedTable
    [graphQlTable]="this.graphQlTable()"
    [patchOptions]="this.graphQlPatchOptions"
    [showExport]="this.showExport()"
    [showColumnFilter]="this.showColumnFilter()"
    [stateKey]="this.stateKey()"
    [parentTemplates]="this.templates">
    @if (this.showCreateButton()) {
      <ng-template libTemplate="caption">
        <a
          [pTooltip]="transloco('new')"
          [routerLink]="['/', 'staff', this.route(), 'new']"
          class="p-button-success !p-2 text-white"
          icon="pi pi-plus"
          pButton
          tooltipPosition="top"
          type="button"></a>
      </ng-template>
    }
    <ng-template let-data libTemplate="actions">
      <div class="flex items-center justify-center gap-2">
        @if (this.showDetailButton()) {
          <a
            [pTooltip]="transloco('show')"
            [routerLink]="['/', 'staff', this.route(), 'detail', this.getRow(data)?.person_id]"
            class="p-button-info p-button-text"
            icon="pi pi-eye"
            pButton
            tooltipPosition="top"
            type="button"></a>
        }
        @if (this.showUpdateButton()) {
          <a
            [pTooltip]="transloco('edit')"
            [routerLink]="['/', 'staff', this.route(), this.getRow(data)?.person_id]"
            class="p-button-warning p-button-text"
            icon="pi pi-pencil"
            pButton
            tooltipPosition="top"
            type="button"></a>
        }
        @if (this.showDeleteButton()) {
          <button
            (click)="this.delete(data)"
            [pTooltip]="transloco('delete')"
            class="p-button-danger p-button-text"
            icon="pi pi-trash"
            pButton
            tooltipPosition="top"
            type="button"></button>
        }
      </div>
    </ng-template>
  </lib-graph-ql-advanced-table>
</div>
