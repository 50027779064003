<div class="flex flex-col gap-4 md:flex-row" *transloco="let transloco">
  <p-menu [model]="this.items()" class="hidden md:block">
    <ng-template let-item pTemplate="item">
      <ng-container *ngTemplateOutlet="itemContent; context: { $implicit: item }"></ng-container>
    </ng-template>
  </p-menu>

  <p-tabMenu
    (activeItemChange)="this.activeItem.set($event)"
    [activeItem]="this.activeItem()"
    [model]="this.items()"
    class="block md:hidden"
    libTabMenuQuery="m">
    <ng-template let-item pTemplate="item">
      <ng-container *ngTemplateOutlet="itemContent; context: { $implicit: item }"></ng-container>
    </ng-template>
  </p-tabMenu>

  <ng-template #itemContent let-item>
    <div class="p-element p-menuitem-link flex cursor-pointer items-center gap-3">
      @if (item.icon) {
        <i [ngClass]="item.icon"></i>
      }
      <span class="">
        {{ transloco(item.id) }}
      </span>
    </div>
  </ng-template>

  <div class="m-base">
    <span class="mb-4 hidden text-3xl md:block">{{ transloco(this.activeItem()?.id || '') }}</span>

    @if (this.keyTemplates?.has(this.activeItem()?.id!)) {
      <ng-container
        [ngTemplateOutletContext]="{ $implicit: this.activeItem() }"
        [ngTemplateOutlet]="this.keyTemplates!.get(this.activeItem()?.id!)!">
      </ng-container>
    }
  </div>
</div>
