import { Component, effect, input, OnInit, ViewChild } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective } from '@jsverse/transloco';
import { TooltipModule } from 'primeng/tooltip';
import { RouterLink } from '@angular/router';
import { gql } from 'apollo-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { AcademySchoolPeriodModel, AcademySchoolPeriodPermission } from '~ngx-shared/models';
import { ConfirmationService } from '~ngx-shared/layout';

@Component({
  selector: 'app-school-period-list',
  standalone: true,
  imports: [
    ButtonDirective,
    GraphQlAdvancedTableComponent,
    TemplateDirective,
    TranslocoDirective,
    TooltipModule,
    RouterLink,
    NgxPermissionsModule,
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent
  ],
  templateUrl: './school-period-list.component.html',
  styleUrl: './school-period-list.component.scss'
})
export class SchoolPeriodListComponent implements OnInit {
  showFilter = input<boolean>(false);
  showSort = input<boolean>(false);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);
  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();

  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  graphQlTable: GraphQlTableModel;
  protected readonly AcademySchoolPeriodPermission = AcademySchoolPeriodPermission;

  constructor(private confirmationService: ConfirmationService) {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ updated_at: 'desc' }];
        }

        return options;
      };
    });
  }

  ngOnInit() {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        type: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        sort: { isSortable: true },
        filter: { type: 'number' }
      },
      {
        path: 'name',
        filter: { type: 'string' },
        sort: { isSortable: true }
      },
      {
        path: 'starts_at',
        type: 'date',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'ends_at',
        type: 'date',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'is_active',
        type: 'boolean',
        filter: { type: 'boolean' },
        sort: { isSortable: true }
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        label: 'course_count',
        path: 'courses_aggregate.count',
        query: `courses_aggregate { aggregate { count } }`,
        filter: { isNotFilterable: true },
        sort: { isSortable: true },
        patchResult: (result: any) => result?.courses_aggregate?.aggregate?.count
      }
    ];

    this.graphQlTable = {
      table: 'academy_school_period',
      isPaginated: true,

      showCurrentPageReport: true,
      columns: columns
    };
  }

  getRow(data: any): AcademySchoolPeriodModel | undefined {
    return data;
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      let mutation: any = gql`
        mutation SoftDeleteAcademySchoolPeriodById($id: bigint!) {
          result: update_academy_school_period_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: "now()" }
          ) {
            __typename
          }
        }
      `;

      this.confirmationService.confirmDeleteApollo({
        name: row.name,
        mutationOptions: {
          mutation,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }
}
