import { Component, effect, EventEmitter, input, Output, signal, ViewChild } from '@angular/core';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { RouterLink } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DateTimePipe } from '~ngx-shared/pipes';
import { AccessRoleModel, AccessRolePermission, CorePersonDataModel } from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';
import { AccessService } from '~ngx-shared/services';
import { Role } from '~ngx-shared/authentication';
import { TemplateDirective } from '~ngx-shared/directives';
import { DataProvider, GraphQLResult, GraphQlTableComponent } from '~ngx-shared/graph-ql';

@UntilDestroy()
@Component({
  selector: 'app-access-role-list',
  standalone: true,
  imports: [
    GraphQlTableComponent,
    TranslocoDirective,
    TemplateDirective,
    ButtonDirective,
    RouterLink,
    TooltipModule,
    DateTimePipe,
    NgxPermissionsModule
  ],
  templateUrl: './access-role-list.component.html',
  styleUrl: './access-role-list.component.scss'
})
export class AccessRoleListComponent {
  @ViewChild('table') tableComponent: GraphQlTableComponent;

  @Output() createAccessEvent = new EventEmitter<void>();

  personId = input<number | undefined>();

  showPerson = input<boolean>(true);
  showCreateButton = input<boolean>(true);

  queryVariables = input<(variables: any) => any>();

  readonly isRevoking = signal<{ [key: number]: boolean }>({});

  dataProvider: DataProvider<AccessRoleModel>;

  protected readonly AccessRolePermission = AccessRolePermission;

  constructor(
    private apollo: Apollo,
    private accessService: AccessService,
    private translocoService: TranslocoService
  ) {
    effect(() => {
      const queryVariables = this.queryVariables();
      const personId = this.personId();
      const showPerson = this.showPerson();

      this.dataProvider = option => {
        let variables: any | undefined = {
          limit: option.limit,
          offset: option.offset,
          filter: option.filter,
          sort_by: { updated_at: 'desc' }
        };

        variables.filter = {
          ...variables.filter,
          _and: []
        };

        if (personId) {
          variables.filter._and = [
            ...variables.filter._and,
            { user: { person_id: { _eq: personId } } }
          ];
        }

        if (queryVariables) {
          variables = queryVariables(variables);
        }

        return this.apollo
          .query<GraphQLResult<AccessRoleModel>>({
            query: gql`
              query GetAccessRolePaginated(
                $limit: Int!
                $offset: Int!
                $filter: access_role_bool_exp!
                $sort_by: [access_role_order_by!]
              ) {
                result: access_role(
                  limit: $limit
                  offset: $offset
                  where: $filter
                  order_by: $sort_by
                ) {
                  id
                  starts_at
                  ends_at
                  role
                  created_at
                  updated_at
                  user {
                    id
                    email
                    person_id
                    ${
                      showPerson
                        ? `current_person_data {
                            person_id
                            academic_degree_prefix
                            academic_degree_suffix
                            first_name
                            last_name
                          }`
                        : ''
                    }
                  }
                }
                aggregate: access_role_aggregate(where: $filter) {
                  aggregate {
                    count
                  }
                }
              }
            `,
            variables
          })
          .pipe(map(queryResult => cloneDeep(queryResult.data)));
      };
    });
  }

  getRow(data: any): AccessRoleModel | undefined {
    return data;
  }

  getFullName(personDataModel: CorePersonDataModel | undefined) {
    return ModelUtil.getFullName(personDataModel, this.translocoService);
  }

  createAccess() {
    this.createAccessEvent.emit();
  }

  revokeAccess(data: any) {
    const id = this.getRow(data)?.user?.person_id;
    const role = this.getRow(data)?.role;
    if (id && role) {
      this.isRevoking.set({ ...this.isRevoking(), [id]: true });

      this.accessService
        .revokeAccess(id, role as Role)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.tableComponent?.updateTable();
          this.isRevoking.set({ ...this.isRevoking(), [id]: false });
        });
    }
  }

  updateTable() {
    this.tableComponent?.updateTable();
  }
}
