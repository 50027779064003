<lib-layout-base-page *transloco="let transloco">
  <div class="w-full">
    <div
      class="mb-6 flex flex-col items-center justify-center rounded-lg bg-[color:var(--primary-color)] text-white shadow">
      <div class="flex items-center gap-4 p-4 !text-xl">
        <i class="pi pi-exclamation-triangle !text-3xl"></i>
        <span>{{
          transloco('madrasa.components.staff.new_registration_wizard.check_for_person_first')
        }}</span>
      </div>
    </div>

    <p-stepper [linear]="true">
      <p-stepperPanel [header]="transloco('create_value', { value: transloco('student') })">
        <ng-template let-nextCallback="nextCallback" pTemplate="content">
          <div class="my-6 flex flex-col gap-6">
            <span class="block text-center text-4xl">
              {{ transloco('student') | uppercase }}
            </span>
            <div class="flex justify-center">
              <app-create-student-data-form
                [form]="this.studentForm"
                [model]="this.studentModel"
                [showCancelButton]="false"
                [showSaveButton]="false"
                class="w-full rounded-lg bg-white p-8 shadow lg:w-3/4 lg:border xl:w-4/6"></app-create-student-data-form>
            </div>
            <div class="flex justify-end">
              <button
                (click)="this.studentNext(nextCallback)"
                [label]="transloco('continue')"
                icon="pi pi-arrow-right"
                iconPos="right"
                pButton
                type="button"></button>
            </div>
          </div>
        </ng-template>
      </p-stepperPanel>
      <p-stepperPanel [header]="transloco('create_value', { value: transloco('father') })">
        <ng-template
          let-nextCallback="nextCallback"
          let-prevCallback="prevCallback"
          pTemplate="content">
          <div class="my-6 flex flex-col gap-6">
            <span class="block text-center text-4xl">
              {{ transloco('father') | uppercase }}
            </span>
            <div class="flex justify-center">
              <app-create-parent-data-form
                [form]="this.fatherForm"
                [model]="this.fatherModel"
                [showCancelButton]="false"
                [showSaveButton]="false"
                class="w-full rounded-lg bg-white p-8 shadow lg:w-3/4 lg:border xl:w-4/6"></app-create-parent-data-form>
            </div>
            <div class="flex justify-between pt-4">
              <button
                (click)="this.back(prevCallback)"
                [label]="transloco('back')"
                icon="pi pi-arrow-left"
                pButton
                type="button"></button>
              <button
                (click)="this.fatherNext(nextCallback)"
                [label]="transloco('continue')"
                icon="pi pi-arrow-right"
                iconPos="right"
                pButton
                type="button"></button>
            </div>
          </div>
        </ng-template>
      </p-stepperPanel>
      <p-stepperPanel [header]="transloco('create_value', { value: transloco('mother') })">
        <ng-template
          let-nextCallback="nextCallback"
          let-prevCallback="prevCallback"
          pTemplate="content">
          <div class="my-6 flex flex-col gap-6">
            <span class="block text-center text-4xl">
              {{ transloco('mother') | uppercase }}
            </span>
            <div class="flex justify-center">
              <app-create-parent-data-form
                [form]="this.motherForm"
                [model]="this.motherModel"
                [showCancelButton]="false"
                [showSaveButton]="false"
                class="w-full rounded-lg bg-white p-8 shadow lg:w-3/4 lg:border xl:w-4/6"></app-create-parent-data-form>
            </div>
            <div class="flex justify-between pt-4">
              <button
                (click)="prevCallback.emit()"
                [label]="transloco('back')"
                icon="pi pi-arrow-left"
                pButton
                type="button"></button>
              <button
                (click)="this.motherNext(nextCallback)"
                [label]="transloco('continue')"
                icon="pi pi-arrow-right"
                iconPos="right"
                pButton
                type="button"></button>
            </div>
          </div>
        </ng-template>
      </p-stepperPanel>
      <p-stepperPanel [header]="transloco('summary')">
        <ng-template let-prevCallback="prevCallback" pTemplate="content">
          <div class="mt-6">
            @if (!this.fatherModel.first_name && !this.motherModel.first_name) {
              <div
                class="mb-6 flex flex-col items-center justify-center rounded-lg bg-red-400 text-white shadow">
                <div class="flex items-center gap-4 p-4 !text-xl">
                  <i class="pi pi-exclamation-triangle !text-3xl"></i>
                  <span>{{
                    transloco(
                      'madrasa.components.staff.new_registration_wizard.missing_vater_or_mother'
                    ) | uppercase
                  }}</span>
                </div>
              </div>
            }

            <p-accordion [multiple]="true">
              <p-accordionTab [selected]="true">
                <ng-template pTemplate="header">
                  <ng-container
                    [ngTemplateOutletContext]="{
                      header: 'student',
                      isDone: this.status()['isStudentDone'],
                      isSaving: this.status()['isStudentSaving'],
                      isSuccessfulSaved: this.status()['isStudentSuccessfulSaved']
                    }"
                    [ngTemplateOutlet]="statusHeader">
                  </ng-container>
                </ng-template>

                <lib-detail [fields]="this.getStudentFields()"></lib-detail>
              </p-accordionTab>

              @if (this.fatherModel.first_name) {
                <p-accordionTab [selected]="true">
                  <ng-template pTemplate="header">
                    <ng-container
                      [ngTemplateOutletContext]="{
                        header: 'father',
                        isDone: this.status()['isFatherDone'],
                        isSaving: this.status()['isFatherSaving'],
                        isSuccessfulSaved: this.status()['isFatherSuccessfulSaved']
                      }"
                      [ngTemplateOutlet]="statusHeader">
                    </ng-container>
                  </ng-template>

                  <lib-detail [fields]="this.getFatherFields()"></lib-detail>
                </p-accordionTab>
              }

              @if (this.motherModel.first_name) {
                <p-accordionTab [selected]="true">
                  <ng-template pTemplate="header">
                    <ng-container
                      [ngTemplateOutletContext]="{
                        header: 'mother',
                        isDone: this.status()['isMotherDone'],
                        isSaving: this.status()['isMotherSaving'],
                        isSuccessfulSaved: this.status()['isMotherSuccessfulSaved']
                      }"
                      [ngTemplateOutlet]="statusHeader">
                    </ng-container>
                  </ng-template>

                  <lib-detail [fields]="this.getMotherFields()"></lib-detail>
                </p-accordionTab>
              }
            </p-accordion>
          </div>
          @if (this.status()['isSaved']) {
            <div class="flex justify-end pt-6">
              <button
                (click)="this.restart()"
                [label]="transloco('restart')"
                icon="pi pi-refresh"
                iconPos="right"
                pButton
                type="button"></button>
            </div>
          } @else {
            <div class="flex justify-between pt-6">
              <button
                (click)="prevCallback.emit()"
                [label]="transloco('back')"
                [loading]="this.status()['isSaving']"
                icon="pi pi-arrow-left"
                pButton
                type="button"></button>
              @if (this.fatherModel.first_name || this.motherModel.first_name) {
                <button
                  (click)="this.save()"
                  [label]="transloco(this.status()['isSaving'] ? 'saving' : 'save')"
                  [loading]="this.status()['isSaving']"
                  icon="pi pi-save"
                  iconPos="right"
                  pButton
                  type="button"></button>
              }
            </div>
          }
        </ng-template>
      </p-stepperPanel>
    </p-stepper>

    <ng-template
      #statusHeader
      let-header="header"
      let-isDone="isDone"
      let-isSaving="isSaving"
      let-isSuccessfulSaved="isSuccessfulSaved">
      <div class="flex items-center gap-8">
        <span
          [ngClass]="{
            'text-green-500': isSuccessfulSaved && isDone,
            'text-red-600': !isSuccessfulSaved && isDone
          }"
          class="block text-2xl text-green-500">
          {{ transloco(header) | uppercase }}
        </span>
        @if (isSaving) {
          <div class="flex h-10 w-10 flex-col items-center justify-center">
            <lib-layout-spinner></lib-layout-spinner>
          </div>
        }
        @if (isDone) {
          <i
            [ngClass]="{
              'pi-check-circle text-green-500': isSuccessfulSaved,
              'pi-ban text-red-600': !isSuccessfulSaved
            }"
            class="pi !text-3xl"></i>
        }
      </div>
    </ng-template>
  </div>
</lib-layout-base-page>
