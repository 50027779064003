import { ResolveFn } from '@angular/router';

export function crudResolver(parameterName: string): ResolveFn<any> {
  return (route, state) => {
    if (route.paramMap.keys.some(key => key.endsWith(parameterName))) {
      // Get parameter value
      // Can be a string with 'new' or a number
      let value: any = route.paramMap.get(parameterName);
      if (value === 'new') {
        return {
          is_new: true
        };
      }
      value = Number(value);
      if (!isNaN(value)) {
        return {
          id: value
        };
      }
    }

    return undefined;
  };
}
