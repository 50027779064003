import { Role } from '~ngx-shared/authentication';
import { DefaultProperties } from '../default.model';
import { AcademyCourseModel } from './course.model';
import { AcademySchoolModel } from './school.model';

export interface AcademyRoomModel extends DefaultProperties {
  // Columns
  id?: number;
  school_id?: number;
  name?: string;
  area?: number;
  has_water_connection?: boolean;
  has_window?: boolean;
  is_heated?: boolean;
  description?: string;
  seating?: number;

  // Relations
  school?: AcademySchoolModel;

  courses?: AcademyCourseModel[];
}

export class AcademyRoomPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AcademyRoomPermission.DELETE, Role.MANAGER, Role.ORGANISATOR];
}
