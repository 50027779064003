import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { MenuChangeEvent } from '../api/menuchangeevent';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menuSource = new Subject<MenuChangeEvent>();
  private resetSource = new Subject();

  menuSource$ = this.menuSource.asObservable();
  resetSource$ = this.resetSource.asObservable();

  onMenuStateChange(event: MenuChangeEvent) {
    this.menuSource.next(event);
  }

  reset() {
    this.resetSource.next(true);
  }

  readonly sideBarItems$ = new BehaviorSubject<MenuItem[]>([]);

  getItemFromRoute(route: string[], stateId?: string): MenuItem | undefined {
    return this.getItemFromRouteHelper(route, this.sideBarItems$.value, stateId);
  }

  getItemFromRouteHelper(
    route: string[],
    items?: MenuItem[],
    stateId?: string
  ): MenuItem | undefined {
    if (items?.length) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (
          Array.isArray(item.routerLink) &&
          item.routerLink.every(r => route.includes(r)) &&
          (!stateId || item.state?.['id'] === stateId)
        ) {
          return item;
        }
        const result = this.getItemFromRouteHelper(route, items[i].items, stateId);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  }
}
