import { Component, forwardRef, input, OnInit, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ButtonDirective } from 'primeng/button';
import { FormlyModule } from '@ngx-formly/core';
import { PrimeTemplate } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';
import { InputTextModule } from 'primeng/inputtext';
import { filter } from 'rxjs/operators';
import { TooltipModule } from 'primeng/tooltip';
import { InputNumberModule } from 'primeng/inputnumber';
import { Nullable } from 'primeng/ts-helpers';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';
import { DropdownModule } from 'primeng/dropdown';
import { AcademyTeacherHourlyRateModel, StaffEducationTypeModel } from '~ngx-shared/models';

@Component({
  selector: 'app-teacher-hourly-rate',
  standalone: true,
  imports: [
    ButtonDirective,
    FormlyModule,
    PrimeTemplate,
    TableModule,
    TranslocoDirective,
    NgxPermissionsModule,
    FormsModule,
    InputTextModule,
    TooltipModule,
    InputNumberModule,
    DropdownModule
  ],
  templateUrl: './teacher-hourly-rate.component.html',
  styleUrl: './teacher-hourly-rate.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TeacherHourlyRateComponent)
    }
  ]
})
export class TeacherHourlyRateComponent implements OnInit, ControlValueAccessor {
  readonly readonly = input<boolean | undefined>();
  readonly educationTypes = signal<StaffEducationTypeModel[]>([]);

  readonly columns = signal<string[]>([]);
  readonly rows = signal<number[][]>([]);

  disabled = false;
  protected readonly filter = filter;

  private touched = false;

  constructor(private apollo: Apollo) {}

  ngOnInit(): void {
    this.apollo
      .query<{
        result: StaffEducationTypeModel[];
      }>({
        query: gql`
          query ReadStaffEducationTypes {
            result: staff_education_type(order_by: { name: asc }) {
              id
              name
            }
          }
        `
      })
      .pipe(
        map(queryResult =>
          queryResult.data.result.map(educationType => ({
            label: educationType.name,
            value: String(educationType.id)
          }))
        )
      )
      .subscribe(educationTypes => {
        this.educationTypes.set(educationTypes);
      });
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  // Form methods

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: AcademyTeacherHourlyRateModel | undefined): void {
    if (!value) {
      // Initialize the table
      this.changeValue();
    } else {
      this.columns.set(value.columns || []);
      this.rows.set(value.rows || []);
    }
  }

  addColumn() {
    const columns = this.columns();
    if (columns) {
      columns.push('');
      this.columns.set(columns);
      this.changeValue();
    }
  }

  removeColumn(index: number) {
    const columns = this.columns();
    if (columns) {
      columns.splice(index, 1);
      this.columns.set(columns);
      // Remove the column from all rows
      const rows = this.rows();
      if (rows) {
        rows.forEach(row => {
          row.splice(index, 1);
        });
        this.rows.set(rows);
      }
      this.changeValue();
    }
  }

  updateColumnValue(value: string, index: number) {
    const columns = this.columns();
    if (columns) {
      columns[index] = value;
      this.columns.set(columns);
      this.changeValue();
    }
  }

  addRow() {
    const rows = this.rows();
    if (rows) {
      rows.push([]);
      this.rows.set(rows);
      this.changeValue();
    }
  }

  removeRow(rowIndex: number) {
    const rows = this.rows();
    if (rows) {
      rows.splice(rowIndex, 1);
      this.rows.set(rows);
      this.changeValue();
    }
  }

  updateRowValue(value: Nullable<number>, rowIndex: number, index: number) {
    const rows = this.rows();
    if (rows) {
      if (!rows[rowIndex]) {
        rows[rowIndex] = [0];
      }
      rows[rowIndex][index] = value || 0;
      this.rows.set(rows);
      this.changeValue();
    }
  }

  changeValue() {
    const value: AcademyTeacherHourlyRateModel = {
      columns: this.columns().map(column => column),
      rows: this.rows().map((row, index) => {
        if (row.length) {
          row[0] = index;
        }
        return row;
      })
    };
    this.onChange(value);
    this.markAsTouched();
  }

  private onChange = (value: AcademyTeacherHourlyRateModel | undefined) => {};

  private onTouched = () => {};
}
