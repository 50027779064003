<div *transloco="let transloco" class="flex flex-col space-y-5">
  @if (this.showFilter() || this.showSort()) {
    <div class="flex flex-wrap justify-stretch gap-5">
      <div class="min-w-fit flex-1">
        @if (this.showFilter()) {
          <lib-graph-ql-advanced-filter
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-filter>
        }
      </div>
      <div class="min-w-fit flex-1">
        @if (this.showSort()) {
          <lib-graph-ql-advanced-sort
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-sort>
        }
      </div>
    </div>
  }

  <lib-graph-ql-advanced-table
    #advancedTable
    [graphQlTable]="this.graphQlTable"
    [patchOptions]="this.graphQlPatchOptions"
    [showExport]="this.showExport()"
    [showColumnFilter]="this.showColumnFilter()"
    [stateKey]="this.stateKey()">
    @if (this.showCreateButton()) {
      <ng-template libTemplate="caption">
        <a
          [pTooltip]="transloco('new')"
          [routerLink]="['/', 'academy', 'schools', 'new']"
          class="p-button-success !p-2 text-white"
          icon="pi pi-plus"
          pButton
          tooltipPosition="top"
          type="button"></a>
      </ng-template>
    }
    <ng-template let-data libTemplate="actions">
      <div class="flex items-center justify-center gap-2">
        <a
          *ngxPermissionsOnly="this.AcademySchoolPermission.READ"
          [pTooltip]="transloco('show')"
          [routerLink]="['/', 'academy', 'schools', 'detail', this.getRow(data)?.id]"
          class="p-button-info p-button-text"
          icon="pi pi-eye"
          pButton
          tooltipPosition="top"
          type="button"></a>
        <a
          *ngxPermissionsOnly="this.AcademySchoolPermission.UPDATE"
          [pTooltip]="transloco('edit')"
          [routerLink]="['/', 'academy', 'schools', this.getRow(data)?.id]"
          class="p-button-warning p-button-text"
          icon="pi pi-pencil"
          pButton
          tooltipPosition="top"
          type="button"></a>

        @if (
          !data?.courses_aggregate?.aggregate?.count &&
          !data?.rooms_aggregate?.aggregate?.count &&
          !data?.school_directors_active_aggregate?.aggregate?.count &&
          !data?.school_parents_active_aggregate?.aggregate?.count &&
          !data?.school_students_active_aggregate?.aggregate?.count &&
          !data?.school_teachers_active_aggregate?.aggregate?.count
        ) {
          <button
            (click)="this.delete(data)"
            *ngxPermissionsOnly="this.AcademySchoolPermission.SOFT_DELETE"
            [pTooltip]="transloco('delete')"
            class="p-button-danger p-button-text"
            icon="pi pi-trash"
            pButton
            tooltipPosition="top"
            type="button"></button>
        }
      </div>
    </ng-template>
  </lib-graph-ql-advanced-table>
</div>
