<div *transloco="let transloco" class="flex flex-col space-y-5">
  <lib-formly-form
    (savedEvent)="this.savedEvent($event)"
    [fields]="this.fields"
    [form]="this.form"
    [model]="this.model"
    [navigateBackOnCancel]="false"
    [navigateBackOnSave]="false"
    [options]="this.options"
    [showCancelButton]="false"
    [submit]="this.submit">
  </lib-formly-form>

  @if (this.showFilter() || this.showSort()) {
    <div class="flex flex-wrap justify-stretch gap-5">
      <div class="min-w-fit flex-1">
        @if (this.showFilter()) {
          <lib-graph-ql-advanced-filter
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable()"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-filter>
        }
      </div>
      <div class="min-w-fit flex-1">
        @if (this.showSort()) {
          <lib-graph-ql-advanced-sort
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable()"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-sort>
        }
      </div>
    </div>
  }

  <lib-graph-ql-advanced-table
    #advancedTable
    [graphQlTable]="this.graphQlTable()"
    [patchOptions]="this.graphQlPatchOptions"
    [showColumnFilter]="false"
    [showExport]="false"
    [stateKey]="this.stateKey()">
    <ng-template let-data libTemplate="created_by_current_person_dataCell">
      <div class="flex flex-col gap-2">
        <app-person-detail-link
          [person]="data.created_by_current_person_data"></app-person-detail-link>
      </div>
    </ng-template>
    <ng-template let-data libTemplate="messageCell">
      <div class="w-full">
        <lib-formly-html-viewer [content]="data?.message"></lib-formly-html-viewer>
      </div>
    </ng-template>
    <ng-template *ngxPermissionsOnly="Role.LESSOR" let-data libTemplate="actions">
      <div class="flex items-center justify-center gap-2">
        <button
          (click)="this.delete(data)"
          [pTooltip]="transloco('delete')"
          class="p-button-danger p-button-text"
          icon="pi pi-trash"
          pButton
          tooltipPosition="top"
          type="button"></button>
      </div>
    </ng-template>
  </lib-graph-ql-advanced-table>
</div>
