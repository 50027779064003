import { Role } from '~ngx-shared/authentication';
import { DefaultProperties } from '../default.model';
import { AccessUserModel } from './user.model';

export interface AccessRoleModel extends DefaultProperties {
  // Columns
  id?: string;
  user_id?: string;
  starts_at?: Date | string;
  ends_at?: Date | string;
  role?: string;

  // Relations
  user?: AccessUserModel;
}

export class AccessRolePermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AccessRolePermission.DELETE];
}
