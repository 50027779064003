<lib-layout-base-page *transloco="let transloco">
  <div *ngxPermissionsOnly="this.StaffManagerPermission.CREATE" action>
    <button
      (click)="menu.toggle($event)"
      class="p-button-success"
      icon="pi pi-bars"
      label="{{ transloco('new') }}"
      pButton
      type="button"></button>
    <p-menu #menu [model]="this.items()" [popup]="true" appendTo="body"></p-menu>
  </div>

  <div class="m-base flex flex-col">
    <app-person-list
      [columns]="this.columns()"
      [deleteFunc]="this.deleteFunc"
      [patchOptions]="this.patchOptions"
      [route]="'managers'"
      [showDeleteButton]="this.authorizationService.can(this.StaffManagerPermission.SOFT_DELETE)"
      [showDetailButton]="this.authorizationService.can(this.StaffManagerPermission.READ)"
      [showExport]="true"
      [showFilter]="true"
      [showSort]="true"
      [showUpdateButton]="this.authorizationService.can(this.StaffManagerPermission.UPDATE)"
      [stateKey]="this.router.url + 'manager-list'">
    </app-person-list>
  </div>
</lib-layout-base-page>
