import { Component, computed, ContentChildren, input, OnInit, QueryList } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { NgTemplateOutlet } from '@angular/common';
import { PersonListComponent } from '~madrasa/staff/components/person-list/person-list.component';
import { DataProviderOptionModel, GraphQlColumnModel } from '~ngx-shared/graph-ql';
import {
  AcademySchoolModel,
  CorePersonDataModel,
  DebitDateEnum,
  StaffStatusEnum,
  StaffStudentPermission
} from '~ngx-shared/models';
import { AuthorizationService } from '~ngx-shared/authentication';
import { ConfirmationService } from '~ngx-shared/layout';
import { TenantService } from '~madrasa/services';
import { TemplateDirective } from '~ngx-shared/directives';
import { PersonDetailLinkComponent } from '~madrasa/staff/components/person-detail-link/person-detail-link.component';
import { ModelUtil } from '~ngx-shared/utils';

@Component({
  selector: 'app-student-list',
  standalone: true,
  imports: [
    PersonListComponent,
    PersonDetailLinkComponent,
    TemplateDirective,
    TranslocoDirective,
    NgTemplateOutlet
  ],
  templateUrl: './student-list.component.html',
  styleUrl: './student-list.component.scss'
})
export class StudentListComponent implements OnInit {
  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>;

  readonly stateKey = input<string>();
  readonly inputColumns = input<GraphQlColumnModel[]>([], { alias: 'columns' });

  readonly columns = computed(() => {
    const inputColumns = this.inputColumns();
    const columns = [...this.getDefaultColumns()];

    columns.push(...(inputColumns || []));

    return columns;
  });

  patchOptions = input<((options: DataProviderOptionModel) => DataProviderOptionModel) | undefined>(
    options => {
      options.filter = {
        ...options.filter,
        _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
      };
      if (
        Array.isArray(options?.filter?._and) &&
        !options.filter?._and?.some((cond: any) => cond.latest_student)
      ) {
        options.filter._and.push({ latest_student: {} });
      }
      return options;
    }
  );
  deleteFunc?: (options: { data: CorePersonDataModel; refresh: () => void }) => void;

  protected readonly StaffStudentPermission = StaffStudentPermission;

  constructor(
    public authorizationService: AuthorizationService,
    private confirmationService: ConfirmationService,
    private apollo: Apollo,
    private translocoService: TranslocoService,
    public tenantService: TenantService
  ) {}

  ngOnInit() {
    this.deleteFunc = options => {
      if (options.data) {
        let mutation: any = gql`
          mutation SoftDeleteStaffStudentById($id: bigint!) {
            result: update_staff_student(
              _set: { deleted_at: "now()" }
              where: { person_id: { _eq: $id } }
            ) {
              __typename
            }
            result_student: update_academy_school_student_active(
              _set: { deleted_at: "now()" }
              where: { person_id: { _eq: $id } }
            ) {
              __typename
            }
          }
        `;
        if (
          options.data.students?.[0]?.deleted_at &&
          this.authorizationService.can(StaffStudentPermission.DELETE)
        ) {
          mutation = gql`
            mutation DeleteStaffStudentById($id: bigint!) {
              result: delete_staff_student(where: { person_id: { _eq: $id } }) {
                __typename
              }
              result_student: update_academy_school_student_active(
                _set: { deleted_at: "now()" }
                where: { person_id: { _eq: $id } }
              ) {
                __typename
              }
            }
          `;
        }

        this.confirmationService.confirmDeleteApollo({
          name: `${options.data.first_name} - ${options.data.last_name}`,
          mutationOptions: {
            mutation,
            variables: {
              id: options.data.person_id
            }
          },
          success: options.refresh
        });
      }
    };
  }

  getDefaultColumns(): GraphQlColumnModel[] {
    const columns: GraphQlColumnModel[] = [
      {
        label: 'schools',
        path: 'school_students_active.school.name',
        filter: {
          label: 'school',
          path: 'school_students_active.school_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademySchoolModel[];
            }>({
              query: gql`
                query ReadAcademySchool($where: academy_school_bool_exp) {
                  result: academy_school(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(school => ({
                  label: school.name,
                  value: school.id
                }))
              )
            )
        },
        sort: { isSortable: false },
        hidden: true
      },
      this.tenantService.currentTenant?.are_course_names_forced
        ? {
            label: 'course',
            path: 'latest_course_students_active.course.course_name.name',
            filter: { type: 'string' },
            sort: { isSortable: false }
          }
        : {
            label: 'course',
            path: 'latest_course_students_active.course.name',
            filter: { type: 'string' },
            sort: { isSortable: false }
          },
      // {
      //   label: 'teachers',
      //   path: 'course_teachers',
      //   query: `
      //     course_teachers (order_by: { current_person_data: { first_name: asc } }) {
      //       teaching_role
      //       current_person_data {
      //         person_id
      //         academic_degree_prefix
      //         academic_degree_suffix
      //         first_name
      //         last_name
      //       }
      //     }
      //   `,
      //   patchResult: value =>
      //     value?.course_teachers
      //       .map((courseTeacher: any) =>
      //         ModelUtil.getFullName(courseTeacher.current_person_data, this.translocoService)
      //       )
      //       .join(', '),
      //   filter: { isNotFilterable: true },
      //   hidden: this.hideTeacherColumn()
      // },
      {
        path: 'latest_student.registration_date',
        type: 'date',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'latest_student.has_photo_publish_approval',
        type: 'boolean',
        filter: { type: 'boolean' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'latest_student.debit_date',
        translate: true,
        prefix: 'debit_date.',
        filter: {
          type: 'array',
          options: Object.values(DebitDateEnum).map(value => ({
            label: 'debit_date.' + value,
            value: value
          }))
        },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'latest_student.status',
        translate: true,
        prefix: 'status.',
        filter: {
          type: 'array',
          options: Object.values(StaffStatusEnum).map(value => ({
            label: 'status.' + value,
            value: value
          }))
        },
        sort: { isSortable: true }
      },
      {
        label: 'relationship.father',
        path: 'father',
        query: `
          father_side_a: side_a_person_relationships(where: {relationship: {_eq: child_father}}) {
            person_in_relationship_id
           current_person_data: person_in_relationship_current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
          }
          father_side_b: side_b_person_relationships(where: {relationship: {_eq: child_father}}) {
            person_id
           current_person_data: person_current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
          }
        `,
        patchResult: value =>
          // Merge father_side_a and father_side_b
          value?.father_side_a
            ?.concat(value?.father_side_b)
            ?.map((father: any) =>
              ModelUtil.getFullName(father.current_person_data, this.translocoService)
            )
            ?.join(', '),
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        hidden: true
      },
      {
        label: 'relationship.mother',
        path: 'mother',
        query: `
          mother_side_a: side_a_person_relationships(where: {relationship: {_eq: child_mother}}) {
            person_in_relationship_id
            current_person_data:person_in_relationship_current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
          }
          mother_side_b: side_b_person_relationships(where: {relationship: {_eq: child_mother}}) {
            person_id
            current_person_data:person_current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
          }
        `,
        patchResult: value =>
          // Merge mother_side_a and mother_side_b
          value?.mother_side_a
            ?.concat(value?.mother_side_b)
            ?.map((mother: any) =>
              ModelUtil.getFullName(mother.current_person_data, this.translocoService)
            )
            ?.join(', '),
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        hidden: true
      },
      {
        label: 'relationship.sibling',
        path: 'sibling',
        query: `
          sibling_side_a: side_a_person_relationships(where: {relationship: {_eq: sibling}}) {
            person_in_relationship_id
            current_person_data:person_in_relationship_current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
          }
          sibling_side_b: side_b_person_relationships(where: {relationship: {_eq: sibling}}) {
            person_id
            current_person_data:person_current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
          }
        `,
        patchResult: value =>
          // Merge sibling_side_a and sibling_side_b
          value?.sibling_side_a
            ?.concat(value?.sibling_side_b)
            ?.map((sibling: any) =>
              ModelUtil.getFullName(sibling.current_person_data, this.translocoService)
            )
            ?.join(', '),
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        hidden: true
      }
    ];

    return columns;
  }
}
