import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, of, switchMap, tap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { format } from 'date-fns';
import { TranslocoService } from '@jsverse/transloco';
import { AuthorizationService, Role } from '~ngx-shared/authentication';
import { FormlyService } from '~madrasa/services';
import {
  AcademyClassBookEntryModel,
  AcademyClassBookEntryStudentStatusEnum,
  AcademyUnitModel,
  AcademyWeekdayEnum
} from '~ngx-shared/models';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil } from '~ngx-shared/utils';
import { PersonUtil } from '~madrasa/core/utils/person.util';

@UntilDestroy()
@Component({
  selector: 'app-update-class-book-entry-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './update-class-book-entry-form-page.component.html',
  styleUrl: './update-class-book-entry-form-page.component.scss'
})
export class UpdateClassBookEntryFormPageComponent implements OnInit {
  form = new FormGroup({});
  readOnlyModel: AcademyClassBookEntryModel = {};
  model: AcademyClassBookEntryModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.update_class_book_entry'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  unit?: AcademyUnitModel;

  constructor(
    private apollo: Apollo,
    private formlyService: FormlyService,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private translocoService: TranslocoService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AcademyClassBookEntryModel }>({
                query: gql`
                  query ReadAcademyClassBookEntryById($id: bigint!) {
                    result: academy_class_book_entry_by_pk(id: $id) {
                      id
                      created_at
                      updated_at
                      unit_id
                      school_id
                      course_id
                      date
                      subject_matter
                      homework
                      is_online

                      school {
                        id
                        organisation_id
                      }

                      course {
                        id
                        name
                        course_name {
                          name
                        }
                      }

                      unit {
                        id
                        weekday
                        starts_at
                        ends_at
                        subject {
                          shortcut
                        }
                      }
                      class_book_entry_students(
                        order_by: { current_person_data: { first_name: asc } }
                      ) {
                        id
                        status
                        person_id
                        current_person_data {
                          person_id
                          academic_degree_prefix
                          academic_degree_suffix
                          first_name
                          last_name
                        }
                      }

                      class_book_entry_teachers(
                        order_by: { current_person_data: { first_name: asc } }
                      ) {
                        id
                        present
                        person_id
                        current_person_data {
                          person_id
                          academic_degree_prefix
                          academic_degree_suffix
                          first_name
                          last_name
                        }
                      }
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          this.readOnlyModel = result;
          let model = cloneDeep(result);

          if (model) {
            this.unit = model.unit;
            this.model = {
              ...model,
              date: FormlyUtil.fromIsoDateString(model.date),
              organisation_id: model.school?.organisation_id,
              students:
                model.class_book_entry_students?.map(class_book_entry_student => ({
                  id: class_book_entry_student.id,
                  person_id: class_book_entry_student.person_id,
                  full_name: `${ModelUtil.getFullName(
                    class_book_entry_student['current_person_data'],
                    this.translocoService
                  )}, Id: ${class_book_entry_student.person_id}`,
                  present:
                    class_book_entry_student.status ===
                    AcademyClassBookEntryStudentStatusEnum.PRESENT,
                  absent:
                    class_book_entry_student.status ===
                    AcademyClassBookEntryStudentStatusEnum.ABSENT,
                  excused:
                    class_book_entry_student.status ===
                    AcademyClassBookEntryStudentStatusEnum.EXCUSED
                })) || [],
              teachers:
                model.class_book_entry_teachers?.map(class_book_entry_teacher => ({
                  id: class_book_entry_teacher.id,
                  person_id: class_book_entry_teacher.person_id,
                  full_name: `${ModelUtil.getFullName(
                    class_book_entry_teacher['current_person_data'],
                    this.translocoService
                  )}, Id: ${class_book_entry_teacher.person_id}`,
                  present: class_book_entry_teacher.present
                })) || []
            };
          }
        }

        this.fields = [
          {
            key: 'id'
          },
          this.formlyService.createOrganisationFieldConfig({ readonly: true }),
          this.formlyService.createSchoolFieldConfig({ readonly: true }),
          FormlyUtil.createRow([
            FormlyUtil.createDatePickerField('date', {
              props: {
                required: true,
                readonly: true
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createSelectField('course_id', {
              props: {
                label: 'course',
                required: true,
                readonly: true,
                options: [
                  {
                    label: this.model.course?.name || this.model.course?.course_name?.name,
                    value: this.model?.course?.id
                  }
                ]
              }
            }),
            FormlyUtil.createSelectField('unit_id', {
              props: {
                label: 'unit',
                required: true,
                readonly: true,
                options: [
                  {
                    label: `${this.unit?.subject?.shortcut}, ${this.translocoService.translate(
                      'weekday.' +
                        ModelUtil.getEnumKeyByValue(AcademyWeekdayEnum, this.unit?.weekday)
                    )}, ${format(FormlyUtil.fromIsoTimeString(this.unit?.starts_at), 'HH:mm')} - ${format(FormlyUtil.fromIsoTimeString(this.unit?.ends_at), 'HH:mm')}`,
                    value: this.unit?.id
                  }
                ]
              }
            })
          ]),
          FormlyUtil.createGroup('content', [
            FormlyUtil.createRow([
              FormlyUtil.createTextAreaField('subject_matter', {
                props: {
                  required: this.authorizationService.can(Role.TEACHER)
                },
                validators: {
                  subject_matter: {
                    expression: (control: AbstractControl, field: FormlyFieldConfig) => {
                      // Value can't empty or whitespace
                      return (
                        this.authorizationService.cannot(Role.TEACHER) ||
                        (!!control.value && control.value.trim() !== '')
                      );
                    },
                    message: () => this.translocoService.translate('validation.required')
                  }
                }
              })
            ]),
            FormlyUtil.createRow([FormlyUtil.createTextAreaField('homework')]),
            FormlyUtil.createRow([FormlyUtil.createCheckboxField('is_online')]),
            FormlyUtil.createGroup('attendances', [
              ...(this.authorizationService.cannot(Role.TEACHER)
                ? [
                    this.formlyService.createPersonSelectFieldConfig({
                      key: 'teacher_select',
                      label: 'teachers',
                      conditions: field => [
                        {
                          latest_teacher: { status: { _eq: 'active' } }
                        },
                        {
                          school_teachers_active: {
                            school_id: { _eq: field?.model?.school_id }
                          }
                        }
                      ],
                      click: (event, field) => {
                        const value = field.formControl?.value;
                        if (
                          value &&
                          (!this.model?.['teachers'] ||
                            this.model['teachers']?.findIndex(
                              (item: any) => item.person_id === value.value?.value?.person_id
                            ) === -1)
                        ) {
                          this.model = {
                            ...this.model,
                            teacher_select: undefined,
                            teachers: [
                              ...(this.model['teachers'] || []),
                              {
                                person_id: value.value?.value?.person_id,
                                full_name: `${value.label}, Id: ${value.value?.value?.person_id}`,
                                is_new: true
                              }
                            ]
                          };
                        }
                      }
                    })
                  ]
                : []),
              FormlyUtil.createRow([
                FormlyUtil.createTable(
                  {
                    key: 'teachers',
                    props: {
                      showIndex: true,
                      canAdd: () => false,
                      canRemove: () => this.authorizationService.cannot(Role.TEACHER),
                      hideLabel: true,
                      columns: [
                        {
                          key: 'full_name',
                          header: 'name'
                        },
                        {
                          key: 'present',
                          header: 'present',
                          class: 'text-center'
                        }
                      ]
                    },
                    hooks: {
                      onInit: (field: FormlyFieldConfig) => {
                        return field.options?.fieldChanges?.pipe(
                          filter(
                            event =>
                              event.type === 'valueChanges' && event.field.key === 'school_id'
                          ),
                          tap(event => {
                            field.formControl?.setValue([]);
                          })
                        );
                      }
                    }
                  },
                  [
                    {
                      key: 'id'
                    },
                    {
                      key: 'person_id'
                    },
                    {
                      key: 'is_new'
                    },
                    FormlyUtil.createLabelField('full_name', {
                      props: {
                        label: 'name',
                        disableMargin: true,
                        hideLabel: true,
                        labelClass: 'font-normal'
                      }
                    }),
                    FormlyUtil.createCheckboxField('present', {
                      className: 'text-center',
                      props: {
                        disableMargin: true,
                        hideLabel: true
                      }
                    })
                  ]
                )
              ]),
              this.formlyService.createPersonSelectFieldConfig({
                key: 'student_select',
                label: 'students',
                conditions: field => [
                  {
                    latest_student: { status: { _eq: 'active' } }
                  },
                  {
                    school_students_active: {
                      school_id: { _eq: field?.model?.school_id }
                    }
                  },
                  {
                    latest_course_students_active: { course_id: { _eq: field?.model?.course_id } }
                  }
                ],
                click: (event, field) => {
                  const value = field.formControl?.value;
                  if (
                    value &&
                    (!this.model?.['students'] ||
                      this.model['students']?.findIndex(
                        (item: any) => item.person_id === value.value?.value?.person_id
                      ) === -1)
                  ) {
                    this.model = {
                      ...this.model,
                      student_select: undefined,
                      students: [
                        ...(this.model['students'] || []),
                        {
                          person_id: value.value?.value?.person_id,
                          full_name: `${value.label}, Id: ${value.value?.value?.person_id}`,
                          present: false,
                          absent: false,
                          excused: false,
                          is_new: true
                        }
                      ]
                    };
                  }
                }
              }),
              FormlyUtil.createRow([
                FormlyUtil.createTable(
                  {
                    key: 'students',
                    props: {
                      showIndex: true,
                      canAdd: () => false,
                      canRemove: () => false,
                      hideLabel: true,
                      columns: [
                        {
                          key: 'full_name',
                          header: 'name'
                        },
                        {
                          key: 'present',
                          header: 'present',
                          class: 'text-center'
                        },
                        {
                          key: 'absent',
                          header: 'absent',
                          class: 'text-center'
                        },
                        {
                          key: 'excused',
                          header: 'excused',
                          class: 'text-center'
                        }
                      ]
                    },
                    hooks: {
                      onInit: (field: FormlyFieldConfig) => {
                        return field.options?.fieldChanges?.pipe(
                          filter(
                            event =>
                              event.type === 'valueChanges' && event.field.key === 'school_id'
                          ),
                          tap(event => {
                            field.formControl?.setValue([]);
                          })
                        );
                      }
                    }
                  },
                  [
                    {
                      key: 'id'
                    },
                    {
                      key: 'person_id'
                    },
                    {
                      key: 'is_new'
                    },
                    FormlyUtil.createLabelField('full_name', {
                      props: {
                        label: 'name',
                        disableMargin: true,
                        hideLabel: true,
                        labelClass: 'font-normal'
                      }
                    }),
                    FormlyUtil.createCheckboxField('present', {
                      className: 'text-center',
                      props: {
                        disableMargin: true,
                        hideLabel: true
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          return field.options?.fieldChanges?.pipe(
                            untilDestroyed(this),
                            filter(
                              event =>
                                event.type === 'valueChanges' &&
                                event.field.parent?.id === field.parent?.id &&
                                (event.field.key === 'absent' || event.field.key === 'excused')
                            ),
                            tap(event => {
                              if (event.value) {
                                field.formControl?.setValue(false);
                              }
                            })
                          );
                        }
                      }
                    }),
                    FormlyUtil.createCheckboxField('absent', {
                      className: 'text-center',
                      props: {
                        disableMargin: true,
                        hideLabel: true
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          return field.options?.fieldChanges?.pipe(
                            untilDestroyed(this),
                            filter(
                              event =>
                                event.type === 'valueChanges' &&
                                event.field.parent?.id === field.parent?.id &&
                                (event.field.key === 'present' || event.field.key === 'excused')
                            ),
                            tap(event => {
                              if (event.value) {
                                field.formControl?.setValue(false);
                              }
                            })
                          );
                        }
                      }
                    }),
                    FormlyUtil.createCheckboxField('excused', {
                      className: 'text-center',
                      props: {
                        disableMargin: true,
                        hideLabel: true
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          return field.options?.fieldChanges?.pipe(
                            untilDestroyed(this),
                            filter(
                              event =>
                                event.type === 'valueChanges' &&
                                event.field.parent?.id === field.parent?.id &&
                                (event.field.key === 'absent' || event.field.key === 'present')
                            ),
                            tap(event => {
                              if (event.value) {
                                field.formControl?.setValue(false);
                              }
                            })
                          );
                        }
                      }
                    })
                  ]
                )
              ])
            ])
          ])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      if (this.model?.id) {
        const input = {
          is_online: formSaveModel.input.is_online,
          subject_matter: formSaveModel.input.subject_matter,
          homework: formSaveModel.input.homework
        };

        const newStudents = PersonUtil.getNewPerson(
          formSaveModel.input.students,
          this.readOnlyModel.class_book_entry_students,
          true
        ).map(item => ({
          class_book_entry_id: this.model.id,
          person_id: item.person_id,
          status: item.present
            ? AcademyClassBookEntryStudentStatusEnum.PRESENT
            : item.excused
              ? AcademyClassBookEntryStudentStatusEnum.EXCUSED
              : AcademyClassBookEntryStudentStatusEnum.ABSENT
        }));

        const updateStudents: any[] = PersonUtil.getDeletedPerson(
          this.readOnlyModel.class_book_entry_students,
          formSaveModel.input.students
        );

        updateStudents.push(
          ...(this.readOnlyModel.class_book_entry_students
            ?.map((item: any) => {
              const student = formSaveModel.input.students?.find(
                (student: any) => student.person_id === item.person_id
              );
              // Get only changes
              if (student && item.status != student.status) {
                return {
                  where: {
                    id: { _eq: item.id }
                  },
                  _set: {
                    status: student.present
                      ? AcademyClassBookEntryStudentStatusEnum.PRESENT
                      : student.excused
                        ? AcademyClassBookEntryStudentStatusEnum.EXCUSED
                        : AcademyClassBookEntryStudentStatusEnum.ABSENT
                  }
                };
              }
              return undefined;
            })
            .filter((item: any) => !!item) || [])
        );

        const newTeachers = PersonUtil.getNewPerson(
          formSaveModel.input.teachers,
          this.readOnlyModel.class_book_entry_teachers,
          true
        ).map(item => ({
          class_book_entry_id: this.model.id,
          person_id: item.person_id,
          present: item.present
        }));

        const updateTeachers: any[] = PersonUtil.getDeletedPerson(
          this.readOnlyModel.class_book_entry_teachers,
          formSaveModel.input.teachers
        );

        // Get the updated teacher roles
        // Compare teachers and the teaching roles
        updateTeachers.push(
          ...(this.readOnlyModel.class_book_entry_teachers
            ?.map((item: any) => {
              const teacher = formSaveModel.input.teachers?.find(
                (teacher: any) => teacher.person_id === item.person_id
              );
              if (teacher && teacher.present !== item.present) {
                return {
                  where: {
                    id: { _eq: item.id }
                  },
                  _set: { present: teacher.present }
                };
              }
              return undefined;
            })
            .filter((item: any) => !!item) || [])
        );

        return this.apollo.mutate({
          mutation: gql`
            mutation UpdateAcademyClassBookEntry(
              $id: bigint!
              $input: academy_class_book_entry_set_input!
              $newStudents: [academy_class_book_entry_student_insert_input!] = []
              $updateStudents: [academy_class_book_entry_student_updates!] = []
              $newTeachers: [academy_class_book_entry_teacher_insert_input!] = []
              $updateTeachers: [academy_class_book_entry_teacher_updates!] = []
            ) {
              result: update_academy_class_book_entry_by_pk(pk_columns: { id: $id }, _set: $input) {
                __typename
              }
              insert_academy_class_book_entry_student(objects: $newStudents) {
                __typename
              }
              update_academy_class_book_entry_student_many(updates: $updateStudents) {
                __typename
              }
              insert_academy_class_book_entry_teacher(objects: $newTeachers) {
                __typename
              }
              update_academy_class_book_entry_teacher_many(updates: $updateTeachers) {
                __typename
              }
            }
          `,
          variables: {
            id: this.model.id,
            input,
            newStudents,
            updateStudents,
            newTeachers,
            updateTeachers
          }
        });
      }
      return of(undefined);
    };
  }
}
