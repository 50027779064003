import { Component, OnInit, signal } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AsyncPipe, NgClass, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { Ripple } from 'primeng/ripple';
import { BadgeModule } from 'primeng/badge';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import { DropdownModule } from 'primeng/dropdown';
import { SchoolPeriodService } from '~madrasa/academy/services/school-period.service';
import { StatisticOverallTableComponent } from '~madrasa/academy/components/statistic-overall-table/statistic-overall-table.component';
import { BasePageComponent, MenuService } from '~ngx-shared/layout';
import { AuthorizationService, Role } from '~ngx-shared/authentication';

@UntilDestroy()
@Component({
  selector: 'app-dashboard-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    ButtonDirective,
    NgxPermissionsModule,
    RouterLink,
    UpperCasePipe,
    AsyncPipe,
    Ripple,
    BadgeModule,
    RouterLinkActive,
    NgClass,
    NgTemplateOutlet,
    StatisticOverallTableComponent,
    DropdownModule,
    ReactiveFormsModule
  ],
  templateUrl: './dashboard-page.component.html',
  styleUrl: './dashboard-page.component.scss'
})
export class DashboardPageComponent implements OnInit {
  readonly isLoading = signal(true);

  schoolPeriods = toSignal(this.schoolPeriodService.periods$);
  schoolPeriodFormControl: FormControl = new FormControl();
  protected readonly Role = Role;

  constructor(
    public menuService: MenuService,
    private schoolPeriodService: SchoolPeriodService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit(): void {
    if (this.authorizationService.cannot(Role.TEACHER)) {
      this.schoolPeriodService
        .getPeriods()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.schoolPeriodFormControl.setValue(
            this.schoolPeriodService.periodStore.value.activePeriod?.id
          );
          this.isLoading.set(false);
        });
    }
  }
}
