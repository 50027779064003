import { Component } from '@angular/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { MultiSelectModule } from 'primeng/multiselect';
import { PrimeTemplate } from 'primeng/api';
import { TranslocoDirective } from '@jsverse/transloco';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { MultiSelectTypeComponent } from '~ngx-shared/formly/multi-select-type/multi-select-type.component';

@Component({
  selector: 'app-formly-school-type',
  standalone: true,
  imports: [
    AsyncPipe,
    FormlySelectModule,
    MultiSelectModule,
    PrimeTemplate,
    TranslocoDirective,
    ReactiveFormsModule,
    FormlyModule,
    NgClass
  ],
  templateUrl: './formly-school-type.component.html',
  styleUrl: './formly-school-type.component.scss'
})
export class FormlySchoolTypeComponent extends MultiSelectTypeComponent {}
