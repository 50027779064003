import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Ripple } from 'primeng/ripple';
import { CommonModule, NgClass } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslocoDirective } from '@jsverse/transloco';
import { LayoutService, MenuService } from '~ngx-shared/layout';
import { AuthorizationService } from '~ngx-shared/authentication';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[layout-menuitem]',
  template: `
    <ng-container *transloco="let transloco">
      @if (root && this.item.visible !== false) {
        <div
          *ngxPermissionsOnly="this.item.state?.['permissions']"
          class="layout-menuitem-root-text">
          {{ transloco(this.item.label || '') }}
        </div>
      }
      @if ((!this.item.routerLink || this.item.items) && this.item.visible !== false) {
        <a
          *ngxPermissionsOnly="this.item.state?.['permissions']"
          [attr.href]="this.item.url"
          (click)="itemClick($event)"
          [ngClass]="this.item.styleClass"
          [attr.target]="this.item.target"
          [routerLink]="this.item.routerLink"
          routerLinkActive="active-route"
          [routerLinkActiveOptions]="
            this.item.routerLinkActiveOptions || {
              paths: 'subset',
              queryParams: 'ignored',
              matrixParams: 'ignored',
              fragment: 'ignored'
            }
          "
          tabindex="0"
          pRipple>
          <i [ngClass]="this.item.icon" class="layout-menuitem-icon"></i>
          <span class="layout-menuitem-text">{{ transloco(this.item.label || '') }}</span>
          @if (this.item.items && this.hasSubItemsPermission()) {
            <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
          }
        </a>
      }
      @if (this.item.routerLink && !this.item.items && this.item.visible !== false) {
        <a
          *ngxPermissionsOnly="this.item.state?.['permissions']"
          (click)="itemClick($event)"
          [ngClass]="this.item.styleClass"
          [routerLink]="this.item.routerLink"
          routerLinkActive="active-route"
          [routerLinkActiveOptions]="
            this.item.routerLinkActiveOptions || {
              paths: 'subset',
              queryParams: 'ignored',
              matrixParams: 'ignored',
              fragment: 'ignored'
            }
          "
          [fragment]="this.item.fragment"
          [queryParamsHandling]="this.item.queryParamsHandling"
          [preserveFragment]="this.item.preserveFragment"
          [skipLocationChange]="this.item.skipLocationChange"
          [replaceUrl]="this.item.replaceUrl"
          [state]="this.item.state"
          [queryParams]="this.item.queryParams"
          [attr.target]="this.item.target"
          tabindex="0"
          pRipple>
          <i [ngClass]="this.item.icon" class="layout-menuitem-icon"></i>
          <span class="layout-menuitem-text">{{ transloco(this.item.label || '') }}</span>
          @if (this.item.badge) {
            <p-badge [value]="this.item.badge"></p-badge>
          }
          @if (this.item.items && this.hasSubItemsPermission()) {
            <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
          }
        </a>
      }

      @if (this.item.items && this.item.visible !== false) {
        <ul *ngxPermissionsOnly="this.item.state?.['permissions']" [@children]="submenuAnimation">
          @for (child of this.item.items; track child; let i = $index) {
            <li
              layout-menuitem
              [item]="child"
              [index]="i"
              [parentKey]="key"
              [class]="child.badgeStyleClass"></li>
          }
        </ul>
      }
    </ng-container>
  `,
  animations: [
    trigger('children', [
      state(
        'collapsed',
        style({
          height: '0'
        })
      ),
      state(
        'expanded',
        style({
          height: '*'
        })
      ),
      transition('collapsed <=> expanded', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  standalone: true,
  imports: [
    Ripple,
    NgClass,
    CommonModule,
    RouterLinkActive,
    RouterLink,
    BadgeModule,
    NgxPermissionsModule,
    TranslocoDirective
  ]
})
export class LayoutMenuitemComponent implements OnInit, OnDestroy {
  @Input() item: MenuItem;

  @Input() index!: number;

  @Input() @HostBinding('class.layout-root-menuitem') root!: boolean;

  @Input() parentKey!: string;

  active = false;

  menuSourceSubscription: Subscription;

  menuResetSubscription: Subscription;

  key: string = '';

  constructor(
    public layoutService: LayoutService,
    public router: Router,
    private menuService: MenuService,
    private authorizationService: AuthorizationService
  ) {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe(value => {
      Promise.resolve(null).then(() => {
        if (value.routeEvent) {
          this.active =
            value.key === this.key || value.key.startsWith(this.key + '-') ? true : false;
        } else {
          if (value.key !== this.key && !value.key.startsWith(this.key + '-')) {
            this.active = false;
          }
        }
      });
    });

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(params => {
      if (this.item.routerLink) {
        this.updateActiveStateFromRoute();
      }
    });
  }

  get submenuAnimation() {
    return this.root ? 'expanded' : this.active ? 'expanded' : 'collapsed';
  }

  @HostBinding('class.active-menuitem')
  get activeClass() {
    return this.active && !this.root;
  }

  ngOnInit() {
    this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);

    if (this.item.routerLink) {
      this.updateActiveStateFromRoute();
    }
  }

  updateActiveStateFromRoute() {
    let activeRoute = this.router.isActive(this.item.routerLink[0], {
      paths: 'exact',
      queryParams: 'ignored',
      matrixParams: 'ignored',
      fragment: 'ignored'
    });

    if (activeRoute) {
      this.menuService.onMenuStateChange({ key: this.key, routeEvent: true });
    }
  }

  itemClick(event: Event) {
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();
      return;
    }

    // execute command
    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    // toggle active state
    if (this.item.items) {
      this.active = !this.active;
    }

    this.menuService.onMenuStateChange({ key: this.key });
  }

  hasSubItemsPermission(items?: MenuItem[]): boolean {
    if (!items) {
      items = this.item?.items;
    }
    if (!items || items.length === 0) {
      return false;
    }
    // Recursively check if any of the subitems have permission
    return items.some(item => {
      return this.authorizationService.can(item.state?.['permissions']);
    });
  }

  ngOnDestroy() {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}
