<lib-layout-base-page *transloco="let transloco">
  <div class="flex flex-col">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('general_information') }}">
        <ng-template pTemplate="content">
          @if (this.isExpired()) {
            <div>
              <a
                *ngxPermissionsOnly="this.AcademyClassBookEntryPermission.UPDATE"
                [label]="transloco('edit_value', { value: transloco('class_book_entry') })"
                [routerLink]="['/', 'academy', 'class-book-entries', this.item()?.id]"
                icon="pi pi-pencil"
                pButton
                type="button"></a>
            </div>
          }
          <lib-detail [fields]="this.fields()"></lib-detail>
        </ng-template>
      </p-tabPanel>

      @if (this.item()?.id) {
        <p-tabPanel header="{{ transloco('students') }}">
          <ng-template pTemplate="content">
            <app-person-list
              [columns]="this.studentColumns()"
              [patchOptions]="this.studentPatchOptions"
              [showDetailButton]="true"
              [showExport]="true"
              [showFilter]="true"
              [showSort]="true"
              [stateKey]="this.router.url + 'cbe-detail-student-list'">
            </app-person-list>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="{{ transloco('teachers') }}">
          <ng-template pTemplate="content">
            <app-person-list
              [columns]="this.teacherColumns()"
              [patchOptions]="this.teacherPatchOptions"
              [showDetailButton]="true"
              [showExport]="true"
              [showFilter]="true"
              [showSort]="true"
              [stateKey]="this.router.url + 'cbe-detail-teacher-list'">
            </app-person-list>
          </ng-template>
        </p-tabPanel>
      }

      <p-tabPanel header="{{ transloco('unit_information') }}">
        <ng-template pTemplate="content">
          <app-unit-detail [id]="this.item()?.unit?.id"></app-unit-detail>
        </ng-template>
      </p-tabPanel>

      <p-tabPanel header="{{ transloco('course_information') }}">
        <ng-template pTemplate="content">
          <app-course-detail [id]="this.item()?.course?.id"></app-course-detail>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</lib-layout-base-page>
