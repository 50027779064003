@if (this.headerTemplate) {
  <ng-container [ngTemplateOutlet]="this.headerTemplate"></ng-container>
}
<div *transloco="let transloco" class="flex items-center gap-2 overflow-x-auto">
  <p-autoComplete
    (completeMethod)="
      this.props.completeMethod ? this.props.completeMethod($event, this.field) : null
    "
    [forceSelection]="this.props.forceSelection || true"
    [formControl]="this.formControl"
    [formlyAttributes]="this.field"
    [multiple]="this.props.multiple"
    [ngClass]="{
      'ng-dirty ng-invalid p-invalid': this.showError
    }"
    [optionLabel]="this.props.labelProp"
    [placeholder]="transloco(this.props.placeholder || 'search') || '&nbsp;'"
    [readonly]="this.props.readonly"
    [showClear]="!this.props.required"
    [suggestions]="(this.props.options | formlySelectOptions: this.field | async)!"
    [virtualScrollItemSize]="this.props.virtualScrollSize"
    [virtualScroll]="this.props.virtualScroll"
    appendTo="body"
    class="p-fluid w-full">
    <ng-template let-item pTemplate="item">
      @if (this.suggestionTemplate) {
        <ng-container
          [ngTemplateOutletContext]="{ $implicit: item }"
          [ngTemplateOutlet]="this.suggestionTemplate"></ng-container>
      } @else {
        {{ transloco(this.getValueAsString(item, 'label')) }}
      }
    </ng-template>
    <ng-template let-selectedItem pTemplate="selectedItem">
      @if (this.suggestionTemplate) {
        <ng-container
          [ngTemplateOutletContext]="{ $implicit: selectedItem }"
          [ngTemplateOutlet]="this.selectedTemplate"></ng-container>
      } @else {
        <span>
          {{ transloco(this.getValueAsString(selectedItem, 'label')) }}
        </span>
      }
    </ng-template>
  </p-autoComplete>

  <button
    (click)="this.props.clickMethod ? this.props.clickMethod($event, this.field) : null"
    [disabled]="!this.formControl.value"
    [class]="this.props.clickClass || 'p-button-success'"
    [icon]="this.props.clickIcon || 'pi pi-plus'"
    pButton
    type="button">
    <span class="ml-2 mr-6">{{ transloco(this.props.clickLabel || 'add') }}</span>
  </button>
</div>
@if (this.footerTemplate) {
  <ng-container [ngTemplateOutlet]="this.footerTemplate"></ng-container>
}
