<lib-layout-base-page *transloco="let transloco">
  <button
    (click)="this.trigger()"
    *ngxPermissionsOnly="Role.LESSOR"
    [label]="transloco('generate')"
    action
    class="p-button-success text-white"
    icon="pi pi-cog"
    pButton
    type="button"></button>

  <div class="m-base flex flex-col">
    <app-class-book-entry-list
      [showExport]="true"
      [showFilter]="true"
      [showSort]="true"
      [stateKey]="this.router.url + 'class-book-entries-list'">
    </app-class-book-entry-list>
  </div>
</lib-layout-base-page>
