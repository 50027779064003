<div
  *transloco="let transloco"
  [ngClass]="{
    'p-inputgroup': this.props.iconPre || this.props.iconPost,
    'flex-col': !(this.props.iconPre || this.props.iconPost)
  }"
  class="flex">
  @if (this.props.iconPre) {
    <span class="p-inputgroup-addon">
      <i [class]="this.props.iconPre"></i>
    </span>
  }
  <p-inputNumber
    class="p-fluid"
    [formlyAttributes]="this.field"
    [formControl]="this.formControl"
    [maxFractionDigits]="this.props.maxFractionDigits || 0"
    [max]="this.props.max"
    [minFractionDigits]="this.props.minFractionDigits || 0"
    [min]="this.props.min"
    [showClear]="this.props.showClear || false"
    [placeholder]="transloco(this.props.placeholder || '')"
    [mode]="this.props.mode || 'decimal'"
    [ngClass]="{
      'ng-dirty ng-invalid p-invalid': this.showError
    }"
    [prefix]="this.props.prefix || ''"
    [readonly]="this.props.readonly"
    [showButtons]="this.props.showButtons"
    [suffix]="this.props.suffix || ''"
    [useGrouping]="false"
    currency="EUR"
    locale="de-DE">
  </p-inputNumber>
  @if (this.props.iconPost) {
    <span class="p-inputgroup-addon">
      <i [class]="this.props.iconPost"></i>
    </span>
  }
</div>
