import {
  APP_INITIALIZER,
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
  LOCALE_ID,
  provideZoneChangeDetection
} from '@angular/core';
import localeDeAt from '@angular/common/locales/de-AT';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { provideTransloco, provideTranslocoMissingHandler } from '@jsverse/transloco';
import { KeycloakService } from 'keycloak-angular';
import { registerLocaleData } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { provideServiceWorker } from '@angular/service-worker';
import {
  AuthenticationModule,
  AuthenticationService,
  AuthorizationService
} from '~ngx-shared/authentication';
import { environment as madrasaEnvironment } from '~ngx-shared/environment/madrasa';
import { apolloOptionsFactory, appInitializerFactory } from '~ngx-shared/factories';
import { CustomMissingHandler, NavigationService, TranslocoHttpLoader } from '~ngx-shared/services';
import { FormlyModule } from '~ngx-shared/formly';
import { GlobalErrorHandler } from '~ngx-shared/errorHandlers';
import { routes } from './app.routes';

registerLocaleData(localeDeAt);

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'de-AT' },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    importProvidersFrom(
      AuthenticationModule.forRoot({
        clientId: madrasaEnvironment.clientId
      }),
      ApolloModule,
      FormlyModule.forRoot()
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
      deps: [NavigationService]
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: apolloOptionsFactory,
      deps: [HttpLink, AuthenticationService, AuthorizationService, KeycloakService]
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    FormlyModule.forRootProvider(),
    provideTransloco({
      config: {
        availableLangs: ['de'],
        defaultLang: 'de',
        prodMode: !isDevMode()
      },
      loader: TranslocoHttpLoader
    }),
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    provideTranslocoMissingHandler(CustomMissingHandler),
    MessageService,
    ConfirmationService,
    DialogService,
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
};
