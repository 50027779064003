<lib-layout-base-page *transloco="let transloco">
  <app-list-tab-menu [items]="this.items()">
    <ng-template libTemplate="profileKey">
      <app-profile-form></app-profile-form>
    </ng-template>

    <ng-template libTemplate="accessKey">
      <app-access></app-access>
    </ng-template>
  </app-list-tab-menu>
</lib-layout-base-page>
