import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { TranslocoService } from '@jsverse/transloco';
import { validateIBAN } from 'ngx-iban-validator';
import { AuthorizationService, Role } from '~ngx-shared/authentication';
import { FormlyService, TenantService } from '~madrasa/services';
import {
  AcademyOrganisationTeacherSalaryPermission,
  AcademySchoolTeacherSalaryPermission,
  CoreAcademicDegreesPrefix,
  CoreAcademicDegreesSuffix,
  CoreCountryEnum,
  CoreGenderEnum,
  CoreMaritalStatusEnum,
  CorePersonDataModel,
  CoreRelationshipEnum,
  FilePersonMediaTypeEnum,
  StaffEducationTypeModel,
  StaffStatusEnum
} from '~ngx-shared/models';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil, QueryUtil } from '~ngx-shared/utils';
import { PersonUtil } from '~madrasa/core/utils/person.util';

@UntilDestroy()
@Component({
  selector: 'app-create-teacher-data-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-teacher-data-form-page.component.html',
  styleUrl: './create-teacher-data-form-page.component.scss'
})
export class CreateTeacherDataFormPageComponent implements OnInit {
  form = new FormGroup({});
  readOnlyModel: CorePersonDataModel = {};
  model: CorePersonDataModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_core_person'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  constructor(
    private apollo: Apollo,
    private tenantService: TenantService,
    private formlyService: FormlyService,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private translocoService: TranslocoService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: CorePersonDataModel[] }>({
                query: gql`
                  query ReadCoreCurrentPersonDataById($id: bigint!) {
                    result: core_current_person_data(where: { person_id: { _eq: $id } }, limit: 1) {
                      id
                      created_at
                      updated_at
                      address
                      email_address
                      phone_number
                      academic_degree_prefix
                      academic_degree_suffix
                      account_owner
                      bic
                      date_of_birth
                      place_of_birth
                      extra_name
                      first_name
                      gender
                      iban
                      insurance_number
                      last_name
                      marital_status
                      profession
                      person_id
                      zmr_number

                      profile_picture: person_media(
                        limit: 1
                        order_by: { created_at: desc }
                        where: { person_media_type: { _eq: "${FilePersonMediaTypeEnum.PROFILE_PICTURE}" } }
                      ) {
                        id
                      }

                      side_a_person_relationships {
                        id
                        relationship
                        person_in_relationship_id
                        person_in_relationship_current_person_data {
                          person_id
                          academic_degree_prefix
                          academic_degree_suffix
                          first_name
                          last_name
                        }
                      }
                      side_b_person_relationships {
                        id
                        relationship
                        person_id
                        person_current_person_data {
                          person_id
                          academic_degree_prefix
                          academic_degree_suffix
                          first_name
                          last_name
                        }
                      }

                      organisation_persons_active {
                        id
                        organisation_id
                      }

                      school_teachers_active {
                        id
                        school {
                          id
                        }
                      }

                      ${
                        this.tenantService.canCrudTeacher
                          ? `
                        latest_teacher {
                          status
                          education_paths {
                            id
                            education_type_id
                            name_of_education
                            training_facility
                            place_of_education
                            starts_at
                            ends_at
                          }
                          start_of_work
                          initial_worked_months

                          ${
                            this.authorizationService.cannot(Role.DIRECTOR)
                              ? `
                          teacher_hourly_rate_id
                          monthly_rate
                          hourly_rate
                          `
                              : ''
                          }
                        }

                        ${
                          this.authorizationService.can(AcademySchoolTeacherSalaryPermission.UPDATE)
                            ? `
                        school_teacher_salaries {
                          id
                          school_id
                          hourly_rate
                          monthly_rate
                          teacher_hourly_rate_id
                        }`
                            : ''
                        }

                        ${
                          this.authorizationService.can(
                            AcademyOrganisationTeacherSalaryPermission.UPDATE
                          )
                            ? `
                        organisation_teacher_salaries {
                          id
                          organisation_id
                          hourly_rate
                          monthly_rate
                          teacher_hourly_rate_id
                        }`
                            : ''
                        }
                        `
                          : ''
                      }
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data.result?.[0]));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          this.readOnlyModel = result;
          let model = cloneDeep(result);

          if (model) {
            this.model = {
              ...model,
              date_of_birth: FormlyUtil.fromIsoDateString(model.date_of_birth),
              profile_picture: model?.['profile_picture']?.map((item: any) => item.id),
              organisations: model.organisation_persons_active?.map(item => item.organisation_id),
              relationships: PersonUtil.convertRelationshipsFromQueryResult(
                model,
                this.translocoService
              ),
              schools: model.school_teachers_active?.map(schoolTeacher => schoolTeacher.school?.id),
              latest_teacher: {
                ...model.latest_teacher,
                education_paths: model.latest_teacher?.education_paths?.map(education_path => {
                  return {
                    ...education_path,
                    starts_at: education_path.starts_at
                      ? FormlyUtil.fromIsoDateString(education_path.starts_at)
                      : undefined,
                    ends_at: education_path.ends_at
                      ? FormlyUtil.fromIsoDateString(education_path.ends_at)
                      : undefined
                  };
                }),
                start_of_work: FormlyUtil.fromIsoDateString(model.latest_teacher?.start_of_work),
                monthly_rate: model.latest_teacher?.monthly_rate
                  ? model.latest_teacher?.monthly_rate / 100
                  : undefined,
                hourly_rate: model.latest_teacher?.hourly_rate
                  ? model.latest_teacher?.hourly_rate / 100
                  : undefined
              },
              organisation_teacher_salaries: model.organisation_teacher_salaries?.map(
                organisation_teacher_salary => {
                  return {
                    ...organisation_teacher_salary,
                    monthly_rate: organisation_teacher_salary.monthly_rate
                      ? organisation_teacher_salary.monthly_rate / 100
                      : undefined,
                    hourly_rate: organisation_teacher_salary.hourly_rate
                      ? organisation_teacher_salary.hourly_rate / 100
                      : undefined
                  };
                }
              ),
              school_teacher_salaries: model.school_teacher_salaries?.map(school_teacher_salary => {
                return {
                  ...school_teacher_salary,
                  monthly_rate: school_teacher_salary.monthly_rate
                    ? school_teacher_salary.monthly_rate / 100
                    : undefined,
                  hourly_rate: school_teacher_salary.hourly_rate
                    ? school_teacher_salary.hourly_rate / 100
                    : undefined
                };
              })
            };
          }
        }

        this.fields = [
          FormlyUtil.createTabbed([
            FormlyUtil.createTab('general_information', [
              {
                key: 'id'
              },
              {
                key: 'person_id'
              },
              this.formlyService.createOrganisationsFieldConfig({ required: true }),
              FormlyUtil.createRow([
                FormlyUtil.createMultiSelectField('academic_degree_prefix', {
                  props: {
                    options: Object.values(CoreAcademicDegreesPrefix).map(value => ({
                      label: 'academic_degree.' + value,
                      value: value
                    }))
                  }
                }),
                FormlyUtil.createMultiSelectField('academic_degree_suffix', {
                  props: {
                    options: Object.values(CoreAcademicDegreesSuffix).map(value => ({
                      label: 'academic_degree.' + value,
                      value: value
                    }))
                  }
                })
              ]),
              FormlyUtil.createRow([
                FormlyUtil.createTextField('first_name', {
                  props: {
                    required: true
                  }
                }),
                FormlyUtil.createTextField('last_name', {
                  props: {
                    required: true
                  }
                }),
                FormlyUtil.createTextField('extra_name')
              ]),
              FormlyUtil.createRow([
                FormlyUtil.createDatePickerField('date_of_birth', {
                  props: {
                    required: true
                  }
                }),
                FormlyUtil.createTextField('place_of_birth')
              ]),
              FormlyUtil.createRow([
                FormlyUtil.createSelectField('citizenship', {
                  defaultValue: 'AT',
                  props: {
                    required: true,
                    options: Object.keys(CoreCountryEnum).map(key => ({
                      label: 'country_iso.' + key,
                      value: key
                    }))
                  }
                }),
                FormlyUtil.createTextField('profession')
              ]),
              FormlyUtil.createRow([
                FormlyUtil.createSelectField('gender', {
                  defaultValue: CoreGenderEnum.MALE,
                  props: {
                    required: true,
                    options: Object.values(CoreGenderEnum).map(value => ({
                      label: 'gender.' + value,
                      value: value
                    }))
                  }
                }),
                FormlyUtil.createSelectField('marital_status', {
                  defaultValue: CoreMaritalStatusEnum.SINGLE,
                  props: {
                    required: true,
                    options: Object.values(CoreMaritalStatusEnum).map(value => ({
                      label: 'marital_status.' + value,
                      value: value
                    }))
                  }
                })
              ]),
              FormlyUtil.createRow([
                FormlyUtil.createInputMaskField('insurance_number', {
                  props: {
                    type: 'number',
                    mask: '9999 999999'
                  }
                }),
                FormlyUtil.createTextField('zmr_number', {
                  props: {
                    type: 'number'
                  }
                })
              ]),
              this.formlyService.createFileUploadFieldConfig('profile_picture', {
                props: {
                  required: false,
                  namespace: 'person_media',
                  accept: 'image/*',
                  maxFiles: 1
                }
              }),
              this.formlyService.createPersonSelectFieldConfig({
                key: 'person_in_relationship_select',
                label: 'relationships',
                excludePersonIds: this.model?.person_id ? [this.model.person_id] : undefined,
                click: (event, field) => {
                  const value = field.formControl?.value;
                  if (
                    value &&
                    (!this.model?.['relationships'] ||
                      this.model['relationships']?.findIndex(
                        (item: any) =>
                          item.person_in_relationship_id === value.value?.value?.person_id ||
                          item.person_id === value.value?.value?.person_id
                      ) === -1)
                  ) {
                    this.model = {
                      ...this.model,
                      person_in_relationship_select: undefined,
                      relationships: [
                        ...(this.model['relationships'] || []),
                        {
                          person_in_relationship_id: value.value?.value?.person_id,
                          full_name: `${value.label}, Id: ${value.value?.value?.person_id}`,
                          relationship: undefined
                        }
                      ]
                    };
                  }
                }
              }),
              FormlyUtil.createRow([
                FormlyUtil.createTable(
                  {
                    key: 'relationships',
                    props: {
                      showIndex: true,
                      canAdd: () => false,
                      hideLabel: true,
                      columns: [
                        {
                          key: 'full_name',
                          header: 'name'
                        },
                        {
                          key: 'relationship',
                          header: 'relationship'
                        }
                      ]
                    }
                  },
                  [
                    {
                      key: 'id'
                    },
                    {
                      key: 'person_in_relationship_id'
                    },
                    {
                      key: 'person_id'
                    },
                    FormlyUtil.createLabelField('full_name', {
                      props: {
                        label: 'name',
                        disableMargin: true,
                        hideLabel: true,
                        labelClass: 'font-normal'
                      }
                    }),
                    FormlyUtil.createSelectField('relationship', {
                      props: {
                        disableMargin: true,
                        hideLabel: true,
                        required: true,
                        options: Object.values(CoreRelationshipEnum).map(relationship => ({
                          label: 'relationship.' + relationship,
                          value: relationship
                        }))
                      }
                    })
                  ]
                )
              ]),
              this.formlyService.createAddressFieldConfig(),
              this.formlyService.createPhoneNumberFieldConfig(),
              this.formlyService.createEmailAddressFieldConfig(),
              FormlyUtil.createGroup('account_infos', [
                FormlyUtil.createRow([
                  FormlyUtil.createInputMaskField('iban', {
                    props: {
                      required: false,
                      mask: 'aa99 9999 9999 9999 9999',
                      characterPattern: '[A-Z]',
                      description: 'iban_description'
                    },
                    validators: {
                      iban: {
                        expression: (control: any) => !validateIBAN(control.value)?.ibanInvalid,
                        message: (error: any, field: any) =>
                          this.translocoService.selectTranslate('invalid_iban')
                      }
                    }
                  }),
                  FormlyUtil.createTextField('bic', {
                    props: {
                      required: false
                    }
                  })
                ]),
                FormlyUtil.createRow([
                  FormlyUtil.createTextField('account_owner', {
                    props: {
                      required: false
                    }
                  })
                ])
              ])
            ]),
            FormlyUtil.createTab('teacher_information', [
              this.formlyService.createSchoolsFieldConfig(),
              ...(this.tenantService.canCrudTeacher
                ? [
                    {
                      key: 'latest_teacher',
                      fieldGroup: [
                        FormlyUtil.createRow([
                          FormlyUtil.createSelectField('status', {
                            defaultValue: 'active',
                            props: {
                              required: true,
                              options: Object.values(StaffStatusEnum)
                                .filter(item => item != StaffStatusEnum.WAITING_LIST)
                                .map(value => ({
                                  label: 'status.' + value,
                                  value: value
                                }))
                            }
                          })
                        ]),
                        FormlyUtil.createRow([
                          FormlyUtil.createDatePickerField('start_of_work', {
                            props: {
                              required: true
                            }
                          }),
                          FormlyUtil.createNumberField('initial_worked_months', {
                            defaultValue: 0,
                            props: {
                              min: 0,
                              showClear: true
                            }
                          })
                        ]),
                        FormlyUtil.createRepeat(
                          {
                            key: 'education_paths',
                            props: {
                              label: 'education_path',
                              initialCount: 0,
                              addTextValue: 'education_path'
                            }
                          },
                          [
                            FormlyUtil.createRow([
                              {
                                key: 'id'
                              },
                              FormlyUtil.createTextField('name_of_education', {
                                props: {
                                  required: true
                                }
                              }),
                              FormlyUtil.createSelectField('education_type_id', {
                                props: {
                                  label: 'education_type',
                                  required: true,
                                  options: this.apollo
                                    .query<{
                                      result: StaffEducationTypeModel[];
                                    }>({
                                      query: gql`
                                        query ReadStaffEducationTypes {
                                          result: staff_education_type(order_by: { name: asc }) {
                                            id
                                            name
                                          }
                                        }
                                      `
                                    })
                                    .pipe(
                                      map(queryResult =>
                                        queryResult.data.result.map(educationType => ({
                                          label: educationType.name,
                                          value: educationType.id
                                        }))
                                      )
                                    )
                                }
                              })
                            ]),
                            FormlyUtil.createRow([
                              FormlyUtil.createTextField('training_facility'),
                              FormlyUtil.createTextField('place_of_education')
                            ]),
                            FormlyUtil.createRow([
                              FormlyUtil.createDatePickerField('starts_at'),
                              FormlyUtil.createDatePickerField('ends_at')
                            ])
                          ]
                        ),
                        ...(this.authorizationService.cannot(Role.DIRECTOR)
                          ? [
                              FormlyUtil.createGroup('teacher_salary', [
                                FormlyUtil.createRow([
                                  {
                                    ...this.formlyService.createTeacherHourlyRateFieldConfig(),
                                    expressions: {
                                      'props.disabled': () => {
                                        return (
                                          !!this.model?.latest_teacher?.hourly_rate ||
                                          !!this.model?.latest_teacher?.monthly_rate
                                        );
                                      }
                                    }
                                  }
                                ]),
                                FormlyUtil.createRow([
                                  FormlyUtil.createNumberField('monthly_rate', {
                                    props: {
                                      maxFractionDigits: 2,
                                      iconPre: 'pi pi-euro',
                                      min: 0,
                                      showClear: true,
                                      disabled: true
                                    },
                                    expressions: {
                                      'props.disabled': () => {
                                        return (
                                          !!this.model?.latest_teacher?.hourly_rate ||
                                          !!this.model?.latest_teacher?.teacher_hourly_rate_id
                                        );
                                      }
                                    }
                                  }),
                                  FormlyUtil.createNumberField('hourly_rate', {
                                    props: {
                                      maxFractionDigits: 2,
                                      iconPre: 'pi pi-euro',
                                      min: 0,
                                      showClear: true,
                                      disabled: true
                                    },
                                    expressions: {
                                      'props.disabled': () => {
                                        return (
                                          !!this.model?.latest_teacher?.monthly_rate ||
                                          !!this.model?.latest_teacher?.teacher_hourly_rate_id
                                        );
                                      }
                                    }
                                  })
                                ])
                              ])
                            ]
                          : [])
                      ]
                    },
                    ...(this.authorizationService.can(AcademySchoolTeacherSalaryPermission.UPDATE)
                      ? [
                          FormlyUtil.createRepeat(
                            {
                              key: 'school_teacher_salaries',
                              props: {
                                label: 'school_teacher_salaries',
                                initialCount: 0,
                                addTextValue: 'school_teacher_salary'
                              }
                            },
                            [
                              {
                                key: 'id'
                              },
                              this.formlyService.createSchoolFieldConfig({
                                initOrganisationFunction: () => true,
                                initVariablesFunction: () => ({}),
                                fieldChangesKey: 'Not_Needed'
                              }),
                              FormlyUtil.createRow([
                                {
                                  ...this.formlyService.createTeacherHourlyRateFieldConfig({
                                    required: true
                                  }),
                                  expressions: {
                                    'props.disabled': field => {
                                      return (
                                        !!field.model?.hourly_rate || !!field.model?.monthly_rate
                                      );
                                    },
                                    'props.required': field => {
                                      return (
                                        !field.model?.hourly_rate && !field.model?.monthly_rate
                                      );
                                    }
                                  }
                                }
                              ]),
                              FormlyUtil.createRow([
                                FormlyUtil.createNumberField('monthly_rate', {
                                  props: {
                                    required: true,
                                    maxFractionDigits: 2,
                                    iconPre: 'pi pi-euro',
                                    min: 0,
                                    showClear: true,
                                    disabled: true
                                  },
                                  expressions: {
                                    'props.disabled': field => {
                                      return (
                                        !!field.model?.hourly_rate ||
                                        !!field.model?.teacher_hourly_rate_id
                                      );
                                    },
                                    'props.required': field => {
                                      return (
                                        !field.model?.hourly_rate &&
                                        !field.model?.teacher_hourly_rate_id
                                      );
                                    }
                                  }
                                }),
                                FormlyUtil.createNumberField('hourly_rate', {
                                  props: {
                                    required: true,
                                    maxFractionDigits: 2,
                                    iconPre: 'pi pi-euro',
                                    min: 0,
                                    showClear: true,
                                    disabled: true
                                  },
                                  expressions: {
                                    'props.disabled': field => {
                                      return (
                                        !!field.model?.monthly_rate ||
                                        !!field.model?.teacher_hourly_rate_id
                                      );
                                    },
                                    'props.required': field => {
                                      return (
                                        !field.model?.monthly_rate &&
                                        !field.model?.teacher_hourly_rate_id
                                      );
                                    }
                                  }
                                })
                              ])
                            ]
                          )
                        ]
                      : []),
                    ...(this.authorizationService.can(
                      AcademyOrganisationTeacherSalaryPermission.UPDATE
                    )
                      ? [
                          FormlyUtil.createRepeat(
                            {
                              key: 'organisation_teacher_salaries',
                              props: {
                                label: 'organisation_teacher_salaries',
                                initialCount: 0,
                                addTextValue: 'organisation_teacher_salary'
                              }
                            },
                            [
                              {
                                key: 'id'
                              },
                              this.formlyService.createOrganisationFieldConfig(),
                              FormlyUtil.createRow([
                                {
                                  ...this.formlyService.createTeacherHourlyRateFieldConfig({
                                    required: true
                                  }),
                                  expressions: {
                                    'props.disabled': field => {
                                      return (
                                        !!field.model?.hourly_rate || !!field.model?.monthly_rate
                                      );
                                    },
                                    'props.required': field => {
                                      return (
                                        !field.model?.hourly_rate && !field.model?.monthly_rate
                                      );
                                    }
                                  }
                                }
                              ]),
                              FormlyUtil.createRow([
                                FormlyUtil.createNumberField('monthly_rate', {
                                  props: {
                                    required: true,
                                    maxFractionDigits: 2,
                                    iconPre: 'pi pi-euro',
                                    min: 0,
                                    showClear: true,
                                    disabled: true
                                  },
                                  expressions: {
                                    'props.disabled': field => {
                                      return (
                                        !!field.model?.hourly_rate ||
                                        !!field.model?.teacher_hourly_rate_id
                                      );
                                    },
                                    'props.required': field => {
                                      return (
                                        !field.model?.hourly_rate &&
                                        !field.model?.teacher_hourly_rate_id
                                      );
                                    }
                                  }
                                }),
                                FormlyUtil.createNumberField('hourly_rate', {
                                  props: {
                                    required: true,
                                    maxFractionDigits: 2,
                                    iconPre: 'pi pi-euro',
                                    min: 0,
                                    showClear: true,
                                    disabled: true
                                  },
                                  expressions: {
                                    'props.disabled': field => {
                                      return (
                                        !!field.model?.monthly_rate ||
                                        !!field.model?.teacher_hourly_rate_id
                                      );
                                    },
                                    'props.required': field => {
                                      return (
                                        !field.model?.monthly_rate &&
                                        !field.model?.teacher_hourly_rate_id
                                      );
                                    }
                                  }
                                })
                              ])
                            ]
                          )
                        ]
                      : [])
                  ]
                : [])
            ])
          ])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      let input = {
        ...formSaveModel.input,

        date_of_birth: FormlyUtil.toIsoDateString(formSaveModel.input.date_of_birth),
        ...(formSaveModel.input?.profile_picture?.length
          ? {
              person_media: {
                data: {
                  id: formSaveModel.input.profile_picture[0],
                  person_media_type: FilePersonMediaTypeEnum.PROFILE_PICTURE
                }
              }
            }
          : {}),
        person: { data: {} },
        organisation_persons_active: {
          data: (this.model?.person_id
            ? PersonUtil.getNewOrganisations(
                formSaveModel.input.organisations,
                this.readOnlyModel.organisation_persons_active
              )
            : formSaveModel.input.organisations
          )?.map((id: number) => ({
            organisation_id: id
          }))
        },
        side_a_person_relationships: {
          data: (this.model?.person_id
            ? formSaveModel.input.relationships?.filter((item: any) => !item.id)
            : formSaveModel.input.relationships || []
          )?.map((item: any) => {
            return {
              person_in_relationship_id: item.person_in_relationship_id,
              relationship: item.relationship
            };
          })
        },
        school_teachers_active: {
          data: (this.model?.person_id
            ? PersonUtil.getNewSchools(
                formSaveModel.input.schools,
                this.readOnlyModel.school_teachers_active
              )
            : formSaveModel.input.schools
          )?.map((id: number) => ({
            school_id: id
          }))
        },
        ...(this.tenantService.canCrudTeacher
          ? {
              teachers: {
                data: [
                  {
                    ...formSaveModel.input.latest_teacher,

                    education_paths: {
                      data: (this.model?.person_id
                        ? formSaveModel.input.latest_teacher?.education_paths?.filter(
                            (item: any) => !item.id
                          )
                        : formSaveModel.input.latest_teacher?.education_paths || []
                      )?.map((item: any) => {
                        return {
                          ...item,

                          starts_at: FormlyUtil.toIsoDateString(item.starts_at),
                          ends_at: FormlyUtil.toIsoDateString(item.ends_at)
                        };
                      })
                    },
                    start_of_work: FormlyUtil.toIsoDateString(
                      formSaveModel.input.latest_teacher?.start_of_work
                    ),

                    ...(this.authorizationService.cannot(Role.DIRECTOR)
                      ? {
                          teacher_hourly_rate_id:
                            formSaveModel.input.latest_teacher?.teacher_hourly_rate_id,
                          monthly_rate: formSaveModel.input.latest_teacher?.monthly_rate
                            ? Math.round(formSaveModel.input.latest_teacher.monthly_rate * 100)
                            : null,
                          hourly_rate: formSaveModel.input.latest_teacher?.hourly_rate
                            ? Math.round(formSaveModel.input.latest_teacher.hourly_rate * 100)
                            : null
                        }
                      : {})
                  }
                ]
              },

              ...(this.authorizationService.can(AcademySchoolTeacherSalaryPermission.UPDATE)
                ? {
                    school_teacher_salaries: {
                      data: (this.model?.person_id
                        ? formSaveModel.input.school_teacher_salaries?.filter(
                            (item: any) => !item.id
                          )
                        : formSaveModel.input.school_teacher_salaries || []
                      )?.map((item: any) => {
                        return {
                          ...item,

                          monthly_rate: item.monthly_rate
                            ? Math.round(item.monthly_rate * 100)
                            : null,
                          hourly_rate: item.hourly_rate ? Math.round(item.hourly_rate * 100) : null
                        };
                      })
                    }
                  }
                : {}),

              ...(this.authorizationService.can(AcademyOrganisationTeacherSalaryPermission.UPDATE)
                ? {
                    organisation_teacher_salaries: {
                      data: (this.model?.person_id
                        ? formSaveModel.input.organisation_teacher_salaries?.filter(
                            (item: any) => !item.id
                          )
                        : formSaveModel.input.organisation_teacher_salaries || []
                      )?.map((item: any) => {
                        return {
                          ...item,

                          monthly_rate: item.monthly_rate
                            ? Math.round(item.monthly_rate * 100)
                            : null,
                          hourly_rate: item.hourly_rate ? Math.round(item.hourly_rate * 100) : null
                        };
                      })
                    }
                  }
                : {})
            }
          : {})
      };

      ModelUtil.deleteKey(input, 'latest_teacher');
      ModelUtil.deleteKey(input, 'schools');
      ModelUtil.deleteKey(input, 'person_in_relationship_select');
      ModelUtil.deleteKey(input, 'relationships');
      ModelUtil.deleteKey(input, 'organisations');
      ModelUtil.deleteKey(input, 'profile_picture');

      const params: string[] = [];
      const queries: string[] = [];
      let variables: any = { input };

      if (this.model?.person_id) {
        ModelUtil.deleteKey(input);
        ModelUtil.deleteKey(input, 'person');

        // Get deleted schools
        const updateSchools = [
          ...PersonUtil.getDeletedSchools(
            this.readOnlyModel.school_teachers_active,
            formSaveModel.input.schools
          )
        ];

        if (updateSchools.length) {
          params.push('$updateSchools: [academy_school_director_active_updates!] = []');
          queries.push(
            `update_academy_school_teacher_active_many(updates: $updateSchools) { __typename }`
          );
          variables = {
            ...variables,
            updateSchools
          };
        }

        // Get deleted organisations
        const updateOrganisations = [
          ...PersonUtil.getDeletedOrganisations(
            this.readOnlyModel.organisation_persons_active,
            formSaveModel.input.organisations
          )
        ];

        if (updateOrganisations.length) {
          params.push('$updateOrganisations: [academy_organisation_person_active_updates!] = []');
          queries.push(
            `update_academy_organisation_person_active_many(updates: $updateOrganisations) { __typename }`
          );
          variables = {
            ...variables,
            updateOrganisations
          };
        }

        // Get deleted relationships
        const updateRelationships = [
          ...PersonUtil.getDeletedRelationships(
            this.readOnlyModel.side_a_person_relationships,
            formSaveModel.input.relationships
          ),
          ...PersonUtil.getDeletedRelationships(
            this.readOnlyModel.side_b_person_relationships,
            formSaveModel.input.relationships,
            true
          )
        ];

        // Get updated relationships
        updateRelationships.push(
          ...PersonUtil.getUpdatedRelationships(
            this.readOnlyModel.side_a_person_relationships,
            formSaveModel.input.relationships
          ),
          ...PersonUtil.getUpdatedRelationships(
            this.readOnlyModel.side_b_person_relationships,
            formSaveModel.input.relationships,
            true
          )
        );

        if (updateRelationships.length) {
          params.push('$updateRelationships: [core_person_relationship_updates!] = []');
          queries.push(
            `update_core_person_relationship_many(updates: $updateRelationships) { __typename }`
          );
          variables = {
            ...variables,
            updateRelationships
          };
        }

        const updatePersonMedia = [
          // Get deleted person media
          ...(QueryUtil.comparer({
            itemsA: this.readOnlyModel?.['profile_picture']?.map((item: any) => item.id),
            itemsB: formSaveModel.input?.profile_picture,
            compareFunc: (itemA: any, itemB: any) => itemA === itemB,
            resultMapFunc: item => ({
              where: {
                id: { _eq: item }
              },
              _set: { deleted_at: 'now()' }
            })
          }) || [])
        ];

        if (updatePersonMedia.length) {
          params.push('$updatePersonMedia: [file_person_media_updates!] = []');
          queries.push('update_file_person_media_many(updates: $updatePersonMedia) { __typename }');
          variables = {
            ...variables,
            updatePersonMedia
          };
        }

        if (this.tenantService.canCrudTeacher) {
          const updateEducationPaths = [
            // Get deleted education paths
            ...(QueryUtil.comparer({
              itemsA: this.readOnlyModel.latest_teacher?.education_paths,
              itemsB: formSaveModel.input.latest_teacher?.education_paths,
              compareFunc: (itemA: any, itemB: any) => !!itemB?.id && itemA.id === itemB.id,
              resultMapFunc: item => ({
                where: {
                  id: { _eq: item.id }
                },
                _set: { deleted_at: 'now()' }
              })
            }) || []),
            // Get updated education paths
            ...(this.readOnlyModel.latest_teacher?.education_paths || []).reduce(
              (acc, itemA: any) => {
                const itemB = (formSaveModel.input.latest_teacher?.education_paths || []).find(
                  (educationPath: any) => educationPath.id === itemA.id
                );
                if (itemB) {
                  acc.push({
                    where: {
                      id: { _eq: itemA.id }
                    },
                    _set: {
                      name_of_education: itemB.name_of_education,
                      education_type_id: itemB.education_type_id,
                      training_facility: itemB.training_facility,
                      place_of_education: itemB.place_of_education,
                      starts_at: FormlyUtil.toIsoDateString(itemB.starts_at),
                      ends_at: FormlyUtil.toIsoDateString(itemB.ends_at)
                    }
                  });
                }
                return acc;
              },
              [] as any[]
            )
          ];

          if (updateEducationPaths.length) {
            params.push('$updateEducationPaths: [staff_education_path_updates!] = []');
            queries.push(
              'update_staff_education_path_many(updates: $updateEducationPaths) { __typename }'
            );

            variables = {
              ...variables,
              updateEducationPaths
            };
          }

          const updateSchoolTeacherSalaries = [
            // Get deleted school teacher salaries
            ...(QueryUtil.comparer({
              itemsA: this.readOnlyModel.school_teacher_salaries,
              itemsB: formSaveModel.input.school_teacher_salaries,
              compareFunc: (itemA: any, itemB: any) => !!itemB?.id && itemA.id === itemB.id,
              resultMapFunc: item => ({
                where: {
                  id: { _eq: item.id }
                },
                _set: { deleted_at: 'now()' }
              })
            }) || []),
            // Get updated school teacher salaries
            ...(this.readOnlyModel.school_teacher_salaries || []).reduce((acc, itemA: any) => {
              const itemB = (formSaveModel.input.school_teacher_salaries || []).find(
                (schoolTeacherSalary: any) => schoolTeacherSalary.id === itemA.id
              );
              if (itemB) {
                acc.push({
                  where: {
                    id: { _eq: itemA.id }
                  },
                  _set: {
                    school_id: itemB.school_id,
                    teacher_hourly_rate_id: itemB.teacher_hourly_rate_id,
                    monthly_rate: itemB.monthly_rate ? Math.round(itemB.monthly_rate * 100) : null,
                    hourly_rate: itemB.hourly_rate ? Math.round(itemB.hourly_rate * 100) : null
                  }
                });
              }
              return acc;
            }, [] as any[])
          ];

          if (
            updateSchoolTeacherSalaries.length &&
            this.authorizationService.can(AcademySchoolTeacherSalaryPermission.UPDATE)
          ) {
            params.push(
              '$updateSchoolTeacherSalaries: [academy_school_teacher_salary_updates!] = []'
            );
            queries.push(
              'update_academy_school_teacher_salary_many(updates: $updateSchoolTeacherSalaries) { __typename }'
            );

            variables = {
              ...variables,
              updateSchoolTeacherSalaries
            };
          }

          const updateOrganisationTeacherSalaries = [
            // Get deleted organisation teacher salaries
            ...(QueryUtil.comparer({
              itemsA: this.readOnlyModel.organisation_teacher_salaries,
              itemsB: formSaveModel.input.organisation_teacher_salaries,
              compareFunc: (itemA: any, itemB: any) => !!itemB?.id && itemA.id === itemB.id,
              resultMapFunc: item => ({
                where: {
                  id: { _eq: item.id }
                },
                _set: { deleted_at: 'now()' }
              })
            }) || []),
            // Get updated organisation teacher salaries
            ...(this.readOnlyModel.organisation_teacher_salaries || []).reduce(
              (acc, itemA: any) => {
                const itemB = (formSaveModel.input.organisation_teacher_salaries || []).find(
                  (organisationTeacherSalary: any) => organisationTeacherSalary.id === itemA.id
                );
                if (itemB) {
                  acc.push({
                    where: {
                      id: { _eq: itemA.id }
                    },
                    _set: {
                      organisation_id: itemB.organisation_id,
                      teacher_hourly_rate_id: itemB.teacher_hourly_rate_id,
                      monthly_rate: itemB.monthly_rate
                        ? Math.round(itemB.monthly_rate * 100)
                        : null,
                      hourly_rate: itemB.hourly_rate ? Math.round(itemB.hourly_rate * 100) : null
                    }
                  });
                }
                return acc;
              },
              [] as any[]
            )
          ];

          if (
            updateOrganisationTeacherSalaries.length &&
            this.authorizationService.can(AcademyOrganisationTeacherSalaryPermission.UPDATE)
          ) {
            params.push(
              '$updateOrganisationTeacherSalaries: [academy_organisation_teacher_salary_updates!] = []'
            );
            queries.push(
              'update_academy_organisation_teacher_salary_many(updates: $updateOrganisationTeacherSalaries) { __typename }'
            );

            variables = {
              ...variables,
              updateOrganisationTeacherSalaries
            };
          }
        }
      }

      return this.apollo.mutate({
        mutation: gql`
          mutation CreateCorePersonData(
            $input: core_person_data_insert_input!,
            ${params.join('\n')}
            ) {
            result: insert_core_person_data_one(object: $input) {
              __typename
            }
            ${queries.join('\n')}
          }
        `,
        variables
      });
    };
  }
}
