<p-overlayPanel #op>
  <ng-template pTemplate="content">
    <p-table
      *transloco="let transloco"
      [value]="this.users()"
      responsiveLayout="scroll"
      scrollHeight="250px"
      styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th class="!text-left">{{ transloco('who') }}</th>
          <th>{{ transloco('last_seen_at') }}</th>
        </tr>
      </ng-template>

      <ng-template let-data pTemplate="body">
        <tr>
          <td class="!text-left">
            <a
              [routerLink]="['/', 'staff', 'people', 'detail', this.getRow(data)?.person_id]"
              class="cursor-pointer hover:text-primary hover:underline">
              <span>{{ this.getFullName(this.getRow(data)?.current_person_data) }}</span>
            </a>
          </td>
          <td>{{ this.getRow(data)?.last_seen_at | libDateTime }}</td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="2">
            {{ transloco('no_one_is_online') }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>

<div
  (click)="op.toggle($event)"
  class="mr-4 flex h-full cursor-pointer flex-col items-center justify-center">
  <div>
    <i
      [ngClass]="{
        '!text-primary': this.users().length > 0
      }"
      [value]="this.users().length.toString() || ''"
      class="pi pi-users p-text-secondary !text-4xl"
      pBadge></i>
  </div>
</div>
