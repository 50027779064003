import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'libDateTime', standalone: true })
export class DateTimePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const datePipe = new DatePipe('de-AT');
    if (!!value && typeof value === 'string' && !value.endsWith('Z')) {
      return datePipe.transform(value + 'Z', 'short');
    }
    return datePipe.transform(value, 'short');
  }
}
