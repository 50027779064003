import {
  CorePersonDataModel,
  CorePersonModel,
  DefaultProperties,
  StaffStatusEnum
} from '~ngx-shared/models';
import { Role } from '~ngx-shared/authentication';

export interface StaffManagerModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  status?: StaffStatusEnum;

  // Relations
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;
}

export class StaffManagerPermission {
  static readonly CREATE = [Role.LESSOR];
  static readonly READ = [Role.LESSOR];
  static readonly UPDATE = [Role.LESSOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffManagerPermission.DELETE];
}
