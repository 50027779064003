import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TagModule } from 'primeng/tag';
import { BasePageComponent } from '~ngx-shared/layout';
import { ClassBookEntryListComponent } from '~madrasa/academy/components/class-book-entry-list/class-book-entry-list.component';
import { Role } from '~ngx-shared/authentication';
import { SchedulerService } from '~madrasa/services';

@Component({
  selector: 'app-class-book-entry-list-page',
  standalone: true,
  imports: [
    ClassBookEntryListComponent,
    ButtonDirective,
    BasePageComponent,
    TranslocoDirective,
    NgxPermissionsModule,
    TagModule
  ],
  templateUrl: './class-book-entry-list-page.component.html',
  styleUrl: './class-book-entry-list-page.component.scss'
})
export class ClassBookEntryListPageComponent {
  protected readonly Role = Role;

  constructor(
    public router: Router,
    private schedulerService: SchedulerService
  ) {}

  trigger() {
    this.schedulerService.triggerClassBook().subscribe();
  }
}
