import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { TranslocoService } from '@jsverse/transloco';
import { FormlyService, TenantService } from '~madrasa/services';
import {
  AcademyCourseModel,
  AcademySubjectModel,
  AcademyUnitModel,
  AcademyWeekdayEnum
} from '~ngx-shared/models';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil } from '~ngx-shared/utils';
import { PersonUtil } from '~madrasa/core/utils/person.util';

@UntilDestroy()
@Component({
  selector: 'app-create-update-unit-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-unit-form-page.component.html',
  styleUrl: './create-update-unit-form-page.component.scss'
})
export class CreateUpdateUnitFormPageComponent implements OnInit {
  form = new FormGroup({});
  readOnlyModel: AcademyUnitModel = {};
  model: AcademyUnitModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_unit'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  constructor(
    private apollo: Apollo,
    private formlyService: FormlyService,
    private tenantService: TenantService,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AcademyUnitModel }>({
                query: gql`
                  query ReadAcademyUnitById($id: bigint!) {
                    result: academy_unit_by_pk(id: $id) {
                      id
                      created_at
                      updated_at
                      school_id
                      course_id
                      subject_id
                      weekday
                      starts_at
                      ends_at
                      color

                      school {
                        organisation_id
                      }

                      course {
                        school_period_id
                      }

                      unit_teachers(order_by: { current_person_data: { first_name: asc } }) {
                        id
                        person_id
                        current_person_data {
                          person_id
                          academic_degree_prefix
                          academic_degree_suffix
                          first_name
                          last_name
                        }
                        ${this.tenantService.canCrudTeacher ? 'hourly_rate' : ''}
                      }
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          this.readOnlyModel = result;
          let model = cloneDeep(result);

          if (model) {
            this.model = {
              ...model,
              starts_at: FormlyUtil.fromIsoTimeString(model.starts_at),
              ends_at: FormlyUtil.fromIsoTimeString(model.ends_at),
              school_period_id: model.course?.school_period_id,
              organisation_id: model.school?.organisation_id,
              teachers:
                model.unit_teachers?.map(unit_teacher => ({
                  id: unit_teacher.id,
                  person_id: unit_teacher.person_id,
                  full_name: `${ModelUtil.getFullName(
                    unit_teacher['current_person_data'],
                    this.translocoService
                  )}, Id: ${unit_teacher.person_id}`,
                  hourly_rate: unit_teacher.hourly_rate ? unit_teacher.hourly_rate / 100 : undefined
                })) || []
            };
          }
        }

        this.fields = [
          {
            key: 'id'
          },
          FormlyUtil.createSelectField('school_period_id', {
            props: {
              label: 'school_period',
              required: true,
              options: this.apollo
                .query<{
                  result: AcademyCourseModel[];
                }>({
                  query: gql`
                    query ReadAcademySchoolPeriods {
                      result: academy_school_period(order_by: { ends_at: desc }) {
                        id
                        name
                      }
                    }
                  `
                })
                .pipe(
                  map(queryResult =>
                    queryResult.data.result.map(schoolPeriod => ({
                      label: schoolPeriod.name,
                      value: schoolPeriod.id
                    }))
                  )
                )
            }
          }),
          this.formlyService.createOrganisationFieldConfig(),
          this.formlyService.createSchoolFieldConfig(),
          FormlyUtil.createRow([
            FormlyUtil.createSelectField('course_id', {
              props: {
                label: 'course',
                required: true,
                options: []
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  if (field.props && field.model.school_id && field.model.school_period_id) {
                    field.props.options = this.getCourses(
                      field.model.school_period_id,
                      field.model.school_id
                    );
                  }
                  return field.options?.fieldChanges?.pipe(
                    filter(
                      event =>
                        event.type === 'valueChanges' &&
                        (event.field.key === 'school_id' || event.field.key === 'school_period_id')
                    ),
                    tap(event => {
                      field.formControl?.setValue(undefined);

                      if (field.props && field.model.school_id && field.model.school_period_id) {
                        field.props.options = this.getCourses(
                          field.model.school_period_id,
                          field.model.school_id
                        );
                      }
                    })
                  );
                }
              }
            }),
            FormlyUtil.createSelectField('subject_id', {
              props: {
                label: 'subject',
                required: true,
                options: []
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  if (field.props && field.model.organisation_id) {
                    field.props.options = this.getSubjects(field.model?.organisation_id);
                  }
                  return field.options?.fieldChanges?.pipe(
                    filter(
                      event =>
                        event.type === 'valueChanges' && event.field.key === 'organisation_id'
                    ),
                    tap(event => {
                      field.formControl?.setValue(undefined);

                      if (event.value && field.props) {
                        field.props.options = this.getSubjects(event.value);
                      }
                    })
                  );
                }
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createSelectField('weekday', {
              defaultValue: AcademyWeekdayEnum.MONDAY,
              props: {
                required: true,
                options: Object.keys(AcademyWeekdayEnum)
                  .filter(key => isNaN(+key))
                  .map(key => ({
                    label: 'weekday.' + key,
                    value: AcademyWeekdayEnum[key as keyof typeof AcademyWeekdayEnum]
                  }))
              }
            })
          ]),
          FormlyUtil.createRow([FormlyUtil.createColorPickerField('color')]),
          FormlyUtil.createRow([
            FormlyUtil.createTimePickerField('starts_at', {
              props: {
                required: true
              }
            }),
            FormlyUtil.createTimePickerField('ends_at', {
              props: {
                required: true
              }
            })
          ]),
          this.formlyService.createPersonSelectFieldConfig({
            key: 'teacher_select',
            label: 'teachers',
            conditions: field => [
              {
                latest_teacher: { status: { _eq: 'active' } }
              },
              {
                school_teachers_active: {
                  school_id: { _eq: field?.model?.school_id }
                }
              }
            ],
            click: (event, field) => {
              const value = field.formControl?.value;
              if (
                value &&
                (!this.model?.['teachers'] ||
                  this.model['teachers']?.findIndex(
                    (item: any) => item.person_id === value.value?.value?.person_id
                  ) === -1)
              ) {
                this.model = {
                  ...this.model,
                  teacher_select: undefined,
                  teachers: [
                    ...(this.model['teachers'] || []),
                    {
                      person_id: value.value?.value?.person_id,
                      full_name: `${value.label}, Id: ${value.value?.value?.person_id}`,
                      hourly_rate: undefined
                    }
                  ]
                };
              }
            }
          }),
          FormlyUtil.createRow([
            FormlyUtil.createTable(
              {
                key: 'teachers',
                props: {
                  showIndex: true,
                  canAdd: () => false,
                  hideLabel: true,
                  columns: [
                    {
                      key: 'full_name',
                      header: 'name'
                    },
                    ...(this.tenantService.canCrudTeacher
                      ? [
                          {
                            key: 'hourly_rate',
                            header: 'hourly_rate'
                          }
                        ]
                      : [])
                  ]
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    return field.options?.fieldChanges?.pipe(
                      filter(
                        event => event.type === 'valueChanges' && event.field.key === 'school_id'
                      ),
                      tap(event => {
                        field.formControl?.setValue([]);
                      })
                    );
                  }
                }
              },
              [
                {
                  key: 'id'
                },
                {
                  key: 'person_id'
                },
                FormlyUtil.createLabelField('full_name', {
                  props: {
                    label: 'name',
                    disableMargin: true,
                    hideLabel: true,
                    labelClass: 'font-normal'
                  }
                }),
                ...(this.tenantService.canCrudTeacher
                  ? [
                      FormlyUtil.createNumberField('hourly_rate', {
                        props: {
                          disableMargin: true,
                          hideLabel: true,
                          maxFractionDigits: 2,
                          iconPre: 'pi pi-euro',
                          min: 0,
                          showClear: true
                        }
                      })
                    ]
                  : [])
              ]
            )
          ])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,

        starts_at: FormlyUtil.toIsoTimeString(formSaveModel.input.starts_at),
        ends_at: FormlyUtil.toIsoTimeString(formSaveModel.input.ends_at),
        unit_teachers: {
          data: formSaveModel.input.teachers?.map((teacher: any) => ({
            person_id: teacher.person_id,
            ...(this.tenantService.canCrudTeacher
              ? {
                  hourly_rate: teacher.hourly_rate ? Math.round(teacher.hourly_rate * 100) : null
                }
              : {})
          }))
        }
      };

      let options: any = {
        mutation: gql`
          mutation CreateAcademyUnit($input: academy_unit_insert_input!) {
            result: insert_academy_unit_one(object: $input) {
              __typename
            }
          }
        `,
        variables: {
          input
        }
      };

      ModelUtil.deleteKey(input, 'school_period_id');
      ModelUtil.deleteKey(input, 'organisation_id');
      ModelUtil.deleteKey(input, 'teacher_select');
      ModelUtil.deleteKey(input, 'teachers');

      if (this.model?.id) {
        ModelUtil.deleteKey(input);
        ModelUtil.deleteKey(input, 'unit_teachers');

        const newTeachers = PersonUtil.getNewPerson(
          formSaveModel.input.teachers,
          this.readOnlyModel.unit_teachers
        ).map(person_id => ({
          person_id,
          unit_id: this.model.id
        }));

        const updateTeachers = PersonUtil.getDeletedPerson(
          this.readOnlyModel.unit_teachers,
          formSaveModel.input.teachers
        );

        if (this.tenantService.canCrudTeacher) {
          updateTeachers.push(
            ...(this.readOnlyModel.unit_teachers
              ?.map((item: any) => {
                const teacher = formSaveModel.input.teachers?.find(
                  (teacher: any) => teacher.person_id === item.person_id
                );
                if (teacher && teacher.hourly_rate != item.hourly_rate) {
                  return {
                    where: {
                      id: { _eq: item.id }
                    },
                    _set: {
                      hourly_rate: teacher.hourly_rate
                        ? Math.round(teacher.hourly_rate * 100)
                        : null
                    }
                  };
                }

                return undefined;
              })
              .filter((item: any) => !!item) || [])
          );
        }

        options = {
          mutation: gql`
            mutation UpdateAcademyUnit(
              $id: bigint!
              $input: academy_unit_set_input!
              $newTeachers: [academy_unit_teacher_insert_input!] = []
              $updateTeachers: [academy_unit_teacher_updates!] = []
            ) {
              result: update_academy_unit_by_pk(pk_columns: { id: $id }, _set: $input) {
                __typename
              }
              insert_academy_unit_teacher(objects: $newTeachers) {
                __typename
              }
              update_academy_unit_teacher_many(updates: $updateTeachers) {
                __typename
              }
            }
          `,
          variables: {
            id: this.model.id,
            input,
            newTeachers,
            updateTeachers
          }
        };
      }

      return this.apollo.mutate(options);
    };
  }

  getCourses(schoolPeriodId?: number, schoolId?: number): Observable<any[]> {
    return this.apollo
      .query<{
        result: AcademyCourseModel[];
      }>({
        query: gql`
          query ReadAcademyCourses($where: academy_course_bool_exp = {}) {
            result: academy_course(order_by: { name: asc }, where: $where) {
              id
              name
              course_name {
                name
              }
            }
          }
        `,
        variables: {
          where: {
            school_id: { _eq: schoolId },
            school_period_id: { _eq: schoolPeriodId }
          }
        }
      })
      .pipe(
        map(queryResult =>
          queryResult.data.result.map(course => ({
            label: course.name || course.course_name?.name,
            value: course.id
          }))
        )
      );
  }

  getSubjects(organisationId?: number): Observable<any[]> {
    return this.apollo
      .query<{
        result: AcademySubjectModel[];
      }>({
        query: gql`
          query ReadAcademySubjects($where: academy_subject_bool_exp = {}) {
            result: academy_subject(order_by: { name: asc }, where: $where) {
              id
              name
            }
          }
        `,
        variables: {
          where: {
            organisation_id: { _eq: organisationId },
            is_active: { _eq: true }
          }
        }
      })
      .pipe(
        map(queryResult =>
          queryResult.data.result.map(subject => ({
            label: subject.name,
            value: subject.id
          }))
        )
      );
  }
}
