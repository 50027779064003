<lib-layout-base-page *transloco="let transloco">
  <div class="m-base">
    <div class="flex flex-col gap-6">
      <lib-formly-form
        (savedEvent)="this.savedEvent($event)"
        [fields]="this.fields"
        [form]="this.form"
        [model]="this.model"
        [navigateBackOnCancel]="false"
        [navigateBackOnSave]="false"
        [options]="this.options"
        [saveButtonText]="'generate'"
        [showCancelButton]="false"
        [showToastMessages]="false"
        [submit]="this.submit">
      </lib-formly-form>

      @if (this.result()) {
        <app-student-list
          [columns]="this.columns()"
          [stateKey]="this.router.url + 'student-invoice-list'"></app-student-list>
      }
    </div>
  </div>
</lib-layout-base-page>
