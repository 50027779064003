<div *transloco="let transloco">
  <div class="flex flex-col gap-6">
    <lib-formly-form
      (savedEvent)="this.savedEvent($event)"
      [fields]="this.fields"
      [form]="this.form"
      [model]="this.model"
      [navigateBackOnCancel]="false"
      [navigateBackOnSave]="false"
      [options]="this.options"
      [saveButtonText]="'generate'"
      [showCancelButton]="false"
      [showToastMessages]="false"
      [submit]="this.submit">
    </lib-formly-form>

    @for (organisation of this.result(); track organisation) {
      <span class="block text-4xl uppercase">{{ organisation.name }}</span>

      <p-accordion [multiple]="true">
        @for (school of organisation.schools; track school) {
          <p-accordionTab header="{{ school.name }}">
            <div class="flex flex-col">
              <p-table
                [value]="[{}]"
                responsiveLayout="scroll"
                styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm ">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="w-2/12">{{ transloco('course') }}</th>
                    <th>{{ this.form.value?.starts_at | libDate }}</th>
                    <th>{{ this.form.value?.ends_at | libDate }}</th>
                    <th>{{ transloco('difference') }}</th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body">
                  @for (course of school.courses; track course) {
                    <tr>
                      <td class="align-top">
                        <div>
                          <a
                            [routerLink]="['/', 'academy', 'courses', 'detail', course.id]"
                            class="cursor-pointer hover:text-primary hover:underline">
                            <span class="text-xl font-bold"
                              >{{ course.course_name?.name || course.name }},</span
                            >
                            <span class="ml-2 text-warmGray-400">Id: {{ course.id }}</span>
                          </a>
                        </div>
                      </td>
                      <td class="align-top">
                        <div class="flex flex-col gap-2">
                          @for (start of course.start; track start) {
                            <div>
                              <a
                                [routerLink]="[
                                  '/',
                                  'staff',
                                  'people',
                                  'detail',
                                  start.current_person_data?.person_id
                                ]"
                                class="cursor-pointer hover:text-primary hover:underline">
                                <span>{{ this.getFullName(start.current_person_data) }},</span>
                                <span class="ml-2 text-warmGray-400"
                                  >Id: {{ start.current_person_data?.person_id }}</span
                                >
                                <span class="ml-2 text-warmGray-400"
                                  >{{ transloco('status') }}:</span
                                ><span
                                  [ngClass]="{
                                    'text-green-500': start.status === 'active',
                                    'text-red-500': start.status === 'inactive'
                                  }">
                                  {{ transloco('status.' + start.status) }}</span
                                >
                              </a>
                            </div>
                          }
                          @if (course.start?.length) {
                            <div class="border-t-2 border-double pt-2 font-bold">
                              {{ course.start?.length }} - {{ transloco('students') }}
                            </div>
                          }
                        </div>
                      </td>
                      <td class="align-top">
                        <div class="flex flex-col gap-2">
                          @for (end of course.end; track end) {
                            <div>
                              <a
                                [routerLink]="[
                                  '/',
                                  'staff',
                                  'people',
                                  'detail',
                                  end.current_person_data?.person_id
                                ]"
                                class="cursor-pointer hover:text-primary hover:underline">
                                <span>{{ this.getFullName(end.current_person_data) }},</span>
                                <span class="ml-2 text-warmGray-400"
                                  >Id: {{ end.current_person_data?.person_id }},</span
                                >
                                <span class="ml-2 text-warmGray-400"
                                  >{{ transloco('status') }}:</span
                                ><span
                                  [ngClass]="{
                                    'text-green-500': end.status === 'active',
                                    'text-red-500': end.status === 'inactive'
                                  }">
                                  {{ transloco('status.' + end.status) }}</span
                                >
                              </a>
                            </div>
                          }
                          <div class="flex gap-4 border-t-2 border-double pt-2 font-bold">
                            <span>{{ course.end?.length }} - {{ transloco('students') }}</span>
                            <span class="text-green-500"
                              >{{ course.active }} - {{ transloco('status.active') }}</span
                            >
                            <span class="text-red-500"
                              >{{ course.inactive }} - {{ transloco('status.inactive') }}</span
                            >
                          </div>
                        </div>
                      </td>
                      <td class="align-top">
                        <div
                          class="text-xl font-bold"
                          [ngClass]="{
                            'text-green-500': course.active > course.start?.length,
                            'text-red-500': course.active < course.start?.length
                          }">
                          {{ course.active - course.start?.length }} - {{ transloco('students') }}
                        </div>
                      </td>
                    </tr>
                  }
                </ng-template>
              </p-table>
            </div>
          </p-accordionTab>
        }
      </p-accordion>
    }
  </div>
</div>
