<lib-graph-ql-table #table *transloco="let transloco" [dataProvider]="this.dataProvider">
  @if (this.showCreateButton()) {
    <ng-template libTemplate="caption">
      <button
        (click)="this.createAccess()"
        [label]="transloco('new')"
        icon="pi pi-plus"
        class="p-button-success"
        pButton
        type="button"></button>
    </ng-template>
  }
  <ng-template libTemplate="header">
    <tr>
      @if (this.showPerson()) {
        <th class="!px-5 !text-left">{{ transloco('person') }}</th>
      }
      <th>{{ transloco('role') }}</th>
      <th>{{ transloco('starts_at') }}</th>
      <th>{{ transloco('ends_at') }}</th>
      <th>{{ transloco('status') }}</th>
      <th>{{ transloco('created_at') }}</th>
      <th>{{ transloco('updated_at') }}</th>
      <th>{{ transloco('actions') }}</th>
    </tr>
  </ng-template>
  <ng-template let-data libTemplate="body">
    <tr>
      @if (this.showPerson()) {
        <td class="!text-left">
          <a
            [label]="this.getFullName(this.getRow(data)?.user?.current_person_data)"
            [routerLink]="['/', 'people', 'detail', this.getRow(data)?.user?.person_id]"
            class="p-button-link"
            pButton
            type="button"></a>
        </td>
      }
      <td>{{ transloco('roles.' + this.getRow(data)?.role) }}</td>
      <td>{{ this.getRow(data)?.starts_at | libDateTime }}</td>
      <td>{{ this.getRow(data)?.ends_at | libDateTime }}</td>
      <td>
        @if (!!this.getRow(data)?.ends_at) {
          <span class="text-red-600">{{ transloco('expired') }}</span>
        } @else {
          <span class="text-green-600">{{ transloco('valid') }}</span>
        }
      </td>
      <td>{{ this.getRow(data)?.created_at | libDateTime }}</td>
      <td>{{ this.getRow(data)?.updated_at | libDateTime }}</td>
      <td>
        @if (!this.getRow(data)?.ends_at) {
          <div
            *ngxPermissionsOnly="this.AccessRolePermission.UPDATE"
            class="flex items-center justify-center">
            <button
              (click)="this.revokeAccess(data)"
              [pTooltip]="transloco('revoke')"
              [loading]="this.isRevoking()[data?.user?.person_id]"
              class="p-button-danger p-button-text"
              icon="pi pi-replay"
              pButton
              tooltipPosition="top"
              type="button"></button>
          </div>
        }
      </td>
    </tr>
  </ng-template>
</lib-graph-ql-table>
