import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, finalize, Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ObjectUtils } from 'primeng/utils';
import { environment } from '~ngx-shared/environment';
import { LoggerService, NavigationService } from '~ngx-shared/services';
import { ConfirmationService, ToastService } from '~ngx-shared/layout';

export type FormSubmitModel<T = any> = (formSaveModel: FormSaveModel<T>) => Observable<any>;

export interface FormSaveModel<T = any> {
  hasError?: boolean;
  init?: T;
  input: T;
  output?: T;
  outputRaw?: T;
}

@UntilDestroy()
@Component({
  selector: 'lib-formly-form',
  templateUrl: './form.component.html',
  styleUrl: './form.component.css'
})
export class FormComponent<T> implements OnInit {
  @Output() savingEvent = new EventEmitter<FormSaveModel<T>>();
  @Output() savedEvent = new EventEmitter<FormSaveModel<T>>();
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Input() form: FormGroup = new FormGroup({});
  @Input() model?: T;
  @Input() options?: FormlyFormOptions;
  @Input() fields: FormlyFieldConfig[];
  @Input() submit?: FormSubmitModel<T>;
  @Input() saveButtonText = 'save';
  @Input() saveButtonIcon = 'pi pi-save';
  @Input() navigateBackOnSave = false;
  @Input() showCancelButton = true;
  @Input() navigateBackOnCancel = false;
  @Input() showSaveButton = true;
  @Input() showToastMessages = true;
  @Input() showSaveConfirmation = false;
  // TODO: Disable form
  @Input() disableForm? = false;
  saving = false;
  showDebug = true;
  readonly environment = environment;
  private readonly _defaultOptions: FormlyFormOptions = {
    formState: {
      readonly: false
    }
  };

  constructor(
    private toastService: ToastService,
    private navigationService: NavigationService,
    private confirmationService: ConfirmationService,
    private loggerService: LoggerService
  ) {}

  ngOnInit(): void {
    if (!this.options) {
      this.options = this._defaultOptions;
    } else {
      this.options = {
        ...this._defaultOptions,
        ...this.options,
        formState: { ...this.options.formState, ...this._defaultOptions.formState }
      };
    }
  }

  submitForm(event?: Event) {
    if (this.form.invalid) {
      this.form.markAllAsTouched();

      if (this.showToastMessages) {
        this.toastService.toastErrorSave();
      }
      return;
    }

    if (!this.form.dirty) {
      this.form.markAllAsTouched();
      return;
    }

    const saveFunction = () => {
      const formSaveModel: FormSaveModel<T> = {
        input: this.form.getRawValue(),
        init: this.model
      };

      this.disableForm = true;

      this.saving = true;
      this.savingEvent.next(formSaveModel);

      const finalFunction = () => {
        this.saving = false;
        this.savedEvent.next(formSaveModel);
        this.disableForm = false;

        if (!formSaveModel.hasError && this.navigateBackOnSave) {
          this.navigationService.back();
        }
      };

      if (this.submit) {
        this.submit(formSaveModel)
          .pipe(untilDestroyed(this), delay(500), finalize(finalFunction))
          .subscribe(mutationResult => {
            const errors = ObjectUtils.resolveFieldData(mutationResult, 'errors');
            if (errors) {
              console.error(errors);
              if (Array.isArray(errors)) {
                errors.forEach(error => {
                  this.loggerService.error(error.message, JSON.stringify(error.extensions));
                });
              } else {
                this.loggerService.error(errors.message, JSON.stringify(errors.extensions));
              }
            }
            if (this.showToastMessages) {
              if (errors) {
                this.toastService.toastErrorSave();
              } else {
                this.toastService.toastSuccessSave();
              }
            }
            formSaveModel.hasError = !!errors;
            formSaveModel.output = mutationResult.data || mutationResult;
            formSaveModel.outputRaw = mutationResult;
          });
      } else {
        finalFunction();
      }
    };

    if (this.showSaveConfirmation && event) {
      this.confirmationService.confirmPopup(event, saveFunction);
    } else {
      saveFunction();
    }
  }

  createRange(number: number) {
    return new Array(number).fill(0).map((n, index) => index + 1);
  }

  cancel() {
    this.cancelEvent.next(this.navigateBackOnCancel);
    if (this.navigateBackOnCancel) {
      this.navigationService.back();
    }
  }
}
