import {
  CorePersonDataModel,
  CorePersonModel,
  DefaultProperties,
  StaffStatusEnum
} from '~ngx-shared/models';
import { Role } from '~ngx-shared/authentication';
import { AcademySchoolParentModel } from '../academy/school-parent.model';

export interface StaffParentModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  status?: StaffStatusEnum;

  // Relations
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;

  school_parents_active?: AcademySchoolParentModel[];
}

export class StaffParentPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffParentPermission.DELETE, Role.MANAGER];
}
