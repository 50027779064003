import { AcademyOrganisationModel, AcademyUnitModel, DefaultProperties } from '~ngx-shared/models';
import { Role } from '~ngx-shared/authentication';

export interface AcademyCourseNameModel extends DefaultProperties {
  // Columns
  id?: number;
  organisation_id?: number;
  name?: string;
  description?: string;
  is_active?: boolean;

  // Relations
  organisation?: AcademyOrganisationModel;

  units?: AcademyUnitModel[];
}

export class AcademyCourseNamePermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AcademyCourseNamePermission.DELETE, Role.MANAGER];
}
