import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { AccessService } from '~ngx-shared/services';

@UntilDestroy()
@Component({
  selector: 'app-change-email-form-dialog',
  standalone: true,
  imports: [FormlyModule],
  templateUrl: './change-email-form-dialog.component.html',
  styleUrl: './change-email-form-dialog.component.scss'
})
export class ChangeEmailFormDialogComponent implements OnInit {
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.change_email'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  constructor(
    public dialogRef: DynamicDialogRef,
    private accessService: AccessService,
    private dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.fields = [
      FormlyUtil.createRow([
        FormlyUtil.createTextField('email', {
          defaultValue: this.dialogConfig.data?.email,
          props: {
            type: 'email',
            required: true
          }
        })
      ])
    ];

    this.submit = (formSaveModel: FormSaveModel) => {
      return this.accessService.changeEmailOfAccess(
        this.dialogConfig.data?.id,
        formSaveModel.input.email
      );
    };
  }

  savedEvent(formSaveModel: FormSaveModel) {
    if (!formSaveModel.hasError) {
      this.dialogRef.close(formSaveModel);
    }
  }

  canceled($event: boolean) {
    if (this.dialogRef) {
      this.dialogRef.close(undefined);
    }
  }
}
