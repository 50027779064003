import { Component, effect, input, OnInit, ViewChild } from '@angular/core';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { RouterLink } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { map } from 'rxjs';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { TemplateDirective } from '~ngx-shared/directives';
import { ConfirmationService } from '~ngx-shared/layout';
import { TenantService } from '~madrasa/services';
import {
  AcademyCoursePermission,
  AcademyOrganisationModel,
  AcademySchoolModel,
  AcademyUnitModel,
  AcademyWeekdayEnum
} from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';
import { PersonDetailLinkComponent } from '~madrasa/staff/components/person-detail-link/person-detail-link.component';

@Component({
  selector: 'app-unit-list',
  standalone: true,
  imports: [
    TranslocoDirective,
    GraphQlAdvancedTableComponent,
    ButtonDirective,
    TooltipModule,
    RouterLink,
    TemplateDirective,
    NgxPermissionsModule,
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    PersonDetailLinkComponent
  ],
  templateUrl: './unit-list.component.html',
  styleUrl: './unit-list.component.scss'
})
export class UnitListComponent implements OnInit {
  showCreateButton = input<boolean>(false);
  showFilter = input<boolean>(false);
  showSort = input<boolean>(false);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);
  hideTeacherColumn = input<boolean>(false);

  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();

  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  graphQlTable: GraphQlTableModel;
  protected readonly AcademyCoursePermission = AcademyCoursePermission;

  constructor(
    private confirmationService: ConfirmationService,
    private tenantService: TenantService,
    private translocoService: TranslocoService,
    private apollo: Apollo
  ) {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ updated_at: 'desc' }];
        }

        return options;
      };
    });
  }

  ngOnInit() {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        type: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        sort: { isSortable: true },
        filter: { type: 'number' }
      },
      {
        path: 'color',
        sort: { isSortable: false },
        filter: { isNotFilterable: true },
        isNotExportable: true
      },
      {
        label: 'organisation',
        path: 'school.organisation.name',
        filter: {
          label: 'organisation',
          path: 'school.organisation_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademyOrganisationModel[];
            }>({
              query: gql`
                query ReadAcademyOrganisation($where: academy_organisation_bool_exp) {
                  result: academy_organisation(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(organisation => ({
                  label: organisation.name,
                  value: organisation.id
                }))
              )
            )
        },
        sort: { isSortable: true }
      },
      {
        label: 'school',
        path: 'school.name',
        filter: {
          label: 'school',
          path: 'school_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademySchoolModel[];
            }>({
              query: gql`
                query ReadAcademySchool($where: academy_school_bool_exp) {
                  result: academy_school(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(school => ({
                  label: school.name,
                  value: school.id
                }))
              )
            )
        },
        sort: { isSortable: true }
      },
      this.tenantService.currentTenant?.are_course_names_forced
        ? {
            label: 'course',
            path: 'course.course_name.name',
            filter: { type: 'string' },
            sort: { isSortable: true }
          }
        : {
            path: 'course.name',
            filter: { type: 'string' },
            sort: { isSortable: true }
          },
      {
        label: 'subject',
        path: 'subject.name',
        filter: { type: 'string' },
        sort: { isSortable: true }
      },
      {
        path: 'weekday',
        translate: true,
        patchResult: value =>
          'weekday.' + ModelUtil.getEnumKeyByValue(AcademyWeekdayEnum, value?.weekday),
        filter: {
          type: 'array',
          options: Object.keys(AcademyWeekdayEnum)
            .filter(key => isNaN(+key))
            .map(key => ({
              label: 'weekday.' + key,
              value: AcademyWeekdayEnum[key as keyof typeof AcademyWeekdayEnum]
            }))
        },
        sort: { isSortable: true }
      },
      {
        label: 'starts',
        path: 'starts_at',
        type: 'time',
        filter: { type: 'time' },
        sort: { isSortable: true }
      },
      {
        label: 'ends',
        path: 'ends_at',
        type: 'time',
        filter: { type: 'time' },
        sort: { isSortable: true }
      },
      {
        label: 'teachers',
        path: 'unit_teachers',
        query: `
          unit_teachers (order_by: { current_person_data: { first_name: asc } }) {
            current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
          }
        `,
        patchResult: value =>
          value?.unit_teachers
            .map((unitTeacher: any) =>
              ModelUtil.getFullName(unitTeacher.current_person_data, this.translocoService)
            )
            .join(', '),
        filter: { isNotFilterable: true },
        hidden: this.hideTeacherColumn()
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      }
    ];

    this.graphQlTable = {
      table: 'academy_unit',
      isPaginated: true,

      showCurrentPageReport: true,
      columns: columns
    };
  }

  getRow(data: any): AcademyUnitModel | undefined {
    return data;
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      let mutation: any = gql`
        mutation SoftDeleteAcademyUnitById($id: bigint!) {
          result: update_academy_unit_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: "now()" }
          ) {
            __typename
          }
        }
      `;

      this.confirmationService.confirmDeleteApollo({
        name: row.subject?.name,
        mutationOptions: {
          mutation,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }
}
