<lib-busy *transloco="let transloco" [height]="'50vh'" [isLoading]="!this.personData()">
  <lib-detail [fields]="this.fields()">
    <ng-template [libTemplate]="this.CoreRelationshipEnum.CHILD_FATHER + 'Key'" let-data>
      <ng-container [ngTemplateOutletContext]="{ $implicit: data }" [ngTemplateOutlet]="renderLink">
      </ng-container>
    </ng-template>
    <ng-template [libTemplate]="this.CoreRelationshipEnum.CHILD_MOTHER + 'Key'" let-data>
      <ng-container [ngTemplateOutletContext]="{ $implicit: data }" [ngTemplateOutlet]="renderLink">
      </ng-container>
    </ng-template>
    <ng-template [libTemplate]="this.CoreRelationshipEnum.CHILD_GRANDFATHER + 'Key'" let-data>
      <ng-container [ngTemplateOutletContext]="{ $implicit: data }" [ngTemplateOutlet]="renderLink">
      </ng-container>
    </ng-template>
    <ng-template [libTemplate]="this.CoreRelationshipEnum.CHILD_GRANDMOTHER + 'Key'" let-data>
      <ng-container [ngTemplateOutletContext]="{ $implicit: data }" [ngTemplateOutlet]="renderLink">
      </ng-container>
    </ng-template>
    <ng-template [libTemplate]="this.CoreRelationshipEnum.PARTNER + 'Key'" let-data>
      <ng-container [ngTemplateOutletContext]="{ $implicit: data }" [ngTemplateOutlet]="renderLink">
      </ng-container>
    </ng-template>
    <ng-template [libTemplate]="this.CoreRelationshipEnum.SIBLING + 'Key'" let-data>
      <ng-container [ngTemplateOutletContext]="{ $implicit: data }" [ngTemplateOutlet]="renderLink">
      </ng-container>
    </ng-template>
  </lib-detail>

  <ng-template #renderLink let-data>
    <div class="flex flex-col gap-2">
      @for (person of data; track person) {
        <div class="flex gap-2">
          <app-person-detail-link
            [showLink]="this.showPersonLink()"
            [person]="person?.['current_person_data']"></app-person-detail-link>
        </div>
      }
    </div>
  </ng-template>
</lib-busy>
