import { Component } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective } from '@jsverse/transloco';
import { Router, RouterLink } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { BasePageComponent } from '~ngx-shared/layout';
import { SchoolListComponent } from '~madrasa/academy/components/school-list/school-list.component';
import { AcademySchoolPermission } from '~ngx-shared/models';

@Component({
  selector: 'app-school-list-page',
  standalone: true,
  imports: [
    SchoolListComponent,
    BasePageComponent,
    ButtonDirective,
    TranslocoDirective,
    RouterLink,
    NgxPermissionsModule
  ],
  templateUrl: './school-list-page.component.html',
  styleUrl: './school-list-page.component.scss'
})
export class SchoolListPageComponent {
  protected readonly AcademySchoolPermission = AcademySchoolPermission;
  constructor(public router: Router) {}
}
