<lib-formly-form
  (savedEvent)="this.savedEvent($event)"
  [fields]="this.fields"
  [form]="this.form"
  [model]="this.model"
  [navigateBackOnSave]="false"
  [options]="this.options"
  [showCancelButton]="false"
  [submit]="this.submit">
</lib-formly-form>
