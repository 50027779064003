import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { validateIBAN } from 'ngx-iban-validator';
import { TranslocoService } from '@jsverse/transloco';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { FormlyService } from '~madrasa/services';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { AccountingOperatingCostModel, AccountingOperatingCostTypeModel } from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-create-update-operating-cost-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-operating-cost-form-page.component.html',
  styleUrl: './create-update-operating-cost-form-page.component.scss'
})
export class CreateUpdateOperatingCostFormPageComponent implements OnInit {
  form = new FormGroup({});
  model: AccountingOperatingCostModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_operating_cost'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  constructor(
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private formlyService: FormlyService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AccountingOperatingCostModel }>({
                query: gql`
                  query ReadAcademyOperatingCostId($id: bigint!) {
                    result: accounting_operating_cost_by_pk(id: $id) {
                      id
                      operating_cost_type_id
                      organisation_id
                      school_id
                      cost
                      description
                      iban
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          const model = cloneDeep(result);
          if (model) {
            this.model = {
              ...model,
              cost: model.cost ? model.cost / 100 : undefined
            };
          }
        }

        this.fields = [
          {
            key: 'id'
          },
          this.formlyService.createOrganisationFieldConfig(),
          this.formlyService.createSchoolFieldConfig({ required: false }),
          FormlyUtil.createRow([
            FormlyUtil.createSelectField('operating_cost_type_id', {
              props: {
                label: 'operating_cost_type',
                required: true,
                options: this.apollo
                  .query<{
                    result: AccountingOperatingCostTypeModel[];
                  }>({
                    query: gql`
                      query ReadAcademyOperatingCostTypes {
                        result: accounting_operating_cost_type(order_by: { name: asc }) {
                          id
                          name
                        }
                      }
                    `
                  })
                  .pipe(
                    map(queryResult =>
                      queryResult.data.result.map(item => ({
                        label: item.name,
                        value: item.id
                      }))
                    )
                  )
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createNumberField('cost', {
              props: {
                required: true,
                maxFractionDigits: 2,
                iconPre: 'pi pi-euro',
                min: 0,
                showClear: true
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createInputMaskField('iban', {
              props: {
                required: false,
                mask: 'aa99 9999 9999 9999 9999',
                characterPattern: '[A-Z]',
                description: 'iban_description'
              },
              validators: {
                iban: {
                  expression: (control: any) => !validateIBAN(control.value)?.ibanInvalid,
                  message: (error: any, field: any) =>
                    this.translocoService.selectTranslate('invalid_iban')
                }
              }
            })
          ]),
          FormlyUtil.createRow([FormlyUtil.createTextField('description')])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,

        cost: formSaveModel.input.cost ? Math.round(formSaveModel.input.cost * 100) : null
      };

      let options: any = {
        mutation: gql`
          mutation CreateAcademyOperatingCost($input: accounting_operating_cost_insert_input!) {
            result: insert_accounting_operating_cost_one(object: $input) {
              __typename
            }
          }
        `,
        variables: {
          input
        }
      };

      if (this.model?.id) {
        ModelUtil.deleteKey(input);

        options = {
          mutation: gql`
            mutation UpdateAcademyOperatingCost(
              $id: bigint!
              $input: accounting_operating_cost_set_input!
            ) {
              result: update_accounting_operating_cost_by_pk(
                pk_columns: { id: $id }
                _set: $input
              ) {
                __typename
              }
            }
          `,
          variables: {
            id: this.model.id,
            input
          }
        };
      }

      return this.apollo.mutate(options);
    };
  }
}
