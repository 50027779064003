import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { AcademySchoolPeriodModel } from '~ngx-shared/models';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil } from '~ngx-shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-create-update-school-period-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-school-period-form-page.component.html',
  styleUrl: './create-update-school-period-form-page.component.scss'
})
export class CreateUpdateSchoolPeriodFormPageComponent implements OnInit {
  form = new FormGroup({});
  model: AcademySchoolPeriodModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_school_period'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  constructor(
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AcademySchoolPeriodModel }>({
                query: gql`
                  query ReadAcademySchoolPeriodById($id: bigint!) {
                    result: academy_school_period_by_pk(id: $id) {
                      id
                      created_at
                      updated_at
                      starts_at
                      ends_at
                      is_active
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          let model = cloneDeep(result);

          if (model) {
            this.model = {
              ...model,
              starts_at: FormlyUtil.fromIsoDateString(model.starts_at),
              ends_at: FormlyUtil.fromIsoDateString(model.ends_at)
            };
          }
        }

        this.fields = [
          {
            key: 'id'
          },
          FormlyUtil.createRow([
            FormlyUtil.createDatePickerField('starts_at', {
              props: {
                required: true
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createDatePickerField('ends_at', {
              props: {
                required: true
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createCheckboxField('is_active', {
              props: {
                required: true
              }
            })
          ])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,
        starts_at: FormlyUtil.toIsoDateString(formSaveModel.input.starts_at),
        ends_at: FormlyUtil.toIsoDateString(formSaveModel.input.ends_at)
      };

      let options: any = {
        mutation: gql`
          mutation CreateAcademySchoolPeriod($input: academy_school_period_insert_input!) {
            result: insert_academy_school_period_one(object: $input) {
              __typename
            }
          }
        `,
        variables: {
          input
        }
      };

      if (this.model?.id) {
        ModelUtil.deleteKey(input);

        options = {
          mutation: gql`
            mutation UpdateAcademySchoolPeriod(
              $id: bigint!
              $input: academy_school_period_set_input!
            ) {
              result: update_academy_school_period_by_pk(pk_columns: { id: $id }, _set: $input) {
                __typename
              }
              ${
                input.is_active
                  ? `
                  update_academy_school_period(
                    where: {
                      id: { _neq: $id }
                      is_active: { _eq: true }
                      deleted_at: { _is_null: true }
                    }
                    _set: { is_active: false }
                  ) {
                    __typename
                  }`
                  : ''
              }
            }
          `,
          variables: {
            id: this.model.id,
            input
          }
        };
      }

      return this.apollo.mutate(options);
    };
  }
}
