import { Component, inject, signal } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { TranslocoDirective } from '@jsverse/transloco';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { RouterLink } from '@angular/router';
import { ButtonDirective } from 'primeng/button';
import { BasePageComponent } from '~ngx-shared/layout';
import { DirectorDetailComponent } from '~madrasa/staff/components/director-detail/director-detail.component';
import { OrganisatorDetailComponent } from '~madrasa/staff/components/organisator-detail/organisator-detail.component';
import { ParentDetailComponent } from '~madrasa/staff/components/parent-detail/parent-detail.component';
import { StudentDetailComponent } from '~madrasa/staff/components/student-detail/student-detail.component';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { TeacherDetailComponent } from '~madrasa/staff/components/teacher-detail/teacher-detail.component';
import { PersonDetailComponent } from '~madrasa/staff/components/person-detail/person-detail.component';
import { CorePersonDataModel } from '~ngx-shared/models';
import { UserService } from '~madrasa/services';
import { HttpImgSrcPipe } from '~ngx-shared/pipes/html-img-src.pipe';
import { PersonDocumentListComponent } from '~madrasa/file/components/person-document-list/person-document-list.component';

@Component({
  selector: 'app-profile-page',
  standalone: true,
  imports: [
    BasePageComponent,
    DirectorDetailComponent,
    OrganisatorDetailComponent,
    ParentDetailComponent,
    PrimeTemplate,
    StudentDetailComponent,
    TabViewModule,
    TabViewQueryDirective,
    TeacherDetailComponent,
    TranslocoDirective,
    PersonDetailComponent,
    RouterLink,
    ButtonDirective,
    HttpImgSrcPipe,
    PersonDocumentListComponent
  ],
  templateUrl: './profile-page.component.html',
  styleUrl: './profile-page.component.scss'
})
export class ProfilePageComponent {
  readonly userService = inject(UserService);

  readonly personId = toSignal(this.userService.user$.pipe(map(user => user?.person_id)));
  readonly personData = signal<CorePersonDataModel | undefined>(undefined);
}
