import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'libTime', standalone: true })
export class TimePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    // Check if it is a date then format to time HH:mm otherwise check if it is HH:mm:ss then remove seconds
    if (value instanceof Date) {
      return value.toLocaleTimeString('de-AT', { hour: '2-digit', minute: '2-digit' });
    }
    if (typeof value === 'string' && value.match(/^\d{2}:\d{2}:\d{2}$/)) {
      return value.slice(0, 5);
    }
    return value;
  }
}
