import { Component, computed, inject } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap, tap } from 'rxjs';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { CurrencyPipe } from '@angular/common';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { AcademyTeacherHourlyRateModel, StaffEducationTypeModel } from '~ngx-shared/models';
import { AuthorizationService } from '~ngx-shared/authentication';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';

@UntilDestroy()
@Component({
  selector: 'app-teacher-hourly-rate-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    DetailComponent,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective,
    TableModule,
    CurrencyPipe
  ],
  templateUrl: './teacher-hourly-rate-detail-page.component.html',
  styleUrl: './teacher-hourly-rate-detail-page.component.scss'
})
export class TeacherHourlyRateDetailPageComponent {
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly authorizationService = inject(AuthorizationService);

  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{
              result: AcademyTeacherHourlyRateModel;
              education_types: StaffEducationTypeModel[];
            }>({
              query: gql`
                query ReadAcademyTeacherHourlyRateById($id: bigint!) {
                  result: academy_teacher_hourly_rate_by_pk(id: $id) {
                    id
                    created_at
                    updated_at
                    name
                    description
                    columns
                    rows
                  }
                  education_types: staff_education_type(order_by: { name: asc }) {
                    id
                    name
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(
              map(queryResult => {
                // Convert education types to an object with id as key
                const educationTypes = queryResult.data.education_types.reduce(
                  (acc, educationType) => {
                    const id = educationType.id;
                    if (id) {
                      acc[id] = educationType.name;
                    }
                    return acc;
                  },
                  {} as any
                );

                return {
                  result: queryResult.data.result,
                  educationTypes
                };
              })
            );
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );

  readonly fields = computed<DetailField[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    const fields: DetailField[] = [
      {
        label: 'id',
        value: item.result.id
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.result.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.result.updated_at
      },
      {
        label: 'name',
        value: item.result.name
      },
      {
        label: 'description',
        value: item.result.description
      }
    ];

    return fields;
  });
}
