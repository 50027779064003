import { Component, OnInit, signal } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuModule } from 'primeng/menu';
import { map } from 'rxjs';
import { BasePageComponent, ConfirmationService } from '~ngx-shared/layout';
import { PersonListComponent } from '~madrasa/staff/components/person-list/person-list.component';
import { DataProviderOptionModel, GraphQlColumnModel } from '~ngx-shared/graph-ql';
import {
  AcademyOrganisationModel,
  CorePersonDataModel,
  StaffOrganisatorPermission,
  StaffStatusEnum
} from '~ngx-shared/models';
import { AuthorizationService } from '~ngx-shared/authentication';
import { SearchPersonFormDialogComponent } from '~madrasa/staff/components/search-person-form-dialog/search-person-form-dialog.component';
import { DialogUtil } from '~ngx-shared/utils/dialog.util';

@UntilDestroy()
@Component({
  selector: 'app-organisator-list-page',
  standalone: true,
  imports: [
    BasePageComponent,
    ButtonDirective,
    PersonListComponent,
    TranslocoDirective,
    NgxPermissionsModule,
    MenuModule
  ],
  templateUrl: './organisator-list-page.component.html',
  styleUrl: './organisator-list-page.component.scss'
})
export class OrganisatorListPageComponent implements OnInit {
  readonly columns = signal<GraphQlColumnModel[]>([]);
  readonly items = signal<MenuItem[]>([]);

  patchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  deleteFunc?: (options: { data: CorePersonDataModel; refresh: () => void }) => void;

  protected readonly StaffOrganisatorPermission = StaffOrganisatorPermission;

  constructor(
    public authorizationService: AuthorizationService,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    private dialogService: DialogService,
    public router: Router,
    private apollo: Apollo
  ) {}

  ngOnInit() {
    this.translocoService
      .selectTranslate(['new', 'madrasa.components.staff.create_from_existing_person'])
      .pipe(untilDestroyed(this))
      .subscribe(translated => {
        this.items.set([
          {
            label: translated[0],
            icon: 'pi pi-plus',
            routerLink: ['.', 'new']
          },
          {
            label: translated[1],
            icon: 'pi pi-arrows-h',
            command: () => {
              this.dialogService
                .open(SearchPersonFormDialogComponent, {
                  ...DialogUtil.BASE_DIALOG_CONFIG,
                  header: this.translocoService.translate(
                    'madrasa.components.staff.select_from_existing_person'
                  )
                })
                .onClose.pipe(untilDestroyed(this))
                .subscribe(result => {
                  if (result?.input?.person_id?.value?.value?.person_id) {
                    void this.router.navigate(
                      [this.router.url, result.input.person_id.value.value.person_id],
                      { queryParams: { is_new: true } }
                    );
                  }
                });
            }
          }
        ]);
      });

    const columns: GraphQlColumnModel[] = [
      {
        label: 'organisation',
        path: 'organisation_organisators_active.organisation.name',
        filter: {
          label: 'organisation',
          path: 'organisation_organisators_active.organisation_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademyOrganisationModel[];
            }>({
              query: gql`
                query ReadAcademyOrganisation($where: academy_organisation_bool_exp) {
                  result: academy_organisation(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(organisation => ({
                  label: organisation.name,
                  value: organisation.id
                }))
              )
            )
        },
        sort: { isSortable: false }
      },
      {
        path: 'latest_organisator.status',
        translate: true,
        prefix: 'status.',
        filter: {
          type: 'array',
          options: Object.values(StaffStatusEnum).map(value => ({
            label: 'status.' + value,
            value: value
          }))
        },
        sort: { isSortable: true }
      }
    ];

    this.columns.set(columns);

    this.patchOptions = options => {
      options.filter = {
        ...options.filter,
        _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
      };
      if (
        Array.isArray(options?.filter?._and) &&
        !options.filter?._and?.some((cond: any) => cond.latest_organisator)
      ) {
        options.filter._and.push({ latest_organisator: {} });
      }
      return options;
    };

    this.deleteFunc = options => {
      if (options.data) {
        let mutation: any = gql`
          mutation SoftDeleteStaffOrganisatorById($id: bigint!) {
            result: update_staff_organisator(
              _set: { deleted_at: "now()" }
              where: { person_id: { _eq: $id } }
            ) {
              __typename
            }
            result_organisator: update_academy_organisation_actor(
              _set: { deleted_at: "now()" }
              where: { person_id: { _eq: $id } }
            ) {
              __typename
            }
          }
        `;
        if (
          options.data.organisators?.[0]?.deleted_at &&
          this.authorizationService.can(StaffOrganisatorPermission.DELETE)
        ) {
          mutation = gql`
            mutation DeleteStaffOrganisatorById($id: bigint!) {
              result: delete_staff_organisator(where: { person_id: { _eq: $id } }) {
                __typename
              }
              result_organisator: update_academy_organisation_actor(
                _set: { deleted_at: "now()" }
                where: { person_id: { _eq: $id } }
              ) {
                __typename
              }
            }
          `;
        }

        this.confirmationService.confirmDeleteApollo({
          name: `${options.data.first_name} - ${options.data.last_name}`,
          mutationOptions: {
            mutation,
            variables: {
              id: options.data.person_id
            }
          },
          success: options.refresh
        });
      }
    };
  }
}
