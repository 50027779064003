<lib-layout-base-page *transloco="let transloco">
  <a
    *ngxPermissionsOnly="this.AcademyOperatingCostTypePermission.CREATE"
    [label]="transloco('new')"
    [routerLink]="['.', 'new']"
    action
    class="p-button-success text-white"
    icon="pi pi-plus"
    pButton
    type="button"></a>

  <div class="m-base flex flex-col">
    <div class="flex flex-col space-y-5">
      @if (this.showFilter() || this.showSort()) {
        <div class="flex flex-wrap justify-stretch gap-5">
          <div class="min-w-fit flex-1">
            @if (this.showFilter()) {
              <lib-graph-ql-advanced-filter
                [graphQlAdvancedTable]="advancedTable"
                [graphQlTable]="this.graphQlTable"
                [stateKey]="this.stateKey()"></lib-graph-ql-advanced-filter>
            }
          </div>
          <div class="min-w-fit flex-1">
            @if (this.showSort()) {
              <lib-graph-ql-advanced-sort
                [graphQlAdvancedTable]="advancedTable"
                [graphQlTable]="this.graphQlTable"
                [stateKey]="this.stateKey()"></lib-graph-ql-advanced-sort>
            }
          </div>
        </div>
      }

      <lib-graph-ql-advanced-table
        #advancedTable
        [graphQlTable]="this.graphQlTable"
        [patchOptions]="this.graphQlPatchOptions"
        [showExport]="this.showExport()"
        [showColumnFilter]="this.showColumnFilter()"
        [stateKey]="this.stateKey()">
        <ng-template let-data libTemplate="actions">
          <div class="flex items-center justify-center gap-2">
            <a
              *ngxPermissionsOnly="this.AcademyOperatingCostTypePermission.UPDATE"
              [pTooltip]="transloco('edit')"
              [routerLink]="['/', 'accounting', 'operating-cost-types', this.getRow(data)?.id]"
              class="p-button-warning p-button-text"
              icon="pi pi-pencil"
              pButton
              tooltipPosition="top"
              type="button"></a>

            @if (!data?.operating_costs_aggregate?.aggregate?.count) {
              <button
                (click)="this.delete(data)"
                *ngxPermissionsOnly="this.AcademyOperatingCostTypePermission.SOFT_DELETE"
                [pTooltip]="transloco('delete')"
                class="p-button-danger p-button-text"
                icon="pi pi-trash"
                pButton
                tooltipPosition="top"
                type="button"></button>
            }
          </div>
        </ng-template>
      </lib-graph-ql-advanced-table>
    </div>
  </div>
</lib-layout-base-page>
