import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface TextProps extends FieldWrapperProps {
  iconPre?: string;
  iconPost?: string;
  type?: string;
}

export interface TextFieldConfig extends FormlyFieldConfig<TextProps> {
  type?: 'text' | Type<TextTypeComponent>;
}

@Component({
  selector: 'lib-formly-text-type',
  templateUrl: './text-type.component.html',
  styleUrl: './text-type.component.css'
})
export class TextTypeComponent extends FieldType<FieldTypeConfig<TextProps>> {}
