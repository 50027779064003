<lib-layout-base-page *transloco="let transloco">
  <a
    [label]="transloco('new')"
    [routerLink]="['.', 'new']"
    action
    class="p-button-success text-white"
    icon="pi pi-plus"
    pButton
    type="button"></a>

  <div class="m-base flex flex-col">
    <lib-graph-ql-advanced-table
      #advancedTable
      [graphQlTable]="this.graphQlTable"
      [patchOptions]="this.graphQlPatchOptions"
      [showExport]="false">
      <ng-template let-data libTemplate="currently_activeCell">
        <div class="flex items-center justify-center gap-2">
          @if ((this.tenantService.currentTenant$ | async)?.id === this.getRow(data)?.id) {
            <i class="pi pi-check-circle text-green-500"></i>
          }
        </div>
      </ng-template>
      <ng-template let-data libTemplate="actions">
        <div class="flex items-center justify-center gap-2">
          <a
            [pTooltip]="transloco('show')"
            [routerLink]="['.', 'detail', this.getRow(data)?.id]"
            class="p-button-info p-button-text"
            icon="pi pi-eye"
            pButton
            tooltipPosition="top"
            type="button"></a>
          <a
            [pTooltip]="transloco('edit')"
            [routerLink]="['.', this.getRow(data)?.id]"
            class="p-button-warning p-button-text"
            icon="pi pi-pencil"
            pButton
            tooltipPosition="top"
            type="button"></a>

          <button
            (click)="this.delete(data)"
            [pTooltip]="transloco('delete')"
            class="p-button-danger p-button-text"
            icon="pi pi-trash"
            pButton
            tooltipPosition="top"
            type="button"></button>

          @if ((this.tenantService.currentTenant$ | async)?.id !== this.getRow(data)?.id) {
            <button
              (click)="this.setCurrentTenant(data)"
              [pTooltip]="transloco('activate')"
              class="p-button-success p-button-text"
              icon="pi pi-check-circle"
              pButton
              tooltipPosition="top"
              type="button"></button>
          }
        </div>
      </ng-template>
    </lib-graph-ql-advanced-table>
  </div>
</lib-layout-base-page>
