<lib-layout-base-page *transloco="let transloco">
  <div *ngxPermissionsOnly="this.StaffDirectorPermission.CREATE" action>
    @if (this.tenantService.canCrudDirector$ | async) {
      <button
        (click)="menu.toggle($event)"
        class="p-button-success"
        icon="pi pi-bars"
        label="{{ transloco('new') }}"
        pButton
        type="button"></button>
      <p-menu #menu [model]="this.items()" [popup]="true" appendTo="body"></p-menu>
    }
  </div>

  <div class="m-base flex flex-col">
    <app-person-list
      [columns]="this.columns()"
      [deleteFunc]="this.deleteFunc"
      [patchOptions]="this.patchOptions"
      [route]="'directors'"
      [showDeleteButton]="
        this.authorizationService.can(this.StaffDirectorPermission.SOFT_DELETE) &&
        !!(this.tenantService.canCrudDirector$ | async)
      "
      [showDetailButton]="this.authorizationService.can(this.StaffDirectorPermission.READ)"
      [showExport]="true"
      [showFilter]="true"
      [showSort]="true"
      [showUpdateButton]="this.authorizationService.can(this.StaffDirectorPermission.UPDATE)"
      [stateKey]="this.router.url + 'director-list'"></app-person-list>
  </div>
</lib-layout-base-page>
