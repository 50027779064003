import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { AccessService } from '~ngx-shared/services';
import { Role } from '~ngx-shared/authentication';
import { CorePersonDataModel } from '~ngx-shared/models';

@UntilDestroy()
@Component({
  selector: 'app-create-access-form-dialog',
  standalone: true,
  imports: [FormlyModule],
  templateUrl: './create-access-form-dialog.component.html',
  styleUrl: './create-access-form-dialog.component.scss'
})
export class CreateAccessFormDialogComponent implements OnInit {
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_access'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  constructor(
    public dialogRef: DynamicDialogRef,
    private accessService: AccessService,
    private dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    // Determine which roles are available for the user to select
    function checkRole(personData: CorePersonDataModel, role: Role): boolean {
      // Check if the user has the entry in the object
      const hasEntryInDb = !!personData['latest_' + role]?.id;

      // Check if has a user where the role is active
      const hasRole = personData.user?.roles_active?.find((r: any) => r.role === role);
      return hasEntryInDb && !hasRole;
    }

    this.fields = [
      FormlyUtil.createRow([
        FormlyUtil.createSelectField('role', {
          props: {
            required: true,
            options: Object.values(Role)
              .filter(value => Role.LESSOR !== value)
              .filter(value => checkRole(this.dialogConfig.data.personData, value))
              .map(value => ({
                label: 'roles.' + value,
                value: value
              }))
          }
        })
      ])
    ];

    if (!this.dialogConfig.data.addingRole) {
      this.fields.unshift(
        FormlyUtil.createRow([
          FormlyUtil.createSelectField('email', {
            props: {
              required: true,
              options: this.dialogConfig.data.personData.email_address?.map(
                (email_address: any) => ({
                  label: email_address.email,
                  value: email_address.email
                })
              )
            }
          })
        ])
      );
    } else {
      this.fields.unshift({
        key: 'email',
        defaultValue: this.dialogConfig.data.personData.user.email
      });
    }

    this.submit = (formSaveModel: FormSaveModel) => {
      return this.accessService.createAccess(formSaveModel.input.role, {
        person_id: this.dialogConfig.data.personData.person_id,
        email: formSaveModel.input.email
      });
    };
  }

  savedEvent(formSaveModel: FormSaveModel) {
    if (!formSaveModel.hasError) {
      this.dialogRef.close(formSaveModel);
    }
  }

  canceled($event: boolean) {
    if (this.dialogRef) {
      this.dialogRef.close(undefined);
    }
  }
}
