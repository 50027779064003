<app-file-upload
  [accept]="this.props.accept"
  [formControl]="this.formControl"
  [formlyAttributes]="this.field"
  [maxFiles]="this.props.maxFiles"
  [multiple]="this.props.multiple"
  [size]="this.props.size"
  [namespace]="this.props.namespace"
  [ngClass]="{
    'ng-dirty ng-invalid p-invalid': this.showError
  }">
</app-file-upload>
