import { Component, computed, inject, input } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { cloneDeep, groupBy } from 'lodash-es';
import { TranslocoDirective } from '@jsverse/transloco';
import { Apollo, gql } from 'apollo-angular';
import { map, switchMap } from 'rxjs';
import { outputFromObservable, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { PersonDetailLinkComponent } from '~madrasa/staff/components/person-detail-link/person-detail-link.component';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  CorePersonDataModel,
  CoreRelationshipEnum,
  FilePersonMediaTypeEnum,
  StaffDirectorPermission,
  StaffManagerPermission,
  StaffOrganisatorPermission,
  StaffParentPermission,
  StaffStudentPermission
} from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';
import { BusyComponent } from '~ngx-shared/layout';
import { AuthorizationService } from '~ngx-shared/authentication';
import { FileService } from '~madrasa/services';

@Component({
  selector: 'app-person-detail',
  standalone: true,
  imports: [
    DetailComponent,
    PersonDetailLinkComponent,
    TemplateDirective,
    NgTemplateOutlet,
    BusyComponent,
    TranslocoDirective
  ],
  templateUrl: './person-detail.component.html',
  styleUrl: './person-detail.component.scss'
})
export class PersonDetailComponent {
  readonly apollo = inject(Apollo);
  readonly fileService = inject(FileService);
  readonly authorizationService = inject(AuthorizationService);

  readonly personId = input.required<number | undefined>();
  readonly showPersonLink = input<boolean>(true);

  readonly personData = toSignal(
    toObservable(this.personId).pipe(
      switchMap(personId =>
        this.apollo
          .query<{ result: CorePersonDataModel[] }>({
            query: gql`
                  query ReadCoreCurrentPersonDataById($personId: bigint!) {
                    result: core_current_person_data(
                      where: { person_id: { _eq: $personId } }
                      limit: 1
                    ) {
                      id
                      person_id
                      created_at
                      updated_at
                      address
                      email_address
                      phone_number
                      academic_degree_prefix
                      academic_degree_suffix
                      account_owner
                      bic
                      date_of_birth
                      place_of_birth
                      extra_name
                      first_name
                      citizenship
                      profession
                      gender
                      iban
                      insurance_number
                      last_name
                      marital_status
                      zmr_number

                      person_media(
                        limit: 1
                        order_by: { created_at: desc }
                        where: { person_media_type: { _eq: "${FilePersonMediaTypeEnum.PROFILE_PICTURE}" } }
                      ) {
                        id
                      }

                      created_by_current_person_data {
                        person_id
                        academic_degree_prefix
                        academic_degree_suffix
                        first_name
                        last_name
                      }

                      side_a_person_relationships {
                        relationship
                        person_in_relationship_id
                        person_in_relationship_current_person_data {
                          person_id
                          academic_degree_prefix
                          academic_degree_suffix
                          first_name
                          last_name
                        }
                      }

                      side_b_person_relationships {
                        relationship
                        person_id
                        person_current_person_data {
                          person_id
                          academic_degree_prefix
                          academic_degree_suffix
                          first_name
                          last_name
                        }
                      }

                      organisation_persons_active {
                        organisation {
                          name
                        }
                      }

                      ${
                        this.authorizationService.can(StaffManagerPermission.READ)
                          ? 'latest_manager { id }'
                          : ''
                      }
                      ${
                        this.authorizationService.can(StaffOrganisatorPermission.READ)
                          ? 'latest_organisator { id }'
                          : ''
                      }
                      ${
                        this.authorizationService.can(StaffDirectorPermission.READ)
                          ? 'latest_director { id }'
                          : ''
                      }
                      latest_teacher { id }
                      ${
                        this.authorizationService.can(StaffStudentPermission.READ)
                          ? 'latest_student { id }'
                          : ''
                      }
                      ${
                        this.authorizationService.can(StaffParentPermission.READ)
                          ? 'latest_parent { id }'
                          : ''
                      }
                    }
                  }
                `,
            variables: {
              personId: personId
            }
          })
          .pipe(
            map(queryResult => {
              const personData = cloneDeep(queryResult.data?.result?.[0]);

              if (personData) {
                const id = personData?.person_media?.[0]?.id;
                if (id) {
                  personData['profile_picture_url'] = this.fileService.getFileUrl(
                    id,
                    'person_media'
                  );
                }
              }

              return personData;
            })
          )
      )
    ),
    { initialValue: undefined }
  );

  readonly outputPersonData = outputFromObservable(toObservable(this.personData), {
    alias: 'personData'
  });

  readonly fields = computed<DetailField[]>(() => {
    const item = this.personData();
    if (!item) {
      return [];
    }
    const fields: DetailField[] = [
      {
        label: 'id',
        value: item.person_id
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'created_by',
        type: 'person_link',
        value: item.created_by_current_person_data
      },
      {
        label: 'organisation',
        value: item.organisation_persons_active?.map(opa => opa.organisation?.name).join(', ')
      },
      {
        label: 'name',
        type: 'full_name',
        value: item
      },
      {
        label: 'date_of_birth',
        type: 'date',
        value: item.date_of_birth
      },
      {
        label: 'place_of_birth',
        value: item.place_of_birth
      },
      {
        label: 'citizenship',
        translate: true,
        value: item.citizenship ? 'country_iso.' + item.citizenship : undefined
      },
      {
        label: 'profession',
        value: item.profession
      },
      {
        label: 'gender',
        translate: true,
        value: item.gender ? 'gender.' + item.gender : undefined
      },
      {
        label: 'marital_status',
        translate: true,
        value: item.marital_status ? 'marital_status.' + item.marital_status : undefined
      },
      {
        label: 'address',
        type: 'address',
        value: item.address
      },
      {
        label: 'email_address',
        value: ModelUtil.getEmailAddresses(item.email_address)
      },
      {
        label: 'phone_number',
        value: ModelUtil.getPhoneNumbers(item.phone_number)
      },
      {
        label: 'insurance_number',
        value: item.insurance_number
      },
      {
        label: 'zmr_number',
        value: item.zmr_number
      },
      {
        label: 'account_owner',
        value: item.account_owner
      },
      {
        label: 'iban',
        value: item.iban
      },
      {
        label: 'bic',
        value: item.bic
      }
    ];

    const unifiedRelationships = [
      ...(item.side_a_person_relationships?.map(person_relationship => ({
        person_id: person_relationship.person_in_relationship_id,
        current_person_data: person_relationship['person_in_relationship_current_person_data'],
        relationship: person_relationship.relationship
      })) || []),
      ...(item.side_b_person_relationships?.map(person_relationship => ({
        person_id: person_relationship.person_id,
        current_person_data: person_relationship['person_current_person_data'],
        relationship: person_relationship.relationship
      })) || [])
    ];

    // Add relationships types to the fields
    // Get relationships per type with lodash groupBy
    const grouped = groupBy(unifiedRelationships, 'relationship');

    Object.keys(grouped)
      .sort((a, b) => a.localeCompare(b))
      .forEach(key => {
        fields.push({
          key,
          label: 'relationship.' + key,
          value: grouped[key]
        });
      });

    return fields;
  });

  protected readonly CoreRelationshipEnum = CoreRelationshipEnum;
}
