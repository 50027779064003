import { StaffStatusEnum } from '~ngx-shared/models';
import { Role } from '~ngx-shared/authentication';
import { DefaultProperties } from '../default.model';
import { CorePersonDataModel, CorePersonModel } from '../core';
import { AcademySchoolDirectorModel } from '../academy';

export interface StaffDirectorModel extends DefaultProperties {
  // Columns
  id?: number;
  person_id?: number;
  status?: StaffStatusEnum;
  start_of_work?: Date | string;
  monthly_rate?: number;
  hourly_rate?: number;

  // Relations
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;

  school_directors_active?: AcademySchoolDirectorModel[];
}

export class StaffDirectorPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffDirectorPermission.DELETE, Role.MANAGER];
}
