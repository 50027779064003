import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { FormlyService, TenantService } from '~madrasa/services';
import { AcademySchoolModel } from '~ngx-shared/models';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil } from '~ngx-shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-create-update-school-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-school-form-page.component.html',
  styleUrl: './create-update-school-form-page.component.scss'
})
export class CreateUpdateSchoolFormPageComponent implements OnInit {
  form = new FormGroup({});
  model: AcademySchoolModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_school'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  constructor(
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private formlyService: FormlyService,
    private tenantService: TenantService
  ) {}

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AcademySchoolModel }>({
                query: gql`
                  query ReadAcademySchoolById($id: bigint!) {
                    result: academy_school_by_pk(id: $id) {
                      id
                      created_at
                      updated_at
                      organisation_id
                      name
                      address

                      ${
                        this.tenantService.canCrudCoursePrice
                          ? `
                            school_course_price {
                              id
                              single_price
                              dual_price
                              three_or_more_price
                            }
                        `
                          : ''
                      }

                      ${
                        this.tenantService.canCrudTeacher
                          ? `
                            teacher_hourly_rate_id
                            hourly_rate
                            monthly_rate
                            `
                          : ''
                      }
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          let model = cloneDeep(result);

          if (model) {
            this.model = {
              ...model,
              school_course_price: {
                ...model.school_course_price,
                single_price: model.school_course_price?.single_price
                  ? model.school_course_price.single_price / 100
                  : undefined,
                dual_price: model.school_course_price?.dual_price
                  ? model.school_course_price.dual_price / 100
                  : undefined,
                three_or_more_price: model.school_course_price?.three_or_more_price
                  ? model.school_course_price.three_or_more_price / 100
                  : undefined
              },
              monthly_rate: model.monthly_rate ? model.monthly_rate / 100 : undefined,
              hourly_rate: model.hourly_rate ? model.hourly_rate / 100 : undefined
            };
          }
        }

        this.fields = [
          {
            key: 'id'
          },
          FormlyUtil.createRow([
            FormlyUtil.createTextField('name', {
              props: {
                required: true
              }
            })
          ]),
          this.formlyService.createOrganisationFieldConfig(),
          this.formlyService.createAddressFieldConfig(),
          ...(this.tenantService.canCrudCoursePrice
            ? [this.formlyService.createCoursePriceFieldConfig({ key: 'school_course_price' })]
            : []),
          ...(this.tenantService.canCrudTeacher
            ? [
                FormlyUtil.createRow([
                  {
                    ...this.formlyService.createTeacherHourlyRateFieldConfig(),
                    expressions: {
                      'props.disabled': () => {
                        return !!this.model?.hourly_rate || !!this.model?.monthly_rate;
                      }
                    }
                  }
                ]),
                FormlyUtil.createRow([
                  FormlyUtil.createNumberField('monthly_rate', {
                    props: {
                      maxFractionDigits: 2,
                      iconPre: 'pi pi-euro',
                      min: 0,
                      disabled: true,
                      showClear: true
                    },
                    expressions: {
                      'props.disabled': () => {
                        return !!this.model?.hourly_rate || !!this.model?.teacher_hourly_rate_id;
                      }
                    }
                  }),
                  FormlyUtil.createNumberField('hourly_rate', {
                    props: {
                      maxFractionDigits: 2,
                      iconPre: 'pi pi-euro',
                      min: 0,
                      disabled: true,
                      showClear: true
                    },
                    expressions: {
                      'props.disabled': () => {
                        return !!this.model?.monthly_rate || !!this.model?.teacher_hourly_rate_id;
                      }
                    }
                  })
                ])
              ]
            : [])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,

        ...(this.tenantService.canCrudCoursePrice
          ? {
              school_course_price: {
                data: {
                  single_price: formSaveModel.input.school_course_price?.single_price
                    ? Math.round(formSaveModel.input.school_course_price.single_price * 100)
                    : null,
                  dual_price: formSaveModel.input.school_course_price?.dual_price
                    ? Math.round(formSaveModel.input.school_course_price.dual_price * 100)
                    : null,
                  three_or_more_price: formSaveModel.input.school_course_price?.three_or_more_price
                    ? Math.round(formSaveModel.input.school_course_price.three_or_more_price * 100)
                    : null
                }
              }
            }
          : {}),
        ...(this.tenantService.canCrudTeacher
          ? {
              teacher_hourly_rate_id: formSaveModel.input.teacher_hourly_rate_id,
              monthly_rate: formSaveModel.input.monthly_rate
                ? Math.round(formSaveModel.input.monthly_rate * 100)
                : null,
              hourly_rate: formSaveModel.input.hourly_rate
                ? Math.round(formSaveModel.input.hourly_rate * 100)
                : null
            }
          : {})
      };

      let options: any = {
        mutation: gql`
          mutation CreateAcademySchool($input: academy_school_insert_input!) {
            result: insert_academy_school_one(object: $input) {
              __typename
            }
          }
        `,
        variables: {
          input
        }
      };

      if (this.model?.id) {
        ModelUtil.deleteKey(input);

        const newCoursePrices = [];
        let coursePriceFilter = undefined;
        let coursePriceInput = undefined;

        if (this.tenantService.canCrudCoursePrice) {
          if (!this.model.school_course_price?.id) {
            newCoursePrices.push({
              school_id: this.model.id,
              ...input.school_course_price?.data
            });
          } else {
            coursePriceFilter = { id: { _eq: this.model.school_course_price?.id } };
            coursePriceInput = input.school_course_price?.data;
          }
        }

        ModelUtil.deleteKey(input, 'school_course_price');

        options = {
          mutation: gql`
            mutation UpdateAcademySchool(
              $id: bigint!
              $input: academy_school_set_input!
              $newCoursePrices: [academy_school_course_price_insert_input!] = []
              $coursePriceFilter: academy_school_course_price_bool_exp = {}
              $coursePriceInput: academy_school_course_price_set_input = {}
            ) {
              result: update_academy_school_by_pk(pk_columns: { id: $id }, _set: $input) {
                __typename
              }
              insert_academy_school_course_price(objects: $newCoursePrices) {
                __typename
              }
              update_academy_school_course_price(
                where: $coursePriceFilter
                _set: $coursePriceInput
              ) {
                __typename
              }
            }
          `,
          variables: {
            id: this.model.id,
            input,
            newCoursePrices,
            coursePriceFilter,
            coursePriceInput
          }
        };
      }

      return this.apollo.mutate(options);
    };
  }
}
