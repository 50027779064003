import { Component, computed, effect, inject } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap, tap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  AcademyCourseModel,
  AcademyCoursePermission,
  AcademyCourseStudentStatusEnum,
  AcademyTeacherRoleEnum
} from '~ngx-shared/models';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { AuthorizationService, Role } from '~ngx-shared/authentication';
import { PersonListComponent } from '~madrasa/staff/components/person-list/person-list.component';
import { DataProviderOptionModel, GraphQlColumnModel } from '~ngx-shared/graph-ql';
import { UnitListComponent } from '~madrasa/academy/components/unit-list/unit-list.component';
import { ClassBookEntryListComponent } from '~madrasa/academy/components/class-book-entry-list/class-book-entry-list.component';
import { CourseDetailComponent } from '~madrasa/academy/components/course-detail/course-detail.component';
import { StudentListComponent } from '~madrasa/staff/components/student-list/student-list.component';
import { NoteListComponent } from '~madrasa/note/components/note-list/note-list.component';
import { FormSaveModel } from '~ngx-shared/formly';

@UntilDestroy()
@Component({
  selector: 'app-course-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective,
    NgxPermissionsModule,
    PersonListComponent,
    UnitListComponent,
    ClassBookEntryListComponent,
    CourseDetailComponent,
    StudentListComponent,
    NoteListComponent
  ],
  templateUrl: './course-detail-page.component.html',
  styleUrl: './course-detail-page.component.scss'
})
export class CourseDetailPageComponent {
  readonly apollo = inject(Apollo);
  readonly router = inject(Router);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly authorizationService = inject(AuthorizationService);

  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{ result: AcademyCourseModel }>({
              query: gql`
                query ReadAcademyCourseById($id: bigint!) {
                  result: academy_course_by_pk(id: $id) {
                    id
                    name
                    course_name {
                      name
                    }
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result));
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );

  readonly studentColumns = computed<GraphQlColumnModel[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    return [
      {
        label: 'course_student_status',
        path: 'latest_course_students.status',
        query: `latest_course_students (where: { course_id: { _eq: ${this.item()?.id} } }) { status }`,
        translate: true,
        prefix: 'status.',
        filter: {
          type: 'array',
          options: Object.values(AcademyCourseStudentStatusEnum).map(value => ({
            label: 'status.' + value,
            value: value
          }))
        },
        sort: { isSortable: true }
      }
    ];
  });

  readonly teacherColumns = computed<GraphQlColumnModel[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    return [
      {
        path: 'course_teachers.teaching_role',
        query: `course_teachers (where: { course_id: { _eq: ${this.item()?.id} } }) { teaching_role }`,
        translate: true,
        prefix: 'teaching_role.',
        filter: {
          type: 'array',
          options: Object.values(AcademyTeacherRoleEnum).map(value => ({
            label: 'teaching_role.' + value,
            value: value
          }))
        },
        sort: { isSortable: true }
      }
    ];
  });

  studentPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  teacherPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  unitPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  cbePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;

  notePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  notePatchInput: (formSaveModel: FormSaveModel, input: any) => any;

  protected readonly Role = Role;
  protected readonly AcademyCoursePermission = AcademyCoursePermission;

  constructor() {
    effect(() => {
      const id = this.item()?.id;
      if (id) {
        this.studentPatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (
            Array.isArray(options?.filter?._and) &&
            !options.filter?._and?.some((cond: any) => cond.latest_course_students)
          ) {
            options.filter._and.push({
              latest_course_students: {
                course_id: {
                  _eq: this.item()?.id
                }
              }
            });
          }

          // Add default sorting
          if (!options.sortBy?.length) {
            options.sortBy = [{ first_name: 'asc' }];
          }
          return options;
        };

        this.teacherPatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (
            Array.isArray(options?.filter?._and) &&
            !options.filter?._and?.some((cond: any) => cond.course_teachers)
          ) {
            options.filter._and.push({
              course_teachers: {
                course_id: {
                  _eq: this.item()?.id
                }
              }
            });
          }

          // Add default sorting
          if (!options.sortBy?.length) {
            options.sortBy = [{ first_name: 'asc' }];
          }
          return options;
        };

        this.unitPatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (
            Array.isArray(options?.filter?._and) &&
            !options.filter?._and?.some((cond: any) => cond.course_id)
          ) {
            options.filter._and.push({
              course_id: {
                _eq: this.item()?.id
              }
            });
          }
          return options;
        };

        this.cbePatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (
            Array.isArray(options?.filter?._and) &&
            !options.filter?._and?.some((cond: any) => cond.course_id)
          ) {
            options.filter._and.push({
              course_id: {
                _eq: this.item()?.id
              }
            });
          }
          return options;
        };

        this.notePatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (
            Array.isArray(options?.filter?._and) &&
            !options.filter?._and?.some((cond: any) => cond.course_id)
          ) {
            options.filter._and.push({
              course_id: {
                _eq: this.item()?.id
              }
            });
          }
          return options;
        };

        this.notePatchInput = (formSaveModel, input) => {
          return {
            ...input,
            course_id: this.item()?.id
          };
        };
      }
    });
  }
}
