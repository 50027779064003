<div *transloco="let transloco" class="flex flex-col space-y-5">
  @if (this.showFilter() || this.showSort()) {
    <div class="flex flex-wrap justify-stretch gap-5">
      <div class="min-w-fit flex-1">
        @if (this.showFilter()) {
          <lib-graph-ql-advanced-filter
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable()"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-filter>
        }
      </div>
      <div class="min-w-fit flex-1">
        @if (this.showSort()) {
          <lib-graph-ql-advanced-sort
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable()"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-sort>
        }
      </div>
    </div>
  }

  <lib-graph-ql-advanced-table
    #advancedTable
    [graphQlTable]="this.graphQlTable()"
    [parentTemplates]="this.templates"
    [patchOptions]="this.graphQlPatchOptions"
    [showColumnFilter]="this.showColumnFilter()"
    [showExport]="this.showExport()"
    [stateKey]="this.stateKey()">
    <ng-template let-data libTemplate="class_book_entry_teachersCell">
      <div class="flex flex-col gap-2">
        @for (teacher of data.class_book_entry_teachers; track teacher) {
          <div class="flex items-center gap-2">
            <app-person-detail-link
              [person]="teacher['current_person_data']"></app-person-detail-link>
            -
            @if (teacher.present) {
              <p-tag severity="success" [value]="transloco('true')"></p-tag>
            } @else {
              <p-tag severity="secondary" [value]="transloco('false')"></p-tag>
            }
          </div>
        }
      </div>
    </ng-template>
    <ng-template let-data libTemplate="course.nameCell">
      <ng-container
        [ngTemplateOutletContext]="{ $implicit: data }"
        [ngTemplateOutlet]="colorCell"></ng-container>
    </ng-template>
    <ng-template let-data libTemplate="course.course_name.nameCell">
      <ng-container
        [ngTemplateOutletContext]="{ $implicit: data }"
        [ngTemplateOutlet]="colorCell"></ng-container>
    </ng-template>
    <ng-template let-data libTemplate="unit.subject.nameCell">
      <div class="item-center flex gap-2">
        <div
          class="my-auto h-3 w-3 rounded"
          style="{{ 'background-color:' + this.getRow(data)?.unit?.color + ';' }}"></div>
        <div class="">
          {{ this.getRow(data)?.unit?.subject?.name }}
        </div>
      </div>
    </ng-template>
    <ng-template #colorCell let-data>
      <div class="item-center flex gap-2">
        <div
          class="my-auto h-3 w-3 rounded"
          style="{{ 'background-color:' + this.getRow(data)?.course?.color + ';' }}"></div>
        <div class="">
          {{ this.getRow(data)?.course?.course_name?.name || this.getRow(data)?.course?.name }}
        </div>
      </div>
    </ng-template>
    <ng-template let-data libTemplate="actions">
      <div class="flex items-center justify-center gap-2">
        <a
          *ngxPermissionsOnly="this.AcademyClassBookEntryPermission.READ"
          [pTooltip]="transloco('show')"
          [routerLink]="['/', 'academy', 'class-book-entries', 'detail', this.getRow(data)?.id]"
          class="p-button-info p-button-text"
          icon="pi pi-eye"
          pButton
          tooltipPosition="top"
          type="button"></a>
        @if (this.authorizationService.cannot(Role.TEACHER) || this.isExpired(data)) {
          <a
            *ngxPermissionsOnly="this.AcademyClassBookEntryPermission.UPDATE"
            [pTooltip]="transloco('edit')"
            [routerLink]="['/', 'academy', 'class-book-entries', this.getRow(data)?.id]"
            class="p-button-warning p-button-text"
            icon="pi pi-pencil"
            pButton
            tooltipPosition="top"
            type="button"></a>
        }

        <button
          (click)="this.delete(data)"
          *ngxPermissionsOnly="this.AcademyClassBookEntryPermission.SOFT_DELETE"
          [pTooltip]="transloco('delete')"
          class="p-button-danger p-button-text"
          icon="pi pi-trash"
          pButton
          tooltipPosition="top"
          type="button"></button>
      </div>
    </ng-template>
  </lib-graph-ql-advanced-table>
</div>
