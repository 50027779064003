import { Component, computed, effect, inject } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, of, switchMap, tap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AcademySchoolModel, AcademySchoolPermission } from '~ngx-shared/models';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { AuthorizationService, Role } from '~ngx-shared/authentication';
import { ClassBookEntryListComponent } from '~madrasa/academy/components/class-book-entry-list/class-book-entry-list.component';
import { PersonListComponent } from '~madrasa/staff/components/person-list/person-list.component';
import { UnitListComponent } from '~madrasa/academy/components/unit-list/unit-list.component';
import { CourseListComponent } from '~madrasa/academy/components/course-list/course-list.component';
import { DataProviderOptionModel } from '~ngx-shared/graph-ql';
import { NoteListComponent } from '~madrasa/note/components/note-list/note-list.component';
import { FormSaveModel } from '~ngx-shared/formly';

@UntilDestroy()
@Component({
  selector: 'app-school-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    DetailComponent,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective,
    ClassBookEntryListComponent,
    PersonListComponent,
    UnitListComponent,
    CourseListComponent,
    ButtonDirective,
    NgxPermissionsModule,
    RouterLink,
    NoteListComponent
  ],
  templateUrl: './school-detail-page.component.html',
  styleUrl: './school-detail-page.component.scss'
})
export class SchoolDetailPageComponent {
  readonly apollo = inject(Apollo);
  readonly router = inject(Router);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly authorizationService = inject(AuthorizationService);

  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{ result: AcademySchoolModel }>({
              query: gql`
                query ReadAcademySchoolById($id: bigint!) {
                  result: academy_school_by_pk(id: $id) {
                    id
                    created_at
                    updated_at
                    organisation {
                      name
                    }
                    name
                    address

                   ${
                     !this.authorizationService.can(Role.TEACHER)
                       ? `
                       school_course_price {
                         single_price
                         dual_price
                         three_or_more_price
                       }

                      teacher_hourly_rate{
                        name
                      }
                      hourly_rate
                      monthly_rate
                       `
                       : ''
                   }
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result));
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );

  readonly fields = computed<DetailField[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    const fields: DetailField[] = [
      {
        label: 'id',
        value: item.id
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'organisation',
        value: item.organisation?.name
      },
      {
        label: 'name',
        value: item.name
      },
      {
        label: 'address',
        type: 'address',
        value: item.address
      }
    ];

    if (!this.authorizationService.can(Role.TEACHER)) {
      fields.push(
        {
          label: 'course_price.single_price',
          type: 'currency',
          value: item.school_course_price?.single_price
        },
        {
          label: 'course_price.dual_price',
          type: 'currency',
          value: item.school_course_price?.dual_price
        },
        {
          label: 'course_price.three_or_more_price',
          type: 'currency',
          value: item.school_course_price?.three_or_more_price
        },
        {
          label: 'teacher_hourly_rate',
          type: 'string',
          value: item.teacher_hourly_rate?.name
        },
        {
          label: 'hourly_rate',
          type: 'currency',
          value: item.hourly_rate
        },
        {
          label: 'monthly_rate',
          type: 'currency',
          value: item.monthly_rate
        }
      );
    }

    return fields;
  });

  studentPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  teacherPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  coursePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  unitPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  cbePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;

  notePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  notePatchInput: (formSaveModel: FormSaveModel, input: any) => any;

  protected readonly Role = Role;
  protected readonly AcademySchoolPermission = AcademySchoolPermission;

  constructor() {
    effect(() => {
      const id = this.item()?.id;
      if (id) {
        this.studentPatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (
            Array.isArray(options?.filter?._and) &&
            !options.filter?._and?.some((cond: any) => cond.school_students_active)
          ) {
            options.filter._and.push({
              school_students_active: {
                school_id: {
                  _eq: this.item()?.id
                }
              }
            });
          }

          // Add default sorting
          if (!options.sortBy?.length) {
            options.sortBy = [{ first_name: 'asc' }];
          }
          return options;
        };

        this.teacherPatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (
            Array.isArray(options?.filter?._and) &&
            !options.filter?._and?.some((cond: any) => cond.school_teachers_active)
          ) {
            options.filter._and.push({
              school_teachers_active: {
                school_id: {
                  _eq: this.item()?.id
                }
              }
            });
          }

          // Add default sorting
          if (!options.sortBy?.length) {
            options.sortBy = [{ first_name: 'asc' }];
          }
          return options;
        };

        this.coursePatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (
            Array.isArray(options?.filter?._and) &&
            !options.filter?._and?.some((cond: any) => cond.school_id)
          ) {
            options.filter._and.push({
              school_id: {
                _eq: this.item()?.id
              }
            });
          }
          return options;
        };

        this.unitPatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (
            Array.isArray(options?.filter?._and) &&
            !options.filter?._and?.some((cond: any) => cond.school_id)
          ) {
            options.filter._and.push({
              school_id: {
                _eq: this.item()?.id
              }
            });
          }
          return options;
        };

        this.cbePatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (
            Array.isArray(options?.filter?._and) &&
            !options.filter?._and?.some((cond: any) => cond.school_id)
          ) {
            options.filter._and.push({
              school_id: {
                _eq: this.item()?.id
              }
            });
          }
          return options;
        };

        this.notePatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (
            Array.isArray(options?.filter?._and) &&
            !options.filter?._and?.some((cond: any) => cond.school_id)
          ) {
            options.filter._and.push({
              school_id: {
                _eq: this.item()?.id
              }
            });
          }
          return options;
        };

        this.notePatchInput = (formSaveModel, input) => {
          return {
            ...input,
            school_id: this.item()?.id
          };
        };
      }
    });
  }
}
