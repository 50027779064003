<lib-layout-base-page *transloco="let transloco">
  <div class="flex flex-col">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('course-student-status-evaluation') }}">
        <ng-template pTemplate="content">
          <app-course-student-status-evaluation></app-course-student-status-evaluation>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</lib-layout-base-page>
