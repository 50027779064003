@if (this.isLoading()) {
  <div
    [ngStyle]="{
      height: this.height()
    }"
    class="flex w-full grow flex-col items-center justify-center gap-5">
    <lib-layout-spinner></lib-layout-spinner>
  </div>
} @else {
  <ng-content></ng-content>
}
