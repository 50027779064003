<lib-busy *transloco="let transloco" [height]="'50vh'" [isLoading]="this.isLoading()">
  <div class="flex flex-col gap-6">
    <lib-detail [fields]="this.fields()"></lib-detail>

    <p-accordion *ngxPermissionsOnly="this.StaffDirectorPermission.CREATE" [multiple]="true">
      <p-accordionTab header="{{ transloco('notes') }}">
        <ng-template pTemplate="content">
          <app-note-list
            [patchInput]="this.notePatchInput"
            [patchOptions]="this.notePatchOptions"
            [stateKey]="this.router.url + 'director-detail-note-list'"
            [table]="'note_person_note'"></app-note-list>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </div>
</lib-busy>
