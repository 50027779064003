import { Injectable } from '@angular/core';
import { ModelUtil } from '~ngx-shared/utils';
import { CorePersonDataModel } from '~ngx-shared/models';
import { DetailField } from '~ngx-shared/ui/detail/detail.component';

@Injectable({ providedIn: 'root' })
export class StaffDetailService {
  constructor() {}

  defaultFields(data: CorePersonDataModel): DetailField[] {
    if (!data) {
      return [];
    }
    return [
      {
        label: 'id',
        value: data.person_id
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: data.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: data.updated_at
      },
      {
        label: 'name',
        type: 'full_name',
        value: data
      },
      {
        label: 'date_of_birth',
        type: 'date',
        value: data.date_of_birth
      },
      {
        label: 'place_of_birth',
        value: data.place_of_birth
      },
      {
        label: 'citizenship',
        translate: true,
        value: data.citizenship ? 'country_iso.' + data.citizenship : undefined
      },
      {
        label: 'profession',
        value: data.profession
      },
      {
        label: 'gender',
        translate: true,
        value: data.gender ? 'gender.' + data.gender : undefined
      },
      {
        label: 'marital_status',
        translate: true,
        value: data.marital_status ? 'marital_status.' + data.marital_status : undefined
      },
      {
        label: 'address',
        type: 'address',
        value: data.address
      },
      {
        label: 'email_address',
        value: ModelUtil.getEmailAddresses(data.email_address)
      },
      {
        label: 'phone_number',
        value: ModelUtil.getPhoneNumbers(data.phone_number)
      },
      {
        label: 'insurance_number',
        value: data.insurance_number
      },
      {
        label: 'zmr_number',
        value: data.zmr_number
      },
      {
        label: 'account_owner',
        value: data.account_owner
      },
      {
        label: 'iban',
        value: data.iban
      },
      {
        label: 'bic',
        value: data.bic
      }
    ];
  }

  studentDetailFields(data: CorePersonDataModel): DetailField[] {
    return this.defaultFields(data);
  }

  parentDetailFields(data: CorePersonDataModel): DetailField[] {
    return this.defaultFields(data);
  }
}
