import { Directive, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TabMenu } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';

@UntilDestroy()
@Directive({
  standalone: true,
  selector: 'p-tabMenu[libTabMenuQuery]'
})
export class TabMenuQueryDirective implements OnInit {
  private _sharedUiTabMenuQuery: string = 't';

  @Input()
  set sharedUiTabMenuQuery(libTabMenuQuery: string) {
    this._sharedUiTabMenuQuery = libTabMenuQuery;
  }

  get sharedUiTabMenuQuery() {
    return this._sharedUiTabMenuQuery;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private TabMenuComponent: TabMenu
  ) {}

  ngOnInit(): void {
    this.TabMenuComponent.activeItemChange.pipe(untilDestroyed(this)).subscribe(item => {
      const index = this._getTabIndexOfItem(item);
      void this.router.navigate([], {
        queryParams: { [this.sharedUiTabMenuQuery]: index },
        queryParamsHandling: 'merge',
        replaceUrl: true
      });
    });
    this.route.params.pipe(untilDestroyed(this)).subscribe(() => {
      this.changeIndex(this.route.snapshot.queryParams[this.sharedUiTabMenuQuery]);
    });
  }

  changeIndex(tabIndex: number | undefined) {
    if (tabIndex && tabIndex >= 0) {
      const item = this._getItemOfTabIndex(tabIndex);
      if (item) {
        setTimeout(() => {
          this.TabMenuComponent.activeItem = item;
        }, 0);
      }
    }
  }

  private _getItemOfTabIndex(index: number): MenuItem | undefined {
    return this.TabMenuComponent.model?.[index];
  }

  private _getTabIndexOfItem(item: MenuItem): number | undefined {
    return this.TabMenuComponent.model?.indexOf(item);
  }
}
