import {
  AfterContentInit,
  Component,
  computed,
  ContentChildren,
  input,
  QueryList,
  signal,
  TemplateRef,
  untracked
} from '@angular/core';
import { MenuModule } from 'primeng/menu';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MenuItem, PrimeTemplate } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import { TranslocoDirective } from '@jsverse/transloco';
import { ListboxModule } from 'primeng/listbox';
import { TemplateUtil } from '~ngx-shared/utils';
import { TabMenuQueryDirective, TemplateDirective } from '~ngx-shared/directives';

@Component({
  selector: 'app-list-tab-menu',
  standalone: true,
  imports: [
    MenuModule,
    NgTemplateOutlet,
    PrimeTemplate,
    TabMenuModule,
    TabMenuQueryDirective,
    TranslocoDirective,
    NgClass,
    ListboxModule
  ],
  templateUrl: './list-tab-menu.component.html',
  styleUrl: './list-tab-menu.component.scss'
})
export class ListTabMenuComponent implements AfterContentInit {
  readonly inputItems = input.required<MenuItem[]>({ alias: 'items' });

  readonly items = computed(() => {
    const items = this.inputItems();

    const command = (event: any) => {
      console.log('event', event);
      this.activeItem.set(event.item);
    };

    for (let i = 0; i < items.length; i++) {
      items[i].command = command;
    }

    untracked(() => {
      this.activeItem.set(items[0]);
    });

    return items;
  });

  readonly activeItem = signal<MenuItem | undefined>(undefined);

  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>;
  keyTemplates: Map<string, TemplateRef<any>> | undefined = undefined;

  ngAfterContentInit(): void {
    TemplateUtil.setTemplates(this, this.templates, undefined, [
      {
        templates: 'keyTemplates',
        name: 'Key'
      }
    ]);
  }
}
