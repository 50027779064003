<div *transloco="let transloco">
  <textarea
    class="w-full"
    pInputTextarea
    [rows]="this.props.rows || 5"
    [cols]="this.props.cols || 30"
    [ngClass]="{
      'ng-dirty ng-invalid p-invalid': this.showError
    }"
    [readonly]="this.props.readonly"
    [formControl]="this.formControl"
    [placeholder]="transloco(this.props.placeholder || '') || '&nbsp;'"
    [formlyAttributes]="this.field"></textarea>
</div>
