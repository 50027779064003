import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ConfirmationService as PrimeNgConfirmationService } from 'primeng/api';
// @ts-ignore
import { MutationOptions } from 'apollo-angular/types';
import { Apollo } from 'apollo-angular';
import { ToastService } from '~ngx-shared/layout';
import { LoggerService } from '~ngx-shared/services';

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
  readonly DIALOG_KEY = 'dialog';
  readonly POPUP_KEY = 'popup';

  constructor(
    private apollo: Apollo,
    private toastService: ToastService,
    private confirmationService: PrimeNgConfirmationService,
    private translocoService: TranslocoService,
    private loggerService: LoggerService
  ) {}

  confirm(accept?: Function, reject?: Function, message?: string) {
    this.confirmPopup(undefined, accept, reject, message, this.DIALOG_KEY);
  }

  confirmPopup(
    event?: Event,
    accept?: Function,
    reject?: Function,
    message?: string,
    key = this.POPUP_KEY
  ) {
    if (!message) {
      message = this.translocoService.translate('confirmation.are_you_sure');
    }
    this.confirmationService.confirm({
      key,
      target: event && event.target ? event.target : undefined,
      header: this.translocoService.translate('confirmation'),
      message: message,
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject
    });
  }

  confirmDelete(name?: string, accept?: Function, reject?: Function) {
    this.confirmDeletePopup(name, undefined, accept, reject, this.DIALOG_KEY);
  }

  confirmDeleteApollo(options: {
    mutationOptions: MutationOptions;
    name?: string;
    accept?: Function;
    reject?: Function;
    success?: Function;
    error?: Function;
  }) {
    this.confirmDeletePopup(
      options.name,
      undefined,
      () => {
        options.accept?.();
        this.apollo.mutate(options.mutationOptions).subscribe(mutationResult => {
          const errors = mutationResult?.errors;
          if (errors) {
            if (Array.isArray(errors)) {
              errors.forEach(error => {
                this.loggerService.error(error.message, JSON.stringify(error.extensions));
              });
            }
            this.toastService.toastErrorDelete();
            options.error?.(errors);
          } else {
            this.toastService.toastSuccessDelete();
            options.success?.();
          }
        });
      },
      options.reject,
      this.DIALOG_KEY
    );
  }

  confirmDeletePopup(
    name?: string,
    event?: Event,
    accept?: Function,
    reject?: Function,
    key = 'popup'
  ) {
    this.confirmationService.confirm({
      key,
      target: event && event.target ? event.target : undefined,
      header: this.translocoService.translate('confirmation'),
      message: this.translocoService.translate('confirmation.delete_item_name', {
        name: name
      }),
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject
    });
  }
}
