import { ToolbarModule } from 'primeng/toolbar';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective } from '@jsverse/transloco';
import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgxFilesizeModule } from 'ngx-filesize';

@Component({
  selector: 'lib-file',
  standalone: true,
  imports: [ToolbarModule, ButtonDirective, TranslocoDirective, NgxFilesizeModule],
  templateUrl: './file.component.html',
  styleUrl: './file.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FileComponent)
    }
  ]
})
export class FileComponent implements ControlValueAccessor {
  private onChange = (value: File | undefined) => {};
  private onTouched = () => {};
  private touched = false;

  disabled = false;
  fileName: string;
  fileSize: number;

  fileSelected(event: Event) {
    this.markAsTouched();
    this.fileName = '';
    this.fileSize = 0;
    const files = (event.target as HTMLInputElement).files;
    if (files?.length) {
      const file = files.item(0);
      if (file) {
        this.fileName = file.name;
        this.fileSize = file.size;
        this.writeValue(file);
      }
    } else {
      this.writeValue(undefined);
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  // Form methods

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: File | undefined): void {
    this.onChange(value);
  }
}
