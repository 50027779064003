<lib-layout-base-page *transloco="let transloco">
  <div *ngxPermissionsOnly="this.StaffOrganisatorPermission.CREATE" action>
    <button
      (click)="menu.toggle($event)"
      class="p-button-success"
      icon="pi pi-bars"
      label="{{ transloco('new') }}"
      pButton
      type="button"></button>
    <p-menu #menu [model]="this.items()" [popup]="true" appendTo="body"></p-menu>
  </div>

  <div class="m-base flex flex-col">
    <app-person-list
      [columns]="this.columns()"
      [deleteFunc]="this.deleteFunc"
      [patchOptions]="this.patchOptions"
      [route]="'organisators'"
      [showDeleteButton]="
        this.authorizationService.can(this.StaffOrganisatorPermission.SOFT_DELETE)
      "
      [showDetailButton]="this.authorizationService.can(this.StaffOrganisatorPermission.READ)"
      [showExport]="true"
      [showFilter]="true"
      [showSort]="true"
      [showUpdateButton]="this.authorizationService.can(this.StaffOrganisatorPermission.UPDATE)"
      [stateKey]="this.router.url + 'organisator-list'"></app-person-list>
  </div>
</lib-layout-base-page>
