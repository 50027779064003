import { Data } from '@angular/router';
import { ObjectUtils } from 'primeng/utils';

export type QueryUtilItemType = {
  query?: string;
  param?: string;
  variable?: Data;
  condition?: Data;
};

export class QueryUtil {
  static comparer<T = any>(params: {
    itemsA?: T[];
    itemsB?: T[];
    compareFunc?: (itemA: T | undefined, itemB: T | undefined) => boolean;
    compareField?: string;
    resultMapFunc?: (item: T) => T;
    resultField?: string;
  }): T[] | undefined {
    const compareField = params.compareField ? params.compareField : 'id';
    const compareFunc = params.compareFunc
      ? params.compareFunc
      : (itemA: T, itemB: T) => {
          return (
            ObjectUtils.resolveFieldData(itemA, compareField) ===
            ObjectUtils.resolveFieldData(itemB, compareField)
          );
        };

    const resultField = params.resultField ? params.resultField : 'id';
    const resultMapFunc = params.resultMapFunc
      ? params.resultMapFunc
      : (item: T) => {
          return ObjectUtils.resolveFieldData(item, resultField);
        };

    return params.itemsA
      ?.filter(itemA => !params.itemsB?.some(itemB => compareFunc(itemA, itemB)))
      ?.map(item => resultMapFunc(item));
  }

  // Detect deleted items
  static getDeletedIds(
    orgItems?: any[],
    items?: any[],
    compareField = 'id',
    resultField = 'id',
    wholeResultItem = false
  ): number[] | any[] | undefined {
    const compared = orgItems
      ?.filter(
        (orgItem: any) =>
          !items?.some(
            (item: any) =>
              ObjectUtils.resolveFieldData(orgItem, compareField) ===
              ObjectUtils.resolveFieldData(item, compareField)
          )
      )
      ?.map((item: any) => {
        if (!wholeResultItem) {
          return ObjectUtils.resolveFieldData(item, resultField);
        }
        return item;
      });
    if (!wholeResultItem) {
      return compared?.filter((id: number): id is number => !!id);
    }
    return compared;
  }

  static deleteItemsByObjectIds(
    items: any[],
    tableName: string,
    idField = 'id'
  ): QueryUtilItemType[] {
    const deleteItems: QueryUtilItemType[] = [];
    if (items?.length && tableName) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item && idField in item) {
          const parameter = `${idField}: ${item[idField]}`;
          deleteItems.push({
            query: `
            delete_${tableName.toUpperCase()}_${i}: delete_${tableName}_by_pk(${parameter}) {
              __typename
            }`
          });
        }
      }
    }
    return deleteItems;
  }

  static deleteItemsByIds(
    tableName: string,
    ids?: number[],
    idField = 'id'
  ): QueryUtilItemType | undefined {
    if (ids?.length) {
      return {
        query: `
            delete_${tableName}(where: { ${idField}: { _in: [${ids}] } }) {
              affected_rows
            }
          `
      };
    }
    return undefined;
  }

  static updateItems(
    items: any[],
    tableName: string,
    columnName: string,
    idField = 'id'
  ): QueryUtilItemType[] {
    const updateItems: QueryUtilItemType[] = [];
    if (items?.length && tableName) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item && idField in item) {
          const queryItem: QueryUtilItemType = {
            query: `update_${tableName.toUpperCase()}_${i}: update_${tableName}_by_pk(
            pk_columns: {${idField}: ${item[idField]}}, _set: { ${columnName}: "${item[columnName]}" }) {
              __typename
            }`
          };
          updateItems.push(queryItem);
        }
      }
    }
    return updateItems;
  }

  static insertItems(
    items: any[],
    tableName: string,
    patchFunc?: (item: any) => any,
    setVariable?: string,
    idField = 'id'
  ): QueryUtilItemType[] {
    const insertItems: QueryUtilItemType[] = [];
    if (items?.length && tableName) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item && !(idField in item)) {
          const queryItem: QueryUtilItemType = {
            query: `insert${tableName.toUpperCase()}${i}: insert_${tableName}_one(
            object: $${setVariable || tableName}_${i}) {
              __typename
            }`,
            param: `$${setVariable || tableName}_${i}: ${tableName}_insert_input!`,
            variable: {}
          };
          queryItem.variable = {
            [`${setVariable || tableName}_${i}`]: patchFunc ? patchFunc(item) : item
          };
          insertItems.push(queryItem);
        }
      }
    }
    return insertItems;
  }
}
