<div *transloco="let transloco" class="flex">
  <p-dropdown
    (onChange)="this.props.change && this.props.change(this.field, $event)"
    [editable]="this.props.editable || false"
    [filterBy]="this.props.canFilter ? 'label' : undefined"
    [filter]="this.props.canFilter"
    [formControl]="this.formControl"
    [formlyAttributes]="this.field"
    [group]="!!this.props.groupProp"
    [autoDisplayFirst]="false"
    [ngClass]="{
      'ng-dirty ng-invalid p-invalid': this.showError
    }"
    [optionGroupChildren]="this.props.groupChildrenProp || 'group'"
    [optionGroupLabel]="this.props.groupLabelProp || 'label'"
    [options]="(this.props.options | formlySelectOptions: this.field | async)!"
    [placeholder]="transloco(this.props.placeholder || '') || '&nbsp;'"
    [readonly]="this.props.readonly"
    [scrollHeight]="this.props.scrollHeight || '500px'"
    [showClear]="this.props.showClear || !this.props.required"
    [virtualScrollItemSize]="this.props.virtualScrollSize"
    [virtualScroll]="this.props.virtualScroll"
    appendTo="body"
    class="p-fluid w-full">
    <ng-template let-item pTemplate="item">
      {{ transloco(item['label']) }}
    </ng-template>
    <ng-template let-selectedItem pTemplate="selectedItem">
      {{ transloco(selectedItem['label']) }}
    </ng-template>
    <ng-template let-group pTemplate="group">
      {{ transloco(group['label']) }}
    </ng-template>
  </p-dropdown>
</div>
