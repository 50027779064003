<app-person-list
  [columns]="this.columns()"
  [deleteFunc]="this.deleteFunc"
  [patchOptions]="this.patchOptions()"
  [route]="'teachers'"
  [showDeleteButton]="
    this.authorizationService.can(this.StaffTeacherPermission.SOFT_DELETE) &&
    !!(this.tenantService.canCrudTeacher$ | async)
  "
  [showDetailButton]="this.authorizationService.can(this.StaffTeacherPermission.READ)"
  [showExport]="true"
  [showFilter]="true"
  [showSort]="true"
  [showUpdateButton]="this.authorizationService.can(this.StaffTeacherPermission.UPDATE)"
  [stateKey]="this.stateKey()">
  <ng-template let-data libTemplate="latest_teacher.school_teacher_salariesCell">
    @for (
      school_teacher_salary of data?.latest_teacher?.school_teacher_salaries;
      track school_teacher_salary;
      let index = $index
    ) {
      <div class="">
        <span>{{ school_teacher_salary?.school?.name }}</span>
        @if (school_teacher_salary?.hourly_rate_value) {
          :<span>{{ school_teacher_salary?.hourly_rate_value / 100 | currency }}</span>
        }
        @if (school_teacher_salary?.monthly_rate) {
          :<span>{{ school_teacher_salary?.monthly_rate / 100 | currency }}</span>
        }
      </div>
    }
  </ng-template>
  <ng-template let-data libTemplate="latest_teacher.organisation_teacher_salariesCell">
    @for (
      organisation_teacher_salary of data?.latest_teacher?.organisation_teacher_salaries;
      track organisation_teacher_salary;
      let index = $index
    ) {
      <div class="flex items-center gap-2">
        <span>{{ organisation_teacher_salary?.organisation?.name }}</span>
        @if (organisation_teacher_salary?.hourly_rate_value) {
          :<span>{{ organisation_teacher_salary?.hourly_rate_value / 100 | currency }}</span>
        }
        @if (organisation_teacher_salary?.monthly_rate) {
          :<span>{{ organisation_teacher_salary?.monthly_rate / 100 | currency }}</span>
        }
      </div>
    }
  </ng-template>
</app-person-list>
