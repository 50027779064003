import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { select, setProp } from '@ngneat/elf';
import { StoreService } from '~ngx-shared/services';
import { AuthenticationService } from './authentication.service';

type AuthorizationStoreModel = {
  availableRoles?: string[];
  allowedRoles?: string[];
  currentRole?: string;
};

@UntilDestroy()
@Injectable()
export class AuthorizationService {
  authorizationStore = this.storeService.createStore<AuthorizationStoreModel>({
    name: 'authorization',
    initial: undefined,
    persist: { storage: 'local' }
  });

  allowedRoles$ = this.authorizationStore.pipe(select(state => state.allowedRoles));
  availableRoles$ = this.authorizationStore.pipe(select(state => state.availableRoles));
  currentRole$ = this.authorizationStore.pipe(select(state => state.currentRole));

  wsRestart: () => void;

  constructor(
    private storeService: StoreService,
    private permissionsService: NgxPermissionsService,
    private authenticationService: AuthenticationService
  ) {
    this.allowedRoles$.pipe(untilDestroyed(this)).subscribe(allowedRoles => {
      const availableRoles = this.authorizationStore.value?.availableRoles?.filter(
        role => !allowedRoles || allowedRoles.includes(role)
      );

      // Filter available roles to only include the allowed roles
      this.authorizationStore.update(setProp('availableRoles', availableRoles));

      let role = this.authorizationStore.value?.currentRole;
      if (!role || (role && availableRoles && !availableRoles.includes(role))) {
        role = availableRoles?.[0];
      }

      if (role) {
        this.setRole(role);
      } else {
        this.authenticationService.updateAuthorization(false);
      }
    });

    this.authenticationService.authModel$.pipe(untilDestroyed(this)).subscribe(authModel => {
      if (authModel.isAuthenticated && authModel.roles && authModel.roles.length) {
        this.authorizationStore.update(setProp('availableRoles', authModel.roles));
      }
    });
  }

  setAllowedRoles(allowedRoles: string[]): void {
    this.authorizationStore.update(setProp('allowedRoles', allowedRoles));
  }

  setRole(role: string): void {
    // Check if the role is in the available roles
    if (!this.authorizationStore.value.availableRoles?.includes(role)) {
      return;
    }
    this.permissionsService.loadPermissions([role]);
    this.authorizationStore.update(setProp('currentRole', role));
    if (this.wsRestart) {
      this.wsRestart();
    }
    this.authenticationService.updateAuthorization(true);
  }

  can(permission: string | string[]): boolean {
    if (this.permissionsService) {
      const permissions = this.permissionsService.getPermissions();
      if (Array.isArray(permission)) {
        return permission.some(fPermission => this._hasPermission(permissions, fPermission));
      } else {
        return this._hasPermission(permissions, permission);
      }
    }
    return false;
  }

  cannot(permission: string | string[]): boolean {
    return !this.can(permission);
  }

  private _hasPermission(permissions: NgxPermissionsObject, permission: string): boolean {
    return permissions && permission in permissions;
  }
}
