import {
  AcademyOrganisationModel,
  AcademySchoolModel,
  AccountingOperatingCostTypeModel,
  DefaultProperties
} from '~ngx-shared/models';
import { Role } from '~ngx-shared/authentication';

export interface AccountingOperatingCostModel extends DefaultProperties {
  // Columns
  id?: number;
  operating_cost_type_id?: number;
  organisation_id?: number;
  school_id?: number;
  cost?: number;
  description?: string;

  // Relations
  operating_cost_type?: AccountingOperatingCostTypeModel;
  organisation?: AcademyOrganisationModel;
  school?: AcademySchoolModel;
}

export class AccountingOperatingCostPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AccountingOperatingCostPermission.DELETE, Role.MANAGER];
}
