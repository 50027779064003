import { Component, OnInit, signal } from '@angular/core';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of, switchMap } from 'rxjs';
import { ButtonDirective } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { AccessComponent } from '~madrasa/access/components/access/access.component';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { CorePersonDataModel } from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';
import { StudentDetailComponent } from '~madrasa/staff/components/student-detail/student-detail.component';
import { TeacherDetailComponent } from '~madrasa/staff/components/teacher-detail/teacher-detail.component';
import { DirectorDetailComponent } from '~madrasa/staff/components/director-detail/director-detail.component';
import { ParentDetailComponent } from '~madrasa/staff/components/parent-detail/parent-detail.component';
import { OrganisatorDetailComponent } from '~madrasa/staff/components/organisator-detail/organisator-detail.component';
import { PersonDetailComponent } from '~madrasa/staff/components/person-detail/person-detail.component';
import { HttpImgSrcPipe } from '~ngx-shared/pipes/html-img-src.pipe';
import { PersonDocumentListComponent } from '~madrasa/file/components/person-document-list/person-document-list.component';

@UntilDestroy()
@Component({
  selector: 'app-person-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective,
    AccessComponent,
    StudentDetailComponent,
    TeacherDetailComponent,
    DirectorDetailComponent,
    ParentDetailComponent,
    ButtonDirective,
    RouterLink,
    TooltipModule,
    OrganisatorDetailComponent,
    PersonDetailComponent,
    HttpImgSrcPipe,
    PersonDocumentListComponent
  ],
  templateUrl: './person-detail-page.component.html',
  styleUrl: './person-detail-page.component.scss'
})
export class PersonDetailPageComponent implements OnInit {
  readonly personId = signal<number | undefined>(undefined);
  readonly personData = signal<CorePersonDataModel | undefined>(undefined);

  constructor(
    private activatedRoute: ActivatedRoute,
    private translocoService: TranslocoService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.startLoading();
    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !!data['crud']['id'];
          if (result) {
            return of(data['crud']['id']);
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        this.personId.set(result);
        this.loadingService.stopLoading();
      });
  }

  getFullName(personDataModel: CorePersonDataModel | undefined) {
    return ModelUtil.getFullName(personDataModel, this.translocoService);
  }
}
