<div *transloco="let transloco" class="flex">
  <p-calendar
    [autofocus]="false"
    [dataType]="this.props.dataType || 'date'"
    [dateFormat]="this.props.dateFormat"
    [defaultDate]="this.props.defaultDate!"
    [disabledDates]="this.props.disabledDates!"
    [disabledDays]="this.props.disabledDays!"
    [formControl]="this.formControl"
    [formlyAttributes]="this.field"
    [hourFormat]="this.props.hourFormat!"
    [inline]="this.props.inline"
    [maxDate]="this.props.maxDate"
    [minDate]="this.props.minDate"
    [ngClass]="{
      'ng-dirty ng-invalid p-invalid': this.showError
    }"
    [numberOfMonths]="this.props.numberOfMonths || 1"
    [placeholder]="transloco(this.props.placeholder || '')"
    [readonlyInput]="this.props.readonly"
    [selectOtherMonths]="this.props.selectOtherMonths"
    [selectionMode]="this.props.selectionMode || 'single'"
    [showButtonBar]="this.props.showButtonBar"
    [showIcon]="!this.props.readonly"
    [showOnFocus]="!this.props.readonly"
    [showOtherMonths]="this.props.showOtherMonths"
    [showTime]="this.props.showTime!"
    [showWeek]="this.props.showWeek"
    [timeOnly]="this.props.timeOnly"
    [touchUI]="this.props.touchUI"
    [view]="this.props.view || 'date'"
    appendTo="body"
    class="p-fluid w-full">
  </p-calendar>
</div>
