import {
  AcademySchoolModel,
  AcademyTeacherHourlyRateModel,
  CorePersonDataModel,
  CorePersonModel,
  DefaultProperties,
  StaffTeacherModel
} from '~ngx-shared/models';
import { Role } from '~ngx-shared/authentication';

export interface AcademySchoolTeacherSalaryModel extends DefaultProperties {
  // Columns
  id?: number;
  school_id?: number;
  person_id?: number;
  hourly_rate?: number;
  monthly_rate?: number;
  teacher_hourly_rate_id?: number;

  // Relations
  school?: AcademySchoolModel;
  person?: CorePersonModel;
  current_person_data?: CorePersonDataModel;
  teacher_hourly_rate?: AcademyTeacherHourlyRateModel;
  latest_teacher?: StaffTeacherModel;
}

export class AcademySchoolTeacherSalaryPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR, Role.TEACHER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [
    ...AcademySchoolTeacherSalaryPermission.DELETE,
    Role.MANAGER,
    Role.ORGANISATOR,
    Role.DIRECTOR
  ];
}
