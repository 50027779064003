import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '~ngx-shared/environment';
import { AuthorizationService } from '~ngx-shared/authentication';

@Injectable()
export class RoleInterceptor implements HttpInterceptor {
  constructor(private authorizationService: AuthorizationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isHasuraOrExternal =
      request.url.startsWith(environment.hasuraUrl) ||
      request.url.startsWith(environment.externalServiceUrl);
    const currentRole = this.authorizationService.authorizationStore.value.currentRole;

    if (!isHasuraOrExternal || !currentRole) {
      return next.handle(request);
    }

    return next.handle(
      request.clone({
        headers: request.headers.set('x-hasura-role', currentRole)
      })
    );
  }
}
