import { Role } from '~ngx-shared/authentication';
import { DefaultProperties } from '../default.model';
import { AcademySchoolModel } from './school.model';
import { AcademySubjectModel } from './subject.model';
import { AcademyCourseTypeModel } from './course-type.model';
import { AcademyOrganisationOrganisatorModel } from './organisation-organisator.model';
import { AcademyCoursePriceModel } from './course-price.model';
import { AcademyTeacherHourlyRateModel } from './teacher-hourly-rate.model';

export interface AcademyOrganisationModel extends DefaultProperties {
  // Columns
  id?: number;
  name?: string;
  address?: any;
  hourly_rate?: number;
  monthly_rate?: number;
  teacher_hourly_rate_id?: number;

  // Relations
  organisation_course_price?: AcademyCoursePriceModel;
  teacher_hourly_rate?: AcademyTeacherHourlyRateModel;

  course_types?: AcademyCourseTypeModel[];
  organisation_organisators_active?: AcademyOrganisationOrganisatorModel[];
  schools?: AcademySchoolModel[];
  subjects?: AcademySubjectModel[];
}

export class AcademyOrganisationPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AcademyOrganisationPermission.DELETE, Role.MANAGER];
}
