import { Component } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective } from '@jsverse/transloco';
import { Router, RouterLink } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CourseListComponent } from '~madrasa/academy/components/course-list/course-list.component';
import { BasePageComponent } from '~ngx-shared/layout';
import { AcademyCoursePermission } from '~ngx-shared/models';

@Component({
  selector: 'app-course-list-page',
  standalone: true,
  imports: [
    BasePageComponent,
    ButtonDirective,
    TranslocoDirective,
    CourseListComponent,
    RouterLink,
    NgxPermissionsModule
  ],
  templateUrl: './course-list-page.component.html',
  styleUrl: './course-list-page.component.scss'
})
export class CourseListPageComponent {
  protected readonly AcademyCoursePermission = AcademyCoursePermission;
  constructor(public router: Router) {}
}
