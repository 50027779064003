<lib-layout-base-page *transloco="let transloco">
  <div *ngxPermissionsOnly="this.StaffStudentPermission.CREATE" action>
    <button
      (click)="menu.toggle($event)"
      class="p-button-success"
      icon="pi pi-bars"
      label="{{ transloco('new') }}"
      pButton
      type="button"></button>
    <p-menu #menu [model]="this.items()" [popup]="true" appendTo="body"></p-menu>
  </div>

  <div class="m-base flex flex-col">
    <app-student-list
      [columns]="this.columns()"
      [stateKey]="this.router.url + 'student-list'"></app-student-list>
  </div>
</lib-layout-base-page>
