import { Component } from '@angular/core';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

export interface GroupWrapperProps extends FieldWrapperProps {
  labelClass?: string;
  valueClass?: string;
}

@Component({
  selector: 'lib-formly-group-wrapper',
  templateUrl: './group-wrapper.component.html',
  styleUrl: './group-wrapper.component.css'
})
export class GroupWrapperComponent extends FieldWrapper<FormlyFieldConfig<GroupWrapperProps>> {}
