<lib-layout-base-page *transloco="let transloco">
  <a
    *ngxPermissionsOnly="this.AcademyCoursePermission.CREATE"
    [label]="transloco('new')"
    [routerLink]="['.', 'new']"
    action
    class="p-button-success text-white"
    icon="pi pi-plus"
    pButton
    type="button"></a>

  <div class="m-base flex flex-col">
    <app-course-list
      [showExport]="true"
      [showFilter]="true"
      [showSort]="true"
      [stateKey]="this.router.url + 'course-list'"></app-course-list>
  </div>
</lib-layout-base-page>
