import { QueryList, TemplateRef } from '@angular/core';
import { ObjectUtils } from 'primeng/utils';
import { TemplateDirective } from '~ngx-shared/directives';

export class TemplateUtil {
  static setTemplate(
    template: TemplateDirective,
    endsWith: string,
    templates: Map<string, TemplateRef<any>> | null
  ): Map<string, TemplateRef<any>> | null {
    if (template.name.endsWith(endsWith)) {
      if (!templates) {
        templates = new Map<string, TemplateRef<any>>();
      }
      templates.set(template.name.replace(endsWith, ''), template.template);
    }
    return templates;
  }

  static setTemplates(
    object: any,
    templateList: QueryList<TemplateDirective>[] | QueryList<TemplateDirective>,
    singleTemplates?: { template: string; name: string }[],
    multiTemplates?: { templates: string; name: string }[]
  ): void {
    if (object) {
      if (singleTemplates?.length) {
        singleTemplates.forEach(single => {
          if (Array.isArray(templateList)) {
            templateList.forEach(templateItem => {
              templateItem?.forEach(tableTemplate => {
                if (tableTemplate.name === single.name && single.template in object) {
                  object[single.template] = tableTemplate.template;
                }
              });
            });
          } else {
            templateList.forEach(tableTemplate => {
              if (tableTemplate.name === single.name && single.template in object) {
                object[single.template] = tableTemplate.template;
              }
            });
          }
        });
      }
      if (multiTemplates?.length) {
        multiTemplates.forEach(multiple => {
          if (Array.isArray(templateList)) {
            templateList.forEach(templateItem => {
              templateItem?.forEach(tableTemplate => {
                if (multiple.templates in object) {
                  object[multiple.templates] = TemplateUtil.setTemplate(
                    tableTemplate,
                    multiple.name,
                    object[multiple.templates] as Map<string, TemplateRef<any>> | null
                  );
                }
              });
            });
          } else {
            templateList?.forEach(tableTemplate => {
              if (multiple.templates in object) {
                object[multiple.templates] = TemplateUtil.setTemplate(
                  tableTemplate,
                  multiple.name,
                  object[multiple.templates] as Map<string, TemplateRef<any>> | null
                );
              }
            });
          }
        });
      }
    }
  }

  static getValueAsString(
    item: any,
    field?: any,
    adhoc?: (item: any) => any,
    separator: string = ', '
  ): string {
    if (item && field) {
      if (ObjectUtils.isFunction(field)) {
        return field(item);
      } else {
        const dotIndex = field.indexOf('.');
        const parentKey = field.slice(0, dotIndex);
        if (dotIndex > 0) {
          if (Array.isArray(item) && item.length) {
            const found = item.map(item => {
              return TemplateUtil.getValueAsString(
                ObjectUtils.resolveFieldData(item, parentKey),
                field.slice(dotIndex + 1),
                adhoc,
                separator
              );
            });
            if (found?.length) {
              return found.join(separator);
            }
          } else if (typeof item === 'object' && parentKey in item) {
            return TemplateUtil.getValueAsString(
              ObjectUtils.resolveFieldData(item, parentKey),
              field.slice(dotIndex + 1),
              adhoc,
              separator
            );
          }
        } else {
          if (Array.isArray(item)) {
            const found = item.map(item => {
              return adhoc
                ? adhoc(ObjectUtils.resolveFieldData(item, field))
                : ObjectUtils.resolveFieldData(item, field);
            });
            if (found?.length) {
              return found.join(separator);
            }
          } else if (typeof item === 'object' && field in item) {
            const resolved = ObjectUtils.resolveFieldData(item, field);
            if (Array.isArray(resolved)) {
              return resolved.map(item => (adhoc ? adhoc(item) : item) as string).join(separator);
            }
            return adhoc
              ? adhoc(ObjectUtils.resolveFieldData(item, field))
              : ObjectUtils.resolveFieldData(item, field);
          }
        }
      }
    } else if (item && !field) {
      return adhoc ? adhoc(item) : item;
    }
    return '';
  }
}
