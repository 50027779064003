import { Component, computed, ContentChildren, input, OnInit, QueryList } from '@angular/core';
import { AsyncPipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';
import { PersonListComponent } from '~madrasa/staff/components/person-list/person-list.component';
import { TemplateDirective } from '~ngx-shared/directives';
import { DataProviderOptionModel, GraphQlColumnModel } from '~ngx-shared/graph-ql';
import {
  AcademySchoolModel,
  CorePersonDataModel,
  StaffStatusEnum,
  StaffTeacherPermission
} from '~ngx-shared/models';
import { AuthorizationService } from '~ngx-shared/authentication';
import { ConfirmationService } from '~ngx-shared/layout';
import { TenantService } from '~madrasa/services';

@Component({
  selector: 'app-teacher-list',
  standalone: true,
  imports: [AsyncPipe, PersonListComponent, TemplateDirective, CurrencyPipe],
  templateUrl: './teacher-list.component.html',
  styleUrl: './teacher-list.component.scss'
})
export class TeacherListComponent implements OnInit {
  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>;

  readonly stateKey = input<string>();
  readonly inputColumns = input<GraphQlColumnModel[]>([], { alias: 'columns' });

  readonly columns = computed(() => {
    const inputColumns = this.inputColumns();
    const columns = [...this.getDefaultColumns()];

    columns.push(...(inputColumns || []));

    return columns;
  });

  patchOptions = input<((options: DataProviderOptionModel) => DataProviderOptionModel) | undefined>(
    options => {
      options.filter = {
        ...options.filter,
        _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
      };
      if (
        Array.isArray(options?.filter?._and) &&
        !options.filter?._and?.some((cond: any) => cond.latest_teacher)
      ) {
        options.filter._and.push({ latest_teacher: {} });
      }
      return options;
    }
  );
  deleteFunc?: (options: { data: CorePersonDataModel; refresh: () => void }) => void;

  protected readonly StaffTeacherPermission = StaffTeacherPermission;

  constructor(
    public authorizationService: AuthorizationService,
    private confirmationService: ConfirmationService,
    private apollo: Apollo,
    public tenantService: TenantService
  ) {}

  ngOnInit() {
    this.deleteFunc = options => {
      if (options.data) {
        let mutation: any = gql`
          mutation SoftDeleteStaffTeacherById($id: bigint!) {
            result: update_staff_teacher(
              _set: { deleted_at: "now()" }
              where: { person_id: { _eq: $id } }
            ) {
              __typename
            }
            result_teacher: update_academy_school_teacher_active(
              _set: { deleted_at: "now()" }
              where: { person_id: { _eq: $id } }
            ) {
              __typename
            }
          }
        `;
        if (
          options.data.teachers?.[0]?.deleted_at &&
          this.authorizationService.can(StaffTeacherPermission.DELETE)
        ) {
          mutation = gql`
            mutation DeleteStaffTeacherById($id: bigint!) {
              result: delete_staff_teacher(where: { person_id: { _eq: $id } }) {
                __typename
              }
              result_teacher: update_academy_school_teacher_active(
                _set: { deleted_at: "now()" }
                where: { person_id: { _eq: $id } }
              ) {
                __typename
              }
            }
          `;
        }

        this.confirmationService.confirmDeleteApollo({
          name: `${options.data.first_name} - ${options.data.last_name}`,
          mutationOptions: {
            mutation,
            variables: {
              id: options.data.person_id
            }
          },
          success: options.refresh
        });
      }
    };
  }

  decimalPipe = new DecimalPipe('de_AT');

  getDefaultColumns(): GraphQlColumnModel[] {
    const columns: GraphQlColumnModel[] = [
      {
        label: 'schools',
        path: 'school_teachers_active.school.name',
        filter: {
          label: 'school',
          path: 'school_teachers_active.school_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademySchoolModel[];
            }>({
              query: gql`
                query ReadAcademySchool($where: academy_school_bool_exp) {
                  result: academy_school(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(school => ({
                  label: school.name,
                  value: school.id
                }))
              )
            )
        },
        sort: { isSortable: false },
        hidden: true
      },
      this.tenantService.currentTenant?.are_course_names_forced
        ? {
            label: 'course',
            path: 'course_teachers.course.course_name.name',
            filter: { type: 'string' },
            sort: { isSortable: false }
          }
        : {
            label: 'course',
            path: 'course_teachers.course.name',
            filter: { type: 'string' },
            sort: { isSortable: false }
          },
      {
        path: 'latest_teacher.start_of_work',
        type: 'date',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'latest_teacher.initial_worked_months',
        type: 'number',
        filter: { type: 'number' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'latest_teacher.worked_months',
        type: 'number',
        filter: { type: 'number' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'latest_teacher.hourly_rate_value',
        type: 'currency',
        filter: { type: 'number' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'latest_teacher.monthly_rate',
        type: 'currency',
        filter: { type: 'number' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        label: 'school_teacher_salary',
        path: 'latest_teacher.school_teacher_salaries',
        query: `latest_teacher { school_teacher_salaries { school { id, name }, hourly_rate_value, monthly_rate } }`,
        patchResult: (result: any) => {
          return result.latest_teacher?.school_teacher_salaries
            ?.map((salary: any) => {
              const value = salary?.hourly_rate_value || salary?.monthly_rate;
              return `${salary.school?.name}: ${this.decimalPipe.transform(value / 100)}`;
            })
            .join('; ');
        },
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        hidden: true
      },

      {
        label: 'organisation_teacher_salary',
        path: 'latest_teacher.organisation_teacher_salaries',
        query: `latest_teacher { organisation_teacher_salaries { organisation { id, name }, hourly_rate_value, monthly_rate } }`,
        patchResult: (result: any) => {
          return result.latest_teacher?.organisation_teacher_salaries
            ?.map((salary: any) => {
              const value = salary?.hourly_rate_value || salary?.monthly_rate;
              return `${salary.organisation?.name}: ${this.decimalPipe.transform(value / 100)}`;
            })
            .join('; ');
        },
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        hidden: true
      },

      {
        path: 'latest_teacher.status',
        translate: true,
        prefix: 'status.',
        filter: {
          type: 'array',
          options: Object.values(StaffStatusEnum).map(value => ({
            label: 'status.' + value,
            value: value
          }))
        },
        sort: { isSortable: true },
        hidden: true
      }
    ];
    return columns;
  }
}
