<lib-layout-base-page *transloco="let transloco">
  <div class="flex flex-col">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('general_information') }}">
        <ng-template pTemplate="content">
          <app-unit-detail [id]="this.item()?.id"></app-unit-detail>
        </ng-template>
      </p-tabPanel>

      @if (this.item()?.id) {
        <p-tabPanel header="{{ transloco('teachers') }}">
          <ng-template pTemplate="content">
            <app-person-list
              [columns]="this.teacherColumns()"
              [patchOptions]="this.teacherPatchOptions"
              [showDetailButton]="true"
              [showExport]="true"
              [showFilter]="true"
              [showSort]="true"
              [stateKey]="this.router.url + 'course-detail-teacher-list'">
            </app-person-list>
          </ng-template>
        </p-tabPanel>
      }

      <p-tabPanel header="{{ transloco('course_information') }}">
        <ng-template pTemplate="content">
          <app-course-detail [id]="this.item()?.course?.id"></app-course-detail>
        </ng-template>
      </p-tabPanel>

      <p-tabPanel header="{{ transloco('class_book_entries') }}">
        <ng-template pTemplate="content">
          <app-class-book-entry-list
            [patchOptions]="this.cbePatchOptions"
            [showExport]="true"
            [showFilter]="true"
            [showSort]="true"
            [stateKey]="this.router.url + 'unit-detail-cbe-list'"></app-class-book-entry-list>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</lib-layout-base-page>
