import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';
import { interval, Observable, of, throttle } from 'rxjs';

interface TabProps extends FormlyFieldProps {
  query?: string;
}

export interface TabFieldConfig extends FormlyFieldConfig<TabProps> {
  type?: 'tab' | Type<TabTypeComponent>;
}

@Component({
  selector: 'lib-tab-type',
  templateUrl: './tab-type.component.html',
  styleUrl: './tab-type.component.css'
})
export class TabTypeComponent extends FieldType<FieldTypeConfig<TabProps>> {
  private getFormErrorCount(field: FormlyFieldConfig): number {
    return field.formControl?.invalid &&
      (field.formControl?.touched || field.options?.parentForm?.submitted)
      ? 1
      : 0;
  }

  errorCount(field: FormlyFieldConfig): number {
    let count = 0;
    if (field.key) {
      count = this.getFormErrorCount(field);
    } else if (field.fieldGroup) {
      count = field.fieldGroup.reduce((total, item) => {
        return total + this.errorCount(item);
      }, 0);
    }
    return count;
  }

  errorCount$(field: FormlyFieldConfig): Observable<number> {
    return of(this.errorCount(field)).pipe(throttle(() => interval(1000)));
  }
}
