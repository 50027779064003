import { ResolveFn } from '@angular/router';

export function breadcrumbResolver(parameterName: string): ResolveFn<string | undefined | null> {
  return (route, state) => {
    if (route.paramMap.keys.some(key => key.endsWith(parameterName))) {
      // Get parameter value
      // Can be a string with 'new' or a number
      const value = route.paramMap.get(parameterName);
      if (value === 'new') {
        return 'add_value';
      }
      if (!isNaN(Number(value))) {
        const isNew = route.queryParamMap.get('is_new');
        if (isNew === 'true') {
          return 'add_value';
        }
        return 'edit_value';
      }
    }

    return route.data['breadcrumb'];
  };
}
