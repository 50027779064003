<lib-layout-base-page *transloco="let transloco">
  <!--  <a-->
  <!--    [label]="transloco('new')"-->
  <!--    [routerLink]="['.', 'new']"-->
  <!--    action-->
  <!--    class="p-button-success text-white"-->
  <!--    icon="pi pi-plus"-->
  <!--    pButton-->
  <!--    type="button"></a>-->

  <div class="m-base flex flex-col">
    <app-person-list
      [showDeleteButton]="this.authorizationService.can(this.CorePersonPermission.SOFT_DELETE)"
      [showDetailButton]="this.authorizationService.can(this.CorePersonPermission.READ)"
      [showExport]="true"
      [showFilter]="true"
      [showSort]="true"
      [stateKey]="this.router.url + 'person-list'"
      [showUpdateButton]="
        this.authorizationService.can(this.CorePersonPermission.UPDATE)
      "></app-person-list>
  </div>
</lib-layout-base-page>
