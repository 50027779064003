<lib-layout-base-page
  *transloco="let transloco"
  [titleParamValue]="this.item()?.date | libDate: 'MMMM yyyy'">
  <div class="flex flex-col">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('general_information') }}">
        <ng-template pTemplate="content">
          <div class="flex flex-col gap-6">
            <lib-detail [fields]="this.fields()"></lib-detail>

            @if (this.item()?.id) {
              <span class="mt-4 block text-2xl font-semibold">{{
                transloco('class_book_entries')
              }}</span>

              <app-class-book-entry-list
                [patchOptions]="this.cbePatchOptions"
                [showExport]="true"
                [columns]="this.cbeColumns()"
                [hideTeacherColumn]="true"
                [showFilter]="false"
                [showSort]="false"
                [stateKey]="
                  this.router.url + 'teacher-wage-detail-cbe-list'
                "></app-class-book-entry-list>
            }
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</lib-layout-base-page>
