import { Component } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective } from '@jsverse/transloco';
import { Router, RouterLink } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { UnitListComponent } from '~madrasa/academy/components/unit-list/unit-list.component';
import { BasePageComponent } from '~ngx-shared/layout';
import { AcademyUnitPermission } from '~ngx-shared/models';

@Component({
  selector: 'app-unit-list-page',
  standalone: true,
  imports: [
    BasePageComponent,
    ButtonDirective,
    TranslocoDirective,
    UnitListComponent,
    RouterLink,
    NgxPermissionsModule
  ],
  templateUrl: './unit-list-page.component.html',
  styleUrl: './unit-list-page.component.scss'
})
export class UnitListPageComponent {
  protected readonly AcademyUnitPermission = AcademyUnitPermission;
  constructor(public router: Router) {}
}
