import { Component, OnInit, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { Router } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { map } from 'rxjs';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { GraphQlColumnModel } from '~ngx-shared/graph-ql';
import { AuthorizationService } from '~ngx-shared/authentication';
import { TenantService } from '~madrasa/services';
import { BasePageComponent } from '~ngx-shared/layout';
import { TeacherListComponent } from '~madrasa/staff/components/teacher-list/teacher-list.component';
import {
  AcademyOrganisationModel,
  AcademySchoolModel,
  AccountingTeacherWageModel
} from '~ngx-shared/models';

@Component({
  selector: 'app-calculate-teacher-wage-list-page',
  standalone: true,
  imports: [BasePageComponent, TranslocoDirective, FormlyModule, TeacherListComponent],
  templateUrl: './calculate-teacher-wage-list-page.component.html',
  styleUrl: './calculate-teacher-wage-list-page.component.scss'
})
export class CalculateTeacherWageListPageComponent implements OnInit {
  form = new FormGroup<any>({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.teacher_wage_list'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  readonly result = signal<{ [key: number]: any } | undefined>(undefined);
  readonly columns = signal<GraphQlColumnModel[]>([]);

  constructor(
    public authorizationService: AuthorizationService,
    private apollo: Apollo,
    public router: Router,
    public tenantService: TenantService
  ) {}

  ngOnInit(): void {
    this.columns.set([]);

    this.fields = [
      FormlyUtil.createRow([
        FormlyUtil.createDatePickerField('date', {
          props: {
            required: true,
            view: 'month'
          }
        })
      ])
    ];

    this.submit = (formSaveModel: FormSaveModel) => {
      // const input = {
      //   ...formSaveModel.input
      // };

      return this.apollo
        .query<{
          result: {
            data: AccountingTeacherWageModel[];
            organisations: AcademyOrganisationModel[];
            schools: AcademySchoolModel[];
          }[];
        }>({
          query: gql`
            query AccountingTeacherWages($input_date: date!) {
              result: accounting_calculate_teacher_wages_by_date(
                args: { input_date: $input_date }
              ) {
                person_id
                wage
                wage_detail
              }

              organisations: academy_organisation {
                id
                name
              }

              schools: academy_school {
                id
                name
                organisation_id
              }
            }
          `,
          variables: {
            input_date: FormlyUtil.toIsoDateString(formSaveModel.input.date)
          }
        })
        .pipe(map(queryResult => queryResult.data));
    };
  }

  savedEvent(formSaveModel: FormSaveModel) {
    // Convert into an object with person_id as key and item as value
    const result = formSaveModel.output?.result?.reduce((acc: any, curr: any) => {
      acc[curr.person_id] = curr;
      return acc;
    }, {});

    const columns: GraphQlColumnModel[] = [];

    formSaveModel.output?.organisations?.forEach((organisation: any) => {
      formSaveModel.output?.schools?.forEach((school: any) => {
        if (school.organisation_id === organisation.id) {
          columns.push({
            label: school.name,
            path: 'id',
            type: 'currency',
            patchResult: (result: any) =>
              this.result()?.[result.person_id]?.wage_detail?.organisations?.[organisation.id]
                ?.schools?.[school.id]?.wage,
            filter: { isNotFilterable: true },
            sort: { isSortable: false },
            hidden: true
          });
        }
      });
    });

    formSaveModel.output?.organisations
      ?.slice()
      ?.reverse()
      ?.forEach((organisation: any) => {
        columns.unshift({
          label: organisation.name,
          path: 'id',
          type: 'currency',
          patchResult: (result: any) =>
            this.result()?.[result.person_id]?.wage_detail?.organisations?.[organisation.id]?.wage,
          filter: { isNotFilterable: true },
          sort: { isSortable: false }
        });
      });

    columns.unshift({
      label: 'calculated_wage',
      path: 'id',
      type: 'currency',
      patchResult: (result: any) => this.result()?.[result.person_id]?.wage,
      filter: { isNotFilterable: true },
      sort: { isSortable: false }
    });

    this.columns.set(columns);
    this.result.set(result);
  }
}
