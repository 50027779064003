import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '~ngx-shared/formly/field-wrapper/field-wrapper.component';

interface LabelProps extends FieldWrapperProps {
  labelClass?: string;
  valueClass?: string;
}

export interface LabelFieldConfig extends FormlyFieldConfig<LabelProps> {
  type?: 'label' | Type<LabelTypeComponent>;
}

@Component({
  selector: 'lib-formly-label-type',
  templateUrl: './label-type.component.html',
  styleUrl: './label-type.component.css'
})
export class LabelTypeComponent extends FieldType<FieldTypeConfig<LabelProps>> {}
