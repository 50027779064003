import {
  Translation,
  TranslocoConfig,
  TranslocoLoader,
  TranslocoMissingHandler,
  TranslocoService
} from '@jsverse/transloco';
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    // example for lang:
    // de.json
    // <scope>/de.json
    const langPath = lang.split('/');
    if (langPath.length === 1) {
      return forkJoin([
        this.http.get<Translation>(`/i18n/common/${lang}.json?v=${Date.now()}`),
        this.http.get<Translation>(`/i18n/${lang}.json?v=${Date.now()}`)
      ]).pipe(
        map(([generalTranslation, projectTranslation]) => {
          return { ...generalTranslation, ...projectTranslation };
        })
      );
    }
    return this.http.get<Translation>(`/i18n/${lang}.json?v=${Date.now()}`);
  }
}

@Injectable()
export class CustomMissingHandler implements TranslocoMissingHandler {
  translocoService: TranslocoService;

  constructor(private injector: Injector) {}

  handle(key: string, config: TranslocoConfig) {
    if (!this.translocoService) {
      this.translocoService = this.injector.get(TranslocoService);
    }
    if (key.includes('.')) {
      // Search for first dot and try to translate it
      const split = key.split('.');
      split.splice(0, 1);
      const newKey = split.join('.');
      const value = this.translocoService.translate(newKey);
      // Check if it is translated
      if (value === newKey) {
        return key;
      } else {
        return value;
      }
    }
    return key;
  }
}
