<lib-layout-base-page *transloco="let transloco" [titleParamValue]="this.item()?.result?.name">
  <div class="">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('general_information') }}">
        <ng-template pTemplate="content">
          <div class="flex flex-col gap-6">
            <lib-detail [fields]="this.fields()"></lib-detail>

            <p-table
              *transloco="let transloco"
              [value]="[{}]"
              responsiveLayout="scroll"
              styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm ">
              <ng-template pTemplate="header">
                <tr>
                  <th class="w-1/12">
                    <div class="flex items-center justify-center">
                      <span class="block">{{ transloco('years') }}</span>
                    </div>
                  </th>
                  @for (column of this.item()?.result?.columns; track column; let index = $index) {
                    <th>
                      <div class="flex items-center justify-center">
                        {{ this.item()?.educationTypes?.[column] || column }}
                      </div>
                    </th>
                  }
                </tr>
              </ng-template>

              <ng-template pTemplate="body">
                @for (row of this.item()?.result?.rows; track row; let rowIndex = $index) {
                  <tr>
                    <td>
                      <span class="block text-center">{{ rowIndex }}</span>
                    </td>
                    @for (
                      column of this.item()?.result?.columns;
                      track column;
                      let index = $index
                    ) {
                      <td>
                        <div class="flex items-center justify-center">
                          {{ (row[index + 1] || 0) / 100 | currency }}
                        </div>
                      </td>
                    }
                  </tr>
                }
              </ng-template>
            </p-table>
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</lib-layout-base-page>
