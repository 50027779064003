<div *transloco="let transloco" class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="/">
    <img
      alt="logo"
      class="h-auto w-[200px]"
      src="images/{{
        layoutService.config().colorScheme === 'light' ? 'logo-dark' : 'logo-white'
      }}.svg" />
  </a>

  <button
    #menubutton
    (click)="layoutService.onMenuToggle()"
    class="p-link layout-menu-button layout-topbar-button">
    <i class="pi pi-bars"></i>
  </button>

  <app-currently-online
    *ngxPermissionsOnly="[this.Role.LESSOR, this.Role.MANAGER]"
    class="layout-topbar-online"></app-currently-online>

  <button
    #topbarmenubutton
    (click)="layoutService.showProfileSidebar()"
    class="p-link layout-topbar-menu-button layout-topbar-button">
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <div
    #topbarmenu
    [ngClass]="{
      'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible,
      'ml-0': this.authorizationService.can([this.Role.LESSOR, this.Role.MANAGER]),
      'ml-auto': !this.authorizationService.can([this.Role.LESSOR, this.Role.MANAGER])
    }"
    class="layout-topbar-menu">
    @if (layoutService.state.profileSidebarVisible) {
      @for (role of this.authorizationService.availableRoles$ | async; track role) {
        <button
          (click)="this.setRole(role); layoutService.showProfileSidebar()"
          class="p-link layout-topbar-button"
          type="button">
          <i class="pi pi-user"></i>
          <span
            [ngClass]="{
              'font-bold': this.currentRole() === role
            }"
            >{{ transloco('roles.' + role) }}</span
          >
        </button>
      }
      <button
        [pTooltip]="transloco('settings')"
        [routerLink]="['/', 'staff', 'settings']"
        [tooltipDisabled]="layoutService.state.profileSidebarVisible"
        class="p-link layout-topbar-button"
        tooltipPosition="bottom"
        type="button">
        <i class="pi pi-cog"></i>
        <span>{{ transloco('settings') }}</span>
      </button>
      <button
        (click)="this.authenticationService.signOut()"
        [pTooltip]="transloco('sign_out')"
        [tooltipDisabled]="layoutService.state.profileSidebarVisible"
        class="p-link layout-topbar-button"
        tooltipPosition="bottom"
        type="button">
        <i class="pi pi-sign-out"></i>
        <span>{{ transloco('sign_out') }}</span>
      </button>
    } @else {
      <div class="p-link layout-topbar-button">
        <p-avatar (click)="topbarspecialmenu.toggle($event)" size="xlarge" type="button">
          @let profile_picture_url = this.user()?.current_person_data?.['profile_picture_url'];
          @if (profile_picture_url) {
            <div class="">
              <img
                class="h-auto w-auto object-cover object-center"
                [src]="profile_picture_url | httpImgSrc"
                alt="Profile photo" />
            </div>
          } @else {
            <div class="mx-2 px-6">
              <i class="pi pi-user"></i>
            </div>
          }
        </p-avatar>
      </div>

      <div
        class="flex items-center px-6 py-0"
        [ngClass]="{ 'flex-col': this.currentRole() !== Role.LESSOR }">
        @if (this.currentRole() !== Role.LESSOR) {
          <span class="block text-base">{{ this.fullName() }}</span>
        }
        <span class="block text-sm">{{ transloco(this.currentRole() || '') | uppercase }}</span>
      </div>
      <p-menu #topbarspecialmenu [model]="this.items()" [popup]="true" appendTo="body"></p-menu>
    }
  </div>
</div>
