import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, of, switchMap, tap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { TranslocoService } from '@jsverse/transloco';
import { FormlyService, TenantService } from '~madrasa/services';
import {
  AcademyCourseModel,
  AcademyCourseNameModel,
  AcademyCourseStudentStatusEnum,
  AcademyCourseTypeModel,
  AcademySchoolPeriodModel,
  AcademyTeacherRoleEnum
} from '~ngx-shared/models';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil } from '~ngx-shared/utils';
import { PersonUtil } from '~madrasa/core/utils/person.util';

@UntilDestroy()
@Component({
  selector: 'app-create-update-course-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-course-form-page.component.html',
  styleUrl: './create-update-course-form-page.component.scss'
})
export class CreateUpdateCourseFormPageComponent implements OnInit {
  form = new FormGroup({});
  readOnlyModel: AcademyCourseModel = {};
  model: AcademyCourseModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_course'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  constructor(
    private apollo: Apollo,
    private formlyService: FormlyService,
    private tenantService: TenantService,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AcademyCourseModel }>({
                query: gql`
                  query ReadAcademyCourseById($id: bigint!) {
                    result: academy_course_by_pk(id: $id) {
                      id
                      created_at
                      updated_at
                      school_period_id
                      course_type_id
                      parent_course_id
                      school_id
                      room_id
                      name
                      course_name_id
                      description
                      is_online
                      address
                      color

                      school {
                        organisation_id
                      }

                      room {
                        id
                        name
                        area
                        seating
                      }

                      ${
                        this.tenantService.canCrudCoursePrice
                          ? `
                            course_course_price {
                              id
                              single_price
                              dual_price
                              three_or_more_price
                            }
                            `
                          : ''
                      }

                      course_students: latest_course_students(order_by: { current_person_data: { first_name: asc } }) {
                        id
                        person_id
                        current_person_data {
                          person_id
                          academic_degree_prefix
                          academic_degree_suffix
                          first_name
                          last_name
                        }
                        status
                        ${this.tenantService.canCrudCoursePrice ? 'course_price' : ''}
                      }

                      course_teachers(order_by: { current_person_data: { first_name: asc } }) {
                        id
                        teaching_role
                        person_id
                        current_person_data {
                          person_id
                          academic_degree_prefix
                          academic_degree_suffix
                          first_name
                          last_name
                        }
                      }
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          this.readOnlyModel = result;
          let model = cloneDeep(result);

          if (model) {
            this.model = {
              ...model,
              organisation_id: model.school?.organisation_id,
              students:
                model.course_students?.map(course_student => ({
                  id: course_student.id,
                  person_id: course_student.person_id,
                  full_name: `${ModelUtil.getFullName(
                    course_student['current_person_data'],
                    this.translocoService
                  )}, Id: ${course_student.person_id}`,
                  status: course_student.status,
                  course_price: course_student.course_price
                    ? course_student.course_price / 100
                    : undefined
                })) || [],
              teachers:
                model.course_teachers?.map(course_teacher => ({
                  id: course_teacher.id,
                  person_id: course_teacher.person_id,
                  full_name: `${ModelUtil.getFullName(
                    course_teacher['current_person_data'],
                    this.translocoService
                  )}, Id: ${course_teacher.person_id}`,
                  teaching_role: course_teacher.teaching_role
                })) || [],
              course_course_price: {
                ...model.course_course_price,
                single_price: model.course_course_price?.single_price
                  ? model.course_course_price.single_price / 100
                  : undefined,
                dual_price: model.course_course_price?.dual_price
                  ? model.course_course_price.dual_price / 100
                  : undefined,
                three_or_more_price: model.course_course_price?.three_or_more_price
                  ? model.course_course_price.three_or_more_price / 100
                  : undefined
              }
            };
          }
        }

        this.fields = [
          {
            key: 'id'
          },
          FormlyUtil.createRow([
            FormlyUtil.createSelectField('school_period_id', {
              props: {
                label: 'school_period',
                required: true,
                options: this.apollo
                  .query<{
                    result: AcademySchoolPeriodModel[];
                  }>({
                    query: gql`
                      query ReadAcademySchoolPeriods {
                        result: academy_school_period(order_by: { ends_at: desc }) {
                          id
                          name
                        }
                      }
                    `
                  })
                  .pipe(
                    map(queryResult =>
                      queryResult.data.result.map(schoolPeriod => ({
                        label: schoolPeriod.name,
                        value: schoolPeriod.id
                      }))
                    )
                  )
              }
            }),
            FormlyUtil.createSelectField('course_type_id', {
              props: {
                label: 'course_type',
                required: true,
                options: this.apollo
                  .query<{
                    result: AcademyCourseTypeModel[];
                  }>({
                    query: gql`
                      query ReadAcademyCourseTypes {
                        result: academy_course_type(
                          order_by: { name: asc }
                          where: { is_active: { _eq: true } }
                        ) {
                          id
                          name
                        }
                      }
                    `
                  })
                  .pipe(
                    map(queryResult =>
                      queryResult.data.result.map(courseType => ({
                        label: courseType.name,
                        value: courseType.id
                      }))
                    )
                  )
              }
            })
          ]),
          this.formlyService.createOrganisationFieldConfig(),
          this.formlyService.createSchoolFieldConfig(),
          FormlyUtil.createRow([
            this.tenantService.currentTenant?.are_course_names_forced
              ? FormlyUtil.createSelectField('course_name_id', {
                  props: {
                    label: 'name',
                    required: true,
                    options: []
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      if (field.props && field.model.organisation_id) {
                        field.props.options = this.apollo
                          .query<{
                            result: AcademyCourseNameModel[];
                          }>({
                            query: gql`
                              query ReadAcademyCourseNames(
                                $where: academy_course_name_bool_exp = {}
                              ) {
                                result: academy_course_name(
                                  order_by: { name: asc }
                                  where: $where
                                ) {
                                  id
                                  name
                                }
                              }
                            `,
                            variables: {
                              where: { organisation_id: { _eq: field.model.organisation_id } }
                            }
                          })
                          .pipe(
                            map(queryResult =>
                              queryResult.data.result.map(courseName => ({
                                label: courseName.name,
                                value: courseName.id
                              }))
                            )
                          );
                      }

                      return field.options?.fieldChanges?.pipe(
                        filter(
                          event =>
                            event.type === 'valueChanges' && event.field.key === 'organisation_id'
                        ),
                        tap(event => {
                          field.formControl?.setValue(undefined);

                          if (event.value && field.props) {
                            field.props.options = this.apollo
                              .query<{
                                result: AcademyCourseNameModel[];
                              }>({
                                query: gql`
                                  query ReadAcademyCourseNames(
                                    $where: academy_course_name_bool_exp = {}
                                  ) {
                                    result: academy_course_name(
                                      order_by: { name: asc }
                                      where: $where
                                    ) {
                                      id
                                      name
                                    }
                                  }
                                `,
                                variables: {
                                  where: { organisation_id: { _eq: event.value } }
                                }
                              })
                              .pipe(
                                map(queryResult =>
                                  queryResult.data.result.map(courseName => ({
                                    label: courseName.name,
                                    value: courseName.id
                                  }))
                                )
                              );
                          }
                        })
                      );
                    }
                  }
                })
              : FormlyUtil.createTextField('name', {
                  props: {
                    required: true
                  }
                })
          ]),
          FormlyUtil.createRow([FormlyUtil.createColorPickerField('color')]),
          this.formlyService.createRoomFieldConfig({ key: 'room' }),
          FormlyUtil.createRow([FormlyUtil.createTextField('description')]),
          FormlyUtil.createRow([
            FormlyUtil.createCheckboxField('is_online', {
              props: {
                required: true
              }
            })
          ]),
          this.formlyService.createAddressFieldConfig({
            initialCount: 0,
            expressions: {
              hide: field => {
                return field.parent?.model?.is_online;
              }
            }
          }),
          this.formlyService.createPersonSelectFieldConfig({
            key: 'teacher_select',
            label: 'teachers',
            conditions: field => [
              {
                latest_teacher: { status: { _eq: 'active' } }
              },
              {
                school_teachers_active: {
                  school_id: { _eq: field?.model?.school_id }
                }
              }
            ],
            click: (event, field) => {
              const value = field.formControl?.value;
              if (
                value &&
                (!this.model?.['teachers'] ||
                  this.model['teachers']?.findIndex(
                    (item: any) => item.person_id === value.value?.value?.person_id
                  ) === -1)
              ) {
                this.model = {
                  ...this.model,
                  teacher_select: undefined,
                  teachers: [
                    ...(this.model['teachers'] || []),
                    {
                      person_id: value.value?.value?.person_id,
                      full_name: `${value.label}, Id: ${value.value?.value?.person_id}`,
                      teaching_role: undefined
                    }
                  ]
                };
              }
            }
          }),
          FormlyUtil.createRow([
            FormlyUtil.createTable(
              {
                key: 'teachers',
                props: {
                  showIndex: true,
                  canAdd: () => false,
                  hideLabel: true,
                  columns: [
                    {
                      key: 'full_name',
                      header: 'name'
                    },
                    {
                      key: 'teaching_role',
                      header: 'teaching_role'
                    }
                  ]
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    return field.options?.fieldChanges?.pipe(
                      filter(
                        event => event.type === 'valueChanges' && event.field.key === 'school_id'
                      ),
                      tap(event => {
                        field.formControl?.setValue([]);
                      })
                    );
                  }
                }
              },
              [
                {
                  key: 'id'
                },
                {
                  key: 'person_id'
                },
                FormlyUtil.createLabelField('full_name', {
                  props: {
                    label: 'name',
                    disableMargin: true,
                    hideLabel: true,
                    labelClass: 'font-normal'
                  }
                }),
                FormlyUtil.createSelectField('teaching_role', {
                  props: {
                    disableMargin: true,
                    hideLabel: true,
                    required: true,
                    options: Object.values(AcademyTeacherRoleEnum).map(teachingRole => ({
                      label: 'teaching_role.' + teachingRole,
                      value: teachingRole
                    }))
                  }
                })
              ]
            )
          ]),
          this.formlyService.createPersonSelectFieldConfig({
            key: 'student_select',
            label: 'students',
            conditions: field => [
              {
                latest_student: { status: { _eq: 'active' } }
              },
              {
                school_students_active: {
                  school_id: { _eq: field?.model?.school_id }
                }
              }
            ],
            click: (event, field) => {
              const value = field.formControl?.value;
              const seating =
                this.model?.room?.seating === undefined ? Infinity : this.model.room.seating;
              if (
                value &&
                seating > (this.model['students']?.length || 0) &&
                (!this.model?.['students'] ||
                  this.model['students']?.findIndex(
                    (item: any) => item.person_id === value.value?.value?.person_id
                  ) === -1)
              ) {
                this.model = {
                  ...this.model,
                  student_select: undefined,
                  students: [
                    ...(this.model['students'] || []),
                    {
                      person_id: value.value?.value?.person_id,
                      full_name: `${value.label}, Id: ${value.value?.value?.person_id}`
                    }
                  ]
                };
              }
            }
          }),
          FormlyUtil.createRow([
            FormlyUtil.createTable(
              {
                key: 'students',
                props: {
                  showIndex: true,
                  canAdd: () => false,
                  canRemove: () => false,
                  hideLabel: true,
                  columns: [
                    {
                      key: 'full_name',
                      header: 'name'
                    },
                    {
                      key: 'status',
                      header: 'status'
                    },
                    ...(this.tenantService.canCrudCoursePrice
                      ? [
                          {
                            key: 'course_price',
                            header: 'course_price'
                          }
                        ]
                      : [])
                  ]
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    return field.options?.fieldChanges?.pipe(
                      filter(
                        event => event.type === 'valueChanges' && event.field.key === 'school_id'
                      ),
                      tap(event => {
                        field.formControl?.setValue([]);
                      })
                    );
                  }
                }
              },
              [
                {
                  key: 'id'
                },
                {
                  key: 'person_id'
                },
                FormlyUtil.createLabelField('full_name', {
                  props: {
                    label: 'name',
                    disableMargin: true,
                    hideLabel: true,
                    labelClass: 'font-normal'
                  }
                }),
                FormlyUtil.createSelectField('status', {
                  defaultValue: 'active',
                  props: {
                    required: true,
                    disableMargin: true,
                    hideLabel: true,
                    options: Object.values(AcademyCourseStudentStatusEnum).map(value => ({
                      label: 'status.' + value,
                      value: value
                    }))
                  }
                }),
                ...(this.tenantService.canCrudCoursePrice
                  ? [
                      FormlyUtil.createNumberField('course_price', {
                        props: {
                          disableMargin: true,
                          hideLabel: true,
                          maxFractionDigits: 2,
                          iconPre: 'pi pi-euro',
                          min: 0
                        }
                      })
                    ]
                  : [])
              ]
            )
          ]),
          // TODO:
          // FormlyUtil.createRow([
          //   FormlyUtil.createSelectField('parent_course_id', {
          //     props: {
          //       label: 'parent_course',
          //       options: this.apollo
          //         .query<{
          //           result: AcademyCourseModel[];
          //         }>({
          //           query: gql`
          //             query ReadAcademyCourses {
          //               result: academy_course(order_by: { name: asc }) {
          //                 id
          //                 name
          //               }
          //             }
          //           `
          //         })
          //         .pipe(
          //           map(queryResult =>
          //             queryResult.data.result.map(course => ({
          //               label: course.name,
          //               value: course.id
          //             }))
          //           )
          //         )
          //     }
          //   })
          // ]),
          ...(this.tenantService.canCrudCoursePrice
            ? [this.formlyService.createCoursePriceFieldConfig({ key: 'course_course_price' })]
            : [])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,
        room_id: formSaveModel.input.room?.id,
        course_students: {
          data: formSaveModel.input.students?.map((students: any) => ({
            person_id: students.person_id,
            status: students.status,
            ...(this.tenantService.canCrudCoursePrice
              ? {
                  course_price: students.course_price
                    ? Math.round(students.course_price * 100)
                    : null
                }
              : {})
          }))
        },
        course_teachers: {
          data: formSaveModel.input.teachers?.map((teacher: any) => ({
            person_id: teacher.person_id,
            teaching_role: teacher.teaching_role
          }))
        },
        ...(this.tenantService.canCrudCoursePrice
          ? {
              course_course_price: {
                data: {
                  single_price: formSaveModel.input.course_course_price?.single_price
                    ? Math.round(formSaveModel.input.course_course_price.single_price * 100)
                    : null,
                  dual_price: formSaveModel.input.course_course_price?.dual_price
                    ? Math.round(formSaveModel.input.course_course_price.dual_price * 100)
                    : null,
                  three_or_more_price: formSaveModel.input.course_course_price?.three_or_more_price
                    ? Math.round(formSaveModel.input.course_course_price.three_or_more_price * 100)
                    : null
                }
              }
            }
          : {})
      };

      ModelUtil.deleteKey(input, 'organisation_id');
      ModelUtil.deleteKey(input, 'school');
      ModelUtil.deleteKey(input, 'room');
      ModelUtil.deleteKey(input, 'student_select');
      ModelUtil.deleteKey(input, 'students');
      ModelUtil.deleteKey(input, 'teacher_select');
      ModelUtil.deleteKey(input, 'teachers');

      let options: any = {
        mutation: gql`
          mutation CreateAcademyCourse($input: academy_course_insert_input!) {
            result: insert_academy_course_one(object: $input) {
              __typename
            }
          }
        `,
        variables: {
          input
        }
      };

      if (this.model?.id) {
        ModelUtil.deleteKey(input);
        ModelUtil.deleteKey(input, 'course_students');
        ModelUtil.deleteKey(input, 'course_teachers');

        const newTeachers = PersonUtil.getNewPerson(
          formSaveModel.input.teachers,
          this.readOnlyModel.course_teachers,
          true
        ).map(item => ({
          course_id: this.model.id,
          person_id: item.person_id,
          teaching_role: item.teaching_role
        }));

        const updateTeachers: any[] = PersonUtil.getDeletedPerson(
          this.readOnlyModel.course_teachers,
          formSaveModel.input.teachers
        );

        // Get the updated teacher roles
        // Compare teachers and the teaching roles
        updateTeachers.push(
          ...(this.readOnlyModel.course_teachers
            ?.map((item: any) => {
              const teacher = formSaveModel.input.teachers?.find(
                (teacher: any) => teacher.person_id === item.person_id
              );
              if (teacher && teacher.teaching_role !== item.teaching_role) {
                return {
                  where: {
                    id: { _eq: item.id }
                  },
                  _set: {
                    teaching_role: teacher.teaching_role
                  }
                };
              }

              return undefined;
            })
            .filter((item: any) => !!item) || [])
        );

        const newStudents = PersonUtil.getNewPerson(
          formSaveModel.input.students,
          this.readOnlyModel.course_students,
          true
        ).map(item => ({
          course_id: this.model.id,
          person_id: item.person_id,
          status: item.status,
          ...(this.tenantService.canCrudCoursePrice
            ? {
                course_price: item.course_price ? Math.round(item.course_price * 100) : null
              }
            : {})
        }));

        // Course Students Table is a bidirectional table, that means changes are a new record
        this.readOnlyModel.course_students
          ?.map((item: any) => {
            const student = formSaveModel.input.students?.find(
              (student: any) => student.person_id === item.person_id
            );

            if (
              student &&
              (student.status !== item.status ||
                (this.tenantService.canCrudCoursePrice &&
                  student.course_price != item.course_price))
            ) {
              return {
                course_id: this.model.id,
                person_id: student.person_id,
                status: student.status,
                ...(this.tenantService.canCrudCoursePrice
                  ? {
                      course_price: student.course_price
                        ? Math.round(student.course_price * 100)
                        : null
                    }
                  : {})
              };
            }
            return undefined;
          })
          .forEach(item => {
            if (item) {
              newStudents.push(item);
            }
          });

        const newCoursePrices = [];
        let coursePriceFilter = undefined;
        let coursePriceInput = undefined;

        if (this.tenantService.canCrudCoursePrice) {
          if (!this.model.course_course_price?.id) {
            newCoursePrices.push({
              course_id: this.model.id,
              ...input.course_course_price?.data
            });
          } else {
            coursePriceFilter = { id: { _eq: this.model.course_course_price?.id } };
            coursePriceInput = input.course_course_price?.data;
          }
        }

        ModelUtil.deleteKey(input, 'course_course_price');

        options = {
          mutation: gql`
            mutation UpdateAcademyCourse(
              $id: bigint!
              $input: academy_course_set_input!
              $newTeachers: [academy_course_teacher_insert_input!] = []
              $updateTeachers: [academy_course_teacher_updates!] = []
              $newStudents: [academy_course_student_insert_input!] = []
              $newCoursePrices: [academy_course_course_price_insert_input!] = []
              $coursePriceFilter: academy_course_course_price_bool_exp = {}
              $coursePriceInput: academy_course_course_price_set_input = {}
            ) {
              result: update_academy_course_by_pk(pk_columns: { id: $id }, _set: $input) {
                __typename
              }
              insert_academy_course_teacher(objects: $newTeachers) {
                __typename
              }
              update_academy_course_teacher_many(updates: $updateTeachers) {
                __typename
              }
              insert_academy_course_student(objects: $newStudents) {
                __typename
              }
              insert_academy_course_course_price(objects: $newCoursePrices) {
                __typename
              }
              update_academy_course_course_price(
                where: $coursePriceFilter
                _set: $coursePriceInput
              ) {
                __typename
              }
            }
          `,
          variables: {
            id: this.model.id,
            input,
            newTeachers,
            updateTeachers,
            newStudents,
            newCoursePrices,
            coursePriceFilter,
            coursePriceInput
          }
        };
      }

      return this.apollo.mutate(options);
    };
  }
}
