import { Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { MenuService } from '~ngx-shared/layout';
import { LayoutMenuitemComponent } from './layout-menuitem.component';

@Component({
  selector: 'lib-layout-menu',
  templateUrl: './layout-menu.component.html',
  standalone: true,
  imports: [LayoutMenuitemComponent, AsyncPipe]
})
export class LayoutMenuComponent {
  constructor(public menuService: MenuService) {}
}
