import { Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';
import {
  localStorageStrategy,
  persistState,
  sessionStorageStrategy
} from '@ngneat/elf-persist-state';

@Injectable({ providedIn: 'root' })
export class StoreService {
  private readonly _persistence: any[] = [];

  createStore<T>(config: {
    name: string;
    initial?: T;
    persist?: { storage: 'local' | 'session' };
  }) {
    const store = createStore({ name: config.name }, withProps<T>(config.initial ?? ({} as T)));
    if (config.persist) {
      this._persistence.push(
        persistState(store, {
          key: config.name,
          storage: config.persist.storage == 'local' ? localStorageStrategy : sessionStorageStrategy
        })
      );
    }
    return store;
  }
}
