import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';

export enum NavigateEnums {
  NOT_FOUND = 'NOT_FOUND',
  ERROR = 'ERROR',
  NO_PERMISSION = 'NO_PERMISSION'
}

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];

  constructor(
    private router: Router,
    private location: Location
  ) {}

  init(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const indexOfQueryStart = event.urlAfterRedirects.indexOf('?');
        let canAdd = true;
        if (indexOfQueryStart > -1) {
          const item = this.history[this.history.length - 1];
          canAdd = item !== event.urlAfterRedirects.substring(0, indexOfQueryStart);
        }
        if (canAdd) {
          this.history.push(event.urlAfterRedirects);
          if (this.history.length > 20) {
            this.history.shift();
          }
        }
      }
    });
  }

  back(commands: string[] = ['..']): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      void this.router.navigate(commands);
    }
  }

  navigateTo(commands: any[], extras?: NavigationExtras | undefined): void {
    void this.router.navigate(commands, extras);
  }

  navigateToStatic(navigate: NavigateEnums): void {
    switch (navigate) {
      case NavigateEnums.NOT_FOUND:
        void this.router.navigate(['/not-found']);
        break;
      case NavigateEnums.ERROR:
        void this.router.navigate(['/error']);
        break;
      case NavigateEnums.NO_PERMISSION:
        void this.router.navigate(['/no-permission']);
        break;
    }
  }
}
