<p-table
  #table
  (onLazyLoad)="this.loadData($event)"
  [alwaysShowPaginator]="this.tableData.length > 0"
  [lazy]="this.isLazy"
  [loading]="this.isLoading"
  [paginator]="this.isPaginated"
  [rowsPerPageOptions]="[10, 25, 50]"
  [rows]="this.limit"
  [showCurrentPageReport]="this.tableData.length > 0 && this.showCurrentPageReport"
  [totalRecords]="this.totalRecords"
  [value]="this.tableData"
  [stateStorage]="this.stateStorage"
  [stateKey]="this.stateKey"
  currentPageReportTemplate="{{ 'components.graph_ql_table.table_report_template' | transloco }}"
  selectionMode="single"
  sortMode="multiple"
  styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm">
  <ng-template pTemplate="caption">
    <div class="flex items-center justify-between">
      <ng-container [ngTemplateOutlet]="this.captionTemplate"></ng-container>
      @if (!this.captionTemplate) {
        <div></div>
      }
      <div>
        <button (click)="this.refresh()" class="refresh-button" pButton type="button">
          <i class="pi pi-refresh"></i>
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <ng-container [ngTemplateOutlet]="this.headerTemplate"> </ng-container>
  </ng-template>
  <ng-template let-data let-rowIndex="rowIndex" pTemplate="body">
    <ng-container
      [ngTemplateOutletContext]="{ $implicit: data, rowIndex: rowIndex }"
      [ngTemplateOutlet]="this.bodyTemplate">
    </ng-container>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    @if (this.emptyTemplate) {
      <ng-container [ngTemplateOutlet]="this.emptyTemplate"> </ng-container>
    } @else {
      <tr>
        <td colspan="100%">
          <div class="flex flex-col items-center justify-center">
            <span>
              {{ 'no_data' | transloco }}
            </span>
          </div>
        </td>
      </tr>
    }
  </ng-template>

  @if (this.footerTemplate) {
    <ng-template pTemplate="summary">
      <ng-container [ngTemplateOutlet]="this.footerTemplate"></ng-container>
    </ng-template>
  }
</p-table>
