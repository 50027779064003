import { Component, computed, effect, inject, input, signal } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ButtonDirective } from 'primeng/button';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { BusyComponent } from '~ngx-shared/layout';
import { AcademyCourseModel, AcademyCoursePermission } from '~ngx-shared/models';
import { AuthorizationService, Role } from '~ngx-shared/authentication';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';

@Component({
  selector: 'app-course-detail',
  standalone: true,
  imports: [
    DetailComponent,
    BusyComponent,
    NgxPermissionsModule,
    ButtonDirective,
    RouterLink,
    TranslocoDirective
  ],
  templateUrl: './course-detail.component.html',
  styleUrl: './course-detail.component.scss'
})
export class CourseDetailComponent {
  readonly id = input.required<number | undefined>();

  readonly apollo = inject(Apollo);
  readonly authorizationService = inject(AuthorizationService);

  readonly item = signal<AcademyCourseModel | undefined>(undefined);

  readonly fields = computed<DetailField[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    const fields: DetailField[] = [
      {
        label: 'id',
        value: item.id
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'created_by',
        type: 'person_link',
        value: item.created_by_current_person_data
      },
      {
        label: 'changed_by',
        type: 'person_link',
        value: item.changed_by_current_person_data
      },
      {
        label: 'school_period',
        value: item.school_period?.name
      },
      {
        label: 'course_type',
        value: item.course_type?.name
      },
      {
        label: 'school',
        value: item.school?.name
      },
      // {
      //   label: 'parent_course_id',
      //   value: item.parent_course_id
      // },
      {
        label: 'units',
        value: item.units_aggregate?.aggregate?.count
      },
      {
        label: 'students',
        value: item.course_students_aggregate?.aggregate?.count
      },
      {
        label: 'teachers',
        value: item.course_teachers_aggregate?.aggregate?.count
      },
      {
        label: 'organisation',
        value: item.school?.organisation?.name
      },
      {
        label: 'name',
        value: item.name || item.course_name?.name
      },
      {
        label: 'description',
        value: item.description
      },
      {
        label: 'is_online',
        type: 'boolean',
        value: item.is_online
      },
      {
        label: 'address',
        type: 'address',
        value: item.address
      }
    ];

    if (!this.authorizationService.can(Role.TEACHER)) {
      fields.push(
        {
          label: 'course_price.single_price',
          type: 'currency',
          value: item.course_course_price?.single_price
        },
        {
          label: 'course_price.dual_price',
          type: 'currency',
          value: item.course_course_price?.dual_price
        },
        {
          label: 'course_price.three_or_more_price',
          type: 'currency',
          value: item.course_course_price?.three_or_more_price
        }
      );
    }

    return fields;
  });

  protected readonly AcademyCoursePermission = AcademyCoursePermission;

  constructor() {
    effect(() => {
      this.apollo
        .query<{ result: AcademyCourseModel }>({
          query: gql`
                query ReadAcademyCourseById($id: bigint!) {
                  result: academy_course_by_pk(id: $id) {
                    id
                    created_at
                    updated_at

                    created_by_current_person_data {
                      person_id
                      academic_degree_prefix
                      academic_degree_suffix
                      first_name
                      last_name
                    }

                    changed_by_current_person_data {
                      person_id
                      academic_degree_prefix
                      academic_degree_suffix
                      first_name
                      last_name
                    }

                    school_period {
                      name
                    }
                    course_type {
                      name
                    }
                    parent_course_id
                    school {
                      name
                      organisation {
                        name
                      }
                    }
                    units_aggregate {
                      aggregate {
                        count
                      }
                    }
                    course_students_aggregate: latest_course_students_active_aggregate {
                      aggregate {
                        count
                      }
                    }
                    course_teachers_aggregate {
                      aggregate {
                        count
                      }
                    }
                    name
                    course_name {
                      name
                    }
                    description
                    is_online
                    address

                    ${
                      !this.authorizationService.can(Role.TEACHER)
                        ? `
                    course_course_price {
                      single_price
                      dual_price
                      three_or_more_price
                    }
                           `
                        : ''
                    }
                  }
                }
              `,
          variables: {
            id: this.id()
          }
        })
        .subscribe(queryResult => {
          this.item.set(queryResult.data?.result);
        });
    });
  }
}
