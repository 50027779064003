<lib-layout-base-page *transloco="let transloco">
  <div class="flex flex-col">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('general_information') }}">
        <ng-template pTemplate="content">
          <div class="mb-2 flex w-full flex-col justify-between gap-4 md:flex-row">
            @let profile_picture_url = this.personData()?.['profile_picture_url'];
            @if (profile_picture_url) {
              <div
                class="flex max-h-96 max-w-96 items-center justify-center rounded-lg border bg-gray-200">
                <img
                  class="h-auto w-auto object-cover object-center"
                  [src]="profile_picture_url | httpImgSrc"
                  alt="Profile photo" />
              </div>
            }
            <div>
              <a
                [label]="transloco('edit')"
                [routerLink]="['/', 'staff', 'settings']"
                class=""
                icon="pi pi-pencil"
                pButton
                type="button"></a>
            </div>
          </div>

          <app-person-detail
            (personData)="this.personData.set($event)"
            [personId]="this.personId()"
            [showPersonLink]="false"></app-person-detail>
        </ng-template>
      </p-tabPanel>

      @if (this.personData()?.latest_student?.id) {
        <p-tabPanel header="{{ transloco('student_information') }}">
          <ng-template pTemplate="content">
            <app-student-detail [personData]="this.personData()"></app-student-detail>
          </ng-template>
        </p-tabPanel>
      }

      @if (this.personData()?.latest_parent?.id) {
        <p-tabPanel header="{{ transloco('parent_information') }}">
          <ng-template pTemplate="content">
            <app-parent-detail [personData]="this.personData()"></app-parent-detail>
          </ng-template>
        </p-tabPanel>
      }

      @if (this.personData()?.latest_teacher?.id) {
        <p-tabPanel header="{{ transloco('teacher_information') }}">
          <ng-template pTemplate="content">
            <app-teacher-detail [personData]="this.personData()"></app-teacher-detail>
          </ng-template>
        </p-tabPanel>
      }

      @if (this.personData()?.latest_director?.id) {
        <p-tabPanel header="{{ transloco('director_information') }}">
          <ng-template pTemplate="content">
            <app-director-detail [personData]="this.personData()"></app-director-detail>
          </ng-template>
        </p-tabPanel>
      }

      @if (this.personData()?.latest_organisator?.id) {
        <p-tabPanel header="{{ transloco('organisator_information') }}">
          <ng-template pTemplate="content">
            <app-organisator-detail [personData]="this.personData()"></app-organisator-detail>
          </ng-template>
        </p-tabPanel>
      }

      <p-tabPanel header="{{ transloco('documents') }}">
        <ng-template pTemplate="content">
          <app-person-document-list
            [personId]="this.personId()"
            [showColumnFilter]="false"
            [showFilter]="true"
            [showSort]="true"
            [stateKey]="'profile-page-person-document-list'"></app-person-document-list>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</lib-layout-base-page>
