import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface ColorPickerProps extends FieldWrapperProps {
  inline?: boolean;
}

export interface ColorPickerFieldConfig extends FormlyFieldConfig<ColorPickerProps> {
  type?: 'color-picker' | Type<ColorTypeComponent>;
}

@Component({
  selector: 'lib-formly-color-type',
  templateUrl: './color-type.component.html',
  styleUrl: './color-type.component.css'
})
export class ColorTypeComponent extends FieldType<FieldTypeConfig<ColorPickerProps>> {}
