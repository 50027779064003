<lib-layout-base-page *transloco="let transloco" [titleParamValue]="this.item()?.name">
  <div class="flex flex-col">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('general_information') }}">
        <ng-template pTemplate="content">
          <lib-detail [fields]="this.fields()"></lib-detail>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</lib-layout-base-page>
