import { Component } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-page-not-found-page',
  standalone: true,
  imports: [TranslocoDirective],
  templateUrl: './page-not-found-page.component.html',
  styleUrl: './page-not-found-page.component.scss'
})
export class PageNotFoundPageComponent {}
