<p-toast></p-toast>

<p-toast
  key="new_version_available"
  position="top-right"
  preventDuplicates="true"
  preventOpenDuplicates="true">
  <ng-template let-message pTemplate="message">
    <div *transloco="let transloco" class="flex items-center gap-4">
      <div class="">
        <i class="pi pi-info-circle !text-3xl"></i>
      </div>
      <div class="space-y-2">
        <div class="text-xl font-semibold">{{ transloco('overlay.version.new_available') }}</div>
        <button
          pButton
          (click)="this.versionService.applyUpdate()"
          [label]="transloco(message.summary)"></button>
      </div>
    </div>
  </ng-template>
</p-toast>

<p-confirmDialog
  [baseZIndex]="10000"
  [style]="{ minWidth: '400px', width: '35vw' }"
  key="dialog"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-confirmPopup key="popup"></p-confirmPopup>

<div @enter [ngClass]="containerClass" class="layout-wrapper">
  <lib-layout-topbar></lib-layout-topbar>
  <div class="layout-sidebar flex flex-col justify-between">
    <lib-layout-sidebar></lib-layout-sidebar>

    <div class="-ml-10 flex-col pr-2 text-center">
      <span class="text-sm font-light text-gray-500">Version {{ this.layoutService.version }}</span>
    </div>
  </div>
  <div class="layout-main-container">
    @if (this.breadcrumbService.breadcrumbs()?.length) {
      <p-breadcrumb
        [home]="this.home"
        [model]="this.breadcrumbService.breadcrumbs() || []"
        class="">
        <ng-template pTemplate="item" let-item>
          <ng-container *ngIf="item.routerLink; else elseBlock">
            <a [routerLink]="item.routerLink" class="p-menuitem-link">
              @if (item.icon) {
                <span [ngClass]="[item.icon ? item.icon : '', 'text-color', 'mr-1']"></span>
              }
              <span class="text-primary">{{ item.label }}</span>
            </a>
          </ng-container>
          <ng-template #elseBlock>
            <a [href]="item.url">
              <span class="text-color">{{ item.label }}</span>
            </a>
          </ng-template>
        </ng-template>
      </p-breadcrumb>
    }

    <lib-layout-main
      [ngClass]="{
        'flex flex-col': this.loadingService.isLoading()
      }"
      class="layout-main">
      <router-outlet></router-outlet>
    </lib-layout-main>

    <!--    <lib-layout-footer></lib-layout-footer>-->
  </div>
  <div class="layout-mask"></div>
</div>
