import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoadingService } from '~ngx-shared/layout';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'lib-layout-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.css',
  imports: [SpinnerComponent, RouterOutlet],
  standalone: true
})
export class MainComponent {
  constructor(public loadingService: LoadingService) {}
}
