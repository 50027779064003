import { Component } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { BasePageComponent } from '~ngx-shared/layout';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { CourseStudentStatusEvaluationComponent } from '~madrasa/academy/components/course-student-status-evaluation/course-student-status-evaluation.component';

@Component({
  selector: 'app-course-evaluations-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective,
    CourseStudentStatusEvaluationComponent
  ],
  templateUrl: './course-evaluations-page.component.html',
  styleUrl: './course-evaluations-page.component.scss'
})
export class CourseEvaluationsPageComponent {}
