import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface TextAreaProps extends FieldWrapperProps {
  rows?: number;
  cols?: number;
}

export interface TextAreaFieldConfig extends FormlyFieldConfig<TextAreaProps> {
  type?: 'textarea' | Type<TextAreaTypeComponent>;
}

@Component({
  selector: 'lib-text-area-type',
  templateUrl: './text-area-type.component.html',
  styleUrl: './text-area-type.component.css'
})
export class TextAreaTypeComponent extends FieldType<FieldTypeConfig<TextAreaProps>> {}
