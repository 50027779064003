<lib-layout-base-page *transloco="let transloco" [showBackButton]="false">
  <div class="space-y-8">
    <div *ngxPermissionsExcept="this.Role.TEACHER" class="flex flex-col gap-8 md:flex-row">
      <a
        [label]="
          transloco('madrasa.components.staff.new_registration_wizard.new_registration') | uppercase
        "
        [outlined]="true"
        [raised]="true"
        [routerLink]="['/', 'staff', 'new-registration']"
        class="p-button-primary bg-white p-8 text-xl text-primary hover:cursor-pointer hover:bg-primary hover:text-white"
        icon="pi pi-plus"
        pButton
        type="button"></a>
    </div>
    <div class="flex flex-col gap-8">
      @for (rootItem of this.menuService.sideBarItems$ | async; track rootItem) {
        @if (rootItem.label !== 'Home') {
          <div *ngxPermissionsOnly="rootItem.state?.['permissions']" class="text-2xl">
            {{ transloco(rootItem.label || '') | uppercase }}
          </div>
          <div
            *ngxPermissionsOnly="rootItem.state?.['permissions']"
            class="flex flex-col gap-4 md:flex-row md:flex-wrap">
            @for (child of rootItem.items; track child; let i = $index) {
              @if (child.routerLink && !child.items && child.visible !== false) {
                <ng-container
                  [ngTemplateOutletContext]="{ $implicit: child }"
                  [ngTemplateOutlet]="template">
                </ng-container>
              }
            }
          </div>
        }
      }
    </div>

    <div *ngxPermissionsExcept="Role.TEACHER">
      <div class="flex items-center gap-2">
        <div class="text-2xl">{{ transloco('school_period') | uppercase }}</div>
        <div class="p-fluid">
          <p-dropdown
            [disabled]="this.isLoading()"
            [formControl]="this.schoolPeriodFormControl"
            [options]="this.schoolPeriods()"
            [placeholder]="'&nbsp;'"
            appendTo="body"
            optionValue="id">
            <ng-template let-item pTemplate="item">
              <span>{{ item.name }}</span>
            </ng-template>
            <ng-template let-selectedItem pTemplate="selectedItem">
              <span>{{ selectedItem.name }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </div>

      <div class="mt-6 w-full overflow-x-auto">
        <app-statistic-overall-table
          [schoolPeriodId]="this.schoolPeriodFormControl.value"></app-statistic-overall-table>
      </div>
    </div>
  </div>

  <ng-template #template let-child>
    <a
      *ngxPermissionsOnly="child.state?.['permissions']"
      [attr.target]="child.target"
      [fragment]="child.fragment"
      [ngClass]="child.styleClass"
      [preserveFragment]="child.preserveFragment"
      [queryParamsHandling]="child.queryParamsHandling"
      [queryParams]="child.queryParams"
      [raised]="true"
      [replaceUrl]="child.replaceUrl"
      [routerLinkActiveOptions]="
        child.routerLinkActiveOptions || {
          paths: 'subset',
          queryParams: 'ignored',
          matrixParams: 'ignored',
          fragment: 'ignored'
        }
      "
      [routerLink]="child.routerLink"
      [skipLocationChange]="child.skipLocationChange"
      [state]="child.state"
      class="p-button-primary bg-white p-8 text-xl text-primary hover:cursor-pointer hover:bg-primary hover:text-white"
      pButton
      pRipple
      routerLinkActive="active-route"
      tabindex="0"
      type="button">
      @if (child.icon) {
        <span [ngClass]="[child.icon, '!text-xl', 'mr-2']"></span>
      }
      <span>{{ transloco(child.label || '') | uppercase }}</span>
    </a>
  </ng-template>
</lib-layout-base-page>
