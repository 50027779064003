import { Component, OnInit, signal } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslocoDirective } from '@jsverse/transloco';
import { TabMenuModule } from 'primeng/tabmenu';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MenuModule } from 'primeng/menu';
import { ProfileFormComponent } from '~madrasa/staff/components/profile-form/profile-form.component';
import { TemplateDirective } from '~ngx-shared/directives';
import { BasePageComponent } from '~ngx-shared/layout';
import { AccessComponent } from '~madrasa/access/components/access/access.component';
import { ListTabMenuComponent } from '~madrasa/core/components/list-tab-menu/list-tab-menu.component';

@UntilDestroy()
@Component({
  selector: 'app-settings-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    ProfileFormComponent,
    TabMenuModule,
    AccessComponent,
    MenuModule,
    ListTabMenuComponent,
    TemplateDirective
  ],
  templateUrl: './settings-page.component.html',
  styleUrl: './settings-page.component.scss'
})
export class SettingsPageComponent implements OnInit {
  readonly items = signal<MenuItem[]>([]);

  ngOnInit(): void {
    this.items.set([
      {
        id: 'profile',
        icon: 'pi pi-user'
      },
      {
        id: 'access',
        icon: 'pi pi-lock'
      }
    ]);
  }
}
