import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { catchError, delay, map, Observable, of } from 'rxjs';
import { environment } from '~ngx-shared/environment';
import { ToastService } from '~ngx-shared/layout';
import { TenantService } from '~madrasa/services/index';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SchedulerService {
  private readonly externalServiceUrl = environment.externalServiceUrl;

  constructor(
    private httpClient: HttpClient,
    private toastService: ToastService,
    private tenantService: TenantService
  ) {}

  triggerClassBook(): Observable<boolean> {
    return this._createPost(`classbook`, {});
  }

  private _createPost(url: string, data: any): Observable<boolean> {
    return this._observablePipe(
      this.httpClient.post(`${this.externalServiceUrl}/scheduler/${url}`, data, {
        headers: {
          'x-hasura-tenant-id': String(this.tenantService.currentTenant?.id)
        }
      })
    );
  }

  private _observablePipe(
    observable: Observable<any>,
    withToast: boolean = true
  ): Observable<boolean> {
    return observable.pipe(
      delay(500),
      map(() => {
        if (withToast) {
          this.toastService.toastSuccessSave();
        }
        return true;
      }),
      catchError(err => {
        if (withToast) {
          this.toastService.toastErrorSave();
        }
        console.error(err);
        return of(false);
      })
    );
  }
}
