import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { FORMLY_CONFIG, FormlyModule as NgxFormly } from '@ngx-formly/core';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { BadgeModule } from 'primeng/badge';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ColorPickerModule } from 'primeng/colorpicker';
import { QuillModule } from 'ngx-quill';
import { SpinnerComponent } from '~ngx-shared/layout';
import { FileComponent } from '~ngx-shared/ui/file/file.component';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { registerFormlyTranslocoExtension } from './extensions/formly-transloco.extension';
import { FieldWrapperComponent } from './field-wrapper/field-wrapper.component';
import { TextTypeComponent } from './text-type/text-type.component';
import { FormComponent } from './form/form.component';
import { CheckboxTypeComponent } from './checkbox-type/checkbox-type.component';
import { DateTypeComponent } from './date-type/date-type.component';
import { SelectTypeComponent } from './select-type/select-type.component';
import { NumberTypeComponent } from './number-type/number-type.component';
import { GroupWrapperComponent } from './group-wrapper/group-wrapper.component';
import { MultiSelectTypeComponent } from './multi-select-type/multi-select-type.component';
import { RepeatTypeComponent } from './repeat-type/repeat-type.component';
import { AutoCompleteTypeComponent } from './auto-complete-type/auto-complete-type.component';
import { TextAreaTypeComponent } from './text-area-type/text-area-type.component';
import { TableTypeComponent } from './table-type/table-type.component';
import { AutoCompleteSelectTypeComponent } from './auto-complete-select-type/auto-complete-select-type.component';
import { LabelTypeComponent } from './label-type/label-type.component';
import { FileTypeComponent } from './file-type/file-type.component';
import { TabTypeComponent } from './tab-type/tab-type.component';
import { ToggleTypeComponent } from './toggle-type/toggle-type.component';
import { InputMaskTypeComponent } from './input-mask-type/input-mask-type.component';
import { ColorTypeComponent } from './color-type/color-type.component';
import { EditorTypeComponent } from './editor-type/editor-type.component';
import { HtmlViewerComponent } from './html-viewer/html-viewer.component';

@NgModule({
  declarations: [
    FormComponent,
    FieldWrapperComponent,
    TextTypeComponent,
    CheckboxTypeComponent,
    DateTypeComponent,
    SelectTypeComponent,
    NumberTypeComponent,
    GroupWrapperComponent,
    MultiSelectTypeComponent,
    RepeatTypeComponent,
    AutoCompleteTypeComponent,
    TextAreaTypeComponent,
    TableTypeComponent,
    AutoCompleteSelectTypeComponent,
    LabelTypeComponent,
    FileTypeComponent,
    TabTypeComponent,
    ToggleTypeComponent,
    InputMaskTypeComponent,
    ColorTypeComponent,
    EditorTypeComponent,
    HtmlViewerComponent
  ],
  exports: [FormComponent, HtmlViewerComponent],
  imports: [
    CommonModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    NgxFormly,
    TranslocoModule,
    SpinnerComponent,
    ButtonModule,
    InputTextModule,
    CheckboxModule,
    CalendarModule,
    DropdownModule,
    FormlySelectModule,
    InputNumberModule,
    MultiSelectModule,
    AutoCompleteModule,
    InputTextareaModule,
    TableModule,
    FileComponent,
    TabViewModule,
    BadgeModule,
    TabViewQueryDirective,
    InputMaskModule,
    InputSwitchModule,
    ColorPickerModule
  ]
})
export class FormlyModule {
  static forRoot(): ModuleWithProviders<NgxFormly> {
    return NgxFormly.forRoot({
      types: [
        { name: 'tab', component: TabTypeComponent },
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'table', component: TableTypeComponent },
        { name: 'label', component: LabelTypeComponent },
        {
          name: 'text',
          component: TextTypeComponent,
          wrappers: ['field-wrapper']
        },
        {
          name: 'number',
          component: NumberTypeComponent,
          wrappers: ['field-wrapper']
        },
        {
          name: 'select',
          component: SelectTypeComponent,
          wrappers: ['field-wrapper']
        },
        {
          name: 'multi-select',
          component: MultiSelectTypeComponent,
          wrappers: ['field-wrapper']
        },
        {
          name: 'date-picker',
          component: DateTypeComponent,
          wrappers: ['field-wrapper']
        },
        {
          name: 'input-mask',
          component: InputMaskTypeComponent,
          wrappers: ['field-wrapper']
        },
        {
          name: 'autocomplete',
          component: AutoCompleteTypeComponent,
          wrappers: ['field-wrapper']
        },
        {
          name: 'autocomplete-select',
          component: AutoCompleteSelectTypeComponent,
          wrappers: ['field-wrapper']
        },
        {
          name: 'file',
          component: FileTypeComponent,
          wrappers: ['field-wrapper']
        },
        {
          name: 'textarea',
          component: TextAreaTypeComponent,
          wrappers: ['field-wrapper']
        },
        {
          name: 'color-picker',
          component: ColorTypeComponent,
          wrappers: ['field-wrapper']
        },
        {
          name: 'checkbox',
          component: CheckboxTypeComponent,
          wrappers: ['field-wrapper']
        },
        {
          name: 'toggle',
          component: ToggleTypeComponent,
          wrappers: ['field-wrapper']
        },
        {
          name: 'editor',
          component: EditorTypeComponent,
          wrappers: ['field-wrapper']
        }
      ],
      wrappers: [
        {
          name: 'field-wrapper',
          component: FieldWrapperComponent
        },
        {
          name: 'group-wrapper',
          component: GroupWrapperComponent
        }
      ]
    });
  }

  static forRootProvider(): Provider[] {
    return [
      {
        provide: FORMLY_CONFIG,
        multi: true,
        useFactory: registerFormlyTranslocoExtension,
        deps: [TranslocoService]
      }
    ];
  }
}
