import { Role } from '~ngx-shared/authentication';
import { DefaultProperties } from '../default.model';
import { StaffEducationPathModel } from './education-path.model';

export interface StaffEducationTypeModel extends DefaultProperties {
  // Columns
  id?: number;
  name?: string;
  description?: string;
  rank?: number;

  // Relations
  education_paths?: StaffEducationPathModel[];
}

export class StaffEducationTypePermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...StaffEducationTypePermission.DELETE, Role.MANAGER];
}
