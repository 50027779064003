import { Component, OnInit, signal } from '@angular/core';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo, gql } from 'apollo-angular';
import { combineLatest, distinctUntilChanged, Subscription } from 'rxjs';
import { BadgeModule } from 'primeng/badge';
import { NgClass } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { RouterLink } from '@angular/router';
import { UserService } from '~madrasa/services';
import { AuthorizationService, Role } from '~ngx-shared/authentication';
import { AccessUserModel, CorePersonDataModel } from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';
import { DateTimePipe } from '~ngx-shared/pipes';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'app-currently-online',
  standalone: true,
  imports: [
    BadgeModule,
    NgClass,
    OverlayPanelModule,
    TableModule,
    TranslocoDirective,
    DateTimePipe,
    RouterLink
  ],
  templateUrl: './currently-online.component.html',
  styleUrl: './currently-online.component.scss'
})
export class CurrentlyOnlineComponent implements OnInit {
  readonly users = signal<AccessUserModel[]>([]);

  subscriptions: Subscription[] = [];

  constructor(
    private apollo: Apollo,
    private userService: UserService,
    private translocoService: TranslocoService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.userService.user$.pipe(untilDestroyed(this), distinctUntilChanged()),
      this.authorizationService.currentRole$.pipe(untilDestroyed(this), distinctUntilChanged())
    ]).subscribe(([user, role]) => {
      this.subscriptions.forEach(s => s.unsubscribe());
      if (
        this.authorizationService.can(Role.LESSOR) ||
        (user && this.authorizationService.can(Role.MANAGER))
      ) {
        this.subscriptions = [];
        this.subscriptions.push(
          this.apollo
            .subscribe<{ users: AccessUserModel[] }>({
              query: gql`
                subscription ReadOnlineUsersCount($personId: bigint!) {
                  users: access_currently_online_view(
                    where: { online_at: { _gte: "now()" }, person_id: { _neq: $personId } }
                    order_by: { last_seen_at: desc_nulls_last }
                  ) {
                    person_id
                    last_seen_at
                    current_person_data {
                      academic_degree_prefix
                      academic_degree_suffix
                      first_name
                      last_name
                    }
                  }
                }
              `,
              variables: {
                personId: user?.person_id || 0
              }
            })
            .pipe(untilDestroyed(this))
            .subscribe(queryResult => {
              this.users.set(queryResult?.data?.users || []);
            })
        );
      }
    });
  }

  getRow(data: any): AccessUserModel | undefined {
    return data;
  }

  getFullName(personDataModel: CorePersonDataModel | undefined) {
    return ModelUtil.getFullName(personDataModel, this.translocoService);
  }
}
