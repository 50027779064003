import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface ToggleProps extends FieldWrapperProps {}

export interface ToggleFieldConfig extends FormlyFieldConfig<ToggleProps> {
  type?: 'toggle' | Type<ToggleTypeComponent>;
}

@Component({
  selector: 'lib-toggle-type',
  templateUrl: './toggle-type.component.html',
  styleUrl: './toggle-type.component.css'
})
export class ToggleTypeComponent extends FieldType<FieldTypeConfig<ToggleProps>> {}
