export enum CoreAcademicDegreesSuffix {
  BA = 'BA',
  B_A = 'B_A',
  BED = 'BEd',
  B_ED = 'B_Ed',
  BSC = 'BSc',
  BTH = 'BTh',
  B_TH = 'B_Th',
  MA = 'MA',
  M_A = 'M_A',
  MED = 'MEd',
  M_ED = 'M_Ed',
  MSC = 'MSc',
  M_SC = 'M_Sc',
  MTH = 'MTh',
  M_TH = 'M_Th',
  LLM = 'LLM',
  LL_M = 'LL_M',
  MAS = 'MAS',
  MBA = 'MBA',
  MPA = 'MPA',
  PHD = 'PhD'
}
