import { isDevMode } from '@angular/core';
import { InMemoryCache, split } from '@apollo/client/core';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { HttpLink } from 'apollo-angular/http';
import { Client, ClientOptions, createClient } from 'graphql-ws';
import { KeycloakService } from 'keycloak-angular';
import { AuthenticationService, AuthorizationService } from '~ngx-shared/authentication';
import { environment } from '~ngx-shared/environment';

interface RestartableClient extends Client {
  restart(): void;
}

function createRestartableClient(options: ClientOptions): RestartableClient {
  let restartRequested = false;
  let restart = () => {
    restartRequested = true;
  };

  const client = createClient({
    ...options,
    on: {
      ...options.on,
      opened: (socket: any) => {
        options.on?.opened?.(socket);

        restart = () => {
          if (socket.readyState === WebSocket.OPEN) {
            // if the socket is still open for the restart, do the restart
            socket.close(4205, 'Client Restart');
          } else {
            // otherwise the socket might've closed, indicate that you want
            // a restart on the next opened event
            restartRequested = true;
          }
        };

        // just in case you were eager to restart
        if (restartRequested) {
          restartRequested = false;
          restart();
        }
      }
    }
  });

  return {
    ...client,
    restart: () => restart()
  };
}

export function apolloOptionsFactory(
  httpLink: HttpLink,
  authenticationService: AuthenticationService,
  authorizationService: AuthorizationService,
  keycloak: KeycloakService
) {
  if (isDevMode()) {
    // Adds messages only in a dev environment
    loadDevMessages();
  }

  loadErrorMessages();

  // Create a http link:
  const http = httpLink.create({
    uri: environment.hasuraUrl
  });

  const client = createRestartableClient({
    url: environment.hasuraWsUrl,
    connectionParams: () =>
      keycloak.getToken().then(token => {
        const headers: any = {
          'x-hasura-role': authorizationService.authorizationStore?.value.currentRole || '',
          authorization: token ? `Bearer ${token}` : ''
        };
        if (authenticationService.authStore.value?.['headers']) {
          Object.keys(authenticationService.authStore.value['headers']).forEach(key => {
            headers[key] = authenticationService.authStore.value[key];
          });
        }
        return { headers };
      })
  });
  const ws = new GraphQLWsLink(client);

  // Add a restart method to ws
  authorizationService.wsRestart = () => client.restart();

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const node = getMainDefinition(query);
      return node.kind === 'OperationDefinition' && node.operation === 'subscription';
    },
    ws,
    http
  );

  return {
    link,
    cache: new InMemoryCache({
      typePolicies: {}
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  };
}
