import {
  Component,
  computed,
  ContentChildren,
  effect,
  input,
  QueryList,
  ViewChild
} from '@angular/core';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { RouterLink } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { map } from 'rxjs';
import { TagModule } from 'primeng/tag';
import { NgTemplateOutlet } from '@angular/common';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { TemplateDirective } from '~ngx-shared/directives';
import { ConfirmationService } from '~ngx-shared/layout';
import { AuthorizationService, Role } from '~ngx-shared/authentication';
import { TenantService } from '~madrasa/services';
import {
  AcademyClassBookEntryModel,
  AcademyClassBookEntryPermission,
  AcademyOrganisationModel,
  AcademySchoolModel
} from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';
import { PersonDetailLinkComponent } from '~madrasa/staff/components/person-detail-link/person-detail-link.component';
import { FormlyUtil } from '~ngx-shared/formly';

@Component({
  selector: 'app-class-book-entry-list',
  standalone: true,
  imports: [
    TranslocoDirective,
    GraphQlAdvancedTableComponent,
    ButtonDirective,
    TooltipModule,
    RouterLink,
    TemplateDirective,
    NgxPermissionsModule,
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    PersonDetailLinkComponent,
    TagModule,
    NgTemplateOutlet
  ],
  templateUrl: './class-book-entry-list.component.html',
  styleUrl: './class-book-entry-list.component.scss'
})
export class ClassBookEntryListComponent {
  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>;

  showFilter = input<boolean>(false);
  showSort = input<boolean>(false);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);
  hideTeacherColumn = input<boolean>(false);

  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();
  columns = input<GraphQlColumnModel[]>([]);

  graphQlTable = computed(() => {
    let columns = this.columns();

    columns.unshift(...this.getDefaultColumns());

    const graphQlTable: GraphQlTableModel = {
      table: 'academy_class_book_entry',
      isPaginated: true,

      showCurrentPageReport: true,
      columns
    };
    return graphQlTable;
  });

  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;
  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  protected readonly AcademyClassBookEntryPermission = AcademyClassBookEntryPermission;
  protected readonly Role = Role;

  constructor(
    private confirmationService: ConfirmationService,
    public authorizationService: AuthorizationService,
    private tenantService: TenantService,
    private apollo: Apollo,
    private translocoService: TranslocoService
  ) {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        // Add default sorting by created_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ date: 'desc' }, { unit: { starts_at: 'desc' } }];
        }

        return options;
      };
    });
  }

  getDefaultColumns(): GraphQlColumnModel[] {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        type: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        sort: { isSortable: true },
        filter: { type: 'number' }
      },
      {
        path: 'is_filled_out',
        type: 'boolean',
        filter: { type: 'boolean' },
        sort: { isSortable: true }
      },
      {
        label: 'organisation',
        path: 'school.organisation.name',
        filter: {
          label: 'organisation',
          path: 'school.organisation_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademyOrganisationModel[];
            }>({
              query: gql`
                query ReadAcademyOrganisation($where: academy_organisation_bool_exp) {
                  result: academy_organisation(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(organisation => ({
                  label: organisation.name,
                  value: organisation.id
                }))
              )
            )
        },
        sort: { isSortable: true }
      },
      {
        label: 'school',
        path: 'school.name',
        filter: {
          label: 'school',
          path: 'school_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademySchoolModel[];
            }>({
              query: gql`
                query ReadAcademySchool($where: academy_school_bool_exp) {
                  result: academy_school(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(school => ({
                  label: school.name,
                  value: school.id
                }))
              )
            )
        },
        sort: { isSortable: false }
      },
      this.tenantService.currentTenant?.are_course_names_forced
        ? {
            label: 'course',
            path: 'course.course_name.name',
            filter: { type: 'string' },
            sort: { isSortable: true }
          }
        : {
            label: 'course',
            path: 'course.name',
            filter: { type: 'string' },
            sort: { isSortable: false }
          },
      {
        path: 'course_helper',
        query: 'course { color }',
        filter: { isNotFilterable: true },
        sort: { isSortable: true },
        isNotExportable: true,
        isNotSelectable: true
      },

      {
        label: 'subject',
        path: 'unit.subject.name',
        filter: { type: 'string' },
        sort: { isSortable: false }
      },
      {
        label: 'starts',
        path: 'unit.starts_at',
        type: 'time',
        filter: { type: 'time' },
        sort: { isSortable: true }
      },
      {
        label: 'ends',
        path: 'unit.ends_at',
        type: 'time',
        filter: { type: 'time' },
        sort: { isSortable: true }
      },
      {
        path: 'unit_helper',
        query: 'unit { color }',
        filter: { isNotFilterable: true },
        sort: { isSortable: true },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        path: 'date',
        type: 'date',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'expires_at',
        type: 'date',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'subject_matter',
        filter: { type: 'string' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'is_online',
        type: 'boolean',
        filter: { type: 'boolean' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        label: 'teachers',
        path: 'class_book_entry_teachers',
        query: `
          class_book_entry_teachers (order_by: { current_person_data: { first_name: asc } }) {
            present
            current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
          }
        `,
        patchResult: value =>
          value?.class_book_entry_teachers
            ?.map(
              (cbeTeacher: any) =>
                `${ModelUtil.getFullName(cbeTeacher.current_person_data, this.translocoService)} - ${this.translocoService.translate('status.' + cbeTeacher.present)}`
            )
            ?.join(', '),
        filter: { isNotFilterable: true },
        hidden: this.hideTeacherColumn()
      },
      {
        label: 'student_present_count',
        query: `student_present_count: class_book_entry_students_aggregate ( where: { status: { _eq: present } } ) { aggregate { count } }`,
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        patchResult: (result: any) => result?.student_present_count?.aggregate?.count,
        hidden: true
      },
      {
        label: 'student_absent_count',
        query: `student_absent_count: class_book_entry_students_aggregate ( where: { status: { _eq: absent } } ) { aggregate { count } }`,
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        patchResult: (result: any) => result?.student_absent_count?.aggregate?.count,
        hidden: true
      },
      {
        label: 'student_excused_count',
        query: `student_excused_count: class_book_entry_students_aggregate ( where: { status: { _eq: excused } } ) { aggregate { count } }`,
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        patchResult: (result: any) => result?.student_excused_count?.aggregate?.count,
        hidden: true
      },
      {
        label: 'teacher_present_count',
        query: `teacher_present_count: class_book_entry_teachers_aggregate ( where: { present: { _eq: true } } ) { aggregate { count } }`,
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        patchResult: (result: any) => result?.teacher_present_count?.aggregate?.count,
        hidden: true
      },
      {
        label: 'teacher_absent_count',
        query: `teacher_absent_count: class_book_entry_teachers_aggregate ( where: { present: { _eq: false } } ) { aggregate { count } }`,
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        patchResult: (result: any) => result?.teacher_absent_count?.aggregate?.count,
        hidden: true
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      }
    ];

    return columns;
  }

  getRow(data: any): AcademyClassBookEntryModel | undefined {
    return data;
  }

  isExpired(data: any): boolean {
    const row = this.getRow(data);
    if (!row?.date) {
      return false;
    }
    return FormlyUtil.fromIsoDateString(row?.expires_at) >= new Date();
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      let mutation: any = gql`
        mutation SoftDeleteAcademyClassBookEntryById($id: bigint!) {
          result: update_academy_class_book_entry_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: "now()" }
          ) {
            __typename
          }
        }
      `;

      this.confirmationService.confirmDeleteApollo({
        name: row.unit?.subject?.name,
        mutationOptions: {
          mutation,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }
}
