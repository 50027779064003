import {
  Component,
  effect,
  EventEmitter,
  Input,
  input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { TranslocoService } from '@jsverse/transloco';
import { validateIBAN } from 'ngx-iban-validator';
import { FormlyService } from '~madrasa/services';
import {
  FormComponent,
  FormlyModule,
  FormlyUtil,
  FormSaveModel,
  FormSubmitModel
} from '~ngx-shared/formly';
import { LoadingService } from '~ngx-shared/layout';
import {
  CoreAcademicDegreesPrefix,
  CoreAcademicDegreesSuffix,
  CoreCountryEnum,
  CoreGenderEnum,
  CoreMaritalStatusEnum,
  CorePersonDataModel,
  CoreRelationshipEnum,
  FilePersonMediaTypeEnum,
  StaffStatusEnum
} from '~ngx-shared/models';
import { PersonUtil } from '~madrasa/core/utils/person.util';
import { StaffService } from '~madrasa/staff/services/staff.service';

@UntilDestroy()
@Component({
  selector: 'app-create-parent-data-form',
  standalone: true,
  imports: [FormlyModule],
  templateUrl: './create-parent-data-form.component.html',
  styleUrl: './create-parent-data-form.component.scss'
})
export class CreateParentDataFormComponent implements OnInit {
  readonly personId = input<number | undefined>(undefined);
  readonly showSaveButton = input<boolean>(true);
  readonly showCancelButton = input<boolean>(true);
  readonly showToastMessages = input<boolean>(true);

  @Input() form = new FormGroup({});
  @Input() model: CorePersonDataModel = {};
  @Output() savedEvent = new EventEmitter<FormSaveModel<CorePersonDataModel>>();
  @ViewChild('formComponent') formComponent?: FormComponent<CorePersonDataModel>;

  readOnlyModel: CorePersonDataModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_core_parent'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  constructor(
    private apollo: Apollo,
    private staffService: StaffService,
    private formlyService: FormlyService,
    private loadingService: LoadingService,
    private translocoService: TranslocoService
  ) {
    effect(
      () => {
        const personId = this.personId();
        if (personId) {
          this.loadingService.startLoading();
          this.apollo
            .query<{ result: CorePersonDataModel[] }>({
              query: gql`
                query ReadCoreCurrentPersonDataById($id: bigint!) {
                  result: core_current_person_data(where: { person_id: { _eq: $id } }, limit: 1) {
                    id
                    created_at
                    updated_at
                    address
                    email_address
                    phone_number
                    academic_degree_prefix
                    academic_degree_suffix
                    account_owner
                    bic
                    date_of_birth
                    place_of_birth
                    extra_name
                    first_name
                    gender
                    iban
                    insurance_number
                    last_name
                    marital_status
                    profession
                    person_id
                    zmr_number

                    profile_picture: person_media(
                      limit: 1
                      order_by: { created_at: desc }
                      where: { person_media_type: { _eq: "${FilePersonMediaTypeEnum.PROFILE_PICTURE}" } }
                    ) {
                      id
                    }

                    side_a_person_relationships {
                      id
                      relationship
                      person_in_relationship_id
                      person_in_relationship_current_person_data {
                        person_id
                        academic_degree_prefix
                        academic_degree_suffix
                        first_name
                        last_name
                      }
                    }
                    side_b_person_relationships {
                      id
                      relationship
                      person_id
                      person_current_person_data {
                        person_id
                        academic_degree_prefix
                        academic_degree_suffix
                        first_name
                        last_name
                      }
                    }

                    organisation_persons_active {
                      id
                      organisation_id
                    }

                    latest_parent {
                      status
                    }

                    school_parents_active {
                      id
                      school {
                        id
                        organisation_id
                      }
                    }
                  }
                }
              `,
              variables: {
                id: personId
              }
            })
            .pipe(map(queryResult => queryResult.data.result?.[0]))
            .subscribe(result => {
              if (result) {
                this.readOnlyModel = result;
                let model = cloneDeep(result);

                if (model) {
                  this.model = {
                    ...model,
                    date_of_birth: FormlyUtil.fromIsoDateString(model.date_of_birth),
                    profile_picture: model?.['profile_picture']?.map((item: any) => item.id),
                    organisations: model.organisation_persons_active?.map(
                      item => item.organisation_id
                    ),
                    relationships: PersonUtil.convertRelationshipsFromQueryResult(
                      model,
                      this.translocoService
                    ),
                    schools: model.school_parents_active?.map(
                      schoolParent => schoolParent.school?.id
                    )
                  };
                }
              }

              this.loadFields();

              this.loadingService.stopLoading();
            });
        } else {
          this.loadFields();
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    this.submit = (formSaveModel: FormSaveModel) => {
      return this.staffService.parentSubmit(formSaveModel, this.model, this.readOnlyModel);
    };
  }

  loadFields(): void {
    this.fields = [
      FormlyUtil.createTabbed(
        [
          FormlyUtil.createTab('general_information', [
            {
              key: 'id'
            },
            {
              key: 'person_id'
            },
            this.formlyService.createOrganisationsFieldConfig({ required: true }),
            FormlyUtil.createRow([
              FormlyUtil.createMultiSelectField('academic_degree_prefix', {
                props: {
                  options: Object.values(CoreAcademicDegreesPrefix).map(value => ({
                    label: 'academic_degree.' + value,
                    value: value
                  }))
                }
              }),
              FormlyUtil.createMultiSelectField('academic_degree_suffix', {
                props: {
                  options: Object.values(CoreAcademicDegreesSuffix).map(value => ({
                    label: 'academic_degree.' + value,
                    value: value
                  }))
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createTextField('first_name', {
                props: {
                  required: true
                }
              }),
              FormlyUtil.createTextField('last_name', {
                props: {
                  required: true
                }
              }),
              FormlyUtil.createTextField('extra_name')
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createDatePickerField('date_of_birth', {
                props: {
                  required: true
                }
              }),
              FormlyUtil.createTextField('place_of_birth')
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createSelectField('citizenship', {
                defaultValue: 'AT',
                props: {
                  required: true,
                  options: Object.keys(CoreCountryEnum).map(key => ({
                    label: 'country_iso.' + key,
                    value: key
                  }))
                }
              }),
              FormlyUtil.createTextField('profession')
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createSelectField('gender', {
                defaultValue: CoreGenderEnum.MALE,
                props: {
                  required: true,
                  options: Object.values(CoreGenderEnum).map(value => ({
                    label: 'gender.' + value,
                    value: value
                  }))
                }
              }),
              FormlyUtil.createSelectField('marital_status', {
                defaultValue: CoreMaritalStatusEnum.SINGLE,
                props: {
                  required: true,
                  options: Object.values(CoreMaritalStatusEnum).map(value => ({
                    label: 'marital_status.' + value,
                    value: value
                  }))
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createInputMaskField('insurance_number', {
                props: {
                  type: 'number',
                  mask: '9999 999999'
                }
              }),
              FormlyUtil.createTextField('zmr_number', {
                props: {
                  type: 'number'
                }
              })
            ]),
            this.formlyService.createFileUploadFieldConfig('profile_picture', {
              props: {
                required: false,
                namespace: 'person_media',
                accept: 'image/*',
                maxFiles: 1
              }
            }),
            this.formlyService.createPersonSelectFieldConfig({
              key: 'person_in_relationship_select',
              label: 'relationships',
              excludePersonIds: this.model?.person_id ? [this.model.person_id] : undefined,
              click: (event, field) => {
                const value = field.formControl?.value;
                if (
                  value &&
                  (!this.model?.['relationships'] ||
                    this.model['relationships']?.findIndex(
                      (item: any) =>
                        item.person_in_relationship_id === value.value?.value?.person_id ||
                        item.person_id === value.value?.value?.person_id
                    ) === -1)
                ) {
                  this.model = {
                    ...this.model,
                    person_in_relationship_select: undefined,
                    relationships: [
                      ...(this.model['relationships'] || []),
                      {
                        person_in_relationship_id: value.value?.value?.person_id,
                        full_name: `${value.label}, Id: ${value.value?.value?.person_id}`,
                        relationship: undefined
                      }
                    ]
                  };
                }
              }
            }),
            FormlyUtil.createRow([
              FormlyUtil.createTable(
                {
                  key: 'relationships',
                  props: {
                    showIndex: true,
                    canAdd: () => false,
                    hideLabel: true,
                    columns: [
                      {
                        key: 'full_name',
                        header: 'name'
                      },
                      {
                        key: 'relationship',
                        header: 'relationship'
                      }
                    ]
                  }
                },
                [
                  {
                    key: 'id'
                  },
                  {
                    key: 'person_in_relationship_id'
                  },
                  {
                    key: 'person_id'
                  },
                  FormlyUtil.createLabelField('full_name', {
                    props: {
                      label: 'name',
                      disableMargin: true,
                      hideLabel: true,
                      labelClass: 'font-normal'
                    }
                  }),
                  FormlyUtil.createSelectField('relationship', {
                    props: {
                      disableMargin: true,
                      hideLabel: true,
                      required: true,
                      options: Object.values(CoreRelationshipEnum).map(relationship => ({
                        label: 'relationship.' + relationship,
                        value: relationship
                      }))
                    }
                  })
                ]
              )
            ]),
            this.formlyService.createAddressFieldConfig(),
            this.formlyService.createPhoneNumberFieldConfig(),
            this.formlyService.createEmailAddressFieldConfig(),
            FormlyUtil.createGroup('account_infos', [
              FormlyUtil.createRow([
                FormlyUtil.createInputMaskField('iban', {
                  props: {
                    required: false,
                    mask: 'aa99 9999 9999 9999 9999',
                    characterPattern: '[A-Z]',
                    description: 'iban_description'
                  },
                  validators: {
                    iban: {
                      expression: (control: any) => !validateIBAN(control.value)?.ibanInvalid,
                      message: (error: any, field: any) =>
                        this.translocoService.selectTranslate('invalid_iban')
                    }
                  }
                }),
                FormlyUtil.createTextField('bic', {
                  props: {
                    required: false
                  }
                })
              ]),
              FormlyUtil.createRow([
                FormlyUtil.createTextField('account_owner', {
                  props: {
                    required: false
                  }
                })
              ])
            ])
          ]),
          FormlyUtil.createTab('parent_information', [
            this.formlyService.createSchoolsFieldConfig(),
            {
              key: 'latest_parent',
              fieldGroup: [
                FormlyUtil.createRow([
                  FormlyUtil.createSelectField('status', {
                    defaultValue: 'active',
                    props: {
                      required: true,
                      options: Object.values(StaffStatusEnum)
                        .filter(item => item != StaffStatusEnum.WAITING_LIST)
                        .map(value => ({
                          label: 'status.' + value,
                          value: value
                        }))
                    }
                  })
                ])
              ]
            }
          ])
        ],
        'p'
      )
    ];
  }
}
