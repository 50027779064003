<p-table
  #table
  (onLazyLoad)="this.loadData($event)"
  *transloco="let transloco"
  [alwaysShowPaginator]="this.tableData.length > 0"
  [first]="this.offset"
  [lazy]="this.isLazy"
  [loading]="this.isLoading"
  [paginator]="this.graphQlTable().isPaginated || true"
  [rowsPerPageOptions]="[10, 25, 50]"
  [rows]="this.limit"
  [showCurrentPageReport]="
    this.tableData.length > 0 && (this.graphQlTable().showCurrentPageReport || true)
  "
  [stateStorage]="this.graphQlTable().stateStorage || 'session'"
  [totalRecords]="this.totalRecords"
  [value]="this.tableData"
  currentPageReportTemplate="{{ transloco('components.graph_ql_table.table_report_template') }}"
  selectionMode="single"
  sortMode="multiple"
  styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm">
  <ng-template pTemplate="caption">
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-2">
        <ng-container [ngTemplateOutlet]="this.captionTemplate"></ng-container>
        @if (this.showColumnFilter) {
          <div class="flex">
            <button
              (click)="overlayPanel?.toggle($event)"
              [pTooltip]="transloco('filter')"
              class="refresh-button"
              pButton
              tooltipPosition="top"
              type="button">
              <i class="pi pi-filter"></i>
            </button>
            <p-overlayPanel #overlayPanel appendTo="body">
              <ng-template pTemplate>
                <div
                  (cdkDropListDropped)="this.dropField($event)"
                  [cdkDropListData]="this.graphQlTable().columns"
                  cdkDropList
                  class="flex h-fit max-h-96 flex-col gap-2 overflow-y-auto p-2">
                  @for (
                    column of this.graphQlTable().columns;
                    track column;
                    let index = $index, last = $last
                  ) {
                    <div
                      [ngClass]="{
                        hidden:
                          column.isNotSelectable ||
                          (column.permissionsOnly
                            ? !this.authorizationService.can(column.permissionsOnly)
                            : false) ||
                          (column.permissionsExcept
                            ? !this.authorizationService.cannot(column.permissionsExcept)
                            : false)
                      }"
                      cdkDrag
                      cdkDragLockAxis="y"
                      cdkDragPreviewClass="hidden"
                      class="grid grid-cols-[auto_minmax(0,_1fr)] gap-4">
                      <div *cdkDragPlaceholder class="drag-placeholder col-span-2 !h-5 !min-h-fit">
                        <div class="flex items-center justify-center">
                          {{ transloco(column.label || column.path || '------') }}
                        </div>
                      </div>
                      <div class="flex items-center gap-2">
                        <i cdkDragHandle class="pi pi-ellipsis-v cursor-move"></i>

                        <p-checkbox
                          (onChange)="this.checkBoxChanged($event, index)"
                          [binary]="true"
                          [ngModel]="!column.hidden"></p-checkbox>
                        <span>{{ transloco(column.label || column.path || '------') }}</span>
                      </div>
                    </div>
                    @if (!last && !column.isNotSelectable) {
                      <hr class="m-0" />
                    }
                  }
                </div>
              </ng-template>
            </p-overlayPanel>
          </div>
        }
        @if (this.showExport) {
          <button
            (click)="this.printTable()"
            [disabled]="this.tableData.length === 0"
            [loading]="this.isPrinting"
            [pTooltip]="transloco('export')"
            class="p-button-info"
            icon="pi pi-file-export"
            pButton
            style="padding: 0.5rem 0"
            tooltipPosition="top"
            type="button"></button>
        }
      </div>
      <div>
        <button (click)="this.updateTable()" class="refresh-button" pButton type="button">
          <i class="pi pi-refresh"></i>
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    @if (this.headerTemplate) {
      <ng-container [ngTemplateOutlet]="this.headerTemplate"></ng-container>
    }

    @if (!this.headerTemplate) {
      <tr>
        @for (column of this.graphQlTable().columns; track column) {
          <ng-template
            [ngxPermissionsExcept]="column.permissionsExcept || ''"
            [ngxPermissionsOnly]="column.permissionsOnly || ''">
            @if (!column.hidden && !column.isNotSelectable) {
              <th class="{{ column.classHeader }}" style="{{ column.styleHeader }}">
                <ng-container [ngTemplateOutlet]="headerContent"></ng-container>
              </th>
            }
          </ng-template>
          <ng-template #headerContent>
            @if (column.path && this.headerTemplates?.has(column.path)) {
              <ng-container
                [ngTemplateOutletContext]="{ $implicit: column }"
                [ngTemplateOutlet]="this.headerTemplates?.get(column.path)!">
              </ng-container>
            } @else {
              {{ transloco(column.label || column.path || '------') }}
            }
          </ng-template>
        }
        @if (this.actionsTemplate) {
          <th class="text-center">{{ transloco('actions') }}</th>
        }
      </tr>
    }
  </ng-template>

  <ng-template let-data let-rowIndex="rowIndex" pTemplate="body">
    @if (this.bodyTemplate) {
      <ng-container
        [ngTemplateOutletContext]="{ $implicit: data, rowIndex: rowIndex }"
        [ngTemplateOutlet]="this.bodyTemplate">
      </ng-container>
    }

    @if (!this.bodyTemplate) {
      <tr>
        @for (column of this.graphQlTable().columns; track column; let index = $index) {
          <ng-template
            [ngxPermissionsExcept]="column.permissionsExcept || ''"
            [ngxPermissionsOnly]="column.permissionsOnly || ''">
            @if (!column.hidden && !column.isNotSelectable) {
              <td
                [ngClass]="column.ngClassBody ? column.ngClassBody(data, column) : undefined"
                [ngStyle]="column.ngStyleBody ? column.ngStyleBody(data, column) : undefined"
                class="{{ column.classBody }}"
                style="{{ column.styleBody }}">
                @if (column.path && this.cellTemplates?.has(column.path)) {
                  <ng-container
                    [ngTemplateOutletContext]="{
                      $implicit: data,
                      rowIndex: rowIndex,
                      column: column
                    }"
                    [ngTemplateOutlet]="this.cellTemplates?.get(column.path)!">
                  </ng-container>
                } @else {
                  <ng-container
                    [ngTemplateOutletContext]="{
                      $implicit: column.translate
                        ? transloco(this.getValue(data, column))
                        : this.getValue(data, column)
                    }"
                    [ngTemplateOutlet]="cellTemplate">
                  </ng-container>
                  <ng-template #cellTemplate let-value>
                    <span
                      >{{ column.append }}
                      @switch (this.getType(column.type, data)) {
                        <!-- DO NOT FORGET IN CODE BEHIND -->
                        @case ('number') {
                          <span>{{ value | number: column.typeOptions }}</span>
                        }
                        @case ('datetime') {
                          <span>{{ value | libDateTime }}</span>
                        }
                        @case ('date') {
                          <span>{{ value | libDate }}</span>
                        }
                        @case ('time') {
                          <span>{{ value | libTime }}</span>
                        }
                        @case ('currency') {
                          @if (!!value) {
                            <span>{{ value / 100 | currency }}</span>
                          }
                        }
                        @case ('boolean') {
                          @if (value) {
                            <p-tag severity="success" [value]="transloco('true')"></p-tag>
                          } @else {
                            <p-tag severity="secondary" [value]="transloco('false')"></p-tag>
                          }
                        }
                        @case ('filesize') {
                          <span>{{ value | filesize: { separator: ',' } }}</span>
                        }
                        @default {
                          <span>{{ value }}</span>
                        }
                      }
                      {{ column.prepend }}</span
                    >
                  </ng-template>
                }
              </td>
            }
          </ng-template>
        }
        @if (this.actionsTemplate) {
          <td>
            <ng-container
              [ngTemplateOutletContext]="{ $implicit: data, rowIndex: rowIndex }"
              [ngTemplateOutlet]="this.actionsTemplate">
            </ng-container>
          </td>
        }
      </tr>
    }
  </ng-template>

  <ng-template pTemplate="emptymessage">
    @if (this.emptyTemplate) {
      <ng-container [ngTemplateOutlet]="this.emptyTemplate"> </ng-container>
    } @else {
      <tr>
        <td colspan="100%">
          <div class="flex flex-col items-center justify-center">
            <span>
              {{ 'no_data' | transloco }}
            </span>
          </div>
        </td>
      </tr>
    }
  </ng-template>

  @if (this.footerTemplate) {
    <ng-template pTemplate="summary">
      <ng-container [ngTemplateOutlet]="this.footerTemplate"></ng-container>
    </ng-template>
  }
</p-table>
