<lib-layout-base-page>
  <div class="m-base">
    <lib-formly-form
      [fields]="this.fields"
      [form]="this.form"
      [model]="this.model"
      [navigateBackOnCancel]="true"
      [navigateBackOnSave]="true"
      [options]="this.options"
      [submit]="this.submit">
    </lib-formly-form>
  </div>
</lib-layout-base-page>
