<div class="w-full overflow-auto">
  <table *transloco="let transloco" class="w-full">
    @for (leftField of this.fieldLeft(); track leftField; let index = $index) {
      <tr class="">
        <ng-container
          [ngTemplateOutletContext]="{ $implicit: leftField }"
          [ngTemplateOutlet]="fieldTemplate">
        </ng-container>

        @let rightField =
          index + 1 <= this.fieldRight().length ? this.fieldRight()[index] : undefined;
        @if (!!rightField) {
          <ng-container
            [ngTemplateOutletContext]="{ $implicit: rightField }"
            [ngTemplateOutlet]="fieldTemplate">
          </ng-container>
        } @else {
          <td></td>
          <td></td>
        }
      </tr>
    }
    <ng-template #fieldTemplate let-field>
      <td class="flex flex-nowrap items-center pl-4">
        <span class="w-2/12 text-nowrap">{{ transloco(field.label) }}:</span>
      </td>
      <td class="w-4/12">
        @if (this.keyTemplates?.has(field.key!)) {
          <ng-container
            [ngTemplateOutletContext]="{ $implicit: field.value }"
            [ngTemplateOutlet]="this.keyTemplates!.get(field.key!)!">
          </ng-container>
        } @else {
          <ng-container
            [ngTemplateOutletContext]="{ $implicit: field }"
            [ngTemplateOutlet]="renderField">
          </ng-container>
        }
      </td>
    </ng-template>

    <ng-template #renderField let-infield let-value="value">
      @let field = this.getChildField(infield, value);

      @switch (field.type) {
        @case ('date') {
          @if (field.value) {
            <span>{{ this.getValueAsString(field) | libDate }}</span>
          } @else {
            <ng-container [ngTemplateOutlet]="renderUndefined"></ng-container>
          }
        }
        @case ('datetime') {
          @if (field.value) {
            <span>{{ this.getValueAsString(field) | libDateTime }}</span>
          } @else {
            <ng-container [ngTemplateOutlet]="renderUndefined"></ng-container>
          }
        }
        @case ('currency') {
          @if (field.value) {
            <span>{{ this.getValueAsNumber(field) / 100 | currency }}</span>
          } @else {
            <ng-container [ngTemplateOutlet]="renderUndefined"></ng-container>
          }
        }
        @case ('file') {
          <!--              @if (field.value?.id) {-->
          <!--                <div-->
          <!--                  (click)="this.openMedia(field)"-->
          <!--                  [ngClass]="{-->
          <!--                    'cursor-wait': this.mediaService.isDownloading-->
          <!--                  }"-->
          <!--                  class="cursor-pointer hover:text-primary hover:underline">-->
          <!--                  <span> {{ field.value?.name }}</span>-->
          <!--                  <span> {{ field.value?.size | filesize: { separator: ',' } }}</span>-->
          <!--                </div>-->
          <!--              }-->
        }
        @case ('filesize') {
          <span>{{ this.getValueAsNumber(field) | filesize: { separator: ',' } }}</span>
        }
        @case ('link') {
          <a
            [label]="field.value"
            [routerLink]="field.routerLink"
            class="p-button-link"
            pButton
            type="button"></a>
        }
        @case ('person_link') {
          @if (field.value) {
            <app-person-detail-link [person]="field.value"></app-person-detail-link>
          } @else {
            <ng-container [ngTemplateOutlet]="renderUndefined"></ng-container>
          }
        }
        @case ('array') {
          @if (field.value?.length > 0) {
            @for (item of field.value; track item) {
              <div>
                @if (field.childField) {
                  <ng-container
                    [ngTemplateOutlet]="renderField"
                    [ngTemplateOutletContext]="{
                      $implicit: field.childField,
                      value: item
                    }"></ng-container>
                } @else {
                  <ng-container
                    [ngTemplateOutlet]="renderOutput"
                    [ngTemplateOutletContext]="{
                      $implicit: field,
                      item
                    }"></ng-container>
                }
              </div>
            }
          } @else {
            <ng-container [ngTemplateOutlet]="renderUndefined"></ng-container>
          }
        }
        @case ('boolean') {
          <p-tag
            [severity]="field.value ? 'success' : 'danger'"
            [value]="field.value ? transloco('yes') : transloco('no')"></p-tag>
        }
        @default {
          <ng-container
            [ngTemplateOutlet]="renderOutput"
            [ngTemplateOutletContext]="{
              $implicit: field
            }"></ng-container>
        }
      }

      <ng-template #renderOutput let-field let-item="item">
        @let value = this.getValueAsString(field, item);
        @if (value) {
          <span>{{ field.translate ? transloco(value) : value }}</span>
        } @else {
          <ng-container [ngTemplateOutlet]="renderUndefined"></ng-container>
        }
      </ng-template>

      <ng-template #renderUndefined>
        <span>------</span>
      </ng-template>
    </ng-template>
  </table>
</div>
