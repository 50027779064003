import { Component, Type } from '@angular/core';
import { FieldArrayType, FieldArrayTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface TableProps extends FieldWrapperProps {
  columns?: { key: string; header?: string; class?: string }[];
  showIndex?: boolean;

  addText?: string;
  addTextValue?: string;

  initialCount?: number;
  maxCount?: number;
  minCount?: number;

  canAdd?: (field: FormlyFieldConfig<TableProps>) => boolean;
  canRemove?: (field: FormlyFieldConfig<TableProps>, index: number) => boolean;
}

export interface TableFieldConfig extends FormlyFieldConfig<TableProps> {
  type?: 'table' | Type<TableTypeComponent>;
}

@Component({
  selector: 'lib-formly-table-type',
  templateUrl: './table-type.component.html',
  styleUrl: './table-type.component.css'
})
export class TableTypeComponent extends FieldArrayType<FieldArrayTypeConfig<TableProps>> {
  getField(field: FormlyFieldConfig, column: any): FormlyFieldConfig | undefined {
    return field.fieldGroup?.find(f => f.key === column.key);
  }
}
