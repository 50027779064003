import { DynamicDialogConfig } from 'primeng/dynamicdialog';

export class DialogUtil {
  static readonly BASE_DIALOG_CONFIG: DynamicDialogConfig = {
    closable: true,
    draggable: true,
    closeOnEscape: false,
    width: '50vw',
    modal: true,
    breakpoints: {
      '960px': '75vw',
      '640px': '90vw'
    }
  };
}
