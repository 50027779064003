import { AfterViewInit, Directive, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TabView } from 'primeng/tabview';

@UntilDestroy()
@Directive({
  standalone: true,
  selector: 'p-tabView[libTabViewQuery]'
})
export class TabViewQueryDirective implements OnInit, AfterViewInit {
  private _sharedUiTabViewQuery: string = 't';

  @Input()
  set sharedUiTabViewQuery(libTabViewQuery: string) {
    this._sharedUiTabViewQuery = libTabViewQuery;
  }

  get sharedUiTabViewQuery() {
    return this._sharedUiTabViewQuery;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tabViewComponent: TabView
  ) {}

  ngAfterViewInit(): void {
    this.tabViewComponent?.tabPanels?.changes?.pipe(untilDestroyed(this)).subscribe(() => {
      this.changeIndex(this.route.snapshot.queryParams[this.sharedUiTabViewQuery]);
    });
  }

  ngOnInit(): void {
    this.tabViewComponent.activeIndexChange.pipe(untilDestroyed(this)).subscribe(index => {
      void this.router.navigate([], {
        queryParams: { [this.sharedUiTabViewQuery]: index },
        queryParamsHandling: 'merge',
        replaceUrl: true
      });
    });
    this.route.params.pipe(untilDestroyed(this)).subscribe(() => {
      this.changeIndex(this.route.snapshot.queryParams[this.sharedUiTabViewQuery]);
    });
  }

  changeIndex(tabIndex?: number) {
    if (tabIndex && tabIndex >= 0) {
      setTimeout(() => {
        this.tabViewComponent.activeIndex = tabIndex;
        this.tabViewComponent.cd.markForCheck();
      }, 0);
    }
  }
}
