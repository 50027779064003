@if (this.showLink()) {
  @if (this.person()?.person_id) {
    <a
      class="block hover:text-[color:var(--primary-color)] hover:underline"
      [routerLink]="['/', 'staff', 'people', 'detail', this.person()?.person_id]"
      >{{ this.getFullName(this.person()) }}, Id:{{ this.person()?.person_id }}</a
    >
  }
} @else {
  <span>{{ this.getFullName(this.person()) }}, Id:{{ this.person()?.person_id }}</span>
}
