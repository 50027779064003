import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SelectOption {
  label: string;
  disabled?: boolean;
  value?: any;
  group?: SelectOption[];
}

type TransformOptionProps = {
  labelProp: (option: any) => string;
  valueProp: (option: any) => any;
  disabledProp: (option: any) => boolean;
  groupProp: (option: any) => string;
};

@Pipe({ name: 'selectOptions', standalone: true })
export class SelectOptionsPipe implements PipeTransform, OnDestroy {
  private _subscription: Subscription | undefined;
  private _options: BehaviorSubject<any[]> | undefined;

  transform(options: any, props?: any): Observable<any[]> {
    if (!(options instanceof Observable)) {
      options = this.observableOf(options);
    } else {
      this.dispose();
    }

    return (options as Observable<any>).pipe(map(value => this.transformOptions(value, props)));
  }

  ngOnDestroy(): void {
    this.dispose();
  }

  private transformOptions(options: any[], props?: any): SelectOption[] {
    const to = this.transformSelectProps(props);

    const opts: SelectOption[] = [];
    const groups: { [id: string]: number } = {};

    options?.forEach(option => {
      const o = this.transformOption(option, to);
      if (o.group) {
        const id = groups[o.label];
        if (id === undefined) {
          groups[o.label] = opts.push(o) - 1;
        } else {
          o.group.forEach(o => opts[id].group?.push(o));
        }
      } else {
        opts.push(o);
      }
    });

    return opts;
  }

  private transformOption(option: any, props: TransformOptionProps): SelectOption {
    const group = props.groupProp(option);
    if (Array.isArray(group)) {
      return {
        label: props.labelProp(option),
        group: group.map(opt => this.transformOption(opt, props))
      };
    }

    option = {
      label: props.labelProp(option),
      value: props.valueProp(option),
      disabled: !!props.disabledProp(option)
    };

    if (group) {
      return { label: group, group: [option] };
    }

    return option;
  }

  private transformSelectProps(props?: any): TransformOptionProps {
    const selectPropFn = (prop: any) => (typeof prop === 'function' ? prop : (o: any) => o[prop]);

    return {
      groupProp: selectPropFn(props?.column?.optionsGroup || 'group'),
      labelProp: selectPropFn(props?.column?.optionLabel || 'label'),
      valueProp: selectPropFn(props?.column?.optionValue || 'value'),
      disabledProp: selectPropFn(props?.column?.isNotFilterable || 'disabled')
    };
  }

  private dispose() {
    if (this._options) {
      this._options.complete();
      this._options = undefined;
    }

    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = undefined;
    }
  }

  private observableOf(options: any) {
    this.dispose();

    this._options = new BehaviorSubject(options);
    return this._options.asObservable();
  }
}
