import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { catchError, delay, map, Observable, of } from 'rxjs';
import { Role } from '~ngx-shared/authentication';
import { environment } from '~ngx-shared/environment';
import { ToastService } from '~ngx-shared/layout';

export type UserRecord = {
  person_id: number;
  email: string;
};

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AccessService {
  private readonly externalServiceUrl = environment.externalServiceUrl;

  constructor(
    private httpClient: HttpClient,
    private toastService: ToastService
  ) {}

  createAccess(role: Role, record: UserRecord): Observable<boolean> {
    if (role && record) {
      return this._observablePipe(
        this.httpClient.post(`${this.externalServiceUrl}/user/${role}`, record)
      );
    }
    return of(false);
  }

  revokeAccess(personId: number | undefined, role: Role): Observable<boolean> {
    if (personId && role) {
      return this._createPatch(`${personId}/revoke/${role}`, {});
    }
    return of(false);
  }

  toggleAccess(personId: number | undefined): Observable<boolean> {
    if (personId) {
      return this._createPatch(`${personId}/toggle`, {});
    }
    return of(false);
  }

  resendAccess(personId: number | undefined): Observable<boolean> {
    if (personId) {
      return this._createPatch(`${personId}/reset-password`, {});
    }
    return of(false);
  }

  changeEmailOfAccess(
    personId: number | undefined,
    newEmail: string | undefined
  ): Observable<boolean> {
    if (personId && newEmail) {
      return this._createPatch(`${personId}/update-email`, newEmail);
    }
    return of(false);
  }

  changeTenantIdOfAccess(tenantId: number | undefined): Observable<boolean> {
    if (tenantId) {
      return this._createPatch(`update-tenant-id`, tenantId);
    }
    return of(false);
  }

  private _createPatch(url: string, data: any): Observable<boolean> {
    return this._observablePipe(
      this.httpClient.patch(`${this.externalServiceUrl}/user/${url}`, data)
    );
  }

  private _observablePipe(
    observable: Observable<any>,
    withToast: boolean = true
  ): Observable<boolean> {
    return observable.pipe(
      delay(500),
      map(() => {
        if (withToast) {
          this.toastService.toastSuccessSave();
        }
        return true;
      }),
      catchError(err => {
        if (withToast) {
          this.toastService.toastErrorSave();
        }
        console.error(err);
        return of(false);
      })
    );
  }
}
