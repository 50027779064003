import { Component, ElementRef } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { LayoutMenuComponent } from '../menu/layout-menu.component';

@Component({
  selector: 'lib-layout-sidebar',
  templateUrl: './layout-sidebar.component.html',
  standalone: true,
  imports: [LayoutMenuComponent]
})
export class LayoutSidebarComponent {
  constructor(
    public layoutService: LayoutService,
    public el: ElementRef
  ) {}
}
