import { Role } from '~ngx-shared/authentication';
import { Aggregate } from '~ngx-shared/models';
import { DefaultProperties } from '../default.model';
import { AcademyUnitTeacherModel } from './unit-teacher.model';
import { AcademySubjectModel } from './subject.model';
import { AcademyCourseModel } from './course.model';
import { AcademyClassBookEntryModel } from './class-book-entry.model';
import { AcademySchoolModel } from './school.model';

export interface AcademyUnitModel extends DefaultProperties {
  // Columns
  id?: number;
  school_id?: number;
  course_id?: number;
  subject_id?: number;
  weekday?: number;
  starts_at?: Date | string;
  ends_at?: Date | string;
  color?: string;

  // Relations
  school?: AcademySchoolModel;
  course?: AcademyCourseModel;
  subject?: AcademySubjectModel;

  class_book_entries?: AcademyClassBookEntryModel[];
  unit_teachers?: AcademyUnitTeacherModel[];
  unit_teachers_aggregate?: Aggregate;
}

export class AcademyUnitPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR, Role.TEACHER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AcademyUnitPermission.DELETE, Role.MANAGER];
}
