import { Component, computed } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap, tap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { AcademySubjectModel } from '~ngx-shared/models';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { TabViewQueryDirective } from '~ngx-shared/directives';

@UntilDestroy()
@Component({
  selector: 'app-subject-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    DetailComponent,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective
  ],
  templateUrl: './subject-detail-page.component.html',
  styleUrl: './subject-detail-page.component.scss'
})
export class SubjectDetailPageComponent {
  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{ result: AcademySubjectModel }>({
              query: gql`
                query ReadAcademySubjectById($id: bigint!) {
                  result: academy_subject_by_pk(id: $id) {
                    id
                    created_at
                    updated_at
                    organisation {
                      id
                      name
                    }
                    name
                    shortcut
                    description
                    is_active
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result));
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );

  readonly fields = computed<DetailField[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    return [
      {
        label: 'id',
        value: item.id
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'organisation',
        value: item.organisation?.name
      },
      {
        label: 'name',
        value: item.name
      },
      {
        label: 'shortcut',
        value: item.shortcut
      },
      {
        label: 'description',
        value: item.description
      },
      {
        label: 'is_active',
        type: 'boolean',
        value: item.is_active
      }
    ];
  });

  constructor(
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService
  ) {}
}
