import { Component, effect, input, OnInit, ViewChild } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { RouterLink } from '@angular/router';
import { gql } from 'apollo-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { TemplateDirective } from '~ngx-shared/directives';
import { ConfirmationService } from '~ngx-shared/layout';
import { AcademyOrganisationModel, AcademyOrganisationPermission } from '~ngx-shared/models';

@Component({
  selector: 'app-organisation-list',
  standalone: true,
  imports: [
    TranslocoDirective,
    GraphQlAdvancedTableComponent,
    ButtonDirective,
    TooltipModule,
    RouterLink,
    TemplateDirective,
    NgxPermissionsModule,
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent
  ],
  templateUrl: './organisation-list.component.html',
  styleUrl: './organisation-list.component.scss'
})
export class OrganisationListComponent implements OnInit {
  showFilter = input<boolean>(false);
  showSort = input<boolean>(false);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);
  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();

  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  graphQlTable: GraphQlTableModel;

  constructor(private confirmationService: ConfirmationService) {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ updated_at: 'desc' }];
        }

        return options;
      };
    });
  }

  ngOnInit() {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        type: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        sort: { isSortable: true },
        filter: { type: 'number' }
      },
      {
        path: 'name',
        filter: { type: 'string' },
        sort: { isSortable: true }
      },
      {
        label: 'course_price.single_price',
        path: 'organisation_course_price.single_price',
        type: 'currency',
        filter: { type: 'number' },
        sort: { isSortable: true }
      },
      {
        label: 'course_price.dual_price',
        path: 'organisation_course_price.dual_price',
        type: 'currency',
        filter: { type: 'number' },
        sort: { isSortable: true }
      },
      {
        label: 'course_price.three_or_more_price',
        path: 'organisation_course_price.three_or_more_price',
        type: 'currency',
        filter: { type: 'number' },
        sort: { isSortable: true }
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        label: 'school_count',
        path: 'schools_aggregate.count',
        query: `schools_aggregate { aggregate { count } }`,
        filter: { isNotFilterable: true },
        sort: { isSortable: true },
        patchResult: (result: any) => result?.schools_aggregate?.aggregate?.count
      },
      {
        label: 'subject_count',
        path: 'subjects_aggregate.count',
        query: `subjects_aggregate { aggregate { count } }`,
        filter: { isNotFilterable: true },
        sort: { isSortable: true },
        patchResult: (result: any) => result?.subjects_aggregate?.aggregate?.count
      },
      {
        label: 'course_type_count',
        path: 'course_types_aggregate.count',
        query: `course_types_aggregate { aggregate { count } }`,
        filter: { isNotFilterable: true },
        sort: { isSortable: true },
        patchResult: (result: any) => result?.course_types_aggregate?.aggregate?.count
      }
    ];

    this.graphQlTable = {
      table: 'academy_organisation',
      isPaginated: true,

      showCurrentPageReport: true,
      columns: columns
    };
  }

  getRow(data: any): AcademyOrganisationModel | undefined {
    return data;
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      let mutation: any = gql`
        mutation SoftDeleteAcademyOrganisationById($id: bigint!) {
          result: update_academy_organisation_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: "now()" }
          ) {
            __typename
          }
        }
      `;

      this.confirmationService.confirmDeleteApollo({
        name: row.name,
        mutationOptions: {
          mutation,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }

  protected readonly AcademyOrganisationPermission = AcademyOrganisationPermission;
}
