import { Component } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslocoDirective } from '@jsverse/transloco';
import { Router, RouterLink } from '@angular/router';
import { AcademyRoomPermission } from '~ngx-shared/models';
import { RoomListComponent } from '~madrasa/academy/components/room-list/room-list.component';
import { BasePageComponent } from '~ngx-shared/layout';

@Component({
  selector: 'app-room-list-page',
  standalone: true,
  imports: [
    BasePageComponent,
    ButtonDirective,
    NgxPermissionsModule,
    RoomListComponent,
    TranslocoDirective,
    RouterLink
  ],
  templateUrl: './room-list-page.component.html',
  styleUrl: './room-list-page.component.scss'
})
export class RoomListPageComponent {
  protected readonly AcademyRoomPermission = AcademyRoomPermission;
  constructor(public router: Router) {}
}
