import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import { ObjectUtils } from 'primeng/utils';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface MultiSelectProps extends FieldWrapperProps, FormlyFieldSelectProps {
  virtualScroll?: boolean;
  virtualScrollSize?: number;

  groupLabelProp?: string;
  groupChildrenProp?: string;

  optionLabel?: string;
  optionValue?: string;

  scrollHeight?: string;
}

export interface MultiSelectFieldConfig extends FormlyFieldConfig<MultiSelectProps> {
  type?: 'multi-select' | Type<MultiSelectTypeComponent>;
}

@Component({
  selector: 'lib-formly-multi-select-type',
  templateUrl: './multi-select-type.component.html',
  styleUrl: './multi-select-type.component.css'
})
export class MultiSelectTypeComponent extends FieldType<FieldTypeConfig<MultiSelectProps>> {
  searchLabelByValue(val: any): string {
    let label = null;
    if (Array.isArray(this.props.options)) {
      for (let i = 0; i < this.props.options?.length; i++) {
        let option = this.props.options[i];
        let optionValue = ObjectUtils.resolveFieldData(option, 'value');
        if ((val == null && optionValue == null) || ObjectUtils.equals(val, optionValue)) {
          label = ObjectUtils.resolveFieldData(option, 'label');
          break;
        }
      }
    }
    return label;
  }
}
