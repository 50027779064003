import { Component } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { Router } from '@angular/router';
import { PersonListComponent } from '~madrasa/staff/components/person-list/person-list.component';
import { BasePageComponent } from '~ngx-shared/layout';
import { AuthorizationService } from '~ngx-shared/authentication';
import { CorePersonPermission } from '~ngx-shared/models';
import { TenantService } from '~madrasa/services';

@Component({
  selector: 'app-person-list-page',
  standalone: true,
  imports: [BasePageComponent, TranslocoDirective, PersonListComponent],
  templateUrl: './person-list-page.component.html',
  styleUrl: './person-list-page.component.scss'
})
export class PersonListPageComponent {
  protected readonly CorePersonPermission = CorePersonPermission;

  constructor(
    public router: Router,
    public authorizationService: AuthorizationService,
    public tenantService: TenantService
  ) {}
}
