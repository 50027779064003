<div
  *transloco="let transloco"
  [ngClass]="{
    'mb-5': !this.props.disableMargin
  }"
  class="flex h-full flex-col gap-2">
  @if (this.props.label && !this.props.hideLabel) {
    <label [for]="id" class="font-bold">
      <span class="text-lg">{{ transloco(props.label) }}</span>
      @if (this.props.required && !this.props.hideRequiredMarker) {
        <span aria-hidden="true" class="text-red-600">*</span>
      }
    </label>
  }
  <ng-container #fieldComponent></ng-container>

  @if (this.showError) {
    <small class="p-error">
      <formly-validation-message
        class="ui-message-text"
        [field]="this.field"></formly-validation-message>
    </small>
  }

  @if (this.props.description) {
    <small>
      {{ transloco(this.props.description) }}
    </small>
  }
</div>
