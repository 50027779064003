import { Role } from '~ngx-shared/authentication';
import { DefaultProperties } from '../default.model';

export interface AcademyCoursePriceModel extends DefaultProperties {
  // Columns
  id?: number;
  single_price?: number;
  dual_price?: number;
  three_or_more_price?: number;
}

export class AcademyCoursePricePermission {
  static readonly CREATE = [];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly UPDATE = [];
  static readonly DELETE = [];
  static readonly SOFT_DELETE = [];
}
